import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    Button
} from "reactstrap";
import { H3, H4 } from "../../AbstractElements";
import fetchWrapper from "../api/fetchWrapper";
import { API_URL } from "../Config/Config";
import { toast, ToastContainer } from "react-toastify";
import ButtonLoader from "./ButtonLoader";
import { useNavigate } from 'react-router';
import { X } from "react-feather";

const ResetConfirmationModal = ({ value, setOpenModal, data }) => {
    const [open, setOpen] = useState(value)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }

    const handleStatus = async () => {
        setLoading(true)
        console.log(data)
        const email = window.location.pathname.split('/')[2]
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: data
        };
        await fetchWrapper(`${API_URL}/resetPassword`, requestOptions)
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Password Changed Successfully!');
                    setLoading(false)
                    setOpen(false)
                    setOpenModal(false)
                    setTimeout(() => {
                        navigate(`${process.env.PUBLIC_URL}/login`);
                    }, 2000)

                }

            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
                toast.error(error.json.message);
            })

    }

    return (
        <Fragment>
            <ToastContainer />
            <Modal isOpen={open} size='md' centered={true}>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Password Confirmation</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <p >Are you sure you want to change the Password?</p>
                    <div className="d-flex">
                        {
                            loading ? <ButtonLoader loaderText={'Submitting'} color='primary' /> : <Button color="primary" onClick={handleStatus}>Yes</Button>
                        }
                        <Button color="secondary"  className="ml-1" onClick={onClose}>No</Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ResetConfirmationModal