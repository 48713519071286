import React, { Fragment, useState } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { logoutAction } from "../../api/helper";
import { useNavigate } from "react-router";
import { Eye, EyeOff } from "react-feather";

const ChangePassword = ({ value, setOpenModal }) => {
    const [open, setOpen] = useState(value);
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const navigate = useNavigate();
    const [toggleCurrentPassword, setToggleCurrentPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    const onSubmit = async (data) => {
        console.log(data)
        let userData = sessionStorage.getItem('auth_profile');
        userData = JSON.parse(userData);
        console.log(userData['cognito:username'])
        const options = {
            method: 'POST',
            body: {
                userName: userData['cognito:username'],
                oldPassword: data.currentPassword,
                newPassword: data.confirmPassword
            }
        }
        await fetchWrapper(`${API_URL}/changePassword`,options)
        .then((res)=>{
            console.log(res)
            if(res.status === 200)  {
                toast.success('Password Changed Successfully!')
                onClose();
                setTimeout(()=>{
                    logoutAction();
                    navigate(`${process.env.PUBLIC_URL}/login`);
                }, 2000)
                
            }
        }, (err) => {
            console.log(err)
            if(err.status === 401) {
                toast.error(err.json?.message);
                onClose()
            }
        })
    }
    
    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='md'>
                <ModalHeader className="d-flex">
                    <H3 attrH3={{ className: 'modal-title-color' }}>
                        Change Password
                    </H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='12'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col lg='12'>
                                                <FormGroup>
                                                    <Label>Current Password <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="currentPassword"
                                                        type={toggleCurrentPassword ? 'text' : 'password'}
                                                        {...register('currentPassword', { required: true })}
                                                    />
                                                    <div className="show-hide reset-password" onClick={() => setToggleCurrentPassword(!toggleCurrentPassword)}>
                                                        <span>{toggleCurrentPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                                    </div>
                                                    <span className="text-danger">{errors.currentPassword && errors.currentPassword.type === 'required' && 'Enter Current Password'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='12'>
                                                <FormGroup>
                                                    <Label>New Password <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="newPassword"
                                                        onCopy={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                        type={toggleNewPassword ? 'text' : 'password'}
                                                        {...register('newPassword', { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g })}
                                                    />
                                                    <div className="show-hide reset-password" onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                                                        <span>{toggleNewPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                                    </div>
                                                    <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'required' && 'Enter New Password'}</span>
                                                    <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'pattern' && 'Your password contains 8 characters, One Upper case, One Special characters, One Numeric Characters'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='12'>
                                                <FormGroup>
                                                    <Label>Confirm New Password <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="confirmPassword"
                                                        onCopy={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                        type={toggleConfirmPassword ? 'text' : 'password'}
                                                        {...register('confirmPassword', { required: true, validate: (value) => getValues('newPassword') === value })}
                                                    />
                                                    <div className="show-hide reset-password" onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                                                        <span>{toggleConfirmPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                                    </div>
                                                    <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'required' && 'Enter Confirm Password'}</span>
                                                    <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'validate' && 'New password and Confirm password must be same'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex " >
                                                <Button color="primary" >Save</Button>
                                                <Button color='secondary' onClick={onClose}  className="ml-1"> Cancel</Button>
                                            </div>
                                        </Row>
                                    </Form>
                                
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ChangePassword