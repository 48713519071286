import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import DataTableComponent from '../DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import AddCategoryModal from './AddCategory';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AgGridTable from '../AgGridTable';
import { thousandSeparator } from '../../api/helper';


const CategoryList = () => {
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [addStatus, setAddStatus] = useState(false);
    const creditRateRef = useRef(null);

    const loadCreditRateData = async () => {
        creditRateRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/projectcategoryrates`)
            .then((res) => {
                creditRateRef?.current?.api?.hideOverlay();
                setTableData(res.json)
            }, (err) => {
                creditRateRef?.current?.api?.hideOverlay();
                console.log(err)
            })

    }

    useEffect(() => {
        if (addStatus) {
            loadCreditRateData();
        }
    }, [addStatus])

    const columnData = [
        {
            headerName: 'Category Type',
            field: 'projectCategory',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'cell-wrap',
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Subcategory Type',
            field: 'projectSubCategory',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'cell-wrap',
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Subcategory Item',
            field: 'subCategoryItem',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'cell-wrap',
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Registry Type',
            field: 'registryType',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
        },
        {
            headerName: 'Unit Type',
            field: 'unitType',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Lower Bound',
            field: 'lowerBound',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellRenderer: (params) => {
                return <div className='text-right pr-10'>
                    {params.data?.lowerBound ? thousandSeparator(params.data?.lowerBound.toFixed(2)) : "0.00"}
                </div>
            }
        },
        {
            headerName: 'Upper Bound',
            field: 'upperBound',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellRenderer: (params) => {
                return <div className='text-right pr-10'>
                    {params.data?.upperBound ? thousandSeparator(params.data?.upperBound.toFixed(2)) : "0.00"}
                </div>
            }
        },
        {
            headerName: 'Mid Rate',
            field: 'midRate',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellRenderer: (params) => {
                return <div className='text-right pr-10'>
                    {params.data?.midRate ? thousandSeparator(params.data?.midRate.toFixed(2)) : "0.00"}
                </div>
            }
        },
        {
            headerName: 'Edit',
            width: 80,
            cellRenderer: params => {
                return <Link to={`${process.env.PUBLIC_URL}/admin/credit-rates/edit/${params.data.id}`} >
                    <Edit className={`edit-icon `} />
                </Link>
            },
            suppressMovable: true,
        },
    ]

    const handleClick = () => {
        setOpen(true)
    }

    const onGridReady = useCallback(async (params) => {
        loadCreditRateData()
    })

    return (
        <Fragment>
            <LayoutLoader />
            <Row>
                <Col sm="12">
                    <div className="d-flex mtop-1">
                        <Breadcrumbs parent="Credit Rates" title="List" mainTitle="Credit Rates" />
                        <div className="text-right  action_button mr-13">
                            <div className="link action_button" onClick={handleClick}>
                                <i className="icon-style icofont  icofont-plus-circle" ></i>
                                &nbsp;&nbsp;{"Add New"}
                            </div>
                        </div>
                    </div>

                    <Card className="newtable">
                        <CardBody className='data-tables credit-rates ptop-0'>
                            <div className='exchange-table'>
                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridTable
                                        columnData={columnData}
                                        tableData={tableData}
                                        agRef={creditRateRef}
                                        onGridReady={onGridReady}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                {
                    open && <AddCategoryModal value={open} setOpenModal={setOpen} setAdd={setAddStatus} />
                }
            </Row>
        </Fragment>
    )
}

export default CategoryList;