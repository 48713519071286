import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";

const LayoutLoader = ({ value }) => {
    const [loading, setLoading] = useState(true)
    const secs = value !== (null || undefined) ? 5000 : 2000;

    useEffect(() => {
        if (value)
            setLoading(value)
    }, [value])

    useEffect(() => {
        setTimeout(() => {
            document.body.style.overflow = 'auto';
            setLoading(false);
        }, secs);
        document.body.style.overflow = 'hidden';
    }, []);
    return (
        <div>
            <div className={loading ? "new_loader" : "d-none"}>
                <Spinner color="primary"></Spinner>{" "}
            </div>
        </div>
    )
}

export default LayoutLoader;

