import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { addNewRow, CustomFooter, thousandSeparator } from "../../../api/helper";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Nav, Tab, Row, Col, Tabs, Accordion, Card } from "react-bootstrap";
import { CardBody } from "reactstrap";
import ProductService from "./ProductService";
import Operations from "./Operations";
import Employees from "./Employees";
import HoldingCompanies from "./HoldingCompanies";
import SocialResponsibility from "./SocialResponsibility";
import TransparencyCompliance from "./TransparencyCompliance";
import PolicyProcess from "./PolicyProcess";
import GovernanceOversight from "./GovernanceOversight";
import NgrbcReview from "./NgrbcReview";
import ExternalEvaluation from "./ExternalEvaluation";
import PolicyCoverage from "./PolicyCoverage";
import EthicalTransparentAccountability from "./EthicalTransparentAccountability";

const CompanyProfile = () => {
    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        console.log(activeTab)
    }, [activeTab])

    return (
        <Fragment>
            <Breadcrumbs mainTitle={'Company Profile Setup'} />
            <Card>
                <CardBody>
                    <div className="company-profile">
                        <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
                            <Row>
                                <Col className="w-20per accord-col" lg={3}>
                                    <Accordion defaultActiveKey={'main1'}>
                                        <Accordion.Item eventKey="main1">
                                            <Accordion.Header>General Disclosures</Accordion.Header>
                                            <Accordion.Body>
                                                <Nav variant="tabs" defaultActiveKey={1} className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={1}>Holding Companies</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={2}>Transparency Compliance</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={3}>Policy Processes</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={4}>Governance Oversight</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={5}>Responsible Business Guidelines</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={6}>External Evaluation</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={7}>Policy Coverage</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={8}>Ethical Transparent Accountability</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="main2">
                                            <Accordion.Header>Economic Disclosures</Accordion.Header>
                                            <Accordion.Body>
                                                <Nav variant="tabs" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={9}>Product/services</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={10}>Operations</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={11}>Responsible Consumer Engagement</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="main3">
                                            <Accordion.Header>Environmental Disclosures</Accordion.Header>
                                            <Accordion.Body>
                                                <Nav variant="tabs" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={12}>Sustainable Safe Practices</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={13}>Environmental Protection Efforts</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="main4">
                                            <Accordion.Header>Social Disclosures</Accordion.Header>
                                            <Accordion.Body>
                                                <Nav variant="tabs" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={14}>Employees</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={15}>Corporate Social Responsibility</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={16}>Employee Well-being Promotion</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={17}>Stakeholder Interest Responsiveness</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={18}>Human Rights Advocacy</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={19}>Inclusive Equitable Development</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={20}>Responsible Transparent Advocacy</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>

                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                </Col>
                                <Col lg={9} className="w-82per">
                                    <Tab.Content>
                                        <Tab.Pane eventKey={1}>
                                            <HoldingCompanies activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={2}>
                                            <TransparencyCompliance activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={3}>
                                            <PolicyProcess activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={4}>
                                            <GovernanceOversight activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={5}>
                                            <NgrbcReview activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={6}>
                                            <ExternalEvaluation activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={7}>
                                            <PolicyCoverage activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={8}>
                                            <EthicalTransparentAccountability activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={9}>
                                            <ProductService activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={10}>
                                            <Operations activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={14}>
                                            <Employees activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={15}>
                                            <SocialResponsibility activeTab={activeTab} setActive={setActiveTab} />
                                        </Tab.Pane>
                                        
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>

                </CardBody>
            </Card>
        </Fragment >
    )
}

export default CompanyProfile;