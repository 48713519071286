import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Row, Col, Button } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import "../../../assets/css/requireDoc.css";
import { Next, Back, CarbonstdMethodology, ClimateCommunity, SustainableDevelopment, METHODOLOGY, SaveasDraft } from '../../../Common/Constant';
import { useDispatch } from 'react-redux';
import fetchWrapper from '../../../Common/api/fetchWrapper';
import SweetAlert from 'sweetalert2';
import { handleProjectData } from '../../../Common/api/helper';
import { API_URL } from '../../../Common/Config/Config';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import ButtonLoader from '../ButtonLoader';

const Methodology = ({ setSteps, setFormdata, formdata }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [selectMethodology, setselectMethodology] = useState(formdata.methodology || '');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [methodology, setMethodology] = useState([]);
    const [draftLoading, setDraftLoading] = useState(false)
    const { origin } = useParams();
    const [registry, setRegistry] = useState(formdata?.registry);

    const onSelectChange = (val) => {
        const type = methodology?.find((i) => i.id === val);
        setselectMethodology(type?.name)
    }

    useEffect(() => {
        getMethodology()
    }, [])

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            if (data) {
                setFormdata(prev => ({ ...prev, ...data }))
                const projectData = {
                    ...data,
                    ...formdata,
                    projectorigin: origin
                }
                const bodyData = handleProjectData(projectData, "SUBMITTED")
                const options = {
                    method: 'POST',
                    body: bodyData
                }
                await fetchWrapper(`${API_URL}/projects`, options)
                    .then((res) => {
                        setLoading(false)
                        const data = res.json;
                        sessionStorage.setItem('projectId', data.id)
                        setSteps(pre => pre + 1);
                        SweetAlert.fire({ title: 'Congratulations!', text: 'Your Project Created Successfully!', icon: 'success' })
                    })
            }
        } catch (error) {
            if (error.status === 412) {
                setLoading(false)
                toast.error(`Please check the Details..${JSON.stringify(error.json)}`)
            }
        }
    }

    const handleDraft = async (data) => {
        try {
            setDraftLoading(true)
            console.log(data);
            if (data) {
                setFormdata(prev => ({ ...prev, ...data }))
                const projectData = {
                    ...data,
                    ...formdata,
                    projectorigin: origin
                }
                const bodyData = handleProjectData(projectData, "DRAFT")
                const options = {
                    method: 'POST',
                    body: bodyData
                }
                await fetchWrapper(`${API_URL}/projects`, options)
                    .then((res) => {
                        setDraftLoading(false)
                        const data = res.json;
                        sessionStorage.setItem('projectId', data.id)
                        setSteps(pre => pre + 1);
                        toast.success('Project saved successfully!')
                    })
            }
        } catch (error) {
            if (error.status === 412) {
                setDraftLoading(false)
                toast.error(`Please check the Details..${JSON.stringify(error.json)}`)
            }
        }
    }

    const getMethodology = async () => {
        await fetchWrapper(`${API_URL}/methodology/${registry}`)
            .then((res) => {
                setMethodology(res.json);
            })
            .catch(error => console.error(error))
    }
    console.log(formdata)

    return (
        <Fragment>
            <h2 className="pb-3 text-color">Methodology</h2>
            <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
                {
                    registry !== 'Oncra' && <Row>
                        <Col sm='6'>
                            <div className="row mb-3">
                                <label for="inputEmail3" >Methodology </label>
                                <FormGroup className="mb-3">
                                    <select className="form-select" defaultValue={formdata.methodology || ""}
                                        {...register('methodology')}
                                        onChange={(e) =>
                                            onSelectChange(e.target.value)
                                        }>
                                        <option value=''>Select Methodology</option>
                                        {
                                            methodology && methodology.length && methodology.map((item) =>
                                                <option value={item.name}>{item.name}</option>
                                            )
                                        }
                                    </select>
                                    <span className='text-danger'>{errors.methodology && errors.methodology.type === 'required' && 'Methodology is required'}</span>
                                    {console.log(errors)}
                                </FormGroup>
                            </div>
                        </Col>
                        {
                             (['Verra', 'Plastic Credit Exchange']?.includes(registry)) &&
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Project Size (tCO<sub>2</sub>e/year) </label>
                                    <select className="form-select" defaultValue={formdata.projectsize || ""}
                                        {...register('projectsize')} >
                                        <option value=''>-Select Project size-</option>
                                        <option value="LESSTHAN20K"> &#60; 20,000 </option>
                                        <option value="BETWEEN20K">20,000 – 100,000 </option>
                                        <option value="BETWEEN1M">100,001 – 1,000,000 </option>
                                        <option value="GREATERTHAN1M"> &#62; 1,000,000 </option>
                                    </select>
                                    <span className='text-danger'>{errors.projectsize && errors.projectsize.type === 'required' && 'Project Size is required'}</span>
                                </FormGroup>
                            </Col>
                        }
                        {
                            registry === 'Gold Standard' && <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Sustainable Development Goal</label>
                                    <select className="form-select"
                                        {...register('susdevgoalcontri')} >
                                        <option value=''>-Select Sustainable Development Goal-</option>
                                        <option value=""> Emission Reductions </option>
                                        <option value=""> Renewable Energy Certification </option>
                                        <option value="">Water Benefits Certification</option>
                                        <option value="">Gender Equality Impacts</option>
                                        <option value="">Improved Health Conditions</option>
                                        <option value="">Black Carbon Reductions</option>
                                    </select>
                                    <span className='text-danger'>{errors.susdevgoalcontri && errors.susdevgoalcontri.type === 'required' && 'Sustainable Development Goal is required'}</span>
                                </FormGroup>
                            </Col>
                        }

                    </Row>
                }
                {

                    registry === 'Gold Standard' && <div>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Project Size (tCO<sub>2</sub>e/year) </label>
                                    <select className="form-select" defaultValue={formdata.projectsize || ""}
                                        {...register('projectsize')} >
                                        <option value=''>-Select Project size-</option>
                                        <option value="LESSTHAN20K"> &#60; 20,000 </option>
                                        <option value="BETWEEN20K">20,000 – 100,000 </option>
                                        <option value="BETWEEN1M">100,001 – 1,000,000 </option>
                                        <option value="GREATERTHAN1M"> &#62; 1,000,000 </option>
                                    </select>
                                    <span className='text-danger'>{errors.projectsize && errors.projectsize.type === 'required' && 'Project Size is required'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Average Annual Carbon Units </label>
                                    <input className={`form-control ${errors.avgannualvcu && 'is-invalid'} mb-1`} placeholder=''
                                        type="text" name='avgannualvcu' defaultValue={formdata.avgannualvcu || ""}
                                        {...register('avgannualvcu', { pattern: /^[0-9\b]+$/ })} />
                                    <span className='text-danger'>{errors.avgannualvcu && errors.avgannualvcu.type === 'required' && 'Average Annual VCU is required'}</span>
                                    <span className='text-danger'>{errors.avgannualvcu && errors.avgannualvcu.type === 'pattern' && 'Average Annual VCU must be decimal'}</span>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm='12'><div>Project Associated with JNR program?</div>
                                <Row>
                                    <Col sm='1'>
                                        <div style={{ width: "26px", paddingTop: "20px" }}>
                                            <label className="switch">
                                                <input type="checkbox" id="togBtn"
                                                    {...register('isassociatewithjnr')}
                                                />
                                                <div className="slider round">
                                                    <span className="on">ON</span>
                                                    <span className="off">OFF</span>
                                                </div>
                                            </label>
                                        </div>
                                    </Col>
                                    <Col sm='11'><div style={{ paddingTop: "20px", paddingLeft: "30px" }}>*JNR -Jurisdictional Nesting REDD (Reduing Emissions from Deforestation and Forest Degradation)</div></Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    registry === 'Oncra' && <div>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Surface Area(ha) <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='ex: 23' type="text" name='surface_area'
                                        {...register('surface_area', { required: true })} />
                                    <span className='text-danger'>{errors.surface_area && errors.surface_area.type === 'required' && 'Surface Area is required'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Carbon Baseline <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='ex: 23' type="text" name='carbonBaseline'
                                        {...register('carbon_baseline', { required: true, pattern: /^[0-9\b]+$/ })} />
                                    <span className='placeholder-text-color'>In % Soil Organic Carbon (this is about 58% of Soil Organic Matter). If applicable.</span>
                                    <span className='text-danger'>{errors.carbon_baseline && errors.carbon_baseline.type === 'required' && 'Carbon Baseline is required'}</span>
                                    <span className='text-danger'>{errors.carbon_baseline && errors.carbon_baseline.type === 'pattern' && 'Carbon Baseline must be decimal'}</span>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Estimation of Carbon Capacity <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='ex: 23' type="text" name='carbonCapacity'
                                        {...register('estimation_carbon_capacity', { required: true, pattern: /^[0-9\b]+$/ })} />
                                    <span className="placeholder-text-color">In % Soil Organic Carbon</span>
                                    <span className='text-danger'>{errors.estimation_carbon_capacity && errors.estimation_carbon_capacity.type === 'required' && 'Carbon Capacity is required'}</span>
                                    <span className='text-danger'>{errors.estimation_carbon_capacity && errors.estimation_carbon_capacity.type === 'pattern' && 'Carbon Capacity must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Total gross sequestration potential <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='ex: 23' type="text" name='total_gross_sequestration_potential'
                                        {...register('total_gross_sequestration_potential', { required: true, pattern: /^[0-9\b]+$/ })} />
                                    <span className="placeholder-text-color">Capacity minus baseline x surface area, tCO2</span>
                                    <span className='text-danger'>{errors.total_gross_sequestration_potential && errors.total_gross_sequestration_potential.type === 'required'
                                        && 'Gross sequestration potential is required'}</span>
                                    <span className='text-danger'>{errors.total_gross_sequestration_potential && errors.total_gross_sequestration_potential.type === 'pattern'
                                        && 'Gross sequestration potential must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Specification of full life cycle analysed operational impacts <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' defaultValue={'0.2'} type="text" name='operational_impacts'
                                        {...register('operational_impacts', { required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+$/ })} />
                                    <span className="placeholder-text-color">t CO2e in percentage of Potential whole project per year (above).
                                        Default is 20% (type 0,2).</span>
                                    <span className='text-danger'>{errors.operational_impacts && errors.operational_impacts.type === 'required'
                                        && 'Required'}</span>
                                    <span className='text-danger'>{errors.operational_impacts && errors.operational_impacts.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Project duration (Years) <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' defaultValue={'20'} type="text" name='project_duration'
                                        {...register('project_duration', { required: true, pattern: /^[0-9\b]+$/ })} />
                                    <span className="placeholder-text-color">20 is the standard Oncra duration</span>
                                    <span className='text-danger'>{errors.project_duration && errors.project_duration.type === 'required'
                                        && 'Project Duration is required'}</span>
                                    <span className='text-danger'>{errors.project_duration && errors.project_duration.type === 'pattern'
                                        && 'Project Duration must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Potential per ha per year (tCO2/ha/y) <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' defaultValue={'0'} type="text" name='potential_per_ha_year'
                                        {...register('potential_per_ha_year', { required: true, pattern: /^[0-9\b]+$/ })} />
                                    <span className='text-danger'>{errors.potential_per_ha_year && errors.potential_per_ha_year.type === 'required'
                                        && 'Potential per ha per year is required'}</span>
                                    <span className='text-danger'>{errors.potential_per_ha_year && errors.potential_per_ha_year.type === 'pattern'
                                        && 'Potential per ha per year must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Potential whole project per year (tCO2/y) <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' defaultValue={'0'} type="text" name='potential_whole_project_year'
                                        {...register('potential_whole_project_year', { required: true, pattern: /^[0-9\b]+$/ })} />
                                    <span className='text-danger'>{errors.potential_whole_project_year && errors.potential_whole_project_year.type === 'required'
                                        && 'Potential whole project per year is required'}</span>
                                    <span className='text-danger'>{errors.potential_whole_project_year && errors.potential_whole_project_year.type === 'pattern'
                                        && 'Potential whole project per year must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Total sequestration potential <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' defaultValue={'0'} type="text" name='total_sequestration_potential'
                                        {...register('total_sequestration_potential', { required: true, pattern: /^[0-9\b]+$/ })} />
                                    <span className="placeholder-text-color">Capacity minus baseline x surface area, tCO2</span>
                                    <span className='text-danger'>{errors.total_sequestration_potential && errors.total_sequestration_potential.type === 'required'
                                        && 'Total sequestration potential is required'}</span>
                                    <span className='text-danger'>{errors.total_sequestration_potential && errors.total_sequestration_potential.type === 'pattern'
                                        && 'Total sequestration potential must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Estimated carbon income at ETS price <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' defaultValue={'0'} type="text" name='estimated_carbon_income'
                                        {...register('estimated_carbon_income', { required: true, pattern: /^[0-9\b]+$/ })} />
                                    <span className="placeholder-text-color">Directly available, 12 year ahead, ex 20% buffer.</span>
                                    <span className='text-danger'>{errors.estimated_carbon_income && errors.estimated_carbon_income.type === 'required'
                                        && 'Total sequestration potential is required'}</span>
                                    <span className='text-danger'>{errors.estimated_carbon_income && errors.estimated_carbon_income.type === 'pattern'
                                        && 'Total sequestration potential must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Name of field or facility <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' type="text" name='facility_field_name'
                                        {...register('facility_field_name', { required: true })} />
                                    <span className='text-danger'>{errors.facility_field_name && errors.facility_field_name.type === 'required' && 'Field name is required'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Geo-location (eg Google Maps link) <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' type="text" name='geo_location'
                                        {...register('geo_location', { required: true })} />
                                    <span className='text-danger'>{errors.geo_location && errors.geo_location.type === 'required' && 'Geo Location is required'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>Legal ownership of field or facility</label>
                                    <textarea className='form-control' {...register('legal_ownership', { maxLength: { value: 4000, message: 'No of characters we can allow 4000 characters.' } })}
                                        rows={4} cols={75} />
                                    <span className="placeholder-text-color">Elaborate when it is not you</span>
                                    <span className='text-danger'>{errors.legal_ownership && errors.legal_ownership.type === 'maxLength' &&
                                        errors.legal_ownership.message}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup className="mb-3">
                                    <label>How will you remove & store carbon?</label>
                                    <textarea className='form-control' {...register('remove_store_carbon',
                                        { maxLength: { value: 4000, message: 'No of characters we can allow 4000 characters.' } })}
                                        rows={4} cols={75} />
                                    <span className="placeholder-text-color">Specify how you will e.g. increase soil carbon, increase the above ground biomass
                                        stock, or other.</span>
                                    <span className='text-danger'>{errors.remove_store_carbon && errors.remove_store_carbon.type === 'maxLength' &&
                                        errors.remove_store_carbon.message}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    (registry === 'Verra')
                        ? <div >
                            <Row>
                                <Col sm='6'>
                                    <FormGroup className="mb-3">
                                        <label>Average Annual Carbon Units </label>
                                        <input className={`form-control ${errors.avgannualvcu && 'is-invalid'} mb-1`} placeholder=''
                                            type="text" name='avgannualvcu' defaultValue={formdata.avgannualvcu || ""}
                                            {...register('avgannualvcu', { pattern: /^[0-9\b]+$/ })} />
                                        <span className='text-danger'>{errors.avgannualvcu && errors.avgannualvcu.type === 'required' && 'Average Annual VCU is required'}</span>
                                        <span className='text-danger'>{errors.avgannualvcu && errors.avgannualvcu.type === 'pattern' && 'Average Annual VCU must be decimal'}</span>
                                    </FormGroup>
                                </Col>
                                <Col sm='6'><div>Project Associated with JNR program?</div>
                                    <Row>
                                        <Col sm='2'>
                                            <div style={{ width: "26px", paddingTop: "20px" }}>
                                                <label className="switch">
                                                    <input type="checkbox" id="togBtn" checked={formdata?.isassociatewithjnr}
                                                        {...register('isassociatewithjnr')}
                                                    />
                                                    <div className="slider round">
                                                        <span className="on">ON</span>
                                                        <span className="off">OFF</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </Col>
                                        <Col sm='10'><div style={{ paddingTop: "20px", paddingLeft: "30px" }}>*JNR -Jurisdictional Nesting REDD (Reduing Emissions from Deforestation and Forest Degradation)</div></Col>
                                    </Row>
                                </Col>
                            </Row>
                            {
                                selectMethodology == 'For Climate,Community,and Biodiversity Standards' && <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>CCB Standard Name </label>
                                            <input className={`form-control ${errors.ccbstdname && 'is-invalid'} mb-1`} placeholder='' type="text" name='ccbstdname' defaultValue={formdata.ccbstdname || ""} {...register('ccbstdname')} />
                                            <span className='text-danger'>{errors.ccbstdname && errors.ccbstdname.type === 'required' && 'CCB Standard Name is required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>CCB Standard </label>
                                            <input className={`form-control ${errors.ccbstd && 'is-invalid'} mb-1`} placeholder='' type="text" name='ccbstd' defaultValue={formdata.ccbstd || ""} {...register('ccbstd')} />
                                            <span className='text-danger'>{errors.ccbstd && errors.ccbstd.type === 'required' && 'CCB Standard is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            {
                                selectMethodology == 'For Sustainable Development Verified Impact Standard' && <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>SD Vista Project Type </label>
                                            <input className={`form-control ${errors.sdvistaprojtype && 'is-invalid'} mb-1`} placeholder='' type="text" name='sdvistaprojtype' defaultValue={formdata.sdvistaprojtype || ""} {...register('sdvistaprojtype')} />
                                            <span className='text-danger'>{errors.sdvistaprojtype && errors.sdvistaprojtype.type === 'required' && 'SD Visya Project Type is required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Sustainable Development Goal Contributions </label>
                                            <input className={`form-control ${errors.susdevgoalcontri && 'is-invalid'} mb-1`}
                                                placeholder='' type="text" name='susdevgoalcontri' defaultValue={formdata.susdevgoalcontri || ""} {...register('susdevgoalcontri')} />
                                            <span className='text-danger'>{errors.susdevgoalcontri && errors.susdevgoalcontri.type === 'required' && 'Sustainable Development Goal Contribution is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </div> : ""
                }
                <div className="text-left">
                    {
                        loading ? <ButtonLoader loaderText={'Submitting'} color='primary' className='btn-font' /> :
                            <Button color='primary' type='submit' className='btn-font ' >{'Submit'}</Button>
                    }
                    {
                        draftLoading ? <ButtonLoader loaderText={'Submitting'} color='secondary' className='btn-font ml-1' /> :
                            <Button color='secondary' className='btn-font ml-1' onClick={handleSubmit(handleDraft)} >{SaveasDraft}</Button>
                    }
                    <Btn attrBtn={{ color: 'light', onClick: () => setSteps(pre => pre - 1), className: 'btn-font ml-1' }}>{Back}</Btn>
                </div>
            </Form>
        </Fragment>
    )
}

export default Methodology