import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { Tree } from 'react-tree-graph';
import { Button } from "reactstrap";

const OrgSummary = ({setTree}) => {
    const [groupdata, setGroups] = useState([]);
    const [locationData, setLocation] = useState([]);
    const [data, setData] = useState({});
    const [orgData, setOrgData] = useState();

    const loadGroups = async () => {
        await fetchWrapper(`${API_URL}/group`)
            .then((res) => {
                if (res.status === 200) {
                    setGroups(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const loadLocation = async () => {
        await fetchWrapper(`${API_URL}/location`)
            .then((res) => {
                if (res.status === 200) {
                    setLocation(res.json)
                    formatData();
                }
            }, (err) => {
                console.log(err)
            })
    }

    const loadData = async () => {
        await fetchWrapper(`${API_URL}/users/orgDetails`)
            .then((res) => {
                if (res.status === 200) {
                    setOrgData(res.json[0])
                }
            }, (err) => {
                console.log(err)
            })
    }

    const formatData = () => {
        if(groupdata?.length > 0) {
            const modifyData = groupdata?.map((item) => {
                return {
                    name: item.name,
                    children: locationData?.filter((i) => i.groupId === item.id).map((i, index) => {
                        return {
                            name:i.locationName + ' - ' + i.city + ", " + i.stateName + ', ' + i.countryName
                        }
                    })
                }
            })
            const fData = { name: '', children: [...modifyData] }
            setData(pre=> ({...pre, ...fData}))
        } else {
            setData({})
        }
        
    }

    useEffect(() => {
        loadGroups();
        loadLocation();
        loadData();
    }, [])

    useEffect(()=>{
        formatData();
    }, [groupdata, locationData]);

    const handleBack = () => {
        setTree(false)
    }

    return (
        <Fragment>
            <LayoutLoader />
            <div >
                <div className="row">
                    <div className="col-4"><h2 className="pl-21">{orgData?.companyName}</h2></div>
                    <div className="col-3"><h2>Groups</h2></div>
                    <div className="col-3"><h2>Locations</h2></div>
                </div>
                <Tree
                    data={data}
                    height={600}
                    width={800}
                    gProps={{
                        className: 'node'
                    }}
                    svgProps={{
                        className: 'word-text'
                    }}
                    textProps={{
                        dy: -5
                    }}
                />
               

            </div>
        </Fragment>
    )
}

export default OrgSummary;