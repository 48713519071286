import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Home } from 'react-feather';
import { OL, LI, H3 } from '../../../AbstractElements';

const Breadcrumbs = (props) => {
  return (
    <Fragment>
      <Container fluid={true} className='pl-0'>
        <div className="page-title">
          <Row>
            <Col xs="6" >
              <H3 attrH3={{ className: 'title-color' }}>{props.mainTitle}</H3>
            </Col>
            {/* <Col xs="6">
              <OL attrOL={{ className: 'breadcrumb' }}>
                <LI attrLI={{ className: 'breadcrumb-item' }}><Link to={``}><Home /></Link></LI>
                <LI attrLI={{ className: 'breadcrumb-item' }}>{props.parent}</LI>
                {props.subParent ? <LI attrLI={{ className: 'breadcrumb-item' }}>{props.subParent}</LI> : ''}
                {props.childParent ? <LI attrLI={{ className: 'breadcrumb-item' }}>{props.childParent}</LI> : ''}
                <LI attrLI={{ className: `${props.title ? 'breadcrumb-item' : ''} active` }}>{props.title}</LI>

              </OL>
            </Col> */}
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;