import React, { Fragment, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { H3, P } from "../../../AbstractElements";
import { currencyFormatter, thousandSeparator } from "../../api/helper";
import badge from '../../../assets/images/status-icons/Active.svg';
import uncertified from '../../../assets/images/uncertified.svg';
import pendingIcon from '../../../assets/images/pending.svg';

const SaleChartDesign = ({ certified, unCertified, pending, currency }) => {
    let totalCRT = 0, totalISS = 0, totalHLD = 0, crtCredit = 0, issCredit = 0, hldCredit = 0;
    certified ?
        certified?.map(i => {
            totalCRT = totalCRT + i.monetaryValue
            crtCredit = crtCredit + i.currencyBalance
        })
        : totalCRT = 0;
    unCertified ?
        unCertified?.map(i => {
            totalISS = totalISS + i.monetaryValue
            issCredit = issCredit + i.currencyBalance
        })
        : (totalISS = 0)
    pending ?
        pending?.map(i => {
            totalHLD = totalHLD + i.monetaryValue
            hldCredit = hldCredit + i.currencyBalance
        })
        : (totalHLD = 0)


    const data = [
        {
            id: 1, name: 'Pending Credits', value: currencyFormatter(totalISS, currency), iconClass: 'fa-sack-xmark', iconColor: '#043434',
            bgColor: '#dededee8', icon: uncertified, credit: issCredit, borderBottom: '#08676c', bubbleClr: '#08676c2b', class: 'iss', 
            // imgBg: '#08676c4d'
        },
        {
            id: 2, name: 'Certified Credits', value: currencyFormatter(totalCRT, currency), iconClass: 'fa-sack-dollar', iconColor: '#1b6105',
            bgColor: '#c8f0cdd4', icon: badge, credit: crtCredit, borderBottom: '#258905', bubbleClr: '#25890542', class: 'crt', 
            // imgBg: '#3fa31f70'
        },
        {
            id: 3, name: 'Pending Sale Settlement ', value: currencyFormatter(totalHLD, currency), iconClass: 'fa-hourglass-end', iconColor: '#a02a0fbd',
            bgColor: '#f7d1bd', icon: pendingIcon, credit: hldCredit, borderBottom: '#fe6a49', bubbleClr: '#fe6a4947', class: 'hld', 
            // imgBg: '#fa866c54'
        },
    ]

    return (
        <Fragment>
            {
                data.map((item, i) => {
                    return (
                        <Col lg="4" sm="6" key={i}>
                            <Card className="sale-chart sale-chart-card ">
                                <CardBody className={`sales-card ${item.class}`} style={{ background: item.bgColor, borderRadius: '0.375rem', borderColor: item.borderBottom }}>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <div className="sale-content">
                                                <H3 attrH3={{ className: `sale-card-title` }}>{item.name}</H3>
                                            </div>
                                            <div className="sale-detail">
                                                <h3 className={`sale-value f-w-600`} >{item.value ? item.value : 0}</h3>
                                            </div>
                                        </div>
                                        <div className="wallet-icon icon-shake" style={{ background: item.imgBg }}>
                                            <i class={`fa-solid ${item?.iconClass} f-s-30 icon `} style={{ color: item.iconColor }}></i>
                                        </div>
                                    </div>
                                    <div className="sale-credit mtop-1">
                                        <p >{thousandSeparator(Number(item?.credit).toFixed(2))} tCO2e</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })
            }
        </Fragment>
    );
};
export default SaleChartDesign;