import React, { Fragment, useState } from "react";
import { CardBody, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { Button } from "reactstrap";
import { H3, H4 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useEffect } from "react";
import moment from "moment";
import { defaultOrderStatusColorCodes, capitalize, defaultOrderFontCode } from "../../api/helper";

const OrderTransition = ({ value, setOpenModal, orderTrasitionData }) => {
    const [open, setOpen] = useState(value);
    const [transitionData, setTransitionData] = useState(orderTrasitionData);

    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>View Order Transitions</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Table className="table">
                        <thead>
                            <tr className="project-transition-table">
                                <th className="table-txt-color">Updated By</th>
                                <th className="table-txt-color">Updated On</th>
                                <th className="table-txt-color">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transitionData && transitionData?.map((item) => {
                                    return (
                                        <tr>
                                            <td className="p-t-1-5r">{item?.createdBy}</td>
                                            <td className="p-t-1-5r">{moment(item.timestamp).format('DD MMM, YYYY hh:mm A')}</td>
                                            <td scope="row" className="w-12">
                                                <p style={{
                                                    width: '125px', fontSize: '14px', backgroundColor: defaultOrderStatusColorCodes[item.orderStatus],
                                                    padding: '2px 10px', fontWeight: 400, borderRadius: '8px', border: `1px solid ${defaultOrderStatusColorCodes[item.orderStatus]}`,
                                                    textAlign: 'center', color: defaultOrderFontCode[item.orderStatus], marginTop: '7px'
                                                }}>
                                                    {capitalize(item.orderStatus)}</p>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button className="margin-inline-end ml-15 mb-15" color="secondary" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default OrderTransition;