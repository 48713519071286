import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Spinner, Button } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import fetchWrapper from '../../api/fetchWrapper';
import moment from 'moment';
import { Download, Trash2 } from 'react-feather';
import { API_URL } from '../../Config/Config';
import DataUploadModal from '../CarbonEmissionData/DataUploadModal';
import { capitalize, defaultQuarter, defaultUserType, ghgUploadStatus } from '../../api/helper';
import DeleteDocument from './DeleteDocument';
import AgGridTable from "../AgGridTable";
import { useNavigate, useParams } from 'react-router';
import EvidenceUploadModal from './EvidenceUpload';
import { typeOfCategories } from './ghgHelper';

const EvidenceList = ({ setSteps, preData, emissionSetId, type }) => {
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false)
    const [dataId, setDataId] = useState();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [uploadStatus, setUpload] = useState(false)
    const evidenceRef = useRef(null);
    const navigate = useNavigate();

    const getEvidences = async () => {
        evidenceRef?.current?.api?.showLoadingOverlay()
        await fetchWrapper(`${API_URL}/document/getAttachments/${emissionSetId}/${typeOfCategories[type]}`)
            .then((res) => {
                evidenceRef?.current?.api?.hideOverlay();
                setTableData(res.json)
                console.log(res.json)
            }, (err) => {
                evidenceRef?.current?.api?.hideOverlay();
                console.log(err)
            });
    }

    const handleDelete = (id) => {
        setDataId(id)
        setDeleteOpen(true)
    }

    const columnData = [
        { 
            headerName: 'File Name',
            field: 'uploadedFileName',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Upload Time',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return moment(params.data.createdTs)?.format('DD MMM, YYYY hh:mm A')
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
        },
        {
            headerName: 'Delete',
            width: 100,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleDelete(params.data.id)} >
                        <Trash2 className='emission-trash' />
                    </div>
                )
            },
            suppressMovable: true,
        },
    ]

    useEffect(() => {
        if (uploadStatus === true || deleteStatus === true){
            getEvidences();
        }
        console.log(uploadStatus)
    }, [uploadStatus, deleteStatus])

    const onGridReady = useCallback((params) => {
        getEvidences()
    })

    return (
        <Fragment>
            <Row>
                <Col lg='8'>
                    <div className='d-flex mtop-1'>
                        <Breadcrumbs mainTitle="Evidence(s) List" />
                        <Button color='primary' onClick={() => setOpen(true)} className='text-right  action_button mr-13'>
                            <i style={{ fontSize: '25px' }} className="icofont  icofont-plus-circle mr-1" ></i> File Upload
                        </Button>
                        {open === true && <EvidenceUploadModal value={open} setOpenModal={setOpen} setUpload={setUpload} emissionId={emissionSetId} type={type} />}
                    </div>
                    <Card className="newtable carbon-emission">
                        <CardBody className='data-tables emission-table ptop-0'>
                            <div className="emission-upload">
                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridTable
                                        columnData={columnData}
                                        tableData={tableData}
                                        agRef={evidenceRef}
                                        onGridReady={onGridReady}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Button color='secondary' 
                    onClick={()=>setSteps(0)}>Back</Button>
                    {deleteOpen && <DeleteDocument value={deleteOpen} setOpenModal={setDeleteOpen} dataId={dataId} setDelete={setDeleteStatus} />}
                </Col>
            </Row>
        </Fragment>
    )
}

export default EvidenceList;