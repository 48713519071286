import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { createProject } from '../../Common/Constant';
import { useSelector } from 'react-redux';
import HeaderCard from '../../Common/Component/HeaderCard';
import fetchWrapper from '../../Common/api/fetchWrapper';
import moment from 'moment';
import { Eye,Edit } from 'react-feather';
import { API_URL } from '../../Common/Config/Config';
import { defaultStatus, defaultStatusColorCodes } from '../../Common/api/helper';
import Loader from '../../Common/Layout/Loader';

const BOUserProjects = () => {
  const [tableData, setTableData] = useState([]);
  const [showFilter, setShowFilter] = useState(false)
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true)
    const options = {
      method: 'GET',
    }
    await fetchWrapper(`${API_URL}/projects`, options)
      .then((res)=>{
        console.log(res)
        setLoading(false)
        setTableData(res.json)
      }).catch((err)=> {
        setLoading(false)
        console.log(err)
      });
  }

  const preValidatedStatus = ['ACCEPTED', 'SUBMITTED_TO_REGISTRY', 'APPROVED_BY_REGISTRY', 'ACTIVE', 
                              'SUSPENDED', 'RETIRED']

  const tableColumns = [
    {
      name: 'Project Name',
      selector: row => row['name'],
      sortable: true,
      center: false,
      filterable: showFilter,
      with: '50px',
    },
    {
      name: 'Asset Owner',
      selector: row => `${row.asset_org_id == null ? 'Unassigned' : row.asset_org_name}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Methodology',
      selector: row => `${row.methodology}`,
      sortable: true,
      center: false,
      filterable: showFilter,
      with: '30px',
  
    },
    {
      name: 'Country',
      selector: row => `${row.country}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Assessor',
      selector: row => `${row.assessorOrgId == null ? 'Unassigned' : row.assessor_org_name}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Status',
      sortable: true,
      center: false,
      with: '20px',
      cell: (row) => (
        <p style={{ fontSize: '12px', background: defaultStatusColorCodes[row.status], color: '#fff', padding: '2px 10px', borderRadius: '15px' }}>
          <span className='status_dot'></span>{defaultStatus[row.status]}</p>
      )
    },
    {
      name: "View",
      cell: (row) => (
        <Link to={ `${process.env.PUBLIC_URL}/back-office/view-edit/view/${row.id}` } >
          <Eye className={`action-icon `} />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <Link to={preValidatedStatus.includes(row.status) === true ? '#' :`${process.env.PUBLIC_URL}/back-office/edit-project/${row.id}` } 
        className={`${preValidatedStatus.includes(row.status) === true ? 'disable-icon' : ''}`}>
          <Edit className={`edit-icon ${preValidatedStatus.includes(row.status) === true ? 'disable-icon' : ''}`} />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect (()=>{
    loadData();
  }, [])

  
  const state = useSelector(state => state.projectArray);
  
  return (
    <Fragment>
      <Breadcrumbs parent="Projects" title="List" mainTitle="Projects" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className=" newtable">
              <CardBody className='data-tables '>
                <DataTableComponent tableData={tableData} columnsData={tableColumns} title={'Recent Projects'} 
                loading={loading} setShowFilter={setShowFilter}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default BOUserProjects;