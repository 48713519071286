import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { complaintsData, misleadingData, nonmonetaryPenaltyData, penaltyData, policyTemplateData, trainingData } from "../settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { currencySymbol } from "../../../api/currencyData";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import moment from "moment";

const EthicalTransparentAccountability = ({ activeTab, setActive }) => {
    const [trainignCoverageData, setTrainingCoverage] = useState(trainingData);
    const [penaltyDisclosure, setPenaltyDisclosure] = useState(penaltyData);
    const [nonMonetaryPenalty, setNonMonetary] = useState(nonmonetaryPenaltyData);
    const [casesData, setCasesData] = useState([...Array(2)].map((_, index) => ({ id: index + 1 })));
    const userCurrency = useSelector(state => state?.user?.userDetails?.currency);
    const [antiCorruptionDetails, setAntiCorruption] = useState();
    const [misleadingPersonData, setMisleadingPersonData] = useState(misleadingData);
    const [complaintDetails, setComplaintDetails] = useState(complaintsData);
    const [conflictData, setConflictData] = useState();
    const [currentPayableDays, setCurrentPayableDays] = useState();
    const [previousPayableDays, setPreviousPayableDays] = useState();
    const [programmesCount, setProgramCount] = useState();
    const [programTopic, setProgramTopic] = useState();
    const [programPercentage, setProgramPercentage] = useState();
    const [interestOfBoardMembers, setInterest] = useState('');
    const [conflictManagement, setConflictManagement] = useState();

    const traingCoverageColumns = [
        {
            headerName: 'Segment',
            field: 'segment',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Total number of training and awareness programmes held',
            field: 'numberOfTraining',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.numberOfTraining ?
                    thousandSeparator(params?.data?.numberOfTraining) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Topics/principles covered under the training and its impact',
            field: 'trainingTopics',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Category coverage percentage in awareness programs',
            field: 'personelPercentage',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.personePercentage ?
                    thousandSeparator(params?.data?.personePercentage.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
    ]

    const penaltyColumns = [
        {
            headerName: '',
            field: 'segment',
            editable: false,
            filter: false,
            flex: 1.5,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Responsible Business Guidelines principle',
            field: 'principle',
            editable: true,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Regulatory/Enforcement agencies/Judicial',
            field: 'regulatoryName',
            editable: true,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false,

        },
        {
            headerName: `Amount (in ${currencySymbol[userCurrency]})`,
            field: 'amount',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.amount ?
                    thousandSeparator(params?.data?.amount) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Brief of the case',
            field: 'case',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Has an appeal been preferred?',
            field: 'appealPreferred',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        }
    ]

    const caseDetailsColumn = [
        {
            headerName: 'Case details',
            field: 'caseDetails',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Regulatory/Enforcement agencies/Judicial',
            field: 'regulatoryName',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ];

    const misLeadingCountsColumn = [
        {
            headerName: '',
            field: 'segment',
            editable: false,
            filter: false,
            flex: 1.5,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `FY ${moment(new Date).format('YYYY')}`,
            field: 'currentYear',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentYear ?
                    thousandSeparator(params?.data?.currentYear) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: `FY ${moment(new Date).subtract(1, 'year').format('YYYY')}`,
            field: 'previousYear',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousYear ?
                    thousandSeparator(params?.data?.previousYear) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        }
    ];

    const conflictColumns = [
        {
            headerName: '',
            field: 'segment',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `FY ${moment(new Date).format('YYYY')}`,
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Number',
                    field: 'currentYearNumber',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Remarks',
                    field: 'remarksCurrentYear',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: `FY ${moment(new Date).subtract(1, 'year').format('YYYY')}`,
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Number',
                    field: 'previousYearNumber',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Remarks',
                    field: 'remarksPreviousYear',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        }
    ]

    const businessColumns = [
        {
            headerName: 'Parameters',
            field: 'parameters',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false,
            rowSpan: 3
        },
        {
            headerName: 'Metrics',
            field: 'metrics',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        {
            headerName: `FY ${moment(new Date).format('YYYY')}`,
            field: 'currentYearBusiness',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentYearBusiness ?
                    thousandSeparator(params?.data?.currentYearBusiness) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: `FY ${moment(new Date).subtract(1, 'year').format('YYYY')}`,
            field: 'previousYearBusiness',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousYearBusiness ?
                    thousandSeparator(params?.data?.previousYearBusiness) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        }
    ]

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    }

    const onSubmit = async () => {
        setActive(9)
    }

    return (
        <Fragment>
            <h3>Ethical Transparent Accountability</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="mtop-1 company-profile">
                <h5>1. Training Coverage Percentage</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={trainignCoverageData}
                        columnData={traingCoverageColumns}
                    />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>2. Disclosure of Fines, Penalties, or Settlements Paid by Entity or Directors/KMPs in Financial Year</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={penaltyDisclosure}
                        columnData={penaltyColumns.map((ele, index) => ({ ...ele, headerName: index === 0 ? 'Monetary' : ele.headerName }))}
                        defaultColDef={{
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                        }}
                    />
                </div>
                <div style={{ width: '100%' }} className="ag-datasheet mtop-1">
                    <AgGridDatasheet
                        tableData={nonMonetaryPenalty}
                        columnData={penaltyColumns?.filter(field => field.field !== 'amount').map((ele, index) => ({ ...ele, headerName: index === 0 ? 'Non - Monetary' : ele.headerName }))}
                    />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>3. Details of Appeals/Revisions Filed for Disclosed Monetary or Non-Monetary Actions</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={casesData}
                        columnData={caseDetailsColumn}
                    />
                    <CustomFooter handleAdd={() => setCasesData([...casesData, { id: casesData?.length + 1 }])} />
                </div>
            </div>
            <Col lg={12} className="mtop-1 d-flex">
                <h5>4 Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in brief
                    and if available, provide a web-link to the policy.</h5>
                <textarea className="form-control mtop-1" value={antiCorruptionDetails} rows={3}
                    onChange={(e) => setAntiCorruption(e.target.value)} />
            </Col>
            <div className="mtop-1 company-profile">
                <h5>5. Count of Directors/KMPs/Employees/Workers Disciplined for Bribery/Corruption Charges by Law Enforcement</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={misleadingPersonData}
                        columnData={misLeadingCountsColumn}
                    />
                </div>
            </div>
            <div className="mtop-1 company-profile ag-group-header">
                <h5>6. Details of complaints with regard to conflict of interest</h5>
                <div className="ngrbc-review">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={complaintDetails}
                            columnData={conflictColumns}
                            gridOptions={gridOptions}
                        />
                    </div>
                </div>
            </div>
            <Col lg={12} className="mtop-1 d-flex">
                <h5 className="col-6">7. Provide details of any corrective action taken or underway on issues related to fines/penalties/
                    action taken by regulators/law enforcement agencies/judicial institutions, on cases of corruption and
                    conflicts of interest.</h5>
                <textarea className="form-control" value={conflictData} rows={3}
                    onChange={(e) => setConflictData(e.target.value)} />
            </Col>
            <h5 className="mtop-1">8. Number of days of accounts payables ((Accounts payable *365) / Cost of goods/services procured) in the following format</h5>
            <Row >
                <Col lg='6'>
                    <FormGroup>
                        <label>In current year({moment(new Date).format('YYYY')})</label>
                        <input type="number" className="form-control"
                            value={currentPayableDays}
                            onChange={(e) => setCurrentPayableDays(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col lg='6'>
                    <FormGroup>
                        <label>In previous year({moment(new Date).subtract(1, 'year').format('YYYY')})</label>
                        <input type="number" className="form-control" placeholder="" value={previousPayableDays} onChange={(e) => setCurrentPayableDays(e.target.value)} />
                    </FormGroup>
                </Col>
            </Row>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <h5 className="mtop-1">1. Awareness programmes conducted for value chain partners on any of the
                principles during the financial year</h5>
            <Col lg='12' className="mtop-1 d-flex">
                <Col lg='6'>
                    <label>Total number of awareness programmes held</label>
                </Col>
                <input type="number" className="form-control"
                    value={programmesCount}
                    onChange={(e) => setProgramCount(e.target.value)} />
            </Col>
            <Col lg='12' className="mtop-1 d-flex">
                <Col lg='6'>
                    <label>Topics / principles covered under the training</label>
                </Col>
                <input type="number" className="form-control"
                    value={programTopic}
                    onChange={(e) => setProgramTopic(e.target.value)} />
            </Col>
            <Col lg='12' className="mtop-1 d-flex">
                <Col lg='6'>
                    <label>Percentage of value chain partners covered (by value of business done with such partners) under the awareness programmes </label>
                </Col>

                <input type="number" className="form-control"
                    value={programPercentage}
                    onChange={(e) => setProgramPercentage(e.target.value)} />
            </Col>
            <h5 className="mtop-1">2. Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board? (Yes/No)
                If yes, provide details of the same.</h5>
            <div className="d-flex">
                <div className="col-6">
                    <select className="form-select" value={interestOfBoardMembers} onChange={(e) => setInterest(e.target.value)}>
                        <option value={''}>-Please select-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div className="col-6">
                    {
                        interestOfBoardMembers === 'Yes' && <textarea className="form-control ml-1 mtop-1" value={conflictManagement} rows={3}
                            onChange={(e) => setConflictManagement(e.target.value)} />
                    }
                </div>
            </div>



            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(7)}>Back</Button>
                <Button color="primary" className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default EthicalTransparentAccountability;