import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table, Spinner } from "reactstrap";
import { Button } from "reactstrap";
import { H3, H4 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useEffect } from "react";
import moment from "moment";
import { currencyFormatter } from "../../api/helper";


const ViewHistoryModal = ({ value, setOpenModal, dataId }) => {
    const [open, setOpen] = useState(value);
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(false)

    const loadData = async () => {
        setLoading(true)
        await fetchWrapper(`${API_URL}/exchangerateshistory/${dataId}`)
            .then(res => {
                setLoading(false)
                setHistoryData(res.json)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        loadData()
    }, [])


    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }
    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>View Exchange rate History</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <div className={`${loading === false ?? 'project-transition'}`} >
                        <Table className="table">
                            <thead>
                                <tr className="project-transition-table">
                                    <th className="table-txt-color">Base Currency</th>
                                    <th className="table-txt-color">Quote Currency</th>
                                    <th className="table-txt-color">Exchange Rate</th>
                                    <th className="table-txt-color">Updated On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !loading && historyData?.length > 0 && historyData?.map((item, index) => {
                                        console.log(index)
                                        return (
                                            <tr>
                                                <td className={`${index === 0 ? 'highlighted-rate' : ''}`}>{item.sourceCurrency}</td>
                                                <td className={`${index === 0 ? 'highlighted-rate' : ''}`}>{item.targetCurrency}</td>
                                                <td className={`${index === 0 ? 'highlighted-rate' : ''}`}>{currencyFormatter(item.rate, item.targetCurrency)}</td>
                                                <td className={`${index === 0 ? 'highlighted-rate' : ''}`}>{moment(item.createdTs).format('DD MMM, YYYY')}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        {
                            loading && <Spinner color="primary" className="modal-spinner"/>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ViewHistoryModal;