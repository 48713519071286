import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {

    return (
        sessionStorage.getItem('accessToken') ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
    );
};
export default PrivateRoute;

