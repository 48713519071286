import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Spinner, Button, Badge } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import fetchWrapper from '../../api/fetchWrapper';
import moment from 'moment';
import { Download, Trash2 } from 'react-feather';
import { API_URL } from '../../Config/Config';
import DataUploadModal from './DataUploadModal';
import { capitalize, defaultQuarter, defaultUserType, ghgUploadStatus } from '../../api/helper';
import DeleteDocument from '../GhgDataCapture/DeleteDocument';
import AgGridTable from "../AgGridTable";
// import { GrAttachment } from "react-icons/gr";
import { useNavigate } from 'react-router';
import { Paperclip } from 'react-feather';
import { toast } from 'react-toastify';

const EmissionList = () => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploadStatus, setUpload] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [dataId, setDataId] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const uploadRef = useRef(null);
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false)

  const userType = sessionStorage.getItem('userType');

  const navigate = useNavigate();

  const loadDocuments = async () => {
    uploadRef?.current?.api?.showLoadingOverlay();
    await fetchWrapper(`${API_URL}/document/emissionList`)
      .then((res) => {
        console.log(res);
        uploadRef?.current?.api?.hideOverlay();
        if (res.status === 200) {
          setTableData(res.json)
        }
      }, (err) => {
        uploadRef?.current?.api?.hideOverlay();
        console.log(err)
      })
  }

  const handleDelete = (id) => {
    setDataId(id)
    setDeleteOpen(true)
  }

  const handleEvidence = (id) => {
    navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ghg-emission/evidenceList/${id}`)
  }

  const downloadDocs = async (id, name, type) => {

    type === 'template' ? setTemplateLoading(true) : setLoading(true);
    const docName = name?.split('.')[0];
    const options = {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
      }
    }
    await fetch(`${API_URL}/document/filedownload/${id}`, options)
      .then((response) => {
        if (response.status === 200) {
          return response.blob()
        } else {
          throw new Error('Something went wrong')
        }
      })
      .then((res) => {
        type === 'template' ? setTemplateLoading(false) : setLoading(false);
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success('Document Downloaded Successfully!')
      })
      .catch(err => {
        console.log(err)
        toast.error('Something went wrong!')
        type === 'template' ? setTemplateLoading(false) : setLoading(false);
      })
  }


  const columnData = [
    {
      headerName: 'Location Name',
      field: 'locationName',
      filter: true,
      flex: 2,
      suppressMovable: true,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      }
    },
    {
      headerName: 'Period',
      filter: true,
      flex: 1,
      sortable: true,
      suppressMovable: true,
      valueGetter: (params) => {
        return `${params.data.uploadPeriod === 'MONTH' ? moment().month(params.data.month).format('MMMM') + ', ' + params.data.year :
          defaultQuarter[params.data.quarter] ? defaultQuarter[params.data.quarter] + ', ' + params.data.year : params.data.year}`
      },
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      },
    },
    {
      headerName: 'Upload Time (UTC)',
      filter: false,
      flex: 1.5,
      sortable: true,
      suppressMovable: true,
      valueGetter: (params) => {
        return moment(params.data.fileUploadTime)?.format('DD MMM, YYYY hh:mm A')
      },
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      },
    },
    {
      headerName: 'Status',
      field: 'fileUploadStatus',
      filter: true,
      flex: 1,
      suppressMovable: true,
      tooltipValueGetter: (params) => {
        if (params.data.errorMessage !== null)
          return params.data.errorMessage
      },
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      },
    },
    {
      headerName: 'Evidences',
      width: 100,
      cellRenderer: (params) => {
        return (
          <div onClick={() => handleEvidence(params.data.emissionDocId)}>
            <Paperclip color='#006666' className='document-icon' /> <Badge className='round-badge'>{params.data.count}</Badge>
          </div>
        )
      },
      suppressMovable: true,
    },
    {
      headerName: 'Download',
      width: 100,
      cellRenderer: params => {
        return (
          <div className='mr-36' onClick={() => downloadDocs(params.data.id, params.data.originalFileName, 'emission')}>
            <Download className='document-icon' />
          </div>
        )
      },
      suppressMovable: true,
    },
  ]

  const getTemplate = async () => {
    await fetchWrapper(`${API_URL}/emissiontemplate`)
      .then((res) => {
        console.log(res.json)
        setTemplate(res.json[0]);
      }, (err) => console.log())
  }


  useEffect(() => {
    if (uploadStatus === true || deleteStatus === true)
      loadDocuments();
  }, [uploadStatus, deleteStatus])

  const onGridReady = useCallback((params) => {
    loadDocuments()
    getTemplate()
  })

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <div className='d-flex mtop-1'>
            <Breadcrumbs parent="Carbon Emission" title="List" mainTitle="GHG Emission Data Upload" />
            <div className="d-flex w-39">
              <Button color='primary' className='template-download d-flex' onClick={() => downloadDocs(template?.documentId, template?.templateName, 'template')}>
                {
                  templateLoading ? <Spinner as="span" size="sm" role="status" aria-hidden="true" className="mr-1" /> : <Download className='doc-download' />
                }
                Download Template
              </Button>
              <Button color='primary' onClick={() => setOpen(true)} className='text-right  action_button mr-13 ml-1 mr-min-5'>
                <i style={{ fontSize: '25px' }} className="icofont  icofont-plus-circle mr-1" ></i> Data Upload
              </Button>
            </div>
          </div>

          <Card className="newtable carbon-emission">
            <CardBody className='data-tables emission-table ptop-0'>
              <div className="emission-upload">
                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                  <AgGridTable
                    columnData={columnData}
                    tableData={tableData}
                    agRef={uploadRef}
                    onGridReady={onGridReady}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {open === true && <DataUploadModal value={open} setOpenModal={setOpen} setUpload={setUpload} template={template} />}
      {deleteOpen && <DeleteDocument value={deleteOpen} setOpenModal={setDeleteOpen} dataId={dataId} setDelete={setDeleteStatus} />}
    </Fragment>
  )
}

export default EmissionList;