import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import walletIcon from '../../../assets/images/wallet-icon.svg';
import walletWhite from '../../../assets/images/wallet-white.svg';

const ProgressTracker = ({ registryId, projectId, steps }) => {
    const [stepList, setStepList] = useState([]);
    const [activeStatusIndex, setIndex] = useState(9);

    const getLifecycleStatus = async () => {
        await fetchWrapper(`${API_URL}/projectregistrylifecycle/project?projectId=${projectId}`)
            .then((res) => {
                const statusIndex = stepList?.indexOf(res.json?.lifecycleStatus);
                setIndex(statusIndex + 1);
            }, (err) => console.log(err))
    }

    const getStatusList = async () => {
        await fetchWrapper(`${API_URL}/registrylifecyclestatuses/registry/${registryId}`)
            .then((res) => {
                setStepList(res.json?.map(ele => ele?.lifecycleStatus))
            }, (err) => console.log(err))
    }


    useEffect(() => {
        getStatusList()
    }, [])

    useEffect(() => {
        getLifecycleStatus()
    }, [stepList])

    return (
        <Fragment>
            {console.log(activeStatusIndex, 465465)}
            <div className="progress-tracker pro-tracker">
                <div className="d-flex stick-1">
                    {stepList.slice(0, 3).map((step, index) => (
                        <div key={index} className={`progress-stick ${index === 0 && 'bar-border-radius'} ${activeStatusIndex >= index && 'tracker-active'}`}>
                            <div className="status-text">
                                <h5>{step}</h5>
                            </div>
                            <div className={`step-icon ${activeStatusIndex >= index && 'icon-active'}`}>
                                <h2>{index + 1}</h2>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`right-rounded ${activeStatusIndex > 3 && 'active-bound'}`}></div>
                <div className="d-flex stick-2">
                    {stepList.slice(3, 6).reverse().map((step, index) => {
                        const reverseIndex = stepList.slice(3, 6)?.length + (stepList.slice(3, 6)?.length - index)
                        return (
                            <div key={index} className={`progress-stick justify-flex-start t-125px ${activeStatusIndex >= (reverseIndex) && 'tracker-active'}`}>
                                <div className="status-text">
                                    <h5>{step}</h5>
                                </div>
                                <div className={`step-icon ${activeStatusIndex >= (reverseIndex) && 'icon-active'}`}>
                                    <h2>{reverseIndex}</h2>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={`left-rounded ${activeStatusIndex > 6 && 'active-bound'}`}></div>
                <div className="d-flex stick-3">
                    {stepList.slice(6, 9).map((step, index) => (
                        <div key={index} className={`progress-stick t-240px ${activeStatusIndex >= index + 7 && 'tracker-active'}`}>
                            <div className="status-text">
                                <h5>{step}</h5>
                            </div>
                            <div className={`step-icon ${activeStatusIndex >= index + 7 && 'icon-active'}`}>
                                <h2>{index + 7}</h2>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`right-rounded t--20px 
                    ${((activeStatusIndex >= 9 && registryId === 'acb1c627-eb73-4447-a01a-3830aef71e3e') || ((activeStatusIndex > 9 && registryId !== 'acb1c627-eb73-4447-a01a-3830aef71e3e'))) && 'active-bound'}`}></div>
                <div className={`d-flex ${registryId === '7f712156-0a04-4db2-a653-85f6653a588a' && 'stick-4'}`}>

                    {registryId === '7f712156-0a04-4db2-a653-85f6653a588a' &&
                        stepList.slice(9, 13).reverse().map((step, index) => {
                            const reverseIndex = (13 - index)

                            return (
                                <div key={index} className={`progress-stick justify-flex-start t-323px w-17 ${activeStatusIndex >= reverseIndex && 'tracker-active'}`}>
                                    <div className="status-text">
                                        <h5>{step}</h5>
                                    </div>

                                    {reverseIndex === stepList?.length &&
                                        <div className={`tracker-end ${activeStatusIndex === stepList.length && 'tracker-complete-icon'} r-40px`}>
                                            <img src={activeStatusIndex === stepList.length ? walletWhite : walletIcon} height={50} width={50} alt="wallet icon" />
                                        </div>
                                    }
                                    {
                                        reverseIndex < stepList?.length && <div className={`step-icon ${activeStatusIndex >= reverseIndex && 'icon-active'}`}>
                                            <h2>{reverseIndex}</h2>
                                        </div>
                                    }

                                </div>
                            )
                        })}
                    {(registryId === 'acb1c627-eb73-4447-a01a-3830aef71e3e') &&
                        <div className={`progress-stick justify-flex-start t--28px l-729px ${activeStatusIndex >= (stepList?.length - 1) && 'tracker-active'}
                       `}>
                        {console.log(stepList?.length)}
                            <div className="status-text">
                                <h5>{stepList[(stepList?.length - 1)]}</h5>
                            </div>
                            <div className={`tracker-end ${activeStatusIndex === (stepList?.length - 1) && 'tracker-complete-icon'} r-40px`}>
                                <img src={activeStatusIndex === (stepList?.length - 1) ? walletWhite : walletIcon} height={50} width={50} alt="wallet icon" />
                            </div>
                        </div>}

                    {
                        (registryId === '30528fce-3c32-43c3-a0ae-2824f8806254' || registryId === 'e4eaaaf2-d142-11e1-b3e4-080027620cdd') && stepList?.slice(9, 11)?.reverse().map((step, index) => {
                            const reverseIndex = (11 - index)
                            return (
                                <div className={`progress-stick justify-flex-start t--28px l-431px
                                                    ${activeStatusIndex >= reverseIndex && 'tracker-active'}`}>
                                    <div className="status-text">
                                        <h5>{step}</h5>
                                    </div>
                                    {reverseIndex === stepList?.length &&
                                        <div className={`tracker-end ${activeStatusIndex === stepList.length && 'tracker-complete-icon'} r-40px`}>
                                            <img src={activeStatusIndex === stepList.length ? walletWhite : walletIcon} height={50} width={50} alt="wallet icon" />
                                        </div>
                                    }
                                    {
                                        reverseIndex < stepList?.length && <div className={`step-icon ${activeStatusIndex >= reverseIndex && 'icon-active'}`}>
                                            <h2>{reverseIndex}</h2>
                                        </div>
                                    }

                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </Fragment>
    )
}

export default ProgressTracker;

// linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,121,107,1) 32%, rgba(94,167,158,1) 100%)