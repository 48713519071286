import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import { defaultHeader, totalScopesDescription, replaceYear, scope1, ValueViewer } from './TargetData';
import { useEffect } from "react";
import ReactDatasheet from 'react-datasheet';
import moment from "moment";
import { CookieStorage } from "@aws-amplify/auth";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import Offset from "../Offset";

const SummaryView = ({ value, setopenModal, baseYear }) => {
    const [open, setOpen] = useState(value)
    const [gridData, setGridData] = useState([]);
    const [scope1Data, setScope1] = useState([]);
    const [scope2Data, setScope2] = useState([]);
    const [scope3Data, setScope3] = useState([]);
    const [offsetData, setOffsetData] = useState([]);
    const [flag, setFlag] = useState(0);

    const onClose = () => {
        setOpen(false)
        setopenModal(false)
    }

    const getScopeData = async(val) => {
        await fetchWrapper(`${API_URL}/emissionTargetDetails/targetDetails/type/GHG/scope/${val}`)
        .then((res) => {
            val === 1 ? setScope1(res?.json) : val === 2 ? 
            setScope2(res?.json) : setScope3(res?.json)
        }, (err)=> console.log(err))
    }

    const getAllScopes = async() => {
        for(let i = 1; i<=3; i++) {
            await getScopeData(i);
            if(i === 3) setFlag(!flag);
        }
    }

    
    const getOffsets = async() => {
        await fetchWrapper(`${API_URL}/offsets`)
        .then((res)=>{
            setOffsetData(res?.json);
        }, (err)=>{console.log(err)})
    } 

    const calculateScopeEmissions = (scopeData, tableData) => {
        let scopeTotal = new Array(tableData[0]?.length - 1).fill(0);
        if(scopeData?.length > 0) {
            scopeData?.map((tracking) => {
                tracking?.trackingData[0]?.targetValues?.map((item, index) => {
                    scopeTotal[index] = scopeTotal[index] + Number(item?.value)
                })
            })
        }
        return scopeTotal;
    }

    const calculateOffset = (offset, tableData) => {
        let offsetTotal = new Array(tableData[0]?.length - 1).fill(0);
        if(offset?.length > 0) {
            offset?.map((itemData) => {
                itemData?.targetValues?.map((item, index) => {
                    offsetTotal[index] = offsetTotal[index] + Number(item?.value)
                })
            })
        }
        return offsetTotal;
    }

    const calculateEmissions = (tableData) => {
        let scope1Tot, scope2Tot, scope3Tot, offsetTotal;
        scope1Tot = calculateScopeEmissions(scope1Data, tableData);
        scope2Tot = calculateScopeEmissions(scope2Data, tableData);
        scope3Tot = calculateScopeEmissions(scope3Data, tableData);
        offsetTotal = calculateOffset(offsetData, tableData);

        console.log(offsetTotal)
        for (let i = 1; i < tableData?.length; i++) {
            for (let j = 1; j<tableData[0]?.length; j++) {
                if(i === 1) {
                    tableData[i][j] = {
                        value: scope1Tot[j-1],
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp'
                    }
                }
                if(i === 2) {
                    tableData[i][j] = {
                        value: scope2Tot[j-1],
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp'
                    }
                }
                if(i === 3) {
                    tableData[i][j] = {
                        value: scope3Tot[j-1],
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp'
                    }
                }
                if(i === 4) {
                    tableData[i][j] = {
                        value: scope1Tot[j-1] + scope2Tot[j-1] + scope3Tot[j-1],
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp'
                    }
                }
                if(i === 5) {
                    tableData[i][j] = {
                        value: offsetTotal[j-1],
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp'
                    }
                }
                if(i === 6) {
                    tableData[i][j] = {
                        value: (scope1Tot[j-1] + scope2Tot[j-1] + scope3Tot[j-1]) - offsetTotal[j-1],
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp'
                    }
                }
            }
        }
        return tableData;
    }

    const loadDefaultHeader = (columnData) => {
        let getHeaderArray = [];
        const columnArray = columnData.map((i) => {
            return [
                {
                    value: i,
                    readOnly: true,
                    width: 400,
                    className: 'side-menu'
                }
            ]
        })
        if (baseYear) {
            const year = moment(baseYear).format('yyyy');
            getHeaderArray = defaultHeader?.map((item, key) => {
                return {
                    value: Number(year) + key,
                    readOnly: true,
                    width: 100,
                    className: 'menu-style'
                }

            })
        } else {
            getHeaderArray = defaultHeader?.map((item) => {
                return {
                    value: item,
                    readOnly: true,
                    width: 100,
                    className: 'menu-style'
                }
            })
        }
        columnArray[0] = [...columnArray[0], ...getHeaderArray]
        let tempArray = columnArray?.map((ele, index) => {
            if (index > 0) {
                let emptyArray;
                emptyArray = new Array(11).fill({ value: '', width: 100, readOnly: true })
                return [...ele, ...emptyArray]
            } else {
                return ele
            }
        })
        const tableData = [...tempArray];
        const calculatedData = calculateEmissions(tableData)
        setGridData(calculatedData)
    }
    

    useEffect(() => {
        getAllScopes();
        getOffsets();
    }, [])

    useEffect(()=>{
        loadDefaultHeader(totalScopesDescription)
    }, [flag])

    return (
        <Fragment>
            <div >
                <Modal centered={true} isOpen={open} size='xl' className="summary-modal">
                    <ModalHeader className="d-flex">
                        <H3 attrH3={{ className: 'modal-title-color' }}>GHG Inventory - Summary View</H3>
                        <X className="x-button" onClick={onClose} />
                    </ModalHeader>
                    <ModalBody >
                        <div style={{ width: '100%', height: '100%' }} className=''>
                            <ReactDatasheet
                                data={gridData}
                                valueRenderer={(cell) => cell?.value}
                                valueViewer={ValueViewer}
                            />
                        </div>
                        
                    </ModalBody>
                </Modal>
            </div>

        </Fragment>
    )
}

export default SummaryView;