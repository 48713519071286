import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { createProject } from '../../Common/Constant';
import { useSelector } from 'react-redux';
import HeaderCard from '../../Common/Component/HeaderCard';
import fetchWrapper from '../../Common/api/fetchWrapper';
import moment from 'moment';
import { API_URL } from '../../Common/Config/Config';
import { Eye, Edit } from 'react-feather';
import { defaultStatus, defaultStatusColorCodes, capitalize, defaultFrequency, statusIconList, goalDescriptions, sdgImgList } from '../../Common/api/helper';
import { Tooltip } from 'react-tooltip';
import LayoutLoader from '../../Common/Component/LayoutLoader/LayoutLoader';
import AgGridTable from '../../Common/Component/AgGridTable';
import ReactCountryFlag from "react-country-flag";
const AsrAdminProjectList = (props) => {
  const [tableData, setTableData] = useState([]);
  const projectRef = useRef(null);

  const preValidatedStatus = ['ACCEPTED', 'SUBMITTED_TO_REGISTRY', 'APPROVED_BY_REGISTRY', 'ACTIVE',
    'SUSPENDED', 'RETIRED', 'RETURNED']

  const ActionButtons = (params) => {
    const options = [
      { value: 1, label: 'View' },
      { value: 2, label: 'Edit' }
    ]
    return (
      <div className='d-flex justify-center'>
        <div>
          <Link to={`${process.env.PUBLIC_URL}/asr-admin/view-project/${params.data.id}`} >
            <Eye className={`action-icon`} />
          </Link>
        </div>
        <div className='ml-2'>
          <Link to={`${process.env.PUBLIC_URL}/asr-admin/edit-project/${params.data.id}`}
            className={`${preValidatedStatus.includes(params.data.status) === true ? 'disable-icon' : ''}`} >
            <Edit className={` ${preValidatedStatus.includes(params.data.status) === true ? 'disable-icon mt-10' : 'edit-icon'}`} />
          </Link>
        </div>
      </div>
    )
  }

  const columnData = [
    {
      headerName: 'Name',
      field: 'name',
      filter: true,
      flex: 2.5,
      sortable: true,
      suppressMovable: true,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      }
    },
    {
      headerName: 'Status',
      flex: 1,
      tooltipValueGetter: (params) => defaultStatus[params.data.status],
      cellRenderer: params => {
        return <div >
            <img src={statusIconList[params.data.status]} height={50} width={50} className='padd-8px'/>
        </div>
      },
      suppressMovable: true,
    },
    {
      headerName: 'UN SDGs',
      flex: 3,
      cellRenderer: (params) => {
        let goalList = params.data.sustainableDevelopmentGoals?.split(',');
        return params.data.sustainableDevelopmentGoals ? <div>{goalList.map((i, index) => (
          <a data-tooltip-id="my-tooltip-no-padding" data-tooltip-content={goalDescriptions[i]}>
            {/* {`${i}${index !== (goalList?.length - 1) ? ',' : ''}`} */}
            <img src={sdgImgList[i]} height={50} width={50} className='pad-5px'/>
          </a>
        ))}
          <Tooltip id="my-tooltip-no-padding" />
        </div> : '--'
      },
      suppressMovable: true,
    },
    {
      headerName: 'Project Type',
      field: 'subCategoryItemName',
      filter: true,
      flex: 3,
      sortable: true,
      suppressMovable: true,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      }
    },
    {
      headerName: 'Assessment Date',
      filter: true,
      flex: 1.5,
      sortable: true,
      suppressMovable: true,
      valueGetter: (params) => {
        return params.data.assessment_due_date === null ? '' : `${moment(new Date(params.data.assessment_due_date)).format('DD MMM, YYYY')}`
      },
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      }
    },
    {
      headerName: 'Country',
      field: 'country',
      tooltipField: 'country',
      cellRenderer: (params) => {
        return (
          <div className='ml-1'>
            <ReactCountryFlag countryCode={params.data.countryCode} style={{
              width: '2em',
              height: '2em',
            }} svg />
          </div>
        )
      },
      width: 80,
      sortable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Actions',
      width: 100,
      cellRendererSelector: params => {
        return {
          component: ActionButtons
        }
      }
    }
  ]

  const loadData = async () => {
    projectRef?.current?.api?.showLoadingOverlay();
    const options = {
      method: 'GET'
    }
    await fetchWrapper(`${API_URL}/projects`, options)
      .then((res) => {
        const data = res.json;
        setTableData(data)
        projectRef?.current?.api?.hideOverlay();
      })
      .catch((err) => {
        projectRef?.current.api.hideOverlay();
        if (err.status === 404) {
          projectRef?.current?.api?.showNoRowsOverlay();
        }
        console.log(err)
      });
  }


  const onGridReady = useCallback(async (params) => {
    loadData()
  })


  const state = useSelector(state => state.projectArray);

  return (
    <Fragment>
      <LayoutLoader />
      <Breadcrumbs parent="Projects" title="List" mainTitle="Projects" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className=" newtable">
              <CardBody className='data-tables'>
              <div className='project-data'>
                  <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                    <AgGridTable
                      columnData={columnData}
                      tableData={tableData}
                      agRef={projectRef}
                      onGridReady={onGridReady}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default AsrAdminProjectList;