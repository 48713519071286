import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Table, Button, Input, FormGroup, Label } from 'reactstrap';
import { Breadcrumbs } from "../../AbstractElements";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { Link, useNavigate } from "react-router-dom";
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { currencyFormatter, capitalize } from '../../Common/api/helper'
import { toast } from 'react-toastify';
import ExchangeOrderDetails from "../../Common/Component/ExchangeOrder/ExchangeOrderDetails";
import { useSelector } from 'react-redux';
import LayoutLoader from "../../Common/Component/LayoutLoader/LayoutLoader";
import AgGridTable from "../../Common/Component/AgGridTable";
import { useRef } from 'react';
import { useCallback } from 'react';

const AdminOrders = () => {
    const [showFilter, setShowFilter] = useState(false)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [orderData, setOrderData] = useState();
    const [orderStatus, setOrderStatus] = useState('NEW');

    const orderRef = useRef(null)

    const loadOrderData = async () => {
        orderRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/orders/status/${orderStatus}`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    orderRef?.current?.api?.hideOverlay();
                    setData(res.json)
                }
            }, (err) => {
                orderRef?.current?.api?.hideOverlay();
                console.log(err)
            })
    }

    const defaultTypes = {
        ASR_USER: 'user',
        ASM_ADMIN: 'asm-admin',
        ASM_USER: 'asm-user',
        ASR_ADMIN: 'asr-admin',
        ASO_USER: 'user',
        ASO_ADMIN: 'aso-admin',
        SYS_ADMIN: 'admin',
        SYS_USER: 'back-office'
    }

    const userType = sessionStorage.getItem('userType')

    const processOrder = async (id) => {
        const options = {
            method: "POST",
            body: {
                orderId: id
            }
        }
        await fetchWrapper(`${API_URL}/orders/processOrder`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    toast.success('Order Processed Successfully!')
                    loadOrderData()
                }
            }, (error) => {
                console.log(error)
            })
    }

    const handleModal = (item) => {
        setOpen(true)
        setOrderData(item)
    }

    const columnData = [
        {
            headerName: 'Order Id',
            field: 'orderId',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Unit Type',
            field: 'currency',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Quantity',
            field: 'quantity',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Project Name',
            field: 'projectName',
            filter: true,
            flex: 3,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Asset Owner',
            field: 'assetOwnerName',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Value',
            field: 'quantity',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return currencyFormatter(params.data.baseValue, params.data.baseCurrency)
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'text-right'
        },
        {
            headerName: 'Monetary value',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return currencyFormatter(params.data.monetaryValue, params.data.baseCurrency)
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'text-right'
        },
        orderStatus !== 'FILLED' && {
            headerName: 'Status',
            flex: 2,
            cellRenderer: (params) => {
                if(orderStatus === 'NEW'){
                     return   <div>
                            <Button color="info" outline={params.data.orderStatus === 'NEW' ? false : true} className={`${params.data.orderStatus === 'NEW' ? '' : 'disable-icon'} process-button`}
                                disabled={params.data.orderStatus != 'NEW' ? true : false} onClick={() => processOrder(params.data.orderId)}>Process</Button>
                        </div>
                }
                if(orderStatus === 'OPEN'){
                     return <div>
                        <Button color="primary" outline={params.data.orderStatus === 'OPEN' ? false : true} className={`${params.data.orderStatus === 'OPEN' ? '' : 'disable-icon'} fill-button`}
                            disabled={params.data.orderStatus != 'OPEN' ? true : false} onClick={() => handleModal(params.data)}>Fill Order</Button>
                    </div>
                }
            }
        }
    ]

    useEffect(() => {
        loadOrderData()
    }, [orderStatus, open])

    const onGridReady = useCallback(async (params) => {
        loadOrderData();
    })

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs parent="Orders" mainTitle='Order List' title="List" />
            {open && <ExchangeOrderDetails value={open} setOpenModal={setOpen} orderData={orderData} />}
            <Row>
                <Col lg={12}>
                    <Card className="order-trading  newtable">
                        <CardBody className="data-tables ">
                            <div className="order-status mbottom-2">
                                <Label>Status</Label>
                                <select className="form-select order-select" name="orderStatus" defaultValue={orderStatus}
                                    onChange={(e) => setOrderStatus(e.target.value)}>
                                    <option value='NEW'>New</option>
                                    <option value='OPEN'>Open</option>
                                    <option value='FILLED'>Filled</option>
                                </select>
                            </div>
                            <div className="order-table">
                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridTable
                                        columnData={columnData}
                                        tableData={data}
                                        agRef={orderRef}
                                        onGridReady={onGridReady}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default AdminOrders;