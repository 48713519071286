import React, { Fragment, useCallback, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component-with-filter';
import { Btn, H4, H3 } from '../../../AbstractElements';
import { Spinner } from 'reactstrap';

const FilterComponent = ({ title, setShowFilter, status, iconComponent }) => {
    const handleFilterSwitch = (val) => {
        console.log(val)
        let getFilterInput = document.querySelectorAll('input[data-filter-id]')
            getFilterInput?.forEach((e)=> {
                e.setAttribute("value", 'abcd')
                console.log(e)
            });
        console.log(document.querySelectorAll('input[data-filter-id]'))
        setShowFilter(val)
    }

    return (
<>  
             <div className='w-100 d-flex align-items-center justify-content-between'>
                {
                    iconComponent ? iconComponent : <H3 >{title}</H3>
                }
                {
                    status === true && <div className='d-flex'>
                        <H4 attrH4={{ className: "mr-1 mb-0 title-color" }}>Filter</H4>
                        <label className="switch mb-15">
                            <input type="checkbox" id="togBtn"
                                onChange={(e) => handleFilterSwitch(e.target.checked)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                }
            </div>
        
    </>
    )
    
};




const CustomLoader = () => (
    <div className='mtop-2'>
        <Spinner color='success' type='border' />
    </div>
)

const DataTableComponent = ({ tableData, columnsData, title, setShowFilter, filterStatus, loading, iconComponent }) => {

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(tableData);
    
    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
        console.log(state)
    }, []);



    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    
    const subHeaderComponentMemo = useMemo(() => {
        // const handleClear = () => {
        //     if (filterText) {
        //         setResetPaginationToggle(!resetPaginationToggle);
        //         setFilterText('');
        //     }
        // };

        return (
            <FilterComponent setShowFilter={setShowFilter} title={title} 
                status={filterStatus === undefined || null ? true : filterStatus} iconComponent={iconComponent} />
        );
    }, [filterText, resetPaginationToggle]);

    const handleDelete = () => {
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
            setToggleDelet(!toggleDelet);
            setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
            setSelectedRows('');
        }
    };

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                width: '30%',
                minWidth: '50px !important',
                fontSize: '17px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
                width: '30%',
                minWidth: '50px !important'
            },
        },
    }

    return (
        <Fragment>
            {
                (selectedRows.length !== 0) &&
                <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                    <Btn attrBtn={{ color: 'danger', onClick: () => handleDelete() }}>Delete</Btn>
                </div>
            }

            <DataTable
                data={tableData}
                keyField
                columns={columnsData}
                striped={true}
                center={true}
                pagination
                customStyles={customStyles}
                // onSelectedRowsChange={handleRowSelected}
                subHeader={(title || filterStatus === true) ? true : false }
                subHeaderComponent={subHeaderComponentMemo}
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
                progressPending={loading}
                progressComponent={<CustomLoader />}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
        </Fragment>
    )
}
export default DataTableComponent