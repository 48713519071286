import React, { Fragment, useState } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const EditUser = () => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [userType, setUserType] = useState()

    return (
        <Fragment>
          <H3>Edit User</H3>
                    <Row>
                        <Col lg='12'>
                            <Card>
                                <CardBody>
                                    <Form >
                                        <Row>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>First Name</Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="firstName"
                                                        type='text'
                                                        value={'Saravanan'}
                                                        {...register('firstName', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.firstName && errors.firstName.type === 'required' && 'First Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>Last Name</Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="lastName"
                                                        type='text'
                                                        value={'Krishnan'}
                                                        {...register('lastName', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.lastName && errors.lastName.type === 'required' && 'Last Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>Email ID</Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="email"
                                                        type='email'
                                                        value={'abc@gmail.com'}
                                                        {...register('email', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.email && errors.email.type === 'required' && 'First Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>Phone</Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="phone"
                                                        type='text'
                                                        value={'+91 9876543215'}
                                                        {...register('phone', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.phone && errors.phone.type === 'required' && 'Last Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='6'>
                                                <Label>User Type</Label>
                                                <select className="form-select" value={'2'}  
                                                onChange={(e)=>setUserType(e.target.value)}
                                                {...register('userType', { required: true })} >
                                                    <option value=''>Select User Type</option>
                                                    <option value="1">{'Admin'}</option>
                                                    <option value="2">{'Power User'}</option>
                                                    <option value="3">{'Operational User'}</option>
                                                    <option value="4">{'Demo User'}</option>
                                                </select>
                                                <span className='text-danger'>{errors.userType && errors.userType.type === 'required' && 'User Type is required'}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center justify-content-center " style={{ marginTop: "25px" }}>
                                                <Link to={`${process.env.PUBLIC_URL}/admin/user-management`}><Button color="primary" >Edit</Button></Link>
                                                <Link to={`${process.env.PUBLIC_URL}/admin/user-management`}><Button color="primary" outline style={{ marginLeft: "20px" }}> Cancel</Button></Link>
                                            </div>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                
        </Fragment>
    )
}

export default EditUser;