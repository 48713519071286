import React, { useState } from "react";
import { Fragment } from "react";
import { H4, H3 } from '../../../AbstractElements'
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import { baselineEmission, projectEmission } from "./CalculationHelper";
import EmissionModal from "./EmissionModal";

const SolarCalculation = () => {
    const [cardShow, setCardShow] = useState(false);
    const [cardData, setCardData] = useState();

    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            <div>
                                <div>
                                    <H3 attrH3={{ className: 'f-w-600' }}>ERy(Carbon credits) = BEy - PEy</H3>
                                </div>
                                <div className="mtop-1">
                                    <H4 attrH4={{ className: 'f-w-600' }}>Description</H4>
                                    <ul>
                                        <li>
                                            <p>BEy = Baseline emission in a year(y) (tCO2e)</p>
                                        </li>
                                        <li>
                                            <p>PEy = Project emission in a year(y) (tCO2e)</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="mtop-1">
                                <div>
                                    <H3 attrH3={{ className: 'f-s-17 f-w-600 ' }}>Baseline Emission(BEy) = EGPJy(Net Electricity Displaced) * EFCO2y(Emission factor)</H3>
                                </div>
                                <div className="mtop-1">
                                    <H4 attrH4={{ className: 'f-w-600' }}>Description</H4>
                                    <ul>
                                        <li>
                                            <p>EGPJy = Quantity of net electricity displaced as a result of the implementation of the 
                                                CDM(Clean Development Mechanism) project activity in year 
                                                (y) (MWh) = Capacity*Plant load factor*24 hrs*365 days</p>
                                        </li>
                                        <li>
                                            <p>EFCO2y = Emission factor (tCO2/MWh) = 0.93</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <p>Example</p>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Plant Load Factor</th>
                                        <th>Emission Factor</th>
                                        <th >
                                            <div className="d-flex cur-pointer">
                                                Baseline Emission 
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex cur-pointer">
                                                Project Emission 
                                            </div>
                                        </th>
                                        <th>Carbon Credits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2024</td>
                                        <td className="text-right">0.20</td>
                                        <td className="text-right">0.93</td>
                                        <td className="text-right">3600.89</td>
                                        <td className="text-right">0.00</td>
                                        <td className="text-right">3600.89</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td className="text-right">0.20</td>
                                        <td className="text-right">0.93</td>
                                        <td className="text-right">3600.89</td>
                                        <td className="text-right">0.00</td>
                                        <td className="text-right">3600.89</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                cardShow && <EmissionModal value={cardShow} setOpenModal={setCardShow} data={cardData} />
            }
        </Fragment>
    )
}

export default SolarCalculation;