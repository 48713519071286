import React, { useState, Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Link, } from 'react-router-dom';
import { Form, FormGroup, Row, Col, Table, CardBody, Card, Spinner } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import "../../../assets/css/requireDoc.css";
import { Submit, Back, AddMoreDocuments, DOCUMENTS_DETAILS, PROJECTS_DATA } from '../../../Common/Constant';
import uploadSignimg from "../../../assets/images/upload-sign.svg"
import { useDispatch, useSelector, useStore } from 'react-redux';
import fetchWrapper from '../../../Common/api/fetchWrapper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Download } from 'react-feather';
import { API_URL } from '../../Config/Config';
import DataTableComponent from '../DataTable/DataTableComponent';
import Dropzone from 'react-dropzone-uploader';

const RequiredDocuments = ({ setSteps, projectId, preData }) => {

    const { register, handleSubmit, formState: { errors }, resetField, trigger, clearErrors } = useForm();
    const [fileArray, setFileArray] = useState([]);
    const [sizeExceed, setSizeExceed] = useState(false);
    const [documentType, setDocumentType] = useState()
    const [otherValue, setOtherValue] = useState()
    const [registry, setRegistry] = useState(preData?.registryName)
    const dispatch = useDispatch();
    const [documentList, setDocumentList] = useState()
    const [loading, setLoading] = useState(false)
    const [err, setError] = useState(false);
    const getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }

    }
    const handleUpload = async (files) => {
        if (errors?.documentType || errors?.otherDocumentType) {
            toast.error('Please select document type')
            return
        } else {
            if (fileArray && fileArray.length < 5) {
                setLoading(true)
                const authToken = sessionStorage.getItem('accessToken')
                const file = files[0]?.file
                let data = new FormData();
                data.append('file', file)
                data.append('documentTypeName', documentType)
                data.append('otherDocumentTypeName', otherValue)
                const options = {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${authToken}`
                    },
                    body: data
                }
                await fetchWrapper(`${API_URL}/document?projectId=${projectId}`, options)
                    .then((res) => {
                        if (res.ok) {
                            let arr = [...fileArray];
                            arr.push(res.json);
                            setFileArray(arr);
                            setSizeExceed(false)
                            resetField('documentType')
                            resetField('otherDocumentType')
                            setLoading(false)
                            toast.success('Document Uploaded successfully!')
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.error(error)
                        if (error.status == 412) {
                            toast.error('File with the same name already exists')
                        }
                    })
            }
        }
    }

    const handleDocument = (val) => {
        setDocumentType(val)
        clearErrors('documentType')
    }

    const handleOtherDocument = (val) => {
        setOtherValue(val)
        clearErrors();
    }

    const loadDocuments = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/document/${projectId}`, options)
            .then((res) => {
                console.log(res)
                setFileArray(res.json)
            })
            .catch(err => console.log(err))
    }

    const loadData = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/document/type/${registry}`, options)
            .then(res => {
                console.log(res)
                setDocumentList(res.json);
            })
    }


    useEffect(() => {
        loadDocuments()
        loadData()
        setRegistry(preData?.registryName)
    }, [err])

    useEffect(() => {
        loadDocuments()
        loadData()
        setRegistry(preData?.registryName)
    }, [])

    const handleBack = () => {
        setSteps(pre => pre - 1)
    }

    const handleNext = async () => {
        setSteps(pre => pre + 1)
    }


    const downloadDocs = async (id, name) => {
        const options = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        }
        await fetch(`${API_URL}/document/filedownload/${id}`, options)
            .then((response) => response.blob())
            .then((res) => {
                const blob = new Blob([res], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}`);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
                toast.success('Document Downloaded Successfully!')
            })
            .catch(err => console.log(err))
    }

    return (
        <Fragment>
            <h2 className="pb-3">Upload Documents</h2>
            <Row>
                <Col sm="6">
                    <FormGroup className="mb-3">
                        <label>Document Type</label>
                        <select className='form-select'
                            {...register('documentType', { required: true })}
                            onChange={(e) => handleDocument(e.target.value)}
                        >
                            <option value=''>Select Document Type</option>
                            {
                                documentList && documentList.length > 0 && documentList?.map((item) => {
                                    return <option value={item.documentTypeName}>{item.documentTypeName}</option>
                                })
                            }
                        </select>
                        <span className="text-danger">{errors.documentType && errors.documentType.type === 'required'
                            && 'Select Document Type'}</span>
                    </FormGroup>
                </Col>
                <Col sm='6'>
                    {
                        documentType === 'Others' && <FormGroup>
                            <label>Other Document Type <span className="text-danger">*</span></label>
                            <input className={`form-control mb-1`} placeholder='' type="text"
                                name='otherDocumentType'
                                {...register('otherDocumentType', { required: true })}
                                onChange={(e) => handleOtherDocument(e.target.value)} />
                            <span className="text-danger">{errors.otherDocumentType && errors.otherDocumentType.type === 'required'
                                && 'Other Document Type Required'}</span>
                        </FormGroup>
                    }
                </Col>
            </Row>
            <Row>
                <Col sm='6' >
                    <FormGroup className="mb-3 mtop-1">
                        <div className="dz-message needsclick">
                            <Dropzone
                                maxFiles={1}
                                multiple={false}
                                accept='.xlsx,.xls, .pdf, .doc, .txt, .jpeg, .kml, .xlsm'
                                getUploadParams={getUploadParams}
                                onChangeStatus={async () => {
                                    const result = await trigger();
                                }}
                                onSubmit={handleUpload}
                                maxSizeBytes={4194304}
                                inputContent="Drag and Drop Files"
                            />
                        </div>
                        <div>
                            {
                                fileArray && fileArray.length === 5 && <span className='text-danger'>Only 5 docs are allowed</span>
                            }
                            {
                                sizeExceed && <span className='text-danger'>File size should be below 4 MB</span>
                            }
                        </div>
                        <p className='no-wrap mtop-1'>[Note:Supported formats xlsx,xls,PDF,Doc,jpeg,KML and file size should be 4MB per file]</p>
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col sm='4' >
                    <div className='mtop-1'>
                        <Btn attrBtn={{ color: 'primary', className: 'btn-font', onClick: handleNext }}>{"Next"}</Btn>
                        <Btn attrBtn={{ color: 'secondary', onClick: handleBack, className: 'btn-font ml-1', }}>{Back}</Btn>
                    </div>
                </Col>
            </Row>
            <Card className='notes-card'>
                <CardBody>
                    <Row>
                        <Col lg='12'>
                            <Table striped bordered>
                                <thead className='f-16'>
                                    <tr className='table-header-bg'>
                                        <th >Document Name</th>
                                        <th >Type</th>
                                        <th >Size</th>
                                        <th >Download</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fileArray && fileArray.length > 0 && fileArray.map((item) => {
                                            return (
                                                <tr scope="row" className='table-body'>
                                                    <td>{item.originalFileName}</td>
                                                    <td>{item.documentTypeName === 'Others' ? item.otherDocumentTypeName : item.documentTypeName}</td>
                                                    <td>{(item.size / 1024).toFixed()}KB</td>
                                                    <td>
                                                        <div onClick={() => downloadDocs(item.id, item.originalFileName)}>
                                                            <Download className='document-icon' />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            {
                                fileArray && fileArray?.length == 0 && <div className='d-flex-center mtop-2'>No Records Found..</div>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default RequiredDocuments