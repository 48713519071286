import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from "../../../../AbstractElements";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import CreditsTable from "./CreditsTable";
import SaleChart from "../SaleChart";
import LayoutLoader from '../../LayoutLoader/LayoutLoader'
import { useNavigate } from "react-router";
import SaleChartDesign from "../SaleChartDesign";
import WalletDetailsPage from "../Details";

const WalletSummary = () => {
    const [loading, setLoading] = useState(false)
    const [unCertifiedData, setUncertified] = useState();
    const [certifiedData, setCertified] = useState();
    const [pendingData, setPending] = useState();
    const [currency, setCurrency] = useState();
    const navigate = useNavigate();

    const loadCreditsData = async () => {
        setLoading(true)
        await fetchWrapper(`${API_URL}/projectcredits/newwalletSummary/assetOwner`)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    const data = res.json.summaryDetails;
                    setCurrency(res.json.orgCurrency)
                    const certified = data?.certified && Object.entries(data.certified).map(([id, obj]) => ({ id, ...obj }))
                    setCertified(certified)
                    const unCertified = data?.uncertified && Object.entries(data?.uncertified).map(([id, obj]) => ({ id, ...obj }))
                    setUncertified(unCertified)
                    const pending = data?.pending && Object.entries(data.pending).map(([id, obj]) => ({ id, ...obj }))
                    setPending(pending)
                }
            }, (error) => {
                setLoading(false)
                console.log(error)
            })
    }

    useEffect(() => {
        loadCreditsData()
    }, [])

    const handleClick = () => {
        const type = window.location.pathname.split('/')[1]
        navigate(`${process.env.PUBLIC_URL}/${type}/carbon-trading/trading`)
    }
    
    return (
        <Fragment>
            <LayoutLoader value={loading}/>
            <Container fluid={true}>
                <Row>
                    <Col lg='12'>
                        <div className="d-flex mtop-1">
                            <Breadcrumbs parent="Wallet" title="Summary" mainTitle='Wallet' />
                            <div className="text-right mtop-1 action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i class="fa-solid fa-cart-shopping mr-1"></i>{"Trading"}
                                </div>
                            </div>
                        </div>
                        <Row>
                            <SaleChartDesign certified={certifiedData} unCertified={unCertifiedData} pending={pendingData} 
                            currency={currency} />
                        </Row>
                        <WalletDetailsPage />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default WalletSummary;