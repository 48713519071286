import React, { Fragment } from "react";
import subscribeImg from '../../../assets/images/subscribe.jpeg';
import { H3 } from "../../../AbstractElements";

const NoDataComponent = () => {
    return (
        <Fragment>
            <div className="subscribe-card">
                <div className="w-55 dashboard-content">
                    <img src={subscribeImg} width={350} height={350} />
                    <H3 attrH3={{ className: 'credits-table text-center' }}>
                        Currenty no data available for GHG Emissions. Please upload data to proceed further.
                    </H3>
                </div>
            </div>
        </Fragment>
    )
}

export default NoDataComponent;