import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import Loader from '../../Common/Layout/Loader';
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { Container, Row, Col, Card, CardBody, CardHeader, Table } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import LayoutLoader from "../../Common/Component/LayoutLoader/LayoutLoader";
import { defaultOrgType } from "../../Common/api/helper";
import { Upload } from "react-feather";
import image from '../../assets/images/document/default-image.jpg';
import { toast } from "react-toastify";

const ViewCustomer = () => {
    const [data, setData] = useState();
    const [uploadLoading, setUploadLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const orgId = useParams()?.id;

    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);

    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization/${id}`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
            })
            .catch((error) => {
                console.debug(error)
            })
    }

    const uploadLogo = async (e) => {
        let file = e.target.files[0];
        let result;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            var img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                var height = img.naturalHeight;
                var width = img.naturalWidth;
                if (height > 48 && width > 48) {
                    console.log(false)
                    result = false;
                    setErrorMessage('Image should be in 48X48 or 96X48 pixel')
                    setError(true)
                } else {
                    console.log(true)
                    result = true;
                    setError(false)
                }
            }
        }
        if (file?.size > 524288) {
            setError(true)
            setErrorMessage('The File Size should be lessthan 512 KB')
            return
        } else {
            setUploadLoading(true)
            const authToken = sessionStorage.getItem('accessToken')
            let data = new FormData();
            data.append('logo', file);
            data.append('orgId', orgId)
            const options = {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${authToken}`
                },
                body: data
            }
            await fetchWrapper(`${API_URL}/organization/uploadLogo`, options)
                .then((res) => {
                    console.log(res)
                    setUploadLoading(false)
                    toast.success('Logo Uploaded Successfully!')
                    loadProjectData();
                }, (err) => {
                    console.log(err)
                    setUploadLoading(false)
                    toast.error('Something went wrong')
                })
        }
        console.log(result)
    }
    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true} className="ecommerce-page">
                <Row>
                    <Col lg={9}>
                        <Card className="customer-view mtop-2">
                            <CardBody className="browser-table box-shadow-0 pt-0">
                                <div className="mtop-1">
                                    <h2>Organization Profile</h2>
                                </div>
                            
                                <div className="table-responsive mb-0 mtop-2">
                                    <Table className="table ">
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Name</td><td>{data?.companyName}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Website</td><td className="text-left w-17">{data?.website}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Organization Type</td><td>{defaultOrgType[data?.orgType]}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Address</td><td className="text-left w-17">{data?.address}</td>
                                            </tr>
                                            <tr className={data?.orgType !== 'ASSET_OWNER' && "bottom-none"}>
                                                <td className="fw-td-bold">State</td><td>{data?.state}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Country</td><td className="text-left w-17">{data?.country}</td>
                                            </tr>
                                            {
                                                data?.orgType === 'ASSET_OWNER' && <tr className="bottom-none">
                                                    <td className="fw-td-bold">Powered By</td><td>{data?.tenantName}</td>
                                                    <td></td>
                                                    <td className="fw-td-bold ">Currency</td><td className="w-17">{data?.currency}</td>
                                                </tr>
                                            }

                                        </tbody>
                                    </Table>
                                </div>
                                <div className="mtop-2">
                                    <div>
                                        <h2>Primary Contact</h2>
                                    </div>
                                    <div className="mtop-2">
                                        <div className="d-flex">
                                            <div className="d-flex">
                                                <h5 className="mb-0 f-15"><i className="fa fa-user"></i> Contact Person: </h5><p className="ml-1 f-15">{data?.firstName + ' ' + data?.lastName}</p>
                                            </div>
                                            <div className="d-flex ml-5">
                                                <h5 className="mb-0 f-15"><i className="fa fa-envelope"></i> Email ID: </h5><p className="ml-1 f-15">{data?.email}</p>
                                            </div>
                                        </div>

                                        <div className="d-flex mtop-2">
                                            <h5 className="mb-0 f-15"><i className="fa fa-phone"></i> Phone: </h5><p className="ml-1 f-15">{data?.phoneNumber}</p>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <div className="mtop-2-half ml-1">
                            <img className="ml-3r" src={image} height={100} width={100} />
                            <div className="mtop-1">
                                <div>
                                    <span className="text-danger">{error === true && errorMessage}</span>
                                </div>
                                <div className="mtop-1">
                                    <label className="logo-pic">
                                        {uploadLoading === true ? <i class="fa fa-spinner fa-spin"></i> : <Upload />}
                                        <span className="ml-1">Upload Logo</span>
                                        <input type='file'
                                            onChange={uploadLogo}
                                            hidden accept='.png'
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="ml-1">
                                <p className="logo-notes">[Note: Maximum Logo size should be less than 512 KB]</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div>
                    <Link to={`${process.env.PUBLIC_URL}/admin/customer-list`}>
                        <Btn attrBtn={{ color: 'primary' }} >
                            Back
                        </Btn>
                    </Link>
                </div>

            </Container>
        </Fragment>
    )
}

export default ViewCustomer;