import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";

const HoldingCompanies = ({ activeTab, setActive }) => {
    const [holdingCompanies, setHoldingCompanies] = useState([...Array(3)].map((_, index) => ({ serialNo: index + 1 })));
    
    const getHoldingCompany = async() => {
        await fetchWrapper(`${API_URL}/emissionsReport/holdingsData`)
        .then((res) => {
            console.log(res.json)
            setHoldingCompanies(res.json?.map((item) => ({...item, participateBusinessInitiatives: true ? 'Yes' : 'No'})))
        }, (err) => {
            console.log(err)
        })
    }

    useEffect(()=>{
        getHoldingCompany()
    }, [])
    
    const holdingColumns = [
        {
            headerName: 'S.No',
            field: 'serialNo',
            editable: false,
            filter: false,
            flex: 0.5,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Name',
            field: 'companyName',
            editable: true,
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Type',
            field: 'relationshipType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Holding', 'Subsidiary', 'Associate', 'Joint Venture']
            },
            filter: false,
            editable: true,
            flex: 1,
            sortable: false
        },
        {
            headerName: '% of shares held by listed entity',
            field: 'percentageSharesHeld',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.percentageSharesHeld ?
                    thousandSeparator(params?.data?.percentageSharesHeld.toFixed(2)) : ''}</div>;
            },
            flex: 1.5,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Effective Date',
            field: 'effectiveDate',
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Participate in Responsibility? (Yes/No)',
            field: 'participateBusinessInitiatives',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        },
    ];

    const onSubmit = async() => {
        const data = holdingCompanies?.filter(item => Object.keys(item)?.length > 2)?.map(({effectiveDate, ...item}) => 
            ({...item, participateBusinessInitiatives: 'Yes' ? true : false}));
        const options = {
            method: 'POST',
            body: {
                holdingsData: data
            }
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
        .then((res) => {
            console.log(res.json)
        }, (err) => {
            console.log(err)
            if(err.status === 200) {
                setActive(2)
            }
        })
    }
    return (
        <Fragment>
            <h3>Holding Companies</h3>
            <div className="mtop-1 company-profile">
                <h5>Names of holding / subsidiary / associate companies / joint ventures: </h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={holdingCompanies}
                        columnData={holdingColumns}
                    />
                    <CustomFooter handleAdd={() => setHoldingCompanies([...holdingCompanies, { id: holdingCompanies?.length + 1 }])} />
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="primary" className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default HoldingCompanies;