import React, { Fragment, useState } from "react";
import {
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardHeader,
} from "reactstrap";
import { P } from '../../../AbstractElements'
import Chart from "react-apexcharts";

const AlmostThere = () => {
  const Chart1 = {
    series: [67],
    options: {
      chart: {
        height: 150,
        type: 'radialBar',
        width: "150px"
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
              offsetY: 76,
              fontSize: '22px',
              color: undefined,
              formatter: function (val) {
                return val + "%";
              }
            }
          }
        },
      },
      fill: {
        colors: ['#00796B']
      },
      stroke: {
        dashArray: 6
      },
      labels: [''],
    },
  };
  return (
    <Fragment>
      <Card className="recent-order d-flex align-items-center justify-content-center " style={{ height: '326px' }}>
        <CardHeader className="pb-0" style={{paddingTop: '4%'}}>
          <h4 style={{ color: '#2b7704', marginTop: '20px' }}>ALMOST THERE</h4>
        </CardHeader>
        <CardBody className="pb-0">
          <div id="chart-widget5" style={{ width: '100%' }}>
            <Chart options={Chart1.options} series={Chart1.series} type="radialBar" height={250} />
            <div className="d-flex align-items-center justify-content-between pb-3 mtop-1">
              <div className="margin_p_bottom">
                <P>Total Saved</P>
                <h4 style={{ color: '#2b7704' }}>3,740 Kg CO<sub>2</sub></h4>
              </div>
              <div className="margin_p_bottom">
                <P>Your Goal</P>
                <h4 style={{ color: '#2b7704' }}>4,740 Kg CO<sub>2</sub> </h4>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default AlmostThere