import React, { Fragment, useCallback, useEffect, useRef, useState, useReducer } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { thousandSeparator } from '../../api/helper';
import { Plus, Trash2, XCircle } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { uuidGenerator } from "./ghgHelper";
import { useSelector } from "react-redux";
import moment from "moment";
import { alertFunction, notifyFunction, storeData, deleteCacheData, getCacheData } from "./ghgHelper";


let error = false, table2Error = false, table3Error = false;
const RefrigerationData = ({ setSteps, preData, status,
    setRfgMaterial, setRfgSimplified, setRfgScreening,
    rfgMaterial, rfgSimplified, rfgScreening, timeStamp }) => {
    const [data, setData] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })));
    const [table2Data, setTable2Data] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })))
    const [table3Data, setTable3Data] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })))
    const [noOfRow, setNoOfRow] = useState(1);
    const [noOfRowTable2, setNoOfRowTable2] = useState(1);
    const [noOfRowTable3, setNoOfRowTable3] = useState(1);
    const [gasData, setGasData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [gasesArray, setGasesArray] = useState([]);
    const materialRef = useRef();
    const simplifiedRef = useRef();
    const screeningRef = useRef();
    const [equipmentData, setEquipmentData] = useState([]);
    const [rowIndex, setRowIndex] = useState([]);
    const [isUpdate, setUpdate] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [type, setType] = useState();
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState()
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }

    const getGasData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/RFGMaterialBalance/${preData?.countryCode}`)
            .then((res) => {
                const data = res.json;
                setGasData(data);
                const gases = data?.map((item) => item.gas);
                setGasesArray(gases);
            }, (err) => console.log(err))
    }

    const getEquipmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/RFRGScreening/${preData?.countryCode}`)
            .then((res) => {
                const data = res.json?.map((item) => item?.typeOfEquipment)
                setEquipmentData(data);
            }, (err) => console.log(err))
    }

    const populateMaterial = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setData(tempData);
        currentData?.length > 0 && setType(1);
    }

    const populateSimplifiedMaterial = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        const data = currentData?.map(({ gas, ...rest }) => ({ gasSimplified: gas, ...rest }))
        let tempData = [...data, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setTable2Data(tempData);
        currentData?.length > 0 && setType(2);
    }

    const populatedScreening = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        const data = currentData?.map(({ gas, ...rest }) => ({ gasScreening: gas, ...rest }))
        let tempData = [...data, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setTable3Data(tempData);
        currentData?.length > 0 && setType(3)
    }

    const loadMaterialData = (savedData, cTimestamp) => {
        if (rfgMaterial?.length > 0 && (savedData?.length === 0 || !savedData)) {
            console.log('HI')
            populateMaterial(rfgMaterial)
        } else if (rfgMaterial?.length === 0 && savedData?.length > 0) {
            populateMaterial(savedData)
        } else if(rfgMaterial?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, rfgMaterial, populateMaterial)
            } else { //saved is latest
                notifyFunction(rfgMaterial, populateMaterial)
            }
        }
    }

    const loadSimplifiedData = (savedData, cTimestamp) => {
        if (rfgSimplified?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateSimplifiedMaterial(rfgSimplified)
        } else if (rfgSimplified?.length === 0 && savedData?.length > 0) {
            populateSimplifiedMaterial(savedData)
        } else if(rfgSimplified?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, rfgSimplified, populateSimplifiedMaterial)
            } else { //saved is latest
                notifyFunction(rfgSimplified, populateSimplifiedMaterial)
            }
        }
    }

    const loadScreeningData = (savedData, cTimestamp) => {
        if (rfgScreening?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populatedScreening(rfgScreening)
        } else if (rfgScreening?.length === 0 && savedData?.length > 0) {
            populatedScreening(savedData)
        } else if(rfgScreening?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, rfgScreening, populatedScreening)
            } else { //saved is latest
                notifyFunction(rfgScreening, populatedScreening)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        if(cacheData?.rfgMaterialData?.length > 0 || cacheData?.rfgSimplifiedMaterialData?.length > 0 || cacheData?.rfgScreeningData?.length > 0) {
            if(cacheData?.rfgMaterialData?.length > 0) {
                loadMaterialData(cacheData?.rfgMaterialData, cacheData?.modifiedTs)
            } else if(cacheData?.rfgSimplifiedMaterialData?.length > 0) {
                loadSimplifiedData(cacheData?.rfgSimplifiedMaterialData, cacheData?.modifiedTs)
            } else if(cacheData?.rfgScreeningData?.length > 0) {
                loadScreeningData(cacheData?.rfgScreeningData, cacheData?.modifiedTs)
            }
        } else {
            loadMaterialData()
            loadSimplifiedData()
            loadScreeningData() 
        }
    }

    useEffect(() => {
        if (status?.length > 0) {
            console.log(status)
            setType(status?.some((ele) => ele?.category === 'RFGMaterialBalance') ? 1 :
                status?.some((ele) => ele?.category === 'RFGSimplifiedMaterialBalance') ? 2 :
                    status?.some((ele) => ele?.category === 'RFGScreeningMethod') ? 3 : 1)
        } else (
            setType(1)
        )
        getGasData()
        getEquipmentData()
        getCache()
        // getGrids()

    }, [])

    const handleClear = async(params, type) => {
        let tempData = type === 1 ? [...data] : type === 2 ? [...table2Data] : [...table3Data];
        const updatedRowData = tempData.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                : ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        let tempObj;
        if(type === 1) {
            tempObj = {
                ...cacheData,
                rfgMaterialData: updatedRowData?.filter(ele => typeof ele?.id === 'string'),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if(type === 2) {
            tempObj = {
                ...cacheData,
                rfgSimplifiedMaterialData: updatedRowData?.filter(ele => typeof ele?.id === 'string'),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if(type === 3) {
            tempObj = {
                ...cacheData,
                rfgScreeningData: updatedRowData?.filter(ele => ele?.sourceId),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        }
        await deleteCacheData(keyObject, tempObj)
        type === 1 ? setData(updatedRowData) : type === 2 ? setTable2Data(updatedRowData) : setTable3Data(updatedRowData);
    };

    const table1ColumnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('gas') || params.data.hasOwnProperty('inventoryChange')
                    || params.data.hasOwnProperty('transferredAmount') || params.data.hasOwnProperty('capacityChange')) {
                    params.data.sourceId = `RFG-${params.node.rowIndex + 1}`;
                    return params.data.sourceId
                }
            },
            filter: false,
            width: 100,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Gas',
            field: 'gas',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: gasesArray,
            },
            editable: true,
            filter: false,
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'Inventory Change',
            field: 'inventoryChange',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.inventoryChange ?
                    thousandSeparator(params?.data?.inventoryChange?.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
        },
        {
            headerName: 'Transferred Amount',
            field: 'transferredAmount',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.transferredAmount ?
                    thousandSeparator(params?.data?.transferredAmount?.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
        },
        {
            headerName: 'Capacity Change',
            field: 'capacityChange',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.capacityChange ?
                    thousandSeparator(params?.data?.capacityChange?.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.gas) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gas);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.gas) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gas);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.gas) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gas);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    (params.data.sourceId) ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 1)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const table2Columns = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('gasSimplified')
                    || params.data.hasOwnProperty('newCharge')
                    || params.data.hasOwnProperty('newCapacity')
                    || params.data.hasOwnProperty('unitsRecharge')
                    || params.data.hasOwnProperty('disposedCapacity')
                    || params.data.hasOwnProperty('disposedRecovered')) {
                    params.data.sourceId = `RFG-${params.node.rowIndex + 1}`;
                    return params.data.sourceId;
                }
            },
            filter: false,
            width: 100,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Gas',
            field: 'gasSimplified',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: gasesArray,
            },
            editable: true,
            filter: false,
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'New Units',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Charge',
                    field: 'newCharge',
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.newCharge ?
                            thousandSeparator(params?.data?.newCharge?.toFixed(2)) : ''}</div>;
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Capacity',
                    field: 'newCapacity',
                    cellEditor: 'agNumberCellEditor',
                    editable: true,
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.newCapacity ?
                            thousandSeparator(params?.data?.newCapacity?.toFixed(2)) : ''}</div>;
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ],
        },
        {
            headerName: 'Existing Units',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Recharge',
                    flex: 1,
                    field: 'unitsRecharge',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.unitsRecharge ?
                            thousandSeparator(params?.data?.unitsRecharge?.toFixed(2)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
            ],
        },
        {
            headerName: 'Disposed Units',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Capacity',
                    flex: 1,
                    field: 'disposedCapacity',
                    cellEditor: 'agNumberCellEditor',
                    editable: true,
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.disposedCapacity ?
                            thousandSeparator(params?.data?.disposedCapacity?.toFixed(2)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
                {
                    headerName: 'Recovered',
                    flex: 1,
                    field: 'disposedRecovered',
                    cellEditor: 'agNumberCellEditor',
                    editable: true,
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.disposedRecovered ?
                            thousandSeparator(params?.data?.disposedRecovered?.toFixed(2)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
            ],
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.gasSimplified) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasSimplified);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.gasSimplified) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasSimplified);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.gasSimplified) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasSimplified);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    (params.data.sourceId) ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 2)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const table3Columns = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('typeOfEquipment')
                    || params.data.hasOwnProperty('gasScreening')
                    || params.data.hasOwnProperty('newUnitsCharge')
                    || params.data.hasOwnProperty('capacityOperatingUnits')
                    || params.data.hasOwnProperty('capacityDisposedUnits')) {
                    params.data.sourceId = `RT-${params.node.rowIndex + 1}`
                }
                return (params.data.hasOwnProperty('typeOfEquipment')
                    || params.data.hasOwnProperty('gasScreening')
                    || params.data.hasOwnProperty('newUnitsCharge')
                    || params.data.hasOwnProperty('capacityOperatingUnits')
                    || params.data.hasOwnProperty('capacityDisposedUnits')) ? `RT-${params.node.rowIndex + 1}` : ''
            },
            filter: false,
            flex: 0.5,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Type of Equipment',
            field: 'typeOfEquipment',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: equipmentData,
            },
            editable: true,
            filter: false,
            flex: 1.5,
            sortable: false,
        },
        {
            headerName: 'Gas',
            field: 'gasScreening',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: gasesArray,
            },
            editable: true,
            filter: false,
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'New Units',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Charge',
                    flex: 1,
                    field: 'newUnitsCharge',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.newUnitsCharge ?
                            thousandSeparator(params?.data?.newUnitsCharge?.toFixed(2)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
            ],
        },
        {
            headerName: 'Capacity',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Operating Units',
                    flex: 1,
                    field: 'capacityOperatingUnits',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.capacityOperatingUnits ?
                            thousandSeparator(params?.data?.capacityOperatingUnits?.toFixed(2)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
                {
                    headerName: 'Disposed Units',
                    flex: 1,
                    field: 'capacityDisposedUnits',
                    cellEditor: 'agNumberCellEditor',
                    editable: true,
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.capacityDisposedUnits ?
                            thousandSeparator(params?.data?.capacityDisposedUnits?.toFixed(2)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
            ],
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.gasScreening) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasScreening);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.gasScreening) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasScreening);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.gasScreening) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasScreening);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    (params.data.hasOwnProperty('typeOfEquipment')
                        || params.data.hasOwnProperty('gasScreening')
                        || params.data.hasOwnProperty('newUnitsCharge')
                        || params.data.hasOwnProperty('capacityOperatingUnits')
                        || params.data.hasOwnProperty('capacityDisposedUnits')) ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 3)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const createCache = async (data, type) => {
        let tempData;
        if(type === 1) {
            tempData = {
                ...cacheData,
                rfgSimplifiedMaterialData:[],
                rfgScreeningData: [],
                rfgMaterialData: data?.filter(ele => typeof ele?.id === 'string'),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if(type === 2) {
            tempData = {
                ...cacheData,
                rfgScreeningData: [],
                rfgMaterialData: [],
                rfgSimplifiedMaterialData: data?.filter(ele => typeof ele?.id === 'string'),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if(type === 3) {
            tempData = {
                ...cacheData,
                rfgSimplifiedMaterialData:[],
                rfgMaterialData: [],
                rfgScreeningData: data?.filter(ele => ele?.sourceId),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = (event, type) => {
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === true || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === true) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        type === 1 ? createCache(data, 1) : type === 2 ? createCache(table2Data, 2) : createCache(table3Data, 3)
    }

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    };

    const addingRows = (val, type) => {
        type === 1 ? setNoOfRow(val) : type === 2 ? setNoOfRowTable2(val) : setNoOfRowTable3(val);
    }

    const addRows = (type) => {
        const typeOfRow = type === 1 ? noOfRow : type === 2 ? noOfRowTable2 : noOfRowTable3;
        const rowData = [];
        for (let i = 1; i <= typeOfRow; i++) {
            const length = type === 1 ? data?.length : type === 2 ? table2Data?.length : table3Data?.length;
            rowData.push({
                id: length + i, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            })
        }
        type === 1 ? setData([...data, ...rowData]) : type === 2 ? setTable2Data([...table2Data, ...rowData]) : setTable3Data([...table3Data, ...rowData]);
    }

    const getGridData = (type) => {
        if (type === 1) {
            if (materialRef.current) {
                const tableState = data
                    .filter(rowNode => (rowNode.gas))
                    .map((rowNode, index) => {
                        if (rowNode.inventoryChange
                            && rowNode.transferredAmount && rowNode.units
                            && rowNode.capacityChange && rowNode.gas) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                gas: rowNode.gas,
                                inventoryChange: parseFloat(rowNode.inventoryChange),
                                transferredAmount: parseFloat(rowNode.transferredAmount),
                                capacityChange: parseFloat(rowNode.capacityChange),
                                units: rowNode.units,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndex.push(rowNode.rowIndex + 1)
                            return {};
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    error = true;
                } else {
                    error = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        } else if (type === 2) {
            if (simplifiedRef.current) {
                const tableState = table2Data
                    .filter(rowNode => (rowNode.gasSimplified))
                    .map((rowNode, index) => {
                        if (rowNode.newCharge &&
                            rowNode.newCapacity && rowNode.unitsRecharge && rowNode.units
                            && rowNode.disposedCapacity && rowNode.disposedRecovered && rowNode.gasSimplified) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                gas: rowNode.gasSimplified,
                                newCharge: parseFloat(rowNode.newCharge),
                                newCapacity: parseFloat(rowNode.newCapacity),
                                unitsRecharge: parseFloat(rowNode.unitsRecharge),
                                disposedCapacity: parseFloat(rowNode.disposedCapacity),
                                disposedRecovered: parseFloat(rowNode.disposedRecovered),
                                units: rowNode.units,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndex.push(rowNode.rowIndex + 1)
                            return {};
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    table2Error = true;
                } else {
                    table2Error = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        } else {
            if (screeningRef.current) {
                const tableState = table3Data
                    .filter(rowNode => rowNode.sourceId)
                    .map((rowNode, index) => {
                        if (rowNode.newUnitsCharge && rowNode.typeOfEquipment && rowNode.sourceId
                            && rowNode.capacityOperatingUnits && rowNode.units && rowNode.sourceId
                            && rowNode.capacityDisposedUnits) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                typeOfEquipment: rowNode.typeOfEquipment,
                                gas: rowNode.gasScreening,
                                units: rowNode.units,
                                newUnitsCharge: parseFloat(rowNode.newUnitsCharge),
                                capacityOperatingUnits: parseFloat(rowNode.capacityOperatingUnits),
                                capacityDisposedUnits: parseFloat(rowNode.capacityDisposedUnits),
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndex.push(rowNode.rowIndex + 1)
                            return {};
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    table3Error = true;
                } else {
                    table3Error = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        }
    }

    const onSubmit = async () => {
        const material = type === 1 ? getGridData(1) : [];
        const simplifiedMaterial = type === 2 ? getGridData(2) : [];
        const screenData = type === 3 ? getGridData(3) : [];
        if (error === true || table2Error === true || table3Error === true) {
            toast.error(`Please fill missing values in row ${[...new Set(rowIndex)].join(',')}`)
            return
        } else {
            setRfgMaterial(material);
            setRfgSimplified(simplifiedMaterial);
            setRfgScreening(screenData);
            setSteps(0);
        }
    }

    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}> Organization-Wide Refrigeration Gas CO<sub>2</sub> Equivalent Emissions</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            <div className='d-flex mtop-1'>
                <h5 className="mb-0 f-15 f-w-600">Refrigeration Type:</h5>
                <input className="form-check-input radio_animated ml-1" type='radio' value={1}
                    checked={type === 1 ? true : false}
                    onChange={(e) => setType(1)}
                    disabled={(table2Data?.some(ele => ele?.createdBy) || table3Data?.some(ele => ele?.createdBy)) ? true : false}
                /> <lable>Material Balance</lable>
                <input className="form-check-input radio_animated ml-1" type='radio' value={2}
                    checked={type === 2 ? true : false}
                    onChange={(e) => setType(2)}
                    disabled={(data?.some(ele => ele?.createdBy) || table3Data?.some(ele => ele?.createdBy)) ? true : false}
                /> <lable>Simplified Material Balance</lable>
                <input className="form-check-input radio_animated ml-1" type='radio' value={3}
                    checked={type === 3 ? true : false}
                    onChange={(e) => setType(3)}
                    disabled={(table2Data?.some(ele => ele?.createdBy) || data?.some(ele => ele?.createdBy)) ? true : false}
                /> <lable>Screening Method</lable>
            </div>

            {deleteLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div style={{ opacity: deleteLoading ? '0.2' : '1' }}>
                {
                    type === 1 && <div className="mtop-1">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={data?.filter(ele => ele?.isDelete === false)}
                                columnData={table1ColumnData}
                                agRef={materialRef}
                                onCellValueChanged={(e) => onCellValueChanged(e, 1)}
                            />
                            <div className="ag-custom-footer">
                                <div className="d-flex ">
                                    <button className="add-row-button" onClick={() => addRows(1)}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                    <input
                                        type="number"
                                        className="ml-1 input-number"
                                        value={noOfRow}
                                        onChange={(e) => addingRows(e.target.value, 1)}
                                    />
                                    <h5 className="ml-1">rows</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    type === 2 && <div className="mtop-1  ag-group-header">
                        <div className="ag-ref-simply-table">
                            <div style={{ width: '100%' }} className="ag-datasheet">
                                <AgGridDatasheet
                                    tableData={table2Data?.filter(ele => ele?.isDelete === false)}
                                    columnData={table2Columns}
                                    gridOptions={gridOptions}
                                    agRef={simplifiedRef}
                                    onCellValueChanged={(e) => onCellValueChanged(e, 2)}
                                />
                                <div className="ag-custom-footer">
                                    <div className="d-flex ">
                                        <button className="add-row-button" onClick={() => addRows(2)}>
                                            <Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add
                                        </button>
                                        <input
                                            type="number"
                                            className="ml-1 input-number"
                                            value={noOfRowTable2}
                                            onChange={(e) => addingRows(e.target.value, 2)}
                                        />
                                        <h5 className="ml-1">rows</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    type === 3 && <div className="mtop-1 ag-group-header ">
                        <div className="ag-ref-screening">
                            <div className="table-3">
                                <div style={{ width: '100%' }} className="ag-datasheet">
                                    <AgGridDatasheet
                                        tableData={table3Data?.filter(ele => ele?.isDelete === false)}
                                        columnData={table3Columns}
                                        gridOptions={gridOptions}
                                        agRef={screeningRef}
                                        onCellValueChanged={(e) => onCellValueChanged(e, 3)}
                                    />
                                    <div className="ag-custom-footer">
                                        <div className="d-flex ">
                                            <button className="add-row-button" onClick={() => addRows(3)}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                            <input
                                                type="number"
                                                className="ml-1 input-number"
                                                value={noOfRowTable3}
                                                onChange={(e) => addingRows(e.target.value, 3)}
                                            />
                                            <h5 className="ml-1">rows</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>


        </Fragment>
    )
}

export default RefrigerationData;