import React, { Fragment, useState, useEffect } from "react";
import { dummytabledata } from "./AsmUserlist";
import UserForm from "../../Common/Component/UserForm";

const EditAsmUser = () => {
    const [ data, setData ] = useState();
    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[4])
    }, []);

    const loadData = (id) => {
        let data = dummytabledata.find(i => i.id == id)
        console.log(data);
        setData(data)
    }

    return (
        <Fragment>
            <UserForm action={'Edit'} title={'Edit Proponent'} navigateUrl={`${process.env.PUBLIC_URL}/asm-admin/user-list`} preData={data} />
        </Fragment>
    )
}

export default EditAsmUser;