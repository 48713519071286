import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Table, Button, Input, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { Breadcrumbs } from "../../../../AbstractElements";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import Loader from "../../../Layout/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { currencyFormatter } from "../../../api/helper";
import LayoutLoader from '../../LayoutLoader/LayoutLoader';
import OrderTransition from "../../OrderTransition";
import { useSelector } from "react-redux";
import { defaultUserType } from "../../../api/helper";
import { H3, H4 } from "../../../../AbstractElements";
import { X } from "react-feather";

const OrderDetails = ({ value, setOpenModal, dataId, orgId }) => {
    // const dataId = window.location.pathname.split('/')[4];
    const [orderData, setOrderData] = useState()
    const [submittedBy, setSubmittedBy] = useState()
    const [transactionDate, setTransactionDate] = useState();
    const [open, setOpen] = useState(false);
    const [currentModalOpen, setCurrent] = useState(value)
    // const orgId = useParams()?.orgId;
    const userType = useSelector(state => state.user?.userType);

    const loadOrder = async () => {
        const url = ['ASM_ADMIN', 'ASM_USER'].includes(userType) ? `/orders/${dataId}?assetOrgId=${orgId}`
            : `/orders/${dataId}`;
        await fetchWrapper(`${API_URL}${url}`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setOrderData(res.json)
                    const findNewStatusObj = res.json.orderTransitions.find(item => item.orderStatus === 'NEW');
                    const findFilledObj = res?.json?.orderTransitions?.find(i => i.orderStatus === 'FILLED');
                    if (findFilledObj)
                        setTransactionDate(findFilledObj?.timestamp)
                    if (findNewStatusObj) {
                        setSubmittedBy(findNewStatusObj.createdBy)
                    }
                }
            }, (err) => {
                console.log(err)
            })
    }
    const onClose = () => {
        setCurrent(false)
        setOpenModal(false)
    }

    useEffect(() => {
        loadOrder()
    }, [])

    return (
        <Fragment>
            {/* <LayoutLoader /> */}
            
            <Modal isOpen={currentModalOpen} size='lg' centered={true}>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Order Details</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                        <h3 className="projetname" >{orderData?.projectName}</h3>
                            <div className="d-flex justify-between header">
                                <p className="mb-0"> Order ID : <span>{orderData?.orderId}</span></p>
                                <p className="mb-0"> Order Date : <span>{moment(orderData?.orderCreatedDate).format('DD MMM, YYYY')}</span></p>
                            </div>
                            <div className="details">
                                

                                <div className="back_table mt-4 px-2" style={{ width: '100%' }}>
                                    <Table className="Table w-12-set  " >
                                        <tbody>
                                            <tr>
                                                <td className="w_46">
                                                    Unit Type
                                                </td>
                                                <td>:</td>
                                                <td className="clr_green">{orderData?.currency}</td>
                                            </tr>
                                            <tr>
                                                <td className="w_46">
                                                    Quantity
                                                </td>
                                                <td>:</td>
                                                <td className="clr_green">{orderData?.quantity}</td>
                                            </tr>
                                            <tr>
                                                <td className="w_46">
                                                    Status
                                                </td>
                                                <td>:</td>
                                                <td className="clr_green">{orderData?.orderStatus}</td>
                                            </tr>
                                            <tr>
                                                <td className="w_46">
                                                    Monetary Value
                                                </td>
                                                <td>:</td>
                                                <td className="clr_green">{orderData?.monetaryValue && orderData?.orgCurrency && currencyFormatter(parseFloat(orderData?.monetaryValue).toFixed(2), orderData?.orgCurrency)}</td>
                                            </tr>
                                            <tr>
                                                <td className="w_46">
                                                    Submitted By
                                                </td>
                                                <td>:</td>
                                                <td className="clr_green">{submittedBy}</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="mtop-2 d-flex">
                                <Button color="secondary" onClick={onClose}>Close</Button>
                                <Button color="primary" className="ml-1" onClick={() => setOpen(true)} >View Transitions</Button>
                            </div>
                            {
                                open === true && <OrderTransition value={open} setOpenModal={setOpen} orderTrasitionData={orderData?.orderTransitions} />
                            }
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}
export default OrderDetails;