import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Table, Button, Input, FormGroup, Label } from 'reactstrap';
import { Breadcrumbs } from "../../AbstractElements";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { Link, useNavigate } from "react-router-dom";
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { currencyFormatter, capitalize } from '../../Common/api/helper'
import { toast } from 'react-toastify';
import ExchangeOrderDetails from "../../Common/Component/ExchangeOrder/ExchangeOrderDetails";

const BOOrders = () => {
    const [showFilter, setShowFilter] = useState(false)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [orderData, setOrderData] = useState();
    const [orderStatus, setOrderStatus] = useState('NEW');

    const loadOrderData = async () => {
        setLoading(true)
        await fetchWrapper(`${API_URL}/orders/status/${orderStatus}`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setLoading(false)
                    setData(res.json)
                }
            }, (err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const defaultTypes = {
        ASR_USER: 'user',
        ASM_ADMIN: 'asm-admin',
        ASM_USER: 'asm-user',
        ASR_ADMIN: 'asr-admin',
        ASO_USER: 'user',
        ASO_ADMIN: 'aso-admin',
        SYS_ADMIN: 'admin',
        SYS_USER: 'back-office'
    }

    const userType = sessionStorage.getItem('userType')

    const processOrder = async (id) => {
        console.log(id)
        const options = {
            method: "POST",
            body: {
                orderId: id
            }
        }
        await fetchWrapper(`${API_URL}/orders/processOrder`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    toast.success('Order Processed Successfully!')
                    loadOrderData()
                }
            }, (error) => {
                console.log(error)
            })
    }

    const handleModal = (item) => {
        setOpen(true)
        setOrderData(item)
    }

    const tableColumns = [
        {
            name: 'Order Id',
            selector: row => `${row.orderId}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'Unit Type',
            selector: row => `${row.currency}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'Quantity',
            selector: row => `${row.quantity}`,
            sortable: true,
            center: false,
            filterable: showFilter,

        },
        {
            name: 'Project Name',
            selector: row => `${row.projectName}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'Asset Owner',
            selector: row => `${row.assetOwnerName}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'Value',
            selector: row => `${currencyFormatter(row.baseValue, row.baseCurrency)}`,
            sortable: true,
            center: false,
            right: false,
            filterable: showFilter,
        },
        {
            name: 'Monetary Value',
            selector: row => `${currencyFormatter(row.monetaryValue, row.orgCurrency)}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        // {
        //     name: 'Status',
        //     selector: row => `${capitalize(row.orderStatus)}`,
        //     sortable: true,
        //     center: false,
        //     filterable: showFilter,
        // },
        
        orderStatus !== 'FILLED' && {
            name: "Action",
            selector: (row) => (
                <>
                    {
                        orderStatus === 'NEW' &&
                        <div>
                            <Button color="info" outline={row.orderStatus === 'NEW' ? false : true} className={`${row.orderStatus === 'NEW' ? '' : 'disable-icon'} process-button`}
                                disabled={row.orderStatus != 'NEW' ? true : false} onClick={() => processOrder(row.orderId)}>Process</Button>
                        </div>
                    }
                    {
                        orderStatus === 'OPEN' && <div>
                            <Button color="success" outline={row.orderStatus === 'OPEN' ? false : true} className={`${row.orderStatus === 'OPEN' ? '' : 'disable-icon'} fill-button`}
                                disabled={row.orderStatus != 'OPEN' ? true : false} onClick={() => handleModal(row)}>Fill</Button>
                        </div>
                    }
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]

    useEffect(() => {
        loadOrderData()
    }, [orderStatus, open])

    return (
        <Fragment>
            <Breadcrumbs parent="Orders" mainTitle='Order List' title="List" />
            {open && <ExchangeOrderDetails value={open} setOpenModal={setOpen} orderData={orderData} />}
            <Row>
                <Col lg={12}>
                    <Card className="order-trading  newtable">
                        <CardBody className="data-tables ">
                                <div className="order-status">
                                    <Label>Status</Label>
                                    <select className="form-select order-select" name="orderStatus" defaultValue={orderStatus}
                                        onChange={(e) => setOrderStatus(e.target.value)}>
                                        <option value='NEW'>New</option>
                                        <option value='OPEN'>Open</option>
                                        <option value='FILLED'>Filled</option>
                                    </select>
                                </div>
                           

                            <DataTableComponent tableData={data} columnsData={tableColumns} 
                                loading={loading} setShowFilter={setShowFilter} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default BOOrders;