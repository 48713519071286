import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { LI, H4, UL } from '../../../AbstractElements';
import { useContext } from 'react';
import CustomizerContext from '../../../Common/_helper/Customizer';
import { Tooltip } from 'react-tooltip';

const SidebarMenulist = ({ MENUITEMS, t, toggletNavActive }) => {
    const { setClickEvent } = useContext(CustomizerContext);
    const { pathname } = useLocation()
    const [activeMenu, setActiveMenu] = useState()
    const [childActiveMenu, setChildActiveMenu] = useState()
    const [subChildActiveMenu, setSubChildActiveMenu] = useState()
    const [childActiveLink, setChildActiveLink] = useState()
    const [subChildActiveLink, setsubChildActiveLink] = useState('Default')

    useEffect(() => {
        if (pathname) {
            MENUITEMS && MENUITEMS.length > 0 && MENUITEMS.forEach((Items) => {
                Items.Items && Items.Items.filter((childrens) => {
                    if (childrens.children ? childrens.children.filter((path) => {
                        if (path.path) {
                            if (pathname === path.path) {
                                setChildActiveMenu()
                                setChildActiveLink()
                                setsubChildActiveLink(path.title); return true
                            } return false;
                        } else {
                            if (path.children && path.children.filter((child) => {
                                if (child.path === pathname) {
                                    setChildActiveLink(child.title);
                                    return true
                                } return false;
                            }).length > 0) { 
                                setsubChildActiveLink()
                                setChildActiveMenu(path.title); return true 
                            } return false;
                        }
                    }).length > 0 : (childrens.path === pathname && setActiveMenu(childrens.title))) { setActiveMenu(childrens.title); return true }
                    return false;
                })
            })
        }
    }, [pathname, MENUITEMS])

    return (
        <Fragment>
            {MENUITEMS && MENUITEMS.length > 0 && MENUITEMS?.map((Item, i) => (
                < Fragment key={i} >
                    {/* <LI attrLI={{ className: 'sidebar-main-title simple-list' }} key={i}>
                        <div>
                            <H4 attrH4={{ className: "lan-1" }}>{Item.menutitle}</H4>
                        </div>
                    </LI> */}
                    {Item.Items.map((menuItem, i) => (
                        <LI attrLI={{ className: `sidebar-list simple-list hoverd menu-li ${i === 0 && 'pad-top-25'}` }} key={i}>
                            {menuItem.type === 'sub' && (
                                <a href="javascript"
                                    className={`sidebar-link sidebar-title ${menuItem.title === activeMenu ? 'active' : ''}`}
                                    onClick={(event) => { event.preventDefault(); setActiveMenu(menuItem.title !== activeMenu && menuItem.title); toggletNavActive(); }} >
                                    <div className="d-flex">
                                        <div>{menuItem.icon !== undefined && <menuItem.icon />}</div>
                                        <span className='lan-3'>{t(menuItem.title)}</span>
                                    </div>
                                    <div className="according-menu">
                                        {menuItem.title === activeMenu ? (<i className="fa fa-angle-down me-0"></i>) : (<i className="fa fa-angle-right me-0"></i>)}
                                    </div>
                                </a>
                            )}
                            {menuItem.type === 'link' && (
                                <Link to={menuItem.path} className={`sidebar-link menu-title ${menuItem.title === activeMenu ? 'active' : ''}`}
                                    onClick={() => { setActiveMenu(menuItem.title); toggletNavActive(); setClickEvent(pre => !pre) }}>
                                    <div className="d-flex">
                                        <div>{menuItem.icon !== undefined && <menuItem.icon />}</div><span>{(menuItem.title)}</span>
                                    </div>
                                </Link>
                            )}
                            {menuItem.children && (
                                <UL attrUL={{
                                    className: `simple-list sidebar-submenu ${menuItem.title === activeMenu ? 'd-block' : 'd-none'}`,
                                }}>
                                    {menuItem.children.map((childrenItem, index) => {
                                        return (
                                            <LI key={index}>
                                                {childrenItem.type === 'sub' && (
                                                    <a href="javascript" className={`${childActiveMenu === childrenItem.title ? 'active' : ''}`}
                                                        onClick={(event) => 
                                                        { event.preventDefault(); setChildActiveMenu(childrenItem.title !== childActiveMenu && childrenItem.title); toggletNavActive() }}>
                                                        <div className="d-flex">
                                                            {/* {childrenItem.icon !== undefined && <childrenItem.icon className={(menuItem.title === activeMenu) ? 'active-svg-icon' : ''} />} */}
                                                            <div>{(childrenItem.title)}</div>
                                                        </div>
                                                        <div className="according-menu">
                                                            {childActiveMenu === childrenItem.title ? (<i className="fa fa-angle-down me-0"></i>) :
                                                                (<i className={`fa fa-angle-right me-0`} ></i>)}
                                                        </div>
                                                    </a>
                                                )
                                                }
                                                {
                                                    childrenItem.type === 'link' && (
                                                        <Link
                                                            to={childrenItem.path} className={`${childrenItem.title === subChildActiveLink ? 'active' : ''}`}
                                                            onClick={() => { setsubChildActiveLink(childrenItem.title); toggletNavActive(); setClickEvent(pre => !pre) }} >
                                                            <div className="d-flex">
                                                                {/* {childrenItem.icon !== undefined && <childrenItem.icon className={(menuItem.title === activeMenu) ? 'active-svg-icon' : ''} />} */}
                                                                <div>{(childrenItem.title)}</div></div>
                                                        </Link>
                                                    )
                                                }
                                                {
                                                    childrenItem.children && (
                                                        <UL attrUL={{
                                                            className: 'simple-list nav-sub-childmenu submenu-content',
                                                            style: { display: `${childrenItem.title === childActiveMenu ? 'block' : 'none'}` }
                                                        }}>
                                                            {childrenItem.children.map(
                                                                (childrenSubItem, key) => (
                                                                    <LI key={key}>
                                                                        {childrenSubItem.type === 'sub' && (
                                                                            <a href="javascript" className={`${subChildActiveMenu === childrenSubItem.title ? 'active' : ''}`}
                                                                                onClick={(event) => { event.preventDefault(); setSubChildActiveMenu(childrenSubItem.title !== subChildActiveMenu && childrenSubItem.title); toggletNavActive() }}>
                                                                                <div className="d-flex">
                                                                                    {/* {childrenSubItem.icon !== undefined && <childrenSubItem.icon className={(menuItem.title === activeMenu) ? 'active-svg-icon' : ''} />} */}
                                                                                    <div>{(childrenSubItem.title)}</div></div>
                                                                                <div className="according-menu">
                                                                                    {subChildActiveMenu === childrenSubItem.title ? (<i className="fa fa-angle-down me-0"></i>) :
                                                                                        (<i className={`fa fa-angle-right me-0`}></i>)}
                                                                                </div>
                                                                            </a>
                                                                        )
                                                                        }
                                                                        {childrenSubItem.type === 'link' && (
                                                                            <Link to={childrenSubItem.path} className={`${childActiveLink === childrenSubItem.title ? 'active' : ''}`}
                                                                                onClick={() => { setChildActiveLink(childrenSubItem.title); toggletNavActive(); setClickEvent(pre => !pre) }}>
                                                                                {t(childrenSubItem.title)}
                                                                            </Link>
                                                                        )}
                                                                        {childrenSubItem?.children && (<UL attrUL={{
                                                                            className: 'simple-list nav-sub-childmenu submenu-content',
                                                                            style: { display: `${childrenSubItem.title === subChildActiveMenu ? 'block' : 'none'}` }
                                                                        }}>
                                                                            {
                                                                                childrenSubItem?.children?.map(
                                                                                    (childrenLastSub, key) => (
                                                                                        <LI key={key}>
                                                                                            {childrenLastSub.type === 'link' && (
                                                                                                <Link to={childrenLastSub.path} className={`${childActiveLink === childrenLastSub.title ? 'active' : ''}`}
                                                                                                    onClick={() => { setChildActiveLink(childrenLastSub.title); toggletNavActive(); setClickEvent(pre => !pre) }}>
                                                                                                    {t(childrenLastSub.title)}
                                                                                                </Link>
                                                                                            )}
                                                                                        </LI>
                                                                                    ))
                                                                            }
                                                                        </UL>)
                                                                        }
                                                                    </LI>
                                                                )
                                                            )}
                                                        </UL>
                                                    )
                                                }
                                            </LI>
                                        );
                                    })}
                                </UL>
                            )}
                        </LI>
                    ))}
                </Fragment>
            ))
            }
        </Fragment >
    )
}
export default SidebarMenulist