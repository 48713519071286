import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import ExchangeRateForm from "../Forms/ExchangeRateForm";
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { Breadcrumbs } from '../../../AbstractElements';

const EditExchangeRate = () => {
    const [ data, setData ] = useState();

    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[4])
    }, []);

    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/exchangerates/${id}`, options)
        .then((res)=> {
            console.log(res)
            setData(res.json)
        })
        .catch((error) => {
            console.debug(error)
        })
    }

    
    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs parent="Exchange Rates" title="List" mainTitle="Exchange Rates" />
            <ExchangeRateForm action={'Edit'}  navigateUrl={`${process.env.PUBLIC_URL}/admin/exchange-rate-list`} preData={data} />
        </Fragment>
    )
}

export default EditExchangeRate;