import React from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "reactstrap";

const ButtonLoader = ({loaderText, className, color}) => {
    return (
        <Button color={color} className={className} disabled>
            <div className="d-flex">
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
            />
            {loaderText}...
            </div>
            
        </Button>
    )
}

export default ButtonLoader;