import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";
import { currencySymbol } from "../../api/currencyData";

const ExchangeRateForm = ({ title, action, navigateUrl, cancel, preData, setAdd }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(action === 'Edit' ? preData : '')
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({ defaultValues: preData });
    const navigate = useNavigate();
    const [currencyList, setCurrencyList] = useState([]);
    const [sourceCurrencyList, setSourceList] = useState([]);

    const getCurrency = async() => {
        await fetchWrapper(`${API_URL}/currency`)
        .then((res)=>{
            const sourceList = res.json?.filter((i) => i.currencyCode === 'USD')
            const targetList = res.json?.filter((i) => i.currencyCode !== 'USD')
            setCurrencyList(targetList)
            setSourceList(sourceList)
        }, (err) => console.log(err))
    }

    useEffect(()=>{
        if(preData) {
            setValue('sourceCurrency', preData?.sourceCurrency);
            setValue('targetCurrency', preData?.targetCurrency);
        }
        reset(preData);
    }, [reset, preData])

    useEffect(()=>{
        getCurrency()
    }, [])

    const onSubmit = async (data) => {
        setLoading(true)
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/exchangerates`
            message = 'Exchange Rate Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/exchangerates/${data.id}`
            message = 'Exchange Rate Updated Successfully!'
        }
        const options = {
            method: method,
            body: data
        }
        await fetchWrapper(url, options)
            .then((res) => {
                setLoading(false)
                setAdd(true)
                action === 'Add' && cancel()
                action === 'Edit' && navigate(navigateUrl)
                toast.success(message)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                action === 'Add' && cancel()
                action === 'Edit' && navigate(navigateUrl)
                if (err.status === 400) {
                    toast.error('Exchange rate alredy Exist!')
                } else if(err.status === 200){
                    toast.success('Exchange rate updated successfully!')
                } else {
                    toast.success(message)
                }
            })
    }
    
    return (
        <Fragment>
            <Row>
                <Col lg={action === 'Add' ? '12' : '8'}>
                    {title && <H3 attrH3={{ className: 'mtop-2' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 bs-none'}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup>
                                            <Label>Base Currency</Label>
                                            <select className="form-select" name="sourceCurrency"
                                                value={preData?.sourceCurrency}
                                                disabled={action === 'Edit' && true}
                                                {...register('sourceCurrency', { required: true })}
                                            >
                                                <option value="">-Select Base Currency-</option>
                                                {/* <option value={'USD'}>USD - US Dollar</option> */}
                                                {
                                                    sourceCurrencyList?.length > 0 && sourceCurrencyList?.map((ele) => {
                                                        return(
                                                            <option value={ele.currencyCode}>{ele.description}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.sourceCurrency?.type === 'required' && 'Source Currency is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        <FormGroup>
                                            <Label>Quote Currency</Label>
                                            <select className="form-select" name="targetCurrency"
                                                value={preData?.targetCurrency}
                                                disabled={action === 'Edit' && true}
                                                {...register('targetCurrency', { required: true })}
                                            >
                                                <option value="">-Select Quote Currency-</option>
                                                {
                                                    currencyList?.length > 0 && currencyList?.map((ele) => {
                                                        return(
                                                            <option value={ele.currencyCode}>{ele.description}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.targetCurrency?.type === 'required' && 'Target Currency is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup>
                                            <Label>Exchange Rate</Label>
                                            <input
                                                className="form-control"
                                                name="rate"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.rate}
                                                {...register('rate', { required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+$/ })}
                                            />
                                            <span className="text-danger">
                                                {errors.rate?.type === 'required' && 'Exchange Rate is Required'}
                                            </span>
                                            <span className="text-danger">{errors.rate?.type === 'pattern' && 'Exchange Rate is Invalid'}</span>
                                            {console.log(errors)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        <Button color="primary" type="submit">{'Submit'}</Button>
                                        <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                    </div>
                                
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ExchangeRateForm;