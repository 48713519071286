import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import ExchangeRateForm from "../Forms/ExchangeRateForm";

const AddExchangeRate = ({value, setOpenModal, setAdd}) => {
    const [open, setOpen] = useState(value);

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    return(
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>New Exchange Rate</H3>
                    <X className="x-button" onClick={onClose}/>
                </ModalHeader>
                <ModalBody>
                    <ExchangeRateForm action={'Add'} cancel={onClose} navigateUrl={`${process.env.PUBLIC_URL}/admin/exchange-rate-list`} setAdd={setAdd}/>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddExchangeRate;