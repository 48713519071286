import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import ProjectCard from '../Widgets/ProjectCard';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { useSelector } from 'react-redux';
import { defaultUserType } from '../../api/helper';

const OffsetProjectList = () => {
    const [projectData, setProjectData] = useState([]);
    const [countryData, setCountryData] = useState([])
    const navigate = useNavigate();
    const userType = useSelector(state => state?.user?.userType);
    const [loading, setLoading] = useState(false);


    const loadProjectList = async () => {
        setLoading(true)
        await fetchWrapper(`${API_URL}/projects`)
            .then((res) => {
                setProjectData(res.json)
                setLoading(false)
            }, (err) => {
                console.log(err)
                setLoading(false);
            })
    }

    const loadCountries = async () => {
        await fetchWrapper(`${API_URL}/country`)
            .then((res) => {
                setCountryData(res.json)
            }, (err) => console.log(err))
    }

    const handleCountryCode = (val) => {
        const countryCode = countryData?.find(ele => ele.name === val);
        return countryCode?.countryCode;
    }

    useEffect(() => {
        loadProjectList()
        loadCountries()
    }, [])


    return (
        <Fragment>
            <LayoutLoader value={loading} />
            <Breadcrumbs mainTitle={'Carbon Offset - Projects'} />
                <Row>
                    <Col lg='3'>
                        <FormGroup>
                            <select className='form-select' value={''} >
                                <option value={''}>-Project Types-</option>
                            </select>
                        </FormGroup>
                    </Col>
                    <Col lg='3'>
                        <FormGroup>
                            <select className='form-select' value={''} >
                                <option value={''}>-Countries List-</option>
                            </select>
                        </FormGroup>
                    </Col>
                    <Col lg='3'>
                        <FormGroup>
                            <input className='form-control' placeholder='Price/t Maximum' type='number' />
                        </FormGroup>
                    </Col>
                    <Col lg='3'>
                        <div className='d-flex project-count'>
                            <p className='card-certify'>{projectData?.length} Projects</p>
                        </div>
                    </Col>
                </Row>
            <div className='d-inline-flex ml-3'>
                <Row>
                    {
                        projectData?.filter(item => item?.status === 'ACTIVE').map((item) => {
                            let code = handleCountryCode(item.country)
                            return (
                                <Col lg='4'>
                                    <div className='d-inline-flex'
                                        onClick={() => navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/offset-project-details/${item.id}`)}>
                                        <ProjectCard data={item} countryCode={code} />
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </Fragment>
    )
}

export default OffsetProjectList;