import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import LocationForm from "../Forms/LocationForm";
import { defaultUserType } from "../../api/helper";

const AddLocationModal = (props) => {
    const [open, setOpen] = useState(props.value);
    const userType = sessionStorage.getItem('userType');

    const onClose = () => {
        setOpen(false);
        props.setOpenModal(false)
    }



    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>New Location
                        <X className="x-button" onClick={onClose} /></H3>
                </ModalHeader>
                <ModalBody>
                    <LocationForm action={'Add'} cancel={onClose} navigateUrl={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/location-list`} setAdd={props.setAdd} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddLocationModal;