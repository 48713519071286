import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { defaultUserType } from '../../api/helper';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import ButtonLoader from '../ButtonLoader';
let total = 0, rigidTotal = 0, compositeTotal = 0, flexTotal = 0;

const PlasticMonitoring = ({ projectId, frequency, year, month, userType, projectName, type }) => {
    const gridRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [totalCollection, setTotal] = useState(0);
    const [rigidCollection, setRigid] = useState(0);
    const [compositeCollection, setComposite] = useState(0);
    const [flexCollection, setFlexible] = useState(0);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState({
        id: 'total',
        period: 'Total (MtCO2e)',
        quantity: total,
    });
    const [loading, setLoading] = useState(false);

    const calculateQuantity = (params) => {
        const rigid = params.data?.rigidQuantity || 0;
        const composite = params.data?.compositeQuantity || 0;
        const flexible = params.data?.flexibleQuantity || 0;
        params.data.quantity = rigid + composite + flexible;
        return rigid + composite + flexible;
    }

    const columnData = [
        {
            headerName: 'Period',
            field: 'period',
            valueGetter: (params) => {
                if (params.node?.rowPinned !== 'bottom') {
                    return frequency === 'DAILY' ? moment(params.data.period).format('DD MMM, YYYY') : params.data.id ? params.data.period.split(',')[0] : params.data.period
                } else {
                    return 'Total (Metric tons)'
                }
            },
            flex: 2,
            suppressMovable: true
        },
        {
            headerName: 'Rigid',
            field: 'rigidQuantity',
            editable: (params) => {
                return (!params.data.id)
            },
            cellEditor: 'agNumberCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.rigidQuantity ?
                    params?.data?.rigidQuantity.toFixed(2) : ''}</div>;
            },
            cellClass: (params) => {
                return params.data.id ? 'disable-field text-input-field' : 'text-input-field'
            },
            flex: 2,
            suppressMovable: true
        },
        {
            headerName: 'Composite',
            field: 'compositeQuantity',
            editable: (params) => {
                return (!params.data.id)
            },
            cellEditor: 'agNumberCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.compositeQuantity ?
                    params?.data?.compositeQuantity.toFixed(2) : ''}</div>;
            },
            cellClass: (params) => {
                return params.data.id ? 'disable-field text-input-field' : 'text-input-field'
            },
            flex: 2,
            suppressMovable: true
        },
        {
            headerName: 'Flexible',
            field: 'flexibleQuantity',
            editable: (params) => {
                return (!params.data.id)
            },
            cellEditor: 'agNumberCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.flexibleQuantity ?
                    params?.data?.flexibleQuantity.toFixed(2) : ''}</div>;
            },
            cellClass: (params) => {
                return params.data.id ? 'disable-field text-input-field' : 'text-input-field'
            },
            flex: 2,
            suppressMovable: true
        },
        {
            headerName: 'Waste Collection (Total)',
            field: 'quantity',
            editable: false,
            valueGetter: calculateQuantity,
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.quantity ?
                    params?.data?.quantity.toFixed(2) : ''}</div>;
            },
            cellClass: (params) => {
                return params.data.id ? 'disable-field text-input-field' : 'text-input-field'
            },
            flex: 2,
            suppressMovable: true
        },
    ]

    const getCollectionList = async (monthValue) => {
        gridRef?.current?.api?.showLoadingOverlay();
        let total = 0, rigid = 0, composite = 0, flexible = 0;
        let apiUrl = frequency === 'DAILY' ?
            `plasticcreditsmonitoring/project/${projectId}/${frequency}/${parseInt(moment(year).format('YYYY'))}/${moment(monthValue).format('MMMM')}`
            : `plasticcreditsmonitoring/project/${projectId}/year/${parseInt(moment(year).format('YYYY'))}`;
        await fetchWrapper(`${API_URL}/${apiUrl}`)
            .then((res) => {
                if (res.status === 200) {
                    gridRef?.current?.api?.hideOverlay();
                    const data = res.json;
                    const dateArray = loadDateArray(new Date(year).getFullYear(), moment(monthValue).format('M'));
                    const formatArray = dateArray?.map((item) => {
                        let findItem;
                        if (frequency === 'DAILY')
                            findItem = data.find((ele) => moment(ele.date).format('DD MMM, YYYY') === item.period);
                        else if (frequency === 'MONTHLY')
                            findItem = data.find((ele) => ele.period.split(',')[0] === item.period);
                        return { ...item, ...findItem }
                    })
                    data?.map((i) => {
                        total = total + Number(i.quantity)
                        rigid = rigid + (i.rigidQuantity ? Number(i.rigidQuantity) : 0)
                        composite = composite + (i.compositeQuantity ? Number(i.compositeQuantity) : 0)
                        flexible = flexible + (i.flexibleQuantity ? Number(i.flexibleQuantity) : 0)
                    })
                    setTotal(total)
                    setComposite(composite)
                    setRigid(rigid)
                    setFlexible(flexible)
                    setPinnedBottomRowData({
                        id: 'total',
                        period: "Total (MtCO2e)",
                        rigidQuantity: rigid,
                        compositeQuantity: composite,
                        flexibleQuantity: flexible,
                        quantity: total
                    });
                    setTableData(formatArray)
                }
            }, (err) => {
                console.log(err)
                gridRef?.current?.api?.hideOverlay();
                if (err.status === 404) {
                    const dateArray = loadDateArray(new Date(year).getFullYear(), moment(monthValue).format('M'));
                    setTableData(dateArray)
                }


            })
    }

    const getGridData = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const data = gridApi.getModel().rowsToDisplay.filter(rowNode => (rowNode.data.quantity)).map((rowNode) => {
                if (frequency === 'DAILY') {
                    return {
                        'date': moment(rowNode.data.period, 'DD MMM, YYYY').format('YYYY-MM-DD'),
                        'rigidQuantity': rowNode.data.rigidQuantity ? rowNode.data.rigidQuantity : 0,
                        'compositeQuantity': rowNode.data.compositeQuantity ? rowNode.data.compositeQuantity : 0,
                        'flexibleQuantity': rowNode.data.flexibleQuantity ? rowNode.data.flexibleQuantity : 0
                    }
                } else if (frequency === 'MONTHLY') {
                    return {
                        'period': rowNode.data.period,
                        'rigidQuantity': rowNode.data.rigidQuantity ? rowNode.data.rigidQuantity : 0,
                        'compositeQuantity': rowNode.data.compositeQuantity ? rowNode.data.compositeQuantity : 0,
                        'flexibleQuantity': rowNode.data.flexibleQuantity ? rowNode.data.flexibleQuantity : 0
                    }
                }

            });
            return data;
        }
        return [];
    }


    const loadDateArray = (year, month) => {
        if (frequency === 'DAILY') {
            let endDate;
            const startDate = new Date(year, month - 1, 1);
            if (month === moment(new Date()).format('M')) {
                endDate = new Date(year, month - 1, moment(new Date()).format('DD'));
            } else {
                endDate = new Date(year, month, 0)
            }
            let dateArray = [];
            let currentDate = startDate;
            while (currentDate <= endDate) {
                dateArray.push({ "period": moment(new Date(currentDate)).format('DD MMM, YYYY') });
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return dateArray;
        } else if (frequency === 'MONTHLY') {
            let array = moment.months();
            const monthsArray = array.map((item) => {
                return {
                    period: item
                }
            })
            return [...monthsArray];
        }

    }

    useEffect(() => {
        getCollectionList(month)
    }, [month])

    const onSubmit = async () => {
        setLoading(true)
        let collectionList = getGridData();
        let bodyData;
        if (frequency === 'DAILY') {
            bodyData = {
                projectId: projectId,
                frequency: frequency,
                period: moment(month).format('MMMM'),
                year: Number(moment(year).format('YYYY')),
                monitoringData: [...collectionList]
            }
        } else if (frequency === 'MONTHLY') {
            bodyData = {
                projectId: projectId,
                frequency: frequency,
                year: Number(moment(year).format('YYYY')),
                monitoringData: [...collectionList]
            }
        }
        const options = {
            method: "POST",
            body: bodyData
        }
        console.log(bodyData)
        await fetchWrapper(`${API_URL}/plasticcreditsmonitoring`, options)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    toast.success('Waste Collection Submitted Successfully!')
                    total = 0;
                    compositeTotal = 0;
                    rigidTotal = 0;
                    flexTotal = 0;
                    setTotal(0);
                    setComposite(0);
                    setRigid(0);
                    setFlexible(0);
                } else {
                    toast.error('Something went wrong')
                }
            }, (err) => {
                console.log(err)
                setLoading(false)
                if (err.status === 200) {
                    toast.success('Waste Collection Submitted Successfully!')
                    total = 0;
                    compositeTotal = 0;
                    rigidTotal = 0;
                    flexTotal = 0;
                    setTotal(0);
                    setComposite(0);
                    setRigid(0);
                    setFlexible(0);
                } else {
                    toast.error('Something went wrong')
                }
            })
    }

    const onCellValueChanged = (event) => {
        let total = totalCollection;
        let rigid = rigidCollection;
        let composite = compositeCollection;
        let flexible = flexCollection;
        const newValue = event.newValue; // The new value of the edited cell
        const columnId = event.column.getId(); // The column ID of the edited cell
        console.log(event)
        if (columnId === 'rigidQuantity') {
            rigid = rigid + newValue;
            setRigid(rigid);
        } else if (columnId === 'compositeQuantity') {
            composite = composite + newValue;
            setComposite(composite)
        } else if (columnId === 'flexibleQuantity') {
            flexible = flexible + newValue;
            setFlexible(flexible)
        }
        total = Number(total) + Number(event.data.quantity);
        setTotal(total);
        setPinnedBottomRowData({
            id: 'total',
            period: "Total (MtCO2e)",
            rigidQuantity: rigid,
            compositeQuantity: composite,
            flexibleQuantity: flexible,
            quantity: total,
        });
    };

    const handleHistory = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/monitoring-history/${projectId}/${projectName}/${type}`)
    }

    const onGridReady = useCallback(async (params) => {
        getCollectionList(month);
    })

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/projects`)
    }

    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    <div className='waste-collection'>
                        <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnData}
                                rowData={tableData}
                                ref={gridRef}
                                rowModelType='clientSide'
                                domLayout='autoHeight'
                                animateRows={true}
                                getRowStyle={() => {
                                    return {
                                        background: '#fafafa'
                                    }
                                }}
                                overlayLoadingTemplate={
                                    `<div class="custom-loading-overlay">
                                                    <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data are loading...</span>
                                                </div>`
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">This is a custom \'no rows\' overlay</span>'
                                }
                                pagination={frequency === 'DAILY' ? true : false}
                                paginationPageSize={10}
                                onCellValueChanged={onCellValueChanged}
                                paginationPageSizeSelector={false}
                                pinnedBottomRowData={[pinnedBottomRowData]}
                                enterNavigatesVerticallyAfterEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                                onGridReady={onGridReady}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='d-flex mtop-1'>
                {
                    loading ? <ButtonLoader color={'primary'} loaderText={'Submitting'} /> : <Button color='primary' onClick={onSubmit}>Submit</Button>
                }
                <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
                <Button color='primary' className='trans-button' onClick={handleHistory}>History View/Update</Button>
            </div>
        </Fragment>
    )
}

export default PlasticMonitoring;