import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import AddPrpUser from './AddAsmUser';

export const dummytabledata = [
    {
        id: 1,
        firstName: 'Kannan',
        lastName: 'Saravanan',
        address: 'Chennai',
        emailId: 'info@oizum.com',
        phone: '+91 7299900027',
        website: 'www.oizum.com',
        country: 'Australia',
        state: 'Victoria',
        status: <div style={{ width: "100px", paddingTop: "20px", paddingLeft: '20px' }}>
            <label className="switch">
                <input type="checkbox" id="togBtn" />
                <div className="slider round">
                    <span className="on"></span>
                    <span className="off"></span>
                </div>
            </label>
        </div>,
        edit: <Link to={`${process.env.PUBLIC_URL}/asm-admin/edit-asm-user/1`} >
            <Edit className='action-icon' />
        </Link>
    },
    {
        id: 2,
        firstName: 'Kannan',
        lastName: 'Saravanan',
        address: 'Bangalore',
        emailId: 'info@sls.com',
        phone: '+91 9834239232',
        website: 'www.sls.com',
        country: 'Australia',
        state: 'Victoria',
        status: <div style={{ width: "100px", paddingTop: "20px", paddingLeft: '20px' }}>
            <label className="switch">
                <input type="checkbox" id="togBtn" />
                <div className="slider round">
                    <span className="on"></span>
                    <span className="off"></span>
                </div>
            </label>
        </div>,
        edit: <Link to={`${process.env.PUBLIC_URL}/asm-admin/edit-asm-user/2`} >
            <Edit className='action-icon' />
        </Link>
    },
    {
        id: 3,
        firstName: 'Kannan',
        lastName: 'Saravanan',
        address: 'Chennai',
        emailId: 'info@whide.com',
        phone: '+91 3454534222',
        website: 'www.whide.com',
        country: 'Australia',
        state: 'Victoria',
        status: <div style={{ width: "100px", paddingTop: "20px", paddingLeft: '20px' }}>
            <label className="switch">
                <input type="checkbox" id="togBtn" />
                <div className="slider round">
                    <span className="on"></span>
                    <span className="off"></span>
                </div>
            </label>
        </div>,
        edit: <Link to={`${process.env.PUBLIC_URL}/asm-admin/edit-asm-user/3`} >
            <Edit className='action-icon' />
        </Link>
    },
    {
        id: 4,
        firstName: 'Kannan',
        lastName: 'Saravanan',
        address: 'Bangalore',
        emailId: 'info@citi.com',
        phone: '+91 8932498344',
        website: 'www.citi.com',
        country: 'Australia',
        state: 'Victoria',
        status: <div style={{ width: "100px", paddingTop: "20px", paddingLeft: '20px' }}>
            <label className="switch">
                <input type="checkbox" id="togBtn" />
                <div className="slider round">
                    <span className="on"></span>
                    <span className="off"></span>
                </div>
            </label>
        </div>,
        edit: <Link to={`${process.env.PUBLIC_URL}/asm-admin/edit-asm-user/4`} >
            <Edit className='action-icon' />
        </Link>
    },
    {
        id: 5,
        firstName: 'Kannan',
        lastName: 'Saravanan',
        address: 'Hyderabad',
        emailId: 'info@cox.com',
        phone: '+91 7287343027',
        website: 'www.cox.com',
        country: 'Australia',
        state: 'Victoria',
        status: <div style={{ width: "100px", paddingTop: "20px", paddingLeft: '20px' }}>
            <label className="switch">
                <input type="checkbox" id="togBtn" />
                <div className="slider round">
                    <span className="on"></span>
                    <span className="off"></span>
                </div>
            </label>
        </div>,
        edit: <Link to={`${process.env.PUBLIC_URL}/asm-admin/edit-asm-user/5`} >
            <Edit className='action-icon' />
        </Link>
    },
];

 

const AsmUserlist = () => {
    const [showFilter, setShowFilter] = useState(false)
    const [open, setOpen] = useState(false);
    const plusIcon = {
        height: 50,
        width: 50,
        fill: '#2E7D32',
        strokeWidth: 1,
        color: '#fff'
    }
    const tableColumns = [
        {
            name: 'First Name',
            selector: row => `${row.firstName}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Last Name',
            selector: row => `${row.lastName}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Address',
            selector: row => `${row.address}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Email Id',
            selector: row => `${row.emailId}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Phone',
            selector: row => `${row.phone}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Website',
            selector: row => `${row.website}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Edit',
            selector: row => row['edit'],
            sortable: true,
            center: false,
        }
    ];
    const handleClick = () => setOpen(true);

    

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Breadcrumbs mainTitle="Active Proponent" parent="Active Proponent" />
                        <Card>
                            <CardBody className='data-tables'>
                                <DataTableComponent tableData={dummytabledata} columnsData={tableColumns} title={'Proponent List'} setShowFilter={setShowFilter} />
                                <div style={{ display: 'flex' }} onClick={handleClick}>
                                    <PlusCircle style={plusIcon} />
                                    <h5 style={{ paddingLeft: '10px', paddingTop: '15px' }}>Add User</h5>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddPrpUser value={open} setOpenModal={setOpen} />}
                </Row>
            </Container>
        </Fragment>
    );

};

export default AsmUserlist;