
import React, { Fragment, useState } from "react";
import ride from '../../../assets/images/user/dashboard/ride.png' 


import {
  ButtonGroup,
  Card,
  Button,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardHeader,
  Row
} from "reactstrap";

const Joinchallenge = () => {
  return (
    <Fragment>
      {/* <Col xxl="4" lg="4" sm="4 box-col-40"> */}
        <Card className="recent-order" style={{ height: '326px' }}>
          <CardHeader className="pb-0" style={{paddingTop: '4%'}}>
            <h4 style={{ color: '#2b7704', marginTop: '20px' }}>DID YOU KNOW THAT...?</h4>
          </CardHeader>
          <CardBody className="pb-0 ">

            <div className="d-flex align-items-end">

              <div>
                <p >By tasking the bike to work twice a week you can save up to 360 kg CO<sub>2</sub>per Week!</p>

                <Button
                  color="primary"
                  outline
                >
                  Join Challenge
                </Button>


              </div>
              <div>
                <img style={{ height: "150px", width: "150px" }} src={ride} alt="" />
              </div>
            </div>
            <div className="d-flex pb-3 mtop-2">
              <img style={{ height: "20px", width: "20px", marginRight: "8px" }} src="https://cdn-icons-png.flaticon.com/512/5198/5198491.png" alt="" />
              <p  >Looks like your car usage is going up.</p>
            </div>
          </CardBody>
        </Card>
        {/* <ActivityChart /> */}
        
      {/* </Col> */}
    </Fragment>
  )
}

export default Joinchallenge