import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { H3 } from '../../../AbstractElements';
import TableView from "./TableView";
import {
    scColumns, msColumns, rfgSimplifiedColumns, rfgMaterialColumns,
    rfgScreeningColumns, fsMaterialColumns, fsSimplifiedColumns, fsScreeningColumns,
    purchasedGasesColumns, electricityColumns, steamColumns,
    businessTravelPersonalColumns,
    businessTravelPublicColumns,
    businessTravelAirwaysColumns,
    commutingPersonalColumns,
    commutingPublicColumns,
    transportColumns,
    distributeColumns,
    indirectWasteColumns,
    deleteCache
} from "./ghgHelper";
import { X } from "react-feather";
import { toast } from "react-toastify";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useSelector } from "react-redux";

const TablePreview = ({ value, setOpenModal, tableData, status, keyObject }) => {
    const [open, setOpen] = useState(value);
    const [missedCategories, setCategories] = useState([]);
    const [scopeList, setScopeList] = useState([]);

    const getScopeCategories = async () => {
        await fetchWrapper(`${API_URL}/scopecategories/organization`)
            .then((res) => {
                let list = res?.json?.map(ele => ele?.categoryName);
                let scopeList = res?.json?.map(ele => ele?.scope);
                setScopeList(list)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getScopeCategories()
    }, [])

    const onClose = () => {
        setOpenModal(false);
        setOpen(false)
    }

    const handleSubmit = async () => {
        if (tableData?.stationaryCombustionData?.length === 0 && scopeList?.some(ele => ele === 'Stationary Cobustion')) {
            missedCategories?.push('Stationary Combustion');
        }
        if (tableData?.mobilesourcesData?.length === 0 && scopeList?.some(ele => ele === 'Mobile Sources')) {
            missedCategories?.push('Mobile Sources');
        }
        if ((tableData?.rfgMaterialData?.length === 0 && tableData?.rfgSimplifiedMaterialData?.length === 0
            && tableData?.rfgScreeningData?.length === 0) && scopeList?.some(ele => ele === 'Refrigeration / AC Equipment Use')) {
            missedCategories?.push('Refrigeration / AC Equipment Use');
        }
        if ((tableData?.fsMaterialData?.length === 0 && tableData?.fsSimplifiedMaterialData?.length === 0
            && tableData?.fsScreeningData?.length === 0) && scopeList?.some(ele => ele === 'Fire Suppression')) {
            missedCategories?.push('Fire Suppression');
        }
        if (tableData?.purchasedGasesData?.length === 0 && scopeList?.some(ele => ele === 'Purchased Gases')) {
            missedCategories?.push('Purchased Gases');
        }
        if (tableData?.energyData?.length === 0 && scopeList?.some(ele => ele === 'Purchased and Consumed Electricity')) {
            missedCategories?.push('Purchased and Consumed Electricity');
        }
        if (tableData?.steamEnergyData?.length === 0 && scopeList?.some(ele => ele === 'Purchased and Consumed Steam')) {
            missedCategories?.push('Purchased and Consumed Steam');
        }
        if ((tableData?.businessTravelPersonalData?.length === 0 && tableData?.businessTravelAirwaysData?.length === 0
            && tableData?.businessTravelPublicData?.length === 0) && scopeList?.some(ele => ele === 'Employee Business Travel')) {
            missedCategories?.push('Employee Business Travel');
        }
        if ((tableData?.travelCommutePersonalData?.length === 0 && tableData?.travelCommutePublicData?.length === 0) && scopeList?.some(ele => ele === 'Employee Commuting')) {
            missedCategories?.push('Employee Commuting');
        }
        if ((tableData?.upstreamVehicleMilesData?.length === 0 && tableData?.upstreamVehicleTonsData?.length === 0) && scopeList?.some(ele => ele === 'Upstream Transportation and Distribution')) {
            missedCategories?.push('Upstream Transportation and Distribution');
        }
        if (tableData?.indirectWasteData?.length === 0 && scopeList?.some(ele => ele === 'Indirect Waste')) {
            missedCategories?.push('Indirect Waste');
        }
        console.log(missedCategories)
        if (missedCategories?.length === 0) {
            const options = {
                method: 'POST',
                body: tableData
            }
            await fetchWrapper(`${API_URL}/ghgemissionsdata/submit`, options)
                .then((res) => {
                    console.log(res.json)
                }, async(err) => {
                    console.log(err)
                    if (err.status === 200) {
                        toast.success('Data submitted successfully!')
                        await deleteCache(keyObject)
                        onClose()
                    }
                })
        } else {
            console.log(missedCategories)
            toast.error(`Please fill missing categories ${JSON.stringify(missedCategories)}`)
        }
    }

    return (
        <Fragment>
            <Modal isOpen={open} size="xl" centered>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Data Preview</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    {
                        tableData?.stationaryCombustionData?.length > 0 && <div>
                            <TableView columns={scColumns} data={tableData?.stationaryCombustionData?.filter(ele => ele?.isDelete === false)} title={'Stationary Combustion'} />
                        </div>
                    }
                    {
                        tableData?.mobilesourcesData?.length > 0 && <div className="mtop-1">
                            <TableView columns={msColumns} data={tableData?.mobilesourcesData?.filter(ele => ele?.isDelete === false)} 
                            title={'Mobile Sources'} />
                        </div>
                    }
                    {
                        tableData?.rfgMaterialData?.length > 0 && <div className="mtop-1">
                            <TableView columns={rfgMaterialColumns} data={tableData?.rfgMaterialData?.filter(ele => ele?.isDelete === false)} 
                            title={'Refrigeration / AC Equipment Use (Material)'} />
                        </div>
                    }
                    {
                        tableData?.rfgSimplifiedMaterialData?.length > 0 && <div className="mtop-1">
                            <TableView columns={rfgSimplifiedColumns} data={tableData?.rfgSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)} 
                            title={'Refrigeration / AC Equipment Use (Simplified Material)'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.rfgScreeningData?.length > 0 && <div className="mtop-1">
                            <TableView columns={rfgScreeningColumns} data={tableData?.rfgScreeningData?.filter(ele => ele?.isDelete === false)} 
                            title={'Refrigeration / AC Equipment Use (Screening Method)'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.fsMaterialData?.length > 0 && <div className="mtop-1">
                            <TableView columns={fsMaterialColumns} data={tableData?.fsMaterialData?.filter(ele => ele?.isDelete === false)} 
                            title={'Fire Suppression (Material)'} />
                        </div>
                    }
                    {
                        tableData?.fsSimplifiedMaterialData?.length > 0 && <div className="mtop-1">
                            <TableView columns={fsSimplifiedColumns} data={tableData?.fsSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)} 
                            title={'Fire Suppression (Simplified Material)'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.fsScreeningData?.length > 0 && <div className="mtop-1">
                            <TableView columns={fsScreeningColumns} data={tableData?.fsScreeningData?.filter(ele => ele?.isDelete === false)} 
                            title={'Fire Suppression (Screening Method)'} />
                        </div>
                    }
                    {
                        tableData?.purchasedGasesData?.length > 0 && <div className="mtop-1">
                            <TableView columns={purchasedGasesColumns} data={tableData?.purchasedGasesData?.filter(ele => ele?.isDelete === false)} 
                            title={'Purchased Gases'} />
                        </div>
                    }
                    {
                        tableData?.energyData?.length > 0 && <div className="mtop-1">
                            <TableView columns={electricityColumns} data={tableData?.energyData?.filter(ele => ele?.isDelete === false)} 
                            title={'Electricity'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.steamEnergyData?.length > 0 && <div className="mtop-1">
                            <TableView columns={steamColumns} data={tableData?.steamEnergyData?.filter(ele => ele?.isDelete === false)} 
                            title={'Steam'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.businessTravelPersonalData?.length > 0 && <div className="mtop-1">
                            <TableView columns={businessTravelPersonalColumns} data={tableData?.businessTravelPersonalData?.filter(ele => ele?.isDelete === false)} 
                            title={'Business Travel(Personal)'} />
                        </div>
                    }
                    {
                        tableData?.businessTravelPublicData?.length > 0 && <div className="mtop-1">
                            <TableView columns={businessTravelPublicColumns} data={tableData?.businessTravelPublicData?.filter(ele => ele?.isDelete === false)} title={'Business Travel(Public)'} />
                        </div>
                    }
                    {
                        tableData?.businessTravelAirwaysData?.length > 0 && <div className="mtop-1">
                            <TableView columns={businessTravelAirwaysColumns} data={tableData?.businessTravelAirwaysData?.filter(ele => ele?.isDelete === false)}
                                title={'Business Travel(Airways)'} />
                        </div>
                    }
                    {
                        tableData?.travelCommutePersonalData?.length > 0 && <div className="mtop-1">
                            <TableView columns={commutingPersonalColumns} data={tableData?.travelCommutePersonalData?.filter(ele => ele?.isDelete === false)}
                                title={'Employee Commuting(Personal)'} />
                        </div>
                    }
                    {
                        tableData?.travelCommutePublicData?.length > 0 && <div className="mtop-1">
                            <TableView columns={commutingPublicColumns} data={tableData?.travelCommutePublicData?.filter(ele => ele?.isDelete === false)}
                                title={'Employee Commuting(Public)'} />
                        </div>
                    }
                    {
                        tableData?.upstreamVehicleMilesData?.length > 0 && <div className="mtop-1">
                            <TableView columns={transportColumns} data={tableData?.upstreamVehicleMilesData?.filter(ele => ele?.isDelete === false)}
                                title={'Upstream Transportation'} />
                        </div>
                    }
                    {
                        tableData?.upstreamVehicleTonsData?.length > 0 && <div className="mtop-1">
                            <TableView columns={distributeColumns} data={tableData?.upstreamVehicleTonsData?.filter(ele => ele?.isDelete === false)}
                                title={'Upstream Distribution'} />
                        </div>
                    }
                    {
                        tableData?.indirectWasteData?.length > 0 && <div className="mtop-1">
                            <TableView columns={indirectWasteColumns} data={tableData?.indirectWasteData?.filter(ele => ele?.isDelete === false)}
                                title={'Indirect Waste'} />
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex">
                        <Button color="secondary" onClick={onClose}>Back</Button>
                        <Button color="primary"
                            disabled={!['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(status)}
                            className="ml-1" onClick={handleSubmit}>Submit</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default TablePreview;