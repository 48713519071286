import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import LayoutLoader from '../LayoutLoader/LayoutLoader'
import SubOrganizationForm from "../Forms/SubOrganizationForm";
import { useSelector } from "react-redux";
import { defaultUserType } from "../../api/helper";

const EditSubOrganization = () => {
    const [ data, setData ] = useState();
    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);

    const userType = useSelector(state => state.user?.userType);
    
    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization/${id}`, options)
        .then((res)=> {
            console.log(res)
            setData(res.json)
        })
        .catch((error) => {
            console.debug(error)
        })
    }

    return (
        <Fragment>
            <LayoutLoader />
            <SubOrganizationForm type={'customer'} action={'Edit'} title={'Edit Organization'} navigateUrl={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/sub-organization-list`} preData={data} />
        </Fragment>
    )
}

export default EditSubOrganization;