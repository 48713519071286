import React, { Suspense, Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../Common/Layout/Layout';
import LayoutRoutes from './LayoutRoutes';
import PrivateRoute from './PrivateRoute'
import { authRoutes } from './AuthRoutes';
import LoginComponent from '../Common/Component/Login/Login';
import { defaultRouter } from '../Common/api/helper';
import Wrapper from './Wrapper';
import ValidateRouter from './ValidateRouter';

const Routers = () => {

        const [authenticated, setAuthenticated] = useState(false);
        const jwt_token = sessionStorage.getItem('accessToken');
        useEffect(() => {
                let abortController = new AbortController();
                setAuthenticated(sessionStorage.getItem('accessToken') ? true : false);
                console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
                console.disableYellowBox = true;
                return () => {
                        abortController.abort();
                };
        }, []);
        const userType = sessionStorage.getItem('userType');

        return (
                <Fragment>
                        <BrowserRouter basename='/'>
                                <Wrapper>
                                        <ValidateRouter>
                                                <Suspense fallback={<Loader />}>
                                                        <Routes>
                                                                <Route path={'/'} element={<PrivateRoute />}>
                                                                        {authenticated || jwt_token || true ?
                                                                                <>
                                                                                        <Route exact
                                                                                                path={`${process.env.PUBLIC_URL}`}
                                                                                                element={<Navigate to={`${process.env.PUBLIC_URL}${defaultRouter[userType]}`} />}
                                                                                        />
                                                                                        <Route exact
                                                                                                path={`/`}
                                                                                                element={<Navigate to={`${process.env.PUBLIC_URL}${defaultRouter[userType]}`} />}
                                                                                        />
                                                                                </> : ''}
                                                                        <Route path={`/*`} element={<LayoutRoutes />} />
                                                                </Route>

                                                                <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<LoginComponent />} />

                                                                {authRoutes.map(({ path, Component }, i) => (
                                                                        <Route path={path} element={Component} key={i} />
                                                                ))}
                                                        </Routes>
                                                </Suspense>
                                        </ValidateRouter>

                                </Wrapper>

                        </BrowserRouter>
                </Fragment>
        );
};
export default Routers;