import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button } from "reactstrap";
import { externalParticulars, policyQuestions, principleList, reviewParticulars } from "../settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";


const PolicyCoverage = ({ activeTab, setActive }) => {
    const [policyCoverageData, setPolicyCoverage] = useState(policyQuestions);

    const getPolicyCoverageData = async() => {
        await fetchWrapper(`${API_URL}/emissionsReport/disclosureQuestionsData`)
        .then((res) => {
            const data = res.json?.filter(item => item?.serialNo.includes('PC'));
            setPolicyCoverage(data)
        }, (err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getPolicyCoverageData()
    }, [])

    const policyCoverageColumns = [
        {
            headerName: 'Questions',
            field: 'questionText',
            editable: false,
            filter: false,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P1',
            field: 'p1Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'P2',
            field: 'p2Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'P3',
            field: 'p3Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'P4',
            field: 'p4Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'P5',
            field: 'p5Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'P6',
            field: 'p6Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'P7',
            field: 'p7Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'P8',
            field: 'p8Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'P9',
            field: 'p9Set1',
            editable: true,
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            width: 150,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ]

    const onSubmit = async() => {
        const data = {
            disclosureQuestionsData: policyCoverageData
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
        .then((res) => {

        }, (err) => {
            console.log(err)
            if(err.status === 200) {
                setActive(8)
            } else if(err.status === 500) {
                toast.error('Something went wrong!')
            }
        })
    }
    
    return (
        <Fragment>
            <h4>External Evaluatiion</h4>
            <div className="mtop-1 company-profile">
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={policyCoverageData}
                        columnData={policyCoverageColumns}
                    />
                </div>
            </div>
            <div className="mtop-1">
                <div className="">
                    <h4>Note:</h4>
                    {
                        principleList?.map((item, index) => (
                            <p className="mb-0 ml-1">P{index + 1} - <span className="ml-1">{item}</span></p>
                        ))
                    }
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(6)}>Back</Button>
                <Button color="primary" className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default PolicyCoverage;