import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import DonutChartClass from "./DonutChart";
import BarChartClass from "./BarChart";
import CarbonFoot from "./CarbonFoot";
import Joinchallenge from "./Joinchallenge";
import ActivityChart from './ActivityChart';
import AlmostThere from "./AlmostThereChart";

const CommonDashboard = () => {
    return (
        <Fragment>
            <Container fluid={true} className="ecommerce-page">
                <Row style={{ minHeight: '100%' }}>
                    <Col sm='12' lg='5' xl='5'>
                        <DonutChartClass />
                    </Col>
                    <Col sm='12' xl='3'>
                            <AlmostThere />
                    </Col>
                    <Col xxl="4" lg="4" sm="4">
                        <Joinchallenge />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <BarChartClass />
                    </Col>
                    <Col lg="6">
                        <ActivityChart />
                    </Col>
                </Row>
                <Row>
                    <CarbonFoot />
                </Row>
            </Container>
        </Fragment>
    );
};

export default CommonDashboard;