import React, { Fragment, useState, useEffect } from "react";
import AssessorViewProject from "../../Common/Component/AssessorViewProject";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { useParams } from "react-router";

const ViewAsrAdminProject = () => {
    const [action, setAction] = useState();
    const [documentsData, setDocumentsData] = useState();
    const projectId = useParams()?.id

    const loadDocuments = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/document/${projectId}`, options)
        .then((res)=> {
            console.log(res)
            const data = res.json
            setDocumentsData(data)
        })
    }

    console.log(window.location.pathname.split('/')[4])

    useEffect(()=>{
        console.log(window.location.pathname.split('/'))
        setAction(window.location.pathname.split('/')[3])
        loadDocuments()
    }, [])

    return (
        <Fragment>
            <AssessorViewProject type={'asr-admin'} formAction={action} 
                documents={documentsData} projectId={projectId} />
        </Fragment>
    )
}

export default ViewAsrAdminProject;