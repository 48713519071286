import React, { Fragment, useState, useEffect } from "react";
import EditProject from "../../Common/Component/EditProject";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { handleGetData, FormData } from '../../Common/api/helper';
import LayoutLoader from "../../Common/Component/LayoutLoader/LayoutLoader";

const AdminEditProject = () => {
    const [data, setData] = useState()
    const [notesData, setNotesData] = useState()
    const loadData = async (id) => {
        const options = {
          method: 'GET'
        }
        await fetchWrapper(`${API_URL}/projects/${id}`, options)
          .then((res) => {
            const data = res.json;
            data.start_date = data.start_date ? new Date(data.start_date) : null
            data.end_date = data.end_date ? new Date(data.end_date) : null
            setData(data)
           })
          .catch((err) => console.log(err));
    }

    
    useEffect(()=> {
        const id = window.location.pathname.split('/')[3]
        console.log(id)
        loadData(id)
    }, [])

    return (
        <Fragment>
            <LayoutLoader />
            <EditProject preData={data} />
        </Fragment>
    )
}

export default AdminEditProject;