
import ErrorPage from "../Common/Component/Error/404ErrorPage";
import ResetPassword from "../Common/Component/ResetPassword";
import ForgotPwd from "../Common/Component/Login/ForgotPassword";
import SessionExpiry from "../Common/Component/Login/SessionExpiry";
import MaintenanceNotification from "../Common/Component/Login/MaintenanceNotification";

export const authRoutes = [
    { path: `${process.env.PUBLIC_URL}/404-error`, Component: <ErrorPage /> },
    { path: `${process.env.PUBLIC_URL}/reset-password/:email`, Component: <ResetPassword /> },
    { path: `${process.env.PUBLIC_URL}/forgot-password`, Component: <ForgotPwd /> },
    { path: `${process.env.PUBLIC_URL}/session-expired`, Component: <SessionExpiry />},
    { path: `${process.env.PUBLIC_URL}/system-maintenance`, Component: <MaintenanceNotification /> }
]   