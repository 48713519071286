import React, { Fragment, useEffect, useRef, useState } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { H3 } from "../../../AbstractElements";
import { Button } from "reactstrap";
import { Plus, Trash2, XCircle } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import ButtonLoader from '../ButtonLoader';
import { thousandSeparator } from "../../api/helper";
import { alertFunction, deleteCacheData, getCacheData, notifyFunction, storeData, uuidGenerator } from './ghgHelper';
import { useSelector } from "react-redux";
import moment from "moment";

let error = false;
const IndirectWaste = ({ setSteps, preData, timeStamp, setIndirectWaste, indirectWasteData }) => {
    const [data, setData] = useState([...Array(10)].map((_, index) => ({ id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false })));
    const [noOfRow, setNoOfRow] = useState(1);
    const wasteRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [rowIndex, setRowIndex] = useState([]);
    const [wasteMaterial, setWasteMaterial] = useState([]);
    const [wasteMaterialData, setWasteMaterialData] = useState([]);
    const [gridLoading, setGridLoading] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState()
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }

    const getWasteMaterialData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/WasteEmissions/${preData?.countryCode}`)
            .then((res) => {
                const data = res?.json?.map(ele => ele.warmMaterial)
                setWasteMaterial(data)
                setWasteMaterialData(res.json);
            }, (err) => console.log(err))
    }

    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({ id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false }))];
            setData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        if (indirectWasteData?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateTable(indirectWasteData)
        } else if(indirectWasteData?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if (indirectWasteData?.length > 0 && savedData?.length > 0) {
            console.log(timeStamp, cTimestamp);
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, indirectWasteData, populateTable)
            } else { //saved is latest
                notifyFunction(indirectWasteData, populateTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        loadData(cacheData?.indirectWasteData, cacheData?.modifiedTs)
    }
    
    useEffect(() => {
        getWasteMaterialData()
        getCache()
    }, [])

    const handleClear = async(params) => {
        const updatedRowData = data.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                : ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        const tempData = {
            ...cacheData,
            indirectWasteData: updatedRowData?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await deleteCacheData(keyObject, tempData)
        setData(updatedRowData);
    };

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription') 
                || params.data.hasOwnProperty('wasteMaterial')
                || params.data.hasOwnProperty('disposalMethod')
                || params.data.hasOwnProperty('wasteClassification')
                || params.data.hasOwnProperty('disposalLocation')
                || params.data.hasOwnProperty('wasteType')
                || params.data.hasOwnProperty('weight')
                || params.data.hasOwnProperty('units')
            ) {
                    params.data.sourceId = `IW-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }
            },
            filter: false,
            width: 100,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: true,
            filter: false,
            width: 300,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Waste Material',
            field: 'wasteMaterial',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: wasteMaterial,
            },
            filter: false,
            editable: true,
            width: 300,
        },
        {
            headerName: 'Disposal Method',
            field: 'disposalMethod',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let disposalMethod = wasteData?.disposalMethod?.split(':');
                    if(disposalMethod?.length > 1) {
                        return { values: disposalMethod }
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let disposalMethod = wasteData?.disposalMethod?.split(':');
                    if(disposalMethod?.length === 1) {
                        params.data.disposalMethod = disposalMethod[0];
                        return params.data.disposalMethod;
                    }
                }
                if(params.data?.disposalMethod) {
                    return params.data?.disposalMethod
                }
            },
            editable: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let disposalMethod = wasteData?.disposalMethod?.split(':');
                    if(disposalMethod?.length > 1) {
                        return true
                    }
                } return false;
            },
            filter: false,
            width: 300,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Hazardous / Non-hazardous Waste',
            field: 'wasteClassification',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let classificationData = wasteData?.wasteClassification?.split(':');
                    if(classificationData?.length > 1) {
                        return { values: classificationData }
                    }
                }
            },
            filter: false,
            editable: true,
            width: 200,
        },
        {
            headerName: 'Onsite / Offsite',
            field: 'disposalLocation',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let locationData = wasteData?.location?.split(':');
                    if(locationData?.length > 1) {
                        return { values: locationData }
                    }
                }
            },
            filter: false,
            editable: true,
            width: 200
        },
        {
            headerName: 'Organic / Inorganic Waste',
            field: 'wasteType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let wasteTypeData = wasteData?.wasteType?.split(':');
                    if(wasteTypeData?.length > 1) {
                        return { values: wasteTypeData }
                    }
                }
            },
            filter: false,
            editable: true,
            width: 200,
        },
        {
            headerName: 'Weight',
            field: 'weight',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.weight ? thousandSeparator(params?.data?.weight?.toFixed(2)) : ''}</div>;
            },
            width: 200,
            editable: true,
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = wasteMaterialData?.find((item) => item?.warmMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = wasteMaterialData?.find((item) => item?.warmMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, 
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = wasteMaterialData?.find((item) => item?.warmMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return params.data.units;
                    }
                }
                if(params.data.units) {
                    return params.data.units;
                }
            },
            sortable: false
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    params.data.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ];

    const createCache = async (data) => {
        const tempData = {
            ...cacheData,
            indirectWasteData: data?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = async (event) => {
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === true || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === true) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        createCache(data)
    }

    const addingRows = (val) => {
        setNoOfRow(val)
    }

    const addRows = () => {
        const rowData = [];
        for (let i = 1; i <= noOfRow; i++) {
            rowData.push({ id: data?.length + i, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false })
        }
        setData([...data, ...rowData]);
    }

    const getGridData = () => {
        if (wasteRef.current) {
            const tableState = data
                .filter((rowNode) => rowNode.sourceId)
                .map((rowNode, index) => {
                    if (rowNode.sourceDescription && rowNode.wasteMaterial
                        && rowNode.disposalMethod && rowNode.wasteClassification
                        && rowNode.disposalLocation && rowNode.wasteType
                        && rowNode.weight && rowNode.units) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            wasteMaterial: rowNode.wasteMaterial,
                            disposalMethod: rowNode.disposalMethod,
                            wasteClassification: rowNode.wasteClassification,
                            disposalLocation: rowNode.disposalLocation,
                            wasteType: rowNode.wasteType,
                            weight: rowNode.weight,
                            units: rowNode.units,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(index + 1);
                        return {}
                    }
                });
            if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                error = true;
            } else {
                error = false
            }
            return tableState.filter(ele => Object.keys(ele)?.length > 0);
        }
        return [];
    }

    const onSubmit = async () => {
        const gridData = getGridData();
        if (error === true) {
            toast.error(`Please fill missing values for row ${[...new Set(rowIndex)].join(',')}`)
            return
        } else {
            setIndirectWaste(gridData);
            setSteps(0);
        }

    }

    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Organization-Wide Indirect Waste Material by Weight 
                    and Disposal Method (CO<sub>2</sub>, CH<sub>4</sub> and N<sub>2</sub>O)</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            {gridLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div className="mtop-1" style={{ opacity: gridLoading ? '0.2' : '1' }}>
                <div className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={data?.filter(ele => ele?.isDelete === false)}
                        columnData={columnData}
                        agRef={wasteRef}
                        onCellValueChanged={onCellValueChanged}
                    />
                    <div className="ag-custom-footer">
                        <div className="d-flex ">
                            <button className="add-row-button" onClick={addRows}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                            <input
                                type="number"
                                className="ml-1 input-number"
                                value={noOfRow}
                                onChange={(e) => addingRows(e.target.value)}
                            />
                            <h5 className="ml-1">rows</h5>
                        </div>
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}

export default IndirectWaste;