import React from 'react';
import { Image } from '../../../AbstractElements';
import logoIcon from '../../../assets/images/logo/logo-icon.svg';

const SidebarIcon = () => {

  return (
    <div className="logo-icon-wrapper">
        <a href="">
          <Image attrImage={{ className: "img-fluid for-light" , src: logoIcon, alt: "" }} />
          <Image attrImage={{ className: "img-fluid for-dark", src: `${require("../../../assets/images/logo/logo-icon-dark.png")}`, alt: "" }} />
        </a>
    </div>  
  );
};
export default SidebarIcon;