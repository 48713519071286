import React, { Fragment, useState } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";

const AssignAssessor = ({ value, setOpenModal, orgList, projectId, type }) => {
    const [open, setOpen] = useState(value);
    const [assesor, setAssessor] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const assignOrg = async () => {
        setLoading(true)
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/projects/assignAssessor/${projectId}/${assesor}`, options)
            .then((res) => {
                console.log(res)
                if (res.status == 200) {
                    setLoading(false)
                    setOpen(false);
                    setOpenModal(false);
                    navigate(`${process.env.PUBLIC_URL}/${type}/projects`)
                    toast.success('Assessor Assigned Successfully!')
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    }

    const onClose = () => {
        setOpen(false);
        setOpenModal(false);
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='md'>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Assign Assessor</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md='12'>
                            <FormGroup>
                                <Label>Select Assessor</Label>
                                <select className="form-select" name="assessor" onChange={(e) => setAssessor(e.target.value)}>
                                    <option value={''}>-Please Select Assessor-</option>
                                    {
                                        orgList && orgList.length > 0 && orgList.map((item) => {
                                            return <option value={item.id} >{item.companyName}</option>
                                        })
                                    }
                                </select>
                            </FormGroup>
                            <Row>
                                <Col lg='6' className="d-flex">
                                    {
                                        loading ? <ButtonLoader loaderText={'Submitting'} color="primary" /> : <Button color="primary" onClick={assignOrg}>Save</Button>
                                    }
                                    <Button color='secondary' className="ml-1" onClick={onClose}>Cancel</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AssignAssessor;