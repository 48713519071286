import fetchWrapper from './fetchWrapper';
import { API_URL } from '../Config/Config';

export const logoutHelper = async () => {
    const requestOptions = {
        method: 'DELETE'
    };
    await fetchWrapper(`${API_URL}/signout`, requestOptions)
        .then((res) => {
            if (res) {
                console.log(new Date().toLocaleTimeString())
                sessionStorage.removeItem('profileURL');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('auth_profile');
                sessionStorage.removeItem('Name');
                sessionStorage.setItem('authenticated', false);
                sessionStorage.setItem('login', false);
                sessionStorage.clear();
                sessionStorage.setItem('logoutTime', new Date().toLocaleTimeString())
                window.location.href = `${process.env.PUBLIC_URL}/login`;
            }
        }, (error) => {
            console.log(error);
                console.log(new Date().toLocaleTimeString())
                sessionStorage.removeItem('profileURL');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('auth_profile');
                sessionStorage.removeItem('Name');
                sessionStorage.setItem('authenticated', false);
                sessionStorage.setItem('login', false);
                sessionStorage.clear();
                sessionStorage.setItem('logoutTime', new Date().toLocaleTimeString())
                window.location.href = `${process.env.PUBLIC_URL}/login`;
            
        });
};

export const updatedAccessToken = async () => {
    const refreshToken = sessionStorage.getItem('refreshToken');
    await fetchWrapper(`${API_URL}/token/${refreshToken}`)
        .then((res) => {
            if (res) {
                const data = res.json;
                sessionStorage.setItem('accessToken', data.accessToken);
                sessionStorage.setItem('refreshToken', data.refreshToken);
                sessionStorage.setItem('expiresIn', data.expiresIn);
            }
        }, (error) => {
            console.log(error);
        });
};

