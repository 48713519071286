import React, { Fragment, useState } from 'react'
import ProjectInitiation from '../../Common/Component/ProjectInitiation';

const AsoAdminProjectInit = () => {
    return (
        <Fragment>
            <ProjectInitiation />
        </Fragment>
    )
}

export default AsoAdminProjectInit