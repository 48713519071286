import moment from "moment"
import Swal from "sweetalert2";

export const preDefinedObject = {isInsert: false, isUpdate: false, isDelete: false, isSubmit: false, isDraft: false}

export const scope1Categories = [
    { category: 'Stationary Combustion', value: 0, type: 1, evidencesCount: 0, hashValue: 'stationarycombustion' },
    { category: 'Mobile Sources', value: 0, type: 2, evidencesCount: 0, hashValue: 'mobilesources' },
    { category: 'Refrigeration / AC Equipment Use', value: 0, type: 3, evidencesCount: 0, hashValue: 'rfg' },
    { category: 'Fire Suppression', value: 0, type: 4, evidencesCount: 0, hashValue: 'firesuppression' },
    { category: 'Purchased Gases', value: 0, type: 5, evidencesCount: 0, hashValue: 'purchasedgases' },
]

export const scope2Categories = [
    { category: 'Purchased and Consumed Electricity', value: 0, type: 6, evidencesCount: 0, hashValue: 'electricity' },
    { category: 'Purchased and Consumed Steam', value: 0, type: 7, evidencesCount: 0, hashValue: 'steam' },
]

export const scope3Categories = [
    { category: 'Employee Business Travel', value: 0, type: 8, evidencesCount: 0, hashValue: 'businesstravel' },
    { category: 'Employee Commuting', value: 0, type: 9, evidencesCount: 0, hashValue: 'commuting' },
    { category: 'Upstream Transportation and Distribution', value: 0, type: 10, evidencesCount: 0, hashValue: 'upstream' },
    { category: 'Indirect Waste', value: 0, type: 11, evidencesCount: 0, hashValue: 'indirectwaste' },
]

export const typeOfCategories = {
    1: 'StationaryCombustion',
    2: 'MobileSources',
    3: 'RefrigerationAc',
    4: 'FireSuppression',
    5: 'PurchasedGases',
    6: 'Electricity',
    7: 'Steam',
    8: 'BusinessTravel',
    9: 'Commuting',
    10: 'UpstreamTransportation',
    11: 'IndirectWaste'
}

export const categoriesType = {
    'Stationary Combustion': 1,
    'Mobile Sources': 2,
    'Refrigeration / AC Equipment Use': 3,
    'Fire Suppression': 4,
    'Purchased Gases': 5,
    'Purchased and Consumed Electricity': 6,
    'Purchased and Consumed Steam': 7,
    'Employee Business Travel': 8,
    'Employee Commuting': 9,
    'Upstream Transportation and Distribution': 10,
    'Indirect Waste': 11
}

export const convertMonthToDate = (month) => {
    return month ? `01 ${month}, 2024` : moment(new Date()).format('dd MMM, 2024')
}

export const convertQuarterToDate = (quarter) => {

    const quarterToMonth = {
        1: 0,  // January
        2: 3,  // April
        3: 6,  // July
        4: 9   // October
    };

    return quarter ? new Date(2024, quarterToMonth[quarter], 1) : new Date()
}


export const scColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Source Area (sq ft)',
        fieldName: 'sourceArea'
    },
    {
        name: 'Fuel Combusted',
        fieldName: 'fuelCombusted'
    },
    {
        name: 'Fuel State',
        fieldName: 'fuelState'
    },
    {
        name: 'Quantity Combusted',
        fieldName: 'quantityCombusted'
    },
    {
        name: 'Units',
        fieldName: 'units'
    },
];

export const msColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'On Road or Non Road?',
        fieldName: 'roadType'
    },
    {
        name: 'Vehicle Type',
        fieldName: 'vehicleTypeDescription'
    },
    {
        name: 'Vehicle Year',
        fieldName: 'vehicleYear'
    },
    {
        name: 'Fuel Usage',
        fieldName: 'fuelUsage'
    },
    {
        name: 'Units',
        fieldName: 'units'
    },
    {
        name: 'Distance Travelled',
        fieldName: 'distanceTraveled'
    },
    {
        name: 'Distance Unit',
        fieldName: 'distanceUnits'
    },
];

export const rfgMaterialColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId',
    },
    {
        name: 'Gas',
        fieldName: 'gas'
    },
    {
        name: 'Inventory Change',
        fieldName: 'inventoryChange'
    },
    {
        name: 'Transferred Amount',
        fieldName: 'transferredAmount'
    },
    {
        name: 'Capacity Change',
        fieldName: 'capacityChange'
    },
    {
        name: 'Units',
        fieldName: 'units'
    },
];

export const rfgSimplifiedColumns = [[
    {
        name: 'Source ID',
        fieldName: 'sourceId',
        rowspan: 2
    },
    {
        name: 'Gas',
        fieldName: 'gas',
        rowspan: 2
    },
    {
        name: 'New Units',
        colspan: 2
    },
    {
        name: 'Existing Units',
    },
    {
        name: 'Disposed Units',
        colspan: 2
    },

],
[
    {
        name: 'Charge',
        fieldName: 'newCharge',
    },
    {
        name: 'Capacity',
        fieldName: 'newCapacity'
    },
    {
        name: 'Recharge',
        fieldName: 'unitsRecharge'
    },
    {
        name: 'Capacity',
        fieldName: 'disposedCapacity'
    },
    {
        name: 'Recovered',
        fieldName: 'disposedRecovered'
    },
    {
        name: 'Units',
        rowspan: 2,
        fieldName: 'units',
    },
]];

export const rfgScreeningColumns = [
    [
        {
            name: 'Source ID',
            fieldName: 'sourceId',
            rowspan: 2
        },
        {
            name: 'Type of Equipment',
            fieldName: 'typeOfEquipment',
            rowspan: 2
        },
        {
            name: 'Gas',
            fieldName: 'gas',
            rowspan: 2
        },
        {
            name: 'New Units'
        },
        {
            name: 'Capacity',
            colspan: 2
        }
    ],
    [
        {
            name: 'Charge',
            fieldName: 'newUnitsCharge',
        },
        {
            name: 'Operating Units',
            fieldName: 'capacityOperatingUnits',
        },
        {
            name: 'Disposed Units',
            fieldName: 'capacityDisposedUnits',
        },
        {
            name: 'Units',
            rowspan: 2,
            fieldName: 'units',
        },
    ]
];

export const fsMaterialColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId',
    },
    {
        name: 'Gas',
        fieldName: 'gas'
    },
    {
        name: 'Inventory Change',
        fieldName: 'inventoryChange'
    },
    {
        name: 'Transferred Amount',
        fieldName: 'transferredAmount'
    },
    {
        name: 'Capacity Change',
        fieldName: 'capacityChange'
    },
    {
        name: 'Units',
        fieldName: 'units'
    },
];

export const fsSimplifiedColumns = [[
    {
        name: 'Source ID',
        fieldName: 'sourceId',
        rowspan: 2
    },
    {
        name: 'Gas',
        fieldName: 'gas',
        rowspan: 2
    },
    {
        name: 'New Units',
        colspan: 2
    },
    {
        name: 'Existing Units',
    },
    {
        name: 'Disposed Units',
        colspan: 2
    },

],
[
    {
        name: 'Charge',
        fieldName: 'unitsCharge',
    },
    {
        name: 'Capacity',
        fieldName: 'unitsCapacity'
    },
    {
        name: 'Recharge',
        fieldName: 'unitsRecharge'
    },
    {
        name: 'Capacity',
        fieldName: 'disposedCapacity'
    },
    {
        name: 'Recovered',
        fieldName: 'disposedRecovered'
    },
    {
        name: 'Units',
        rowspan: 2,
        fieldName: 'units',
    },
]];

export const fsScreeningColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId',
    },
    {
        name: 'Type of Equipment',
        fieldName: 'typeOfEquipment',
    },
    {
        name: 'Gas',
        fieldName: 'gasType',
    },
    {
        name: 'Unit Capacity',
        fieldName: 'unitCapacity',
    },
    {
        name: 'Units',
        fieldName: 'units',
    },
];

export const purchasedGasesColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Gas',
        fieldName: 'gas',
    },
    {
        name: 'Purchased Amount',
        fieldName: 'purchasedAmount',
    },
    {
        name: 'Units',
        fieldName: 'units',
    },
];

export const electricityColumns = [
    [
        {
            name: 'Source ID',
            fieldName: 'sourceId',
            rowspan: 2
        },
        {
            name: 'Source Description',
            fieldName: 'sourceDescription',
            rowspan: 2
        },
        {
            name: 'Source Area (sq ft)',
            fieldName: 'sourceArea',
            rowspan: 2
        },
        {
            name: 'Electricity Purchased (kWh)',
            fieldName: 'electricityPurchased',
            rowspan: 2
        },
        {
            name: 'Market Based',
            colspan: 3
        },
    ],
    [
        {
            name: 'CO\u2082 Emission Factors (kg/kWh)',
            fieldName: 'co2Factor',
        },
        {
            name: 'CH\u2084 Emission Factors (g/kWh)',
            fieldName: 'ch4Factor'
        },
        {
            name: 'N\u2082O Emission Factors (g/kWh)',
            fieldName: 'n2oFactor'
        },
    ]
]

export const steamColumns = [
    [
        {
            name: 'Source ID',
            fieldName: 'sourceId',
            rowspan: 2
        },
        {
            name: 'Source Description',
            fieldName: 'sourceDescription',
            rowspan: 2
        },
        {
            name: 'Source Area (sq ft)',
            fieldName: 'sourceArea',
            rowspan: 2
        },
        {
            name: 'Fuel Type',
            fieldName: 'fuelType',
            rowspan: 2
        },
        {
            name: 'Fuel State',
            fieldName: 'fuelState',
            rowspan: 2
        },
        {
            name: 'Boiler Electricity (%)',
            fieldName: 'boilerEfficiency',
            rowspan: 2
        },
        {
            name: 'Steam Purchased',
            fieldName: 'steamPurchased',
            rowspan: 2
        },
        {
            name: 'Units',
            fieldName: 'units',
            rowspan: 2
        },
        {
            name: 'Location Based',
            colspan: 3
        },
        {
            name: 'Market Based',
            colspan: 3
        },
    ],
    [
        {
            name: 'CO\u2082 Emission Factors (kg/unit)',
            fieldName: 'locationCO2Factor',
        },
        {
            name: 'CH\u2084 Emission Factors (g/unit)',
            fieldName: 'locationCH4Factor'
        },
        {
            name: 'N\u2082O Emission Factors (g/unit)',
            fieldName: 'locationN2OFactor'
        }, {
            name: 'CO\u2082 Emission Factors (kg/unit)',
            fieldName: 'marketCO2Factor',
        },
        {
            name: 'CH\u2084 Emission Factors (g/unit)',
            fieldName: 'marketCH4Factor'
        },
        {
            name: 'N\u2082O Emission Factors (g/unit)',
            fieldName: 'marketN2OFactor'
        },
    ]
];

export const businessTravelPersonalColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Vehicle Type',
        fieldName: 'vehicleType'
    },
    {
        name: 'Distance Travelled',
        fieldName: 'vehicleMiles'
    },
    {
        name: 'Units',
        fieldName: 'units'
    },
];

export const businessTravelPublicColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Vehicle Type',
        fieldName: 'vehicleType'
    },
    {
        name: 'Distance Travelled',
        fieldName: 'passengerMiles'
    },
    {
        name: 'Units',
        fieldName: 'units'
    },
];

export const businessTravelAirwaysColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Flight Length',
        fieldName: 'flightLength'
    },
    {
        name: 'Distance Travelled',
        fieldName: 'passengerMiles'
    },
    {
        name: 'Units',
        fieldName: 'units'
    },
];

export const commutingPersonalColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Vehicle Type',
        fieldName: 'vehicleType'
    },
    {
        name: 'Distance Travelled',
        fieldName: 'vehicleMiles'
    },
    {
        name: 'Units',
        fieldName: 'units'
    }
];

export const commutingPublicColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Vehicle Type',
        fieldName: 'transportType'
    },
    {
        name: 'Distance Travelled',
        fieldName: 'passengerMiles'
    },
    {
        name: 'Units',
        fieldName: 'units'
    }
];

export const transportColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Vehicle Type',
        fieldName: 'vehicleType'
    },
    {
        name: 'Distance Travelled',
        fieldName: 'vehicleMiles'
    },
    {
        name: 'Units',
        fieldName: 'units'
    }
];

export const distributeColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Vehicle Type',
        fieldName: 'vehicleType'
    },
    {
        name: 'Distance Travelled',
        fieldName: 'shortTonMiles'
    },
    {
        name: 'Units',
        fieldName: 'units'
    }
];

export const indirectWasteColumns = [
    {
        name: 'Source ID',
        fieldName: 'sourceId'
    },
    {
        name: 'Source Description',
        fieldName: 'sourceDescription'
    },
    {
        name: 'Waste Material',
        fieldName: 'wasteMaterial'
    },
    {
        name: 'Disposal Method',
        fieldName: 'disposalMethod'
    },
    {
        name: 'Hazardous / Non-hazardous Waste',
        fieldName: 'wasteClassification'
    },
    {
        name: 'Onsite / Offsite',
        fieldName: 'disposalLocation'
    },
    {
        name: 'Organic / Inorganic Waste',
        fieldName: 'wasteType'
    },
    {
        name: 'Weight',
        fieldName: 'weight'
    },
    {
        name: 'Units',
        fieldName: 'units'
    },
];

export const uuidGenerator = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const removeIsSubmit = (array) => {
    return array?.map(({ isSubmit, ...rest }) => rest);
}

export const addIsSubmit = (array) => {
    return array?.map(obj => ({ ...obj, isSubmit: true }));
}

export const addIsSubmitFalse = (array) => {
    return array?.map(obj => ({ ...obj, isSubmit: false }));
}


export const storeData = async (request, dataObject) => {
    let requestObj = typeof request === 'string' ? request :  JSON.stringify(request);
    const cache = await caches.open('data-capture');
    await cache.put(requestObj, new Response(JSON.stringify(dataObject)))
        .then(res => res.json())
        .then(resp => {
            console.log(resp)
        })
        .catch(err => console.log(err))
}

export const deleteCacheData = async (request, dataObject) => {
    const cache = await caches.open('data-capture');
    await cache.delete(JSON.stringify(request))
    storeData(request, dataObject)
}

export const getCacheData = async (request) => {
    let getData = [];
    const cache = await caches.open('data-capture');
    await cache.match(JSON.stringify(request))
        .then((res) => res.json())
        .then((response) => {
            console.log(response)
            getData = response
        })
        .catch((err) => { 
            console.log(err)
            getData = []
        })
    return getData;
}

export const deleteCache = async (request) => {
    const cache = await caches.open('data-capture');
    await cache.delete(JSON.stringify(request))
}

export const alertFunction = (cacheData, savedData, callback) => {
    console.log(cacheData, savedData)
    Swal.fire({
        text: 'You have unsaved data. Would you like to recover this information, or would you prefer to use the saved data instead?',
        showCancelButton: true,
        confirmButtonText: "Yes, recover",
        cancelButtonText: "No, cancel!",
    }).then((result) => {
        if (result.isConfirmed) {
            callback(cacheData)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire({
                text: 'You have unsaved changes that will be lost if you proceed with the saved data. Do you want to continue with the saved data?',
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if(result.isConfirmed) {
                    callback(savedData)
                } else if(result.dismiss === Swal.DismissReason.cancel) {
                    callback(cacheData)
                }
            })
        }
    })
}

export const notifyFunction = (savedData, callback) => {
    Swal.fire({
        text: 'Your data will be replaced with the latest saved data from the database.',
        position: 'top-end',
        timer: 5000,
        timerProgressBar: true
    })
    callback(savedData)
}

