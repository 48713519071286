import moment from "moment"
import { thousandSeparator } from "../../api/helper"

export const defaultHeader = [
    'Category',
    'Base Year',
    'Year 2',
    'Year 3',
    'Year 4',
    'Year 5',
    'Year 6',
    'Year 7',
    'Year 8',
    'Year 9',
    'Year 10',
    'Year 11',
];

export const totalScopes = {
    1: 'Total Scope 1 Direct Emissions',
    2: 'Total Scope 2 Indirect Emissions',
    3: 'Total Scope 3 Indirect Emissions'
}


export const totalScopesDescription = [
    'Description',
    'Total Scope 1 Emissions',
    'Total Scope 2 Emissions',
    'Total Scope 3 Emissions',
    'Total Emissions',
    'Offset Reductions',
    'Revised Total'
]

export const replaceYear = (tempData, year, count, type) => {
    let yearValue = typeof year === 'object' ? moment(year).format('yyyy') : year;
    if (type === 'scope') {
        if ((tempData[0]?.length - 2) < count) {
            for (let i = 0; i < tempData?.length; i++) {
                for (let j = 11; j <= count; j++) {
                    if(i === 0) {
                        tempData[i][j + 1] = { ...tempData[i][j - 1] }
                    } else {
                        tempData[i][j] = { ...tempData[i][j - 1] }
                    }
                }
            }
        }
        if ((tempData[0]?.length - 2) > count) {
            let difference = (tempData[0]?.length - 2) - count;
            for (let i = 0; i < tempData?.length; i++) {
                tempData[i]?.splice((tempData[i]?.length - difference), difference);
            }
        }
    } else {
        if ((tempData[0]?.length - 2) < count) {
            for (let i = 0; i < tempData?.length; i++) {
                for (let j = 11; j <= count; j++) {
                    if(i === 0) {
                        tempData[i][j + 1] = { ...tempData[i][j - 1] }
                    } else {
                        tempData[i][j] = { ...tempData[i][j - 1] }
                    }
                }
            }
        }
        if ((tempData[0]?.length - 2) > count) {
            let difference = (tempData[0]?.length - 2) - count;
            for (let i = 0; i < tempData?.length; i++) {
                tempData[i]?.splice((tempData[i]?.length - difference), difference);
            }
        }
    }
    tempData[0]?.map((item, index) => {
        if (index > 1) {
            item.value = Number(yearValue) + (index - 2)
        }
    })
    return tempData;
}

export const ValueViewer = ({ row, col, value }) => {
    // Customize the rendering logic based on your requirements
    return <span>{row > 0 ? thousandSeparator(value) : value}</span>;
};

export const isNumber = (value) => {
    if (!isNaN(value)) {
        return true
    }
}

export function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}


