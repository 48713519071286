import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import UserForm from "../../Common/Component/UserForm";


const AddAsrUser = (props) => {
    const [open, setOpen] = useState(props.value);
    
    const onClose = () => {
        setOpen(false);
        props.setOpenModal(false)
    }

    const onSubmit = () => {
        onClose();
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{display: 'flex'}}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>{props.orgData.companyName} - Onboarding <X className="x-button" onClick={onClose}/></H3>
                </ModalHeader>
                <ModalBody>
                <UserForm action={'Add'} onSubmit={onSubmit} cancel={onClose} navigateUrl={`${process.env.PUBLIC_URL}/asr-admin/user-list`}/>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddAsrUser;