import React, { Fragment, useState, useEffect } from "react";
import { H3 } from "../../../AbstractElements";
import { Card, CardBody, Row, Col, FormGroup, Label, Badge, Button } from "reactstrap";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import DatePicker from 'react-datepicker';
import {
    addIsSubmit, categoriesType, convertMonthToDate, convertQuarterToDate,
    removeIsSubmit, scope1Categories, scope2Categories, scope3Categories, uuidGenerator, deleteCache,
    getCacheData,
    addIsSubmitFalse,
    storeData
} from "./ghgHelper";
import { H5 } from '../../../AbstractElements';
import AgGridDatasheet from "./AgGridDatasheet";
import { useNavigate } from "react-router";
import { convertTimeformat, defaultUserType } from "../../api/helper";
import Accordion from 'react-bootstrap/Accordion';
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import check from '../../../assets/images/tick.png';
import { Paperclip } from 'react-feather';
import { useSelector } from "react-redux";
import ButtonLoader from '../ButtonLoader'
import TablePreview from "./GhgTablePreview";

const Summary = ({
    setSteps,
    setPreData,
    preData,
    setEmissionId,
    setType,
    setStatus,
    stationaryCombustionData, setStationaryCombustion,
    mobilesourcesData, setMobileSourcesData,
    rfgMaterialData, setRfgMaterial,
    rfgSimplifiedMaterialData, setRfgSimplified,
    rfgScreeningData, setRfgScreening,
    fsMaterialData, setFsMaterial,
    fsSimplifiedMaterialData, setFsSimplified,
    fsScreeningData, setFsScreening,
    purchasedGasesData, setPurchasedGases,
    energyData, setElectricity,
    steamEnergyData, setSteam,
    businessTravelPersonalData, setTravelPersonalData,
    businessTravelPublicData, setTravelPublicData,
    businessTravelAirwaysData, setTravelAirwaysData,
    travelCommutePersonalData, setTravelCommutePersonalData,
    travelCommutePublicData, setTravelCommutePublicData,
    upstreamVehicleMilesData, setTransportData,
    upstreamVehicleTonsData, setDistributeData,
    indirectWasteData, setIndirectWaste,
    timeStamp, setTimestamp
}) => {
    const [locationData, setLocationData] = useState([]);
    const [locationId, setLocationId] = useState(preData ? preData?.locationId : '');
    const [uploadPeriod, setUploadPeriod] = useState('YEAR');
    const [year, setYearValue] = useState(preData ? preData?.year : new Date(moment(new Date()).subtract(1, 'year').format('YYYY')));
    const [monthValue, setMonthValue] = useState('');
    const [quarter, setQuarterValue] = useState('');
    const { register, formState: { errors }, trigger } = useForm();
    const [scope1, setScope1Categories] = useState([])
    const [scope2, setScope2Categories] = useState([])
    const [scope3, setScope3Categories] = useState([])
    const [emissionSetId, setEmissionSetId] = useState([]);
    const [countryCode, setCountryCode] = useState();
    const [employeeCount, setEmployeeCount] = useState();
    const ghgReportingFrequency = useSelector(state => state?.user?.userDetails?.ghgReportingFrequency);
    const [draftLoading, setDraftLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [tablesObject, setObject] = useState({});
    const [ghgStatus, setGhgStatus] = useState('NO_DATA');
    const [dataObject, setDataObject] = useState({});
    const [scopeCategoryList, setScopeCategoryList] = useState([]);
    const [scopeList, setScopeList] = useState([]);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState();
    const [stateObject, setStateObject] = useState({
        stationaryCombustionData: stationaryCombustionData,
        mobilesourcesData: mobilesourcesData,
        rfgMaterialData: rfgMaterialData,
        rfgSimplifiedMaterialData: rfgSimplifiedMaterialData,
        rfgScreeningData: rfgScreeningData,
        fsMaterialData: fsMaterialData,
        fsSimplifiedMaterialData: fsSimplifiedMaterialData,
        fsScreeningData: fsScreeningData,
        purchasedGasesData: purchasedGasesData,
        energyData: energyData,
        steamEnergyData: steamEnergyData,
        businessTravelPersonalData: businessTravelPersonalData,
        businessTravelPublicData: businessTravelPublicData,
        businessTravelAirwaysData: businessTravelAirwaysData,
        travelCommutePersonalData: travelCommutePersonalData,
        travelCommutePublicData: travelCommutePublicData,
        upstreamVehicleMilesData: upstreamVehicleMilesData,
        upstreamVehicleTonsData: upstreamVehicleTonsData,
        indirectWasteData: indirectWasteData,
    })

    const scope1Mapping = {
        stationaryCombustionData: 0,
        mobilesourcesData: 1,
        rfgMaterialData: 2,
        rfgSimplifiedMaterialData: 2,
        rfgScreeningData: 2,
        fsMaterialData: 3,
        fsSimplifiedMaterialData: 3,
        fsScreeningData: 3,
        purchasedGasesData: 4,
    };

    const scope2Mapping = {
        energyData: 0,
        steamEnergyData: 1,
    };

    const scope3Mapping = {
        businessTravelPersonalData: 0,
        businessTravelPublicData: 0,
        businessTravelAirwaysData: 0,
        travelCommutePersonalData: 1,
        travelCommutePublicData: 1,
        upstreamVehicleMilesData: 2,
        upstreamVehicleTonsData: 2,
        indirectWasteData: 3,
    };

    const getLocations = async () => {
        await fetchWrapper(`${API_URL}/location`)
            .then((res) => {
                setLocationData(res.json)
            }, (err) => console.log(err))
    }

    const getCache = async (dataObject) => {
        const keyObject = {
            customerId: userProfile?.org,
            userId: userProfile?.id,
            ...dataObject
        }
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData);
        const scope1Updated = [...scope1Categories];
        const scope2Updated = [...scope2Categories];
        const scope3Updated = [...scope3Categories];
        cacheData?.employeeCountData?.count ? setEmployeeCount(cacheData?.employeeCountData?.count) : setEmployeeCount(0)
        Object.keys(scope1Mapping).forEach(key => {
            if (scope1Mapping[key] === 2) {
                if (cacheData?.rfgMaterialData?.length > 0
                    || cacheData?.rfgSimplifiedMaterialData?.length > 0
                    || cacheData?.rfgScreeningData?.length > 0) {
                    scope1Updated[2].value = 1;
                } else {
                    scope1Updated[2].value = 0;
                }
            } else if (scope1Mapping[key] === 3) {
                if (cacheData?.fsMaterialData?.length > 0
                    || cacheData?.fsSimplifiedMaterialData?.length > 0
                    || cacheData?.fsScreeningData?.length > 0) {
                    scope1Updated[3].value = 1;
                } else {
                    scope1Updated[3].value = 0;
                }
            } else {
                if (cacheData[key]?.length > 0) {
                    scope1Updated[scope1Mapping[key]].value = 1;
                } else {
                    scope1Updated[scope1Mapping[key]].value = 0;
                }
            }

        });
        Object.keys(scope2Mapping).forEach(key => {
            if (cacheData[key]?.length > 0) {
                scope2Updated[scope2Mapping[key]].value = 1;
            } else {
                scope2Updated[scope2Mapping[key]].value = 0;
            }
        });
        Object.keys(scope3Mapping).forEach(key => {
            if (scope3Mapping[key] === 0) {
                if (cacheData?.businessTravelPersonalData?.length > 0
                    || cacheData?.businessTravelPublicData?.length > 0
                    || cacheData?.businessTravelAirwaysData?.length > 0) {
                    scope3Updated[0].value = 1
                } else {
                    scope3Updated[0].value = 0
                }
            } else if (scope3Mapping[key] === 1) {
                if (cacheData?.travelCommutePersonalData?.length > 0
                    || cacheData?.travelCommutePublicData?.length > 0) {
                    scope3Updated[1].value = 1
                } else {
                    scope3Updated[1].value = 0
                }
            } else if (scope3Mapping[key] === 2) {
                if (cacheData?.upstreamVehicleMilesData?.length > 0
                    || cacheData?.upstreamVehicleTonsData?.length > 0) {
                    scope3Updated[2].value = 1
                } else {
                    scope3Updated[2].value = 0
                }
            } else {
                if (cacheData[key]?.length > 0) {
                    scope3Updated[scope3Mapping[key]].value = 1;
                } else {
                    scope3Updated[scope3Mapping[key]].value = 0;
                }
            }
        });
        console.log(scope1Updated, scope2Updated, scope3Updated);
    }

    const nullifyEvidence = () => {
        scope1Categories?.map((ele) => ele.evidencesCount = 0);
        scope2Categories?.map((ele) => ele.evidencesCount = 0);
        scope3Categories?.map((ele) => ele.evidencesCount = 0);
    };

    const getScopeCategories = async () => {
        await fetchWrapper(`${API_URL}/scopecategories/organization`)
            .then((res) => {
                let list = res?.json?.map(ele => ele?.categoryName);
                let scopeList = res?.json?.map(ele => ele?.scope);
                setScopeList([...new Set(scopeList)])
                setScopeCategoryList([...list]);
                setScope1Categories(scope1Categories?.filter(item => list?.includes(item?.category)))
                setScope2Categories(scope2Categories?.filter(item => list?.includes(item?.category)))
                setScope3Categories(scope3Categories?.filter(item => list?.includes(item?.category)))
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getScopeCategories()
    }, [])

    useEffect(() => {

    }, [scopeCategoryList])

    const getEmissionSetId = async (data) => {
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/ghgemissionsdata`, options)
            .then(async (res) => {
                if (res.status === 200) {
                    console.log(res.json);
                    const data = res.json;
                    setGhgStatus(res.json?.status)
                    setDataObject(data);
                    setTimestamp(data?.lastModifiedTS);
                    data?.employeeCountData?.count ? setEmployeeCount(data?.employeeCountData?.count) : setEmployeeCount(0)
                    if (['PROCESSING_COMPLETE'].includes(res.json?.status)) {
                        setStationaryCombustion(addIsSubmit(data?.stationaryCombustionData));
                        setMobileSourcesData(addIsSubmit(data?.mobilesourcesData));
                        setRfgMaterial(addIsSubmit(data?.rfgMaterialData));
                        setRfgSimplified(addIsSubmit(data?.rfgSimplifiedMaterialData));
                        setRfgScreening(addIsSubmit(data?.rfgScreeningData));
                        setFsMaterial(addIsSubmit(data?.fsMaterialData));
                        setFsSimplified(addIsSubmit(data?.fsSimplifiedMaterialData));
                        setFsScreening(addIsSubmit(data?.fsScreeningData));
                        setPurchasedGases(addIsSubmit(data?.purchasedGasesData));
                        setElectricity(addIsSubmit(data?.energyData));
                        setSteam(addIsSubmit(data?.steamEnergyData));
                        setTravelPersonalData(addIsSubmit(data?.businessTravelPersonalData));
                        setTravelPublicData(addIsSubmit(data?.businessTravelPublicData));
                        setTravelAirwaysData(addIsSubmit(data?.businessTravelAirwaysData));
                        setTravelCommutePersonalData(addIsSubmit(data?.travelCommutePersonalData));
                        setTravelCommutePublicData(addIsSubmit(data?.travelCommutePublicData));
                        setTransportData(addIsSubmit(data?.upstreamVehicleMilesData));
                        setDistributeData(addIsSubmit(data?.upstreamVehicleTonsData));
                        setIndirectWaste(addIsSubmit(data?.indirectWasteData));
                    } else if (res.json?.status === 'SAVE_AS_DRAFT') {
                        setStationaryCombustion(addIsSubmitFalse(data?.stationaryCombustionData));
                        setMobileSourcesData(addIsSubmitFalse(data?.mobilesourcesData));
                        setRfgMaterial(addIsSubmitFalse(data?.rfgMaterialData));
                        setRfgSimplified(addIsSubmitFalse(data?.rfgSimplifiedMaterialData));
                        setRfgScreening(addIsSubmitFalse(data?.rfgScreeningData));
                        setFsMaterial(addIsSubmitFalse(data?.fsMaterialData));
                        setFsSimplified(addIsSubmitFalse(data?.fsSimplifiedMaterialData));
                        setFsScreening(addIsSubmitFalse(data?.fsScreeningData));
                        setPurchasedGases(addIsSubmitFalse(data?.purchasedGasesData));
                        setElectricity(addIsSubmitFalse(data?.energyData));
                        setSteam(addIsSubmitFalse(data?.steamEnergyData));
                        setTravelPersonalData(addIsSubmitFalse(data?.businessTravelPersonalData));
                        setTravelPublicData(addIsSubmitFalse(data?.businessTravelPublicData));
                        setTravelAirwaysData(addIsSubmitFalse(data?.businessTravelAirwaysData));
                        setTravelCommutePersonalData(addIsSubmitFalse(data?.travelCommutePersonalData));
                        setTravelCommutePublicData(addIsSubmitFalse(data?.travelCommutePublicData));
                        setTransportData(addIsSubmitFalse(data?.upstreamVehicleMilesData));
                        setDistributeData(addIsSubmitFalse(data?.upstreamVehicleTonsData));
                        setIndirectWaste(addIsSubmitFalse(data?.indirectWasteData));
                    }
                    await updateSummaryStatus(data)
                }
            }, async (err) => {
                console.log(err)
                if (err.status === 404) {
                    setGhgStatus('NO_DATA')
                    setEmissionId()
                    setEmployeeCount(0)
                    nullifyEvidence();
                    await setStationaryCombustion([]);
                    await setMobileSourcesData([]);
                    await setRfgMaterial([]);
                    await setRfgSimplified([]);
                    await setRfgScreening([]);
                    await setFsMaterial([]);
                    await setFsSimplified([]);
                    await setFsScreening([]);
                    await setPurchasedGases([]);
                    await setElectricity([]);
                    await setSteam([]);
                    await setTravelPersonalData([]);
                    await setTravelPublicData([]);
                    await setTravelAirwaysData([]);
                    await setTravelCommutePersonalData([]);
                    await setTravelCommutePublicData([]);
                    await setTransportData([]);
                    await setDistributeData([]);
                    await setIndirectWaste([]);
                    await getCache(data)
                }
            })
    }

    const handleSheets = async (type, hashValue) => {
        if (locationId) {
            let data = locationData.find(ele => ele.locationId == locationId);
            const objectData = {
                locationId: Number(locationId),
                countryCode: data?.countryCode === 'UAE' ? 'AE' : data?.countryCode,
                uploadPeriod: uploadPeriod ? uploadPeriod : 'YEAR',
                year: year ? parseInt(moment(year).format('YYYY')) : '',
                month: uploadPeriod === 'MONTH' ? moment(monthValue).format('MMM') : null,
                quarter: uploadPeriod === 'QUARTER' ? parseInt(moment(quarter).format('Q')) : 0
            }
            setPreData({ ...objectData })
            // await getEmissionSetId(objectData)
            setSteps(categoriesType[type])
            console.log(employeeCount)
            saveEmployeeCount(employeeCount)
        } else {
            toast.error('Please select location and period')
        }
    }

    const handleEvidence = (id, type) => {
        if (id && locationId) {
            setType(type)
            console.log(preData);
            setPreData(preData)
            setSteps(-1)
        } else if (!locationId) {
            toast.error('Please select location and period')
        } else if (!id) {
            toast.error('Please update GHG data')
        }

    }

    const columnData = [
        {
            headerName: 'Category',
            field: 'category',
            filter: false,
            flex: 3
        },
        {
            headerName: 'Status',
            filter: false,
            flex: 1,
            cellRenderer: (params) => {
                return <div>
                    {
                        params.data.value === 1 ? <img src={check} height={30} width={30} /> : <></>
                    }
                </div>
            },
            cellClass: 'tick-center'
        },
        {
            headerName: 'Evidences',
            flex: 1,
            cellRenderer: (params) => {
                return (
                    <div onClick={() => handleEvidence(emissionSetId, params.data.type)} >
                        <Paperclip color='#006666' className='evidence-icon' /> <Badge className='round-badge'>{params.data.evidencesCount}</Badge>
                    </div>
                )
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {

            width: 100,
            cellRenderer: (params) => {
                return (
                    <div>
                        <button
                            className={['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(ghgStatus) ? "border-1px-solid" : 'disabled-button-border'}
                            disabled={!['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(ghgStatus)}
                            onClick={() => handleSheets(params.data.category, params.data.hashValue)}>Click here</button>
                    </div>
                )
            }
        },
    ]

    const updateSummaryStatus = async (data) => {
        const scope1Updated = [...scope1Categories];
        const scope2Updated = [...scope2Categories];
        const scope3Updated = [...scope3Categories];

        console.log(data, stateObject, cacheData)
        if (Object?.keys(data)?.length > 0) {
            Object.keys(scope1Mapping).forEach(key => {
                if (scope1Mapping[key] === 2) {
                    if (data?.rfgMaterialData?.length > 0
                        || data?.rfgSimplifiedMaterialData?.length > 0
                        || data?.rfgScreeningData?.length > 0) {
                        scope1Updated[2].value = 1;
                    } else {
                        scope1Updated[2].value = 0;
                    }
                } else if (scope1Mapping[key] === 3) {
                    if (data?.fsMaterialData?.length > 0
                        || data?.fsSimplifiedMaterialData?.length > 0
                        || data?.fsScreeningData?.length > 0) {
                        scope1Updated[3].value = 1;
                    } else {
                        scope1Updated[3].value = 0;
                    }
                } else {
                    if (data[key]?.length > 0) {
                        scope1Updated[scope1Mapping[key]].value = 1;
                    } else {
                        scope1Updated[scope1Mapping[key]].value = 0;
                    }
                }

            });
            Object.keys(scope2Mapping).forEach(key => {
                if (data[key]?.length > 0) {
                    scope2Updated[scope2Mapping[key]].value = 1;
                } else {
                    scope2Updated[scope2Mapping[key]].value = 0;
                }
            });
            Object.keys(scope3Mapping).forEach(key => {
                if (scope3Mapping[key] === 0) {
                    if (data?.businessTravelPersonalData?.length > 0
                        || data?.businessTravelPublicData?.length > 0
                        || data?.businessTravelAirwaysData?.length > 0) {
                        scope3Updated[0].value = 1
                    } else {
                        scope3Updated[0].value = 0
                    }
                } else if (scope3Mapping[key] === 1) {
                    if (data?.travelCommutePersonalData?.length > 0
                        || data?.travelCommutePublicData?.length > 0) {
                        scope3Updated[1].value = 1
                    } else {
                        scope3Updated[1].value = 0
                    }
                } else if (scope3Mapping[key] === 2) {
                    if (data?.upstreamVehicleMilesData?.length > 0
                        || data?.upstreamVehicleTonsData?.length > 0) {
                        scope3Updated[2].value = 1
                    } else {
                        scope3Updated[2].value = 0
                    }
                } else {
                    if (data[key]?.length > 0) {
                        scope3Updated[scope3Mapping[key]].value = 1;
                    } else {
                        scope3Updated[scope3Mapping[key]].value = 0;
                    }
                }
            });
        } else if (Object?.keys(data)?.length === 0) {
            Object.keys(scope1Mapping).forEach(key => {
                if (scope1Mapping[key] === 2) {
                    if ((stateObject?.rfgMaterialData?.length > 0
                        || stateObject?.rfgSimplifiedMaterialData?.length > 0
                        || stateObject?.rfgScreeningData?.length > 0) || (cacheData?.rfgMaterialData?.length > 0
                            || cacheData?.rfgSimplifiedMaterialData?.length > 0
                            || cacheData?.rfgScreeningData?.length > 0)) {
                        scope1Updated[2].value = 1;
                    } else {
                        scope1Updated[2].value = 0;
                    }
                } else if (scope1Mapping[key] === 3) {
                    if ((stateObject?.fsMaterialData?.length > 0
                        || stateObject?.fsSimplifiedMaterialData?.length > 0
                        || stateObject?.fsScreeningData?.length > 0) || (cacheData?.fsMaterialData?.length > 0
                            || cacheData?.fsSimplifiedMaterialData?.length > 0
                            || cacheData?.fsScreeningData?.length > 0)) {
                        scope1Updated[3].value = 1;
                    } else {
                        scope1Updated[3].value = 0;
                    }
                } else {
                    if (((stateObject?.[key] || [])?.length > 0) || ((cacheData?.[key] || [])?.length > 0)) {
                        scope1Updated[scope1Mapping[key]].value = 1;
                    } else {
                        scope1Updated[scope1Mapping[key]].value = 0;
                    }
                }

            });
            Object.keys(scope2Mapping).forEach(key => {
                if (((stateObject?.[key] || [])?.length > 0) || ((cacheData?.[key] || [])?.length > 0)) {
                    scope2Updated[scope2Mapping[key]].value = 1;
                } else {
                    scope2Updated[scope2Mapping[key]].value = 0;
                }
            });

            // Update scope3Categories
            Object.keys(scope3Mapping).forEach(key => {
                if (scope3Mapping[key] === 0) {
                    if ((cacheData?.businessTravelPersonalData?.length > 0
                        || cacheData?.businessTravelPublicData?.length > 0
                        || cacheData?.businessTravelAirwaysData?.length > 0)
                        || (stateObject?.businessTravelPersonalData?.length > 0
                            || stateObject?.businessTravelPublicData?.length > 0
                            || stateObject?.businessTravelAirwaysData?.length > 0)) {
                        scope3Updated[0].value = 1
                    } else {
                        scope3Updated[0].value = 0
                    }
                } else if (scope3Mapping[key] === 1) {
                    if ((cacheData?.travelCommutePersonalData?.length > 0
                        || cacheData?.travelCommutePublicData?.length > 0)
                        || (stateObject?.travelCommutePersonalData?.length > 0
                            || stateObject?.travelCommutePublicData?.length > 0)) {
                        scope3Updated[1].value = 1
                    } else {
                        scope3Updated[1].value = 0
                    }
                } else if (scope3Mapping[key] === 2) {
                    if ((cacheData?.upstreamVehicleMilesData?.length > 0
                        || cacheData?.upstreamVehicleTonsData?.length > 0)
                        || (stateObject?.upstreamVehicleMilesData?.length > 0
                            || stateObject?.upstreamVehicleTonsData?.length > 0)) {
                        scope3Updated[2].value = 1
                    } else {
                        scope3Updated[2].value = 0
                    }
                } else {
                    if (((stateObject?.[key] || [])?.length > 0) || ((cacheData?.[key] || [])?.length > 0)) {
                        scope3Updated[scope3Mapping[key]].value = 1;
                    } else {
                        scope3Updated[scope3Mapping[key]].value = 0;
                    }
                }
            });
        }
    }

    const getSummaryStatus = async (locationId, uploadPeriod, year, month, quarter) => {
        let data = locationData.find(ele => ele.locationId == locationId);
        const dataObject = {
            locationId: Number(locationId),
            countryCode: data?.countryCode,
            uploadPeriod: uploadPeriod ? uploadPeriod : 'YEAR',
            year: year ? parseInt(moment(year).format('YYYY')) : parseInt(moment(new Date()).format('YYYY')),
            month: uploadPeriod === 'MONTH' ? moment(month).format('MMM') : null,
            quarter: uploadPeriod === 'QUARTER' ? parseInt(moment(quarter).format('Q')) : 0
        }
        await getEmissionSetId(dataObject);
        setPreData(dataObject)
        await nullifyEvidence()
        // await updateSummaryStatus()
    }

    const handleLocation = async (value) => {
        setLocationId(value)
        let data = locationData.find(ele => ele.locationId === value);
        setCountryCode(data?.countryCode);
        await getSummaryStatus(value, uploadPeriod, year, monthValue, quarter)
    }

    const handleYear = async (e) => {
        setYearValue(e)
        await getSummaryStatus(locationId, uploadPeriod, e, monthValue, quarter)
    }

    const handleMonth = async (e) => {
        setMonthValue(e)
        await getSummaryStatus(locationId, uploadPeriod, year, e, quarter)
    }

    const handleQuarter = async (e) => {
        setQuarterValue(e)
        await getSummaryStatus(locationId, uploadPeriod, year, monthValue, e)
    }

    const handlePeriod = async (value) => {
        setUploadPeriod(value);
        await getSummaryStatus(locationId, value, year, monthValue, quarter)
    }

    useEffect(() => {
        getLocations()
        if (preData) {
            setYearValue(new Date(convertTimeformat(preData?.year)))
            setUploadPeriod(preData?.uploadPeriod)
            setMonthValue(new Date(convertMonthToDate(preData?.month)))
            setQuarterValue(convertQuarterToDate(preData?.quarter))
            setUploadPeriod(preData?.uploadPeriod)
            getCache(preData)
        }
    }, [])

    useEffect(() => {
        updateSummaryStatus(dataObject);
    }, [scope1, scope2, scope3]);


    const handleSaveDraft = async () => {
        const tableObject = {
            ...preData,
            status: 'SAVE_AS_DRAFT',
            stationaryCombustionData: stationaryCombustionData?.length > 0 ? removeIsSubmit(stationaryCombustionData) : removeIsSubmit(cacheData?.stationaryCombustionData),
            mobilesourcesData: mobilesourcesData?.length > 0 ? removeIsSubmit(mobilesourcesData) : removeIsSubmit(cacheData?.mobilesourcesData),
            rfgMaterialData: rfgMaterialData?.length > 0 ? removeIsSubmit(rfgMaterialData) : cacheData?.rfgMaterialData?.length > 0 ? removeIsSubmit(cacheData?.rfgMaterialData) : [],
            rfgSimplifiedMaterialData: rfgSimplifiedMaterialData?.length > 0 ? removeIsSubmit(rfgSimplifiedMaterialData) : cacheData?.rfgSimplifiedMaterialData?.length > 0 ? removeIsSubmit(cacheData?.rfgSimplifiedMaterialData) : [],
            rfgScreeningData: rfgScreeningData?.length > 0 ? removeIsSubmit(rfgScreeningData) : cacheData?.rfgScreeningData?.length > 0 ? removeIsSubmit(cacheData?.rfgScreeningData) : [],
            fsMaterialData: fsMaterialData?.length > 0 ? removeIsSubmit(fsMaterialData) : cacheData?.fsMaterialData?.length ? removeIsSubmit(cacheData?.fsMaterialData) : [],
            fsSimplifiedMaterialData: fsSimplifiedMaterialData?.length > 0 ? removeIsSubmit(fsSimplifiedMaterialData) : cacheData?.fsSimplifiedMaterialData?.length > 0 ? removeIsSubmit(cacheData?.fsSimplifiedMaterialData) : [],
            fsScreeningData: fsScreeningData?.length > 0 ? removeIsSubmit(fsScreeningData) : cacheData?.fsScreeningData?.length > 0 ? removeIsSubmit(cacheData?.fsScreeningData) : [],
            purchasedGasesData: purchasedGasesData?.length > 0 ? removeIsSubmit(purchasedGasesData) : removeIsSubmit(cacheData?.purchasedGasesData),
            energyData: energyData?.length > 0 ? removeIsSubmit(energyData) : removeIsSubmit(cacheData?.energyData),
            steamEnergyData: steamEnergyData?.length > 0 ? removeIsSubmit(steamEnergyData) : removeIsSubmit(cacheData?.steamEnergyData),
            businessTravelPersonalData: businessTravelPersonalData?.length > 0 ? removeIsSubmit(businessTravelPersonalData) : cacheData?.businessTravelPersonalData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelPersonalData) : [],
            businessTravelPublicData: businessTravelPublicData?.length > 0 ? removeIsSubmit(businessTravelPublicData) : cacheData?.businessTravelPublicData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelPublicData) : [],
            businessTravelAirwaysData: businessTravelAirwaysData?.length > 0 ? removeIsSubmit(businessTravelAirwaysData) : cacheData?.businessTravelAirwaysData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelAirwaysData) : [],
            travelCommutePersonalData: travelCommutePersonalData?.length > 0 ? removeIsSubmit(travelCommutePersonalData) : cacheData?.travelCommutePersonalData?.length > 0 ? removeIsSubmit(cacheData?.travelCommutePersonalData) : [],
            travelCommutePublicData: travelCommutePublicData?.length > 0 ? removeIsSubmit(travelCommutePublicData) : cacheData?.travelCommutePublicData?.length > 0 ? removeIsSubmit(cacheData?.travelCommutePublicData) : [],
            upstreamVehicleMilesData: upstreamVehicleMilesData?.length > 0 ? removeIsSubmit(upstreamVehicleMilesData) : cacheData?.upstreamVehicleMilesData?.length > 0 ? removeIsSubmit(cacheData?.upstreamVehicleMilesData) : [],
            upstreamVehicleTonsData: upstreamVehicleTonsData?.length > 0 ? removeIsSubmit(upstreamVehicleTonsData) : cacheData?.upstreamVehicleTonsData?.length > 0 ? removeIsSubmit(cacheData?.upstreamVehicleTonsData) : [],
            indirectWasteData: indirectWasteData?.length > 0 ? removeIsSubmit(indirectWasteData) : removeIsSubmit(cacheData?.indirectWasteData),
            employeeCountData: {
                id: uuidGenerator(),
                count: employeeCount,
                isInsert: true,
                isUpdate: false,
                isDelete: false
            }
        };
        const options = {
            method: 'POST',
            body: tableObject
        }
        await fetchWrapper(`${API_URL}/ghgemissionsdata/saveasdraft`, options)
            .then((res) => {
                console.log(res.json)
                toast.success('Data saved as a draft successfully!')
            }, async (err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Data saved as a draft successfully!')
                    await deleteCache({
                        customerId: userProfile?.org,
                        userId: userProfile?.id,
                        ...preData,
                    })
                    getEmissionSetId(preData)
                }
            })
    }

    const hanldeSubmit = async () => {
        const tableObject = {
            ...preData,
            status: 'DATA_SUBMITTED',
            stationaryCombustionData: stationaryCombustionData?.length > 0 ? removeIsSubmit(stationaryCombustionData) : removeIsSubmit(cacheData?.stationaryCombustionData),
            mobilesourcesData: mobilesourcesData?.length > 0 ? removeIsSubmit(mobilesourcesData) : removeIsSubmit(cacheData?.mobilesourcesData),
            rfgMaterialData: rfgMaterialData?.length > 0 ? removeIsSubmit(rfgMaterialData) : cacheData?.rfgMaterialData?.length > 0 ? removeIsSubmit(cacheData?.rfgMaterialData) : [],
            rfgSimplifiedMaterialData: rfgSimplifiedMaterialData?.length > 0 ? removeIsSubmit(rfgSimplifiedMaterialData) : cacheData?.rfgSimplifiedMaterialData?.length > 0 ? removeIsSubmit(cacheData?.rfgSimplifiedMaterialData) : [],
            rfgScreeningData: rfgScreeningData?.length > 0 ? removeIsSubmit(rfgScreeningData) : cacheData?.rfgScreeningData?.length > 0 ? removeIsSubmit(cacheData?.rfgScreeningData) : [],
            fsMaterialData: fsMaterialData?.length > 0 ? removeIsSubmit(fsMaterialData) : cacheData?.fsMaterialData?.length ? removeIsSubmit(cacheData?.fsMaterialData) : [],
            fsSimplifiedMaterialData: fsSimplifiedMaterialData?.length > 0 ? removeIsSubmit(fsSimplifiedMaterialData) : cacheData?.fsSimplifiedMaterialData?.length > 0 ? removeIsSubmit(cacheData?.fsSimplifiedMaterialData) : [],
            fsScreeningData: fsScreeningData?.length > 0 ? removeIsSubmit(fsScreeningData) : cacheData?.fsScreeningData?.length > 0 ? removeIsSubmit(cacheData?.fsScreeningData) : [],
            purchasedGasesData: purchasedGasesData?.length > 0 ? removeIsSubmit(purchasedGasesData) : removeIsSubmit(cacheData?.purchasedGasesData),
            energyData: energyData?.length > 0 ? removeIsSubmit(energyData) : removeIsSubmit(cacheData?.energyData),
            steamEnergyData: steamEnergyData?.length > 0 ? removeIsSubmit(steamEnergyData) : removeIsSubmit(cacheData?.steamEnergyData),
            businessTravelPersonalData: businessTravelPersonalData?.length > 0 ? removeIsSubmit(businessTravelPersonalData) : cacheData?.businessTravelPersonalData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelPersonalData) : [],
            businessTravelPublicData: businessTravelPublicData?.length > 0 ? removeIsSubmit(businessTravelPublicData) : cacheData?.businessTravelPublicData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelPublicData) : [],
            businessTravelAirwaysData: businessTravelAirwaysData?.length > 0 ? removeIsSubmit(businessTravelAirwaysData) : cacheData?.businessTravelAirwaysData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelAirwaysData) : [],
            travelCommutePersonalData: travelCommutePersonalData?.length > 0 ? removeIsSubmit(travelCommutePersonalData) : cacheData?.travelCommutePersonalData?.length > 0 ? removeIsSubmit(cacheData?.travelCommutePersonalData) : [],
            travelCommutePublicData: travelCommutePublicData?.length > 0 ? removeIsSubmit(travelCommutePublicData) : cacheData?.travelCommutePublicData?.length > 0 ? removeIsSubmit(cacheData?.travelCommutePublicData) : [],
            upstreamVehicleMilesData: upstreamVehicleMilesData?.length > 0 ? removeIsSubmit(upstreamVehicleMilesData) : cacheData?.upstreamVehicleMilesData?.length > 0 ? removeIsSubmit(cacheData?.upstreamVehicleMilesData) : [],
            upstreamVehicleTonsData: upstreamVehicleTonsData?.length > 0 ? removeIsSubmit(upstreamVehicleTonsData) : cacheData?.upstreamVehicleTonsData?.length > 0 ? removeIsSubmit(cacheData?.upstreamVehicleTonsData) : [],
            indirectWasteData: indirectWasteData?.length > 0 ? removeIsSubmit(indirectWasteData) : removeIsSubmit(cacheData?.indirectWasteData),
            employeeCountData: {
                id: dataObject?.employeeCountData?.id,
                count: employeeCount,
                isInsert: ghgStatus === 'SAVE_AS_DRAFT' ? true : false,
                isUpdate: (ghgStatus === 'PROCESSING_COMPLETE') ? true : false,
                isDelete: false
            }
        };
        setObject(tableObject);
        console.log(tableObject);
        setPreviewOpen(true);
    }

    const handleEmployeeCount = (value) => {
        if (locationId) {
            setEmployeeCount(value)
        } else {
            setEmployeeCount()
        }
    }

    

    const saveEmployeeCount = async (value) => {
        if (!locationId) {
            setEmployeeCount()
            toast.error('Please select Location and Period')
        } else {
            setEmployeeCount(value)
            let data = locationData.find(ele => ele.locationId == locationId);
            const dataObject = {
                customerId: userProfile?.org,
                userId: userProfile?.id,
                locationId: Number(locationId),
                countryCode: data.countryCode,
                uploadPeriod: ghgReportingFrequency,
                year: year ? parseInt(moment(year).format('YYYY')) : parseInt(moment(new Date()).format('YYYY')),
                month: ghgReportingFrequency === 'MONTH' ? moment(monthValue).format('MMM') : null,
                quarter: ghgReportingFrequency === 'QUARTER' ? parseInt(moment(quarter).format('Q')) : 0,
            }
            
            
            const tempData = {
                ...cacheData,
                employeeCountData: {
                    id: uuidGenerator(),
                    count: value,
                    isInsert: true,
                    isUpdate: false,
                    isDelete: false
                }
            }
            console.log(tempData)
            await storeData(dataObject, tempData)
            
        }
    }

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className='mtop-1'>
                        <Row>
                            <Col lg='3'>
                                <FormGroup>
                                    <Label className=''>Location</Label>
                                    <select className='form-select ' value={locationId} onChange={(e) => handleLocation(e.target.value)}>
                                        <option value="">--Select Location--</option>
                                        {
                                            locationData?.map((item) => (<option value={item?.locationId}>{item?.locationName}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col lg='2'>
                                <FormGroup>
                                    <Label>Period</Label>
                                    <select className='form-select' value={ghgReportingFrequency}
                                        {...register('upload_period', { required: true })}
                                        onChange={(e) => handlePeriod(e.target.value)} disabled
                                    >
                                        <option value=''>Select Period</option>
                                        <option value="MONTH">MONTH</option>
                                        <option value="QUARTER">QUARTER</option>
                                        <option value="YEAR">YEAR</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col lg='2'>
                                <FormGroup>
                                    <Label className="mr-1">Select Year</Label>
                                    <DatePicker
                                        className="datepicker-here form-control"
                                        selected={year}
                                        onChange={(e) => handleYear(e)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        maxDate={new Date(moment(new Date()).subtract(1, 'year'))}
                                    />
                                </FormGroup>
                            </Col>

                            {
                                uploadPeriod === 'MONTH' && <Col lg='2'>
                                    <FormGroup>
                                        <Label>Select Month</Label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={month}
                                            onChange={(e) => handleMonth(e)}
                                            showMonthYearPicker
                                            dateFormat="MMMM"
                                            showTwoColumnMonthYearPicker
                                            placeholderText="-Month-"
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            {
                                uploadPeriod === 'QUARTER' && <Col lg='2'>
                                    <FormGroup>
                                        <Label>Select Quarter</Label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={quarter}
                                            onChange={(e) => handleQuarter(e)}
                                            showQuarterYearPicker
                                            dateFormat="QQQ"
                                            placeholderText="-Quarter-"
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            <Col lg='3'>
                                <FormGroup>
                                    <Label >Employee Count</Label>
                                    <input
                                        className='form-control'
                                        type='number'
                                        min={0}
                                        placeholder="0"
                                        value={employeeCount}
                                        disabled={!locationId}
                                        onChange={(e) => handleEmployeeCount(e.target.value)}
                                        onBlur={(e) => saveEmployeeCount(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="mtop-1">
                        <H3 attrH3={{ className: 'f-s-16' }}>Summary of Organization's Emissions</H3>
                    </div>
                    <div className="accord-container">
                        <Row>
                            <Col lg='12' style={{ opacity: !['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(ghgStatus) ? 0.1 : 1 }}>
                                <Accordion>
                                    {
                                        scopeList?.includes(1) && <Accordion.Item eventKey="0">
                                            <Accordion.Header>Scope 1 Emissions</Accordion.Header>
                                            <Accordion.Body >
                                                <div style={{ width: '100%' }} className="ag-datasheet">
                                                    <AgGridDatasheet
                                                        tableData={scope1?.filter(ele => scopeCategoryList?.includes(ele?.category))}
                                                        columnData={columnData}
                                                    />
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }
                                    {
                                        scopeList?.includes(2) && <Accordion.Item eventKey="1">
                                            <Accordion.Header>Scope 2 Emissions - Location and Market based</Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ width: '100%' }} className="ag-datasheet">
                                                    <AgGridDatasheet
                                                        tableData={scope2?.filter(ele => scopeCategoryList?.includes(ele?.category))}
                                                        columnData={columnData}

                                                    />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }
                                    {
                                        scopeList?.includes(3) && <Accordion.Item eventKey="2">
                                            <Accordion.Header>Scope 3 Emissions</Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ width: '100%' }} className="ag-datasheet">
                                                    <AgGridDatasheet
                                                        tableData={scope3?.filter(ele => scopeCategoryList?.includes(ele?.category))}
                                                        columnData={columnData}
                                                    />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }

                                </Accordion>
                            </Col>
                        </Row>
                        {
                            !['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(ghgStatus) && <div className="accord-overlay">
                                <div className="accord-overlay-p w-55">
                                    <p className="text-center">Data processing is currently in progress, so editing is temporarily unavailable. Thank you for your patience.</p>
                                </div>

                            </div>
                        }

                    </div>

                    <div className="d-flex mtop-1">
                        {
                            draftLoading ? <ButtonLoader color={"light"} loaderText={'Saving'} />
                                : <Button color="light"
                                    disabled={!['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(ghgStatus)}
                                    onClick={handleSaveDraft}
                                >Save as Draft</Button>
                        }
                        {
                            submitLoading ? <ButtonLoader color={"primary"} loaderText={'Submitting'} />
                                : <Button color="primary"
                                    disabled={!['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(ghgStatus)}
                                    className={'ml-1'}
                                    onClick={hanldeSubmit}
                                >Submit</Button>
                        }
                    </div>

                </CardBody>
            </Card>
            {
                previewOpen && <TablePreview
                    value={previewOpen}
                    setOpenModal={setPreviewOpen}
                    tableData={tablesObject}
                    status={ghgStatus}
                    keyObject={{
                        customerId: userProfile?.org,
                        userId: userProfile?.id,
                        ...preData,
                    }}
                />
            }

        </Fragment>
    )
}

export default Summary;