import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import Loader from '../../Common/Layout/Loader';
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { Container, Row, Col, Card, CardBody, CardHeader, Table } from "reactstrap";
import { Link } from "react-router-dom";
import LayoutLoader from "../../Common/Component/LayoutLoader/LayoutLoader";

const BOViewCustomer = () => {
    const [data, setData] = useState();
    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);

    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization/${id}`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
            })
            .catch((error) => {
                console.debug(error)
            })
    }

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs parent="Organizations" title="View Organization" mainTitle="View Organization" />
            <Container fluid={true} className="ecommerce-page">
                <Row>
                    <Col lg={12}>
                        <Card className="browser-table">
                            <CardHeader className="">
                                <h2>Organization Details</h2>
                            </CardHeader>
                            <CardBody className="br-10">
                                <div className="table-responsive mb-0">
                                    <Table className="table ">
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Name:</td><td>{data?.companyName}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Website:</td><td className="text-left">{data?.website}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Organization Type:</td><td>{data?.orgType}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Address:</td><td className="text-left">{data?.address}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">State:</td><td>{data?.state}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Country:</td><td className="text-left">{data?.country}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Currency:</td><td>{data?.currency}</td>
                                                <td></td>
                                                <td></td><td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card className="browser-table">
                            <CardHeader >
                                <h2>Contact Person Details</h2>
                            </CardHeader>
                            <CardBody className="br-10">
                                <div className="table-responsive mb-0">
                                    <Table className="table ">
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Name:</td><td>{data?.firstName + ' ' + data?.lastName}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Email ID:</td><td className="text-left">{data?.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Phone:</td><td>{data?.phoneNumber}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Address:</td><td className="text-left">{data?.address}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">User Name:</td><td>{data?.userName}</td>
                                                <td></td>
                                                <td ></td><td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className="mb-5">
                <Link to={`${process.env.PUBLIC_URL}/back-office/customer-list`}>
                            <Btn attrBtn={{ color: 'primary' }} >
                                Back
                            </Btn>
                        </Link>
                </div>
                
            </Container>
        </Fragment>
    )
}

export default BOViewCustomer;