import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import Loader from '../../Layout/Loader';
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Container, Row, Col, Card, CardBody, CardHeader, Table } from "reactstrap";
import { Link } from "react-router-dom";
import LayoutLoader from "../LayoutLoader/LayoutLoader";
import { defaultOrgType, defaultUserType } from "../../api/helper";
import { useSelector } from "react-redux";

const ViewSubOrganization = () => {
    const [data, setData] = useState();

    const userType = useSelector(state => state?.user?.userType);

    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);

    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization/${id}`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
            })
            .catch((error) => {
                console.debug(error)
            })
    }

    const orgControl = {
        'Equity Control': 'Equity',
        'Operational Control': 'Operational',
        'Financial Control': 'Financial'
    }

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs parent="Organizations" title="View" mainTitle="Organization" />
            <Container fluid={true} className="ecommerce-page">
                <Row>
                    <Col lg={12}>
                        <Card className="browser-table">
                            <CardHeader className="">
                                <h2>Organization Details</h2>
                            </CardHeader>
                            <CardBody className="br-10">
                                <div className="table-responsive mb-0">
                                    <Table className="table ">
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Name</td><td>{data?.companyName}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Website</td><td className="text-left">{data?.website}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Organization Type</td><td>{defaultOrgType[data?.orgType]}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Address</td><td className="text-left">{data?.address}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">State</td><td>{data?.state}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Country</td><td className="text-left">{data?.country}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Powered By</td><td>{data?.tenantName}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Currency</td><td>{data?.currency}</td>
                                            </tr>
                                            <tr className="bottom-none">
                                                <td className="fw-td-bold">Control Type</td><td>{data?.controlType}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Control Percentage</td><td>{data?.controlTypePercentage}%</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card className="browser-table">
                            <CardHeader >
                                <h2>Contact Person Details</h2>
                            </CardHeader>
                            <CardBody className="br-10">
                                <div className="table-responsive mb-0">
                                    <Table className="table ">
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Name</td><td>{data?.firstName + ' ' + data?.lastName}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Email ID</td><td className="text-left">{data?.email}</td>
                                            </tr>
                                            <tr className="bottom-none">
                                                <td className="fw-td-bold">Phone</td><td>{data?.phoneNumber}</td>
                                                <td></td>
                                                <td className="fw-td-bold">User Name</td><td>{data?.userName}</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className="mb-5">
                    <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/sub-organization-list`}>
                        <Btn attrBtn={{ color: 'primary' }} >
                            Back
                        </Btn>
                    </Link>
                </div>

            </Container>
        </Fragment>
    )
}

export default ViewSubOrganization;