import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { Eye, PlusCircle, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { toast } from 'react-toastify';
import LayoutLoader from '../../Common/Component/LayoutLoader/LayoutLoader';

const BOCustomersList = () => {
    const [open, setOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [orgList, setOrgList] = useState()
    const [loading, setLoading] = useState(false)

    const plusIcon = {
        height: 50,
        width: 50,
        fill: '#2E7D32',
        strokeWidth: 1,
        color: '#fff'
    }

    const defaultOrgType = {
        "ASSET_OWNER": 'Asset Owner',
        "ASSESSOR": 'Assessor',
        "ASSET_MANAGER": 'Asset Manager'
    }

    const loadData = async () => {
        setLoading(true)
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization`, options)
            .then(res => {
                console.log(res)
                setLoading(false)
                setOrgList(res.json)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    

    const tableColumns = [
        {
            name: 'Company Name',
            selector: row => `${row.companyName}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'City',
            selector: row => `${row.state}, ${row.country}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'Organization Type',
            selector: row => `${defaultOrgType[row.orgType]}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: "View",
            cell: (row) => (
                <Link to={`${process.env.PUBLIC_URL}/back-office/view-customer/${row.id}`}>
                    <Eye className={`action-icon`} />
                </Link>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Edit",
            cell: (row) => (
                <Link to={`${process.env.PUBLIC_URL}/back-office/edit-customer/${row.id}`}>
                    <Edit className={`edit-icon`} />
                </Link>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        loadData()
    }, [open])

    const handleClick = () => setOpen(true);
    
    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Breadcrumbs mainTitle="Organizations"  parent="Organizations" title="List"/>

                        <Card className=" newtable">
                            <CardBody className='data-tables responsive-view'>
                                <DataTableComponent tableData={orgList} columnsData={tableColumns} title={'Organizations List'}
                                    setShowFilter={setShowFilter} loading={loading} />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

};

export default BOCustomersList;