import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { Breadcrumbs, P } from '../../../AbstractElements'
import { Details, Methodology, Proponent, RequiredDocuments } from '../../../Common/Constant'
import DetailsComponent from './Details'
import MethodologyComponent from './Methodology'
import RequiredDocumentsComponent from './RequiredDocuments'
import Notes from './Notes';
import { useParams } from "react-router-dom";
import "../../../assets/css/style.css";
import { useSelector } from 'react-redux';
import fetchWrapper from '../../../Common/api/fetchWrapper';
import { API_URL } from '../../Config/Config';

const ProjectInitiation = () => {
    const [steps, setSteps] = useState(1);
    const [formdata, setFormdata] = useState({});
    const progressWidth = ['14%', '39%', '64%', '89%'];
    const [orgData, setOrgData] = useState();
    const { origin } = useParams();
    const [title, setTitle] = useState('');

    const userType = useSelector(state => state.user?.userType)

    const loadData = async () => {
        await fetchWrapper(`${API_URL}/users/orgDetails`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setOrgData(res.json[0])
                }
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        setTitle(origin === 'External' ? 'Onboard Existing Project' : 'Project Initiation')
        if(['ASO_ADMIN','ASO_USER'].includes(userType)) 
            loadData();
    }, [])

    return (
        <Fragment> 
            <Breadcrumbs subParent="Projects" parent="Carbon Credits" title={title} mainTitle={title} />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card style={{ minHeight: '100vh' }} className=''>
                            {/* <HeaderCard title={'Form Wizard With Icon'} /> */}
                            <CardBody>
                                <div className='project-init'>
                                    <Form className='f1' method='post'>
                                        <div className="f1-steps row-eq-height">
                                            <div className="f1-progress  progress-bar-align">
                                                <div className="f1-progress-line" data-now-value="49" style={{ width: progressWidth[steps - 1] }} data-number-of-steps={3} />
                                            </div>
                                            <div className={`f1-step activated ${steps >= 1 && "active"}`}>
                                                <div className="f1-step-icon"><i className="fa fa-info fa-2x" /></div>
                                                <P attrPara={{className: 'f-17'}}>{Details}</P>
                                            </div>
                                            <div className={`f1-step activated ${steps >= 2 && "active"}`}>
                                                <div className="f1-step-icon" ><i className="fa fa-tasks fa-2x" /></div>
                                                <P attrPara={{className: 'f-17'}}>{Methodology}</P>
                                            </div>
                                            <div className={`f1-step activated ${steps >= 3 && "active"}`}>
                                                <div className="f1-step-icon" ><i className="fa fa-file fa-2x" /></div>
                                                <P attrPara={{className: 'f-17'}}>{RequiredDocuments}</P>
                                            </div>
                                            <div className={`f1-step activated ${steps >= 4 && "active"}`}>
                                                <div className="f1-step-icon" ><i className="fa fa-edit fa-2x" /></div>
                                                <P attrPara={{className: 'f-17'}}>{'Notes'}</P>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                {steps === 1 && <DetailsComponent setSteps={setSteps} setFormdata={setFormdata} orgData={orgData} formdata={formdata} />}
                                {steps === 2 && <MethodologyComponent setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}
                                {steps === 3 && <RequiredDocumentsComponent setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}
                                {steps === 4 && <Notes setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ProjectInitiation

