import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, CardBody, Col, Container, Row, Table, Button, Input } from 'reactstrap';
import { Breadcrumbs } from "../../../AbstractElements";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import Loader from "../../Layout/Loader";
import { useNavigate } from "react-router-dom";
import { currencyFormatter, formatter } from "../../api/helper";
import DataTableComponent from '../DataTable/DataTableComponent'
import PlaceOrderModal from "./PlaceOrderModal";
import { useSelector } from "react-redux";
import AgGridTable from "../AgGridTable";
import { useCallback } from "react";


const CarbonTrading = () => {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false);
    const [creditsData, setCreditsData] = useState()
    const [showFilter, setShowFilter] = useState(false);
    const [asoList, setAsoList] = useState([])
    const [selectedAsoOrg, setSelectedAsoOrg] = useState();
    const [addStatus, setAddStatus] = useState(false)
    const tradingRef = useRef(null);
    const currency = useSelector(state => state?.user?.userDetails?.currency);

    const userType = useSelector(state => state?.user?.userType);

    const handleModal = (data) => {
        setCreditsData(data)
        setOpen(true)
    }

    const loadDetailsData = async (val) => {
        setLoading(true)
        const url = ['ASM_ADMIN', 'ASM_USER'].includes(userType) ? `/projectcredits/newtradingDetails/assetOwner/${val}`
            : `/projectcredits/newtradingDetails/assetOwner`;
        await fetchWrapper(`${API_URL}${url}`)
            .then((res) => {
                if (res.status === 200) {
                    setData(res.json)
                    setLoading(false)
                }
            }, (err) => {
                setLoading(false)
            })
    }

    const loadAsoOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization/assetmanager/mappedOrgs`)
            .then((res) => {
                const data = res.json;
                setAsoList(data?.mappedAssetOwners)
            }, (err) => { console.log(err) })
    }

    const tableColumns = [
        {
            headerName: 'Project Name',
            field: 'projectName',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Certified Credits (MtCO2e)',
            field: 'certifiedCurrencyBalance',
            filter: false,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return Number(params.data.certifiedCurrencyBalance).toFixed(2)
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Unit Rate (USD)',
            field: 'midRate',
            filter: false,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.midRate ? currencyFormatter(params.data.midRate, 'USD') : '-'
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Total Value (USD)',
            field: 'midRate',
            filter: false,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                let calc = params.data?.certifiedCurrencyBalance * params.data?.midRate * params.data?.splitValue;
                return calc ? currencyFormatter(calc, 'USD') : '-'
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: `Monetary Value (${currency})`,
            field: 'midRate',
            filter: false,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return currencyFormatter(params.data.monetaryValue, params.data.orgCurrency)
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Action',
            flex: 1,
            cellRenderer: params => {
                return (
                    <div  >
                        <Button color="primary" className={`trading-sell ${params.data.certifiedCurrencyBalance > 0 ? '' : 'disable-icon'}`}
                            outline={params.data.certifiedCurrencyBalance > 0 ? false : true}
                            onClick={() => handleModal(params.data)} disabled={params.data.certifiedCurrencyBalance > 0 ? false : true}>Sell</Button>
                    </div>
                )
            },
            suppressMovable: true,
        }
    ]

    const loadData = (val) => {
        setSelectedAsoOrg(val);
        loadDetailsData(val);
    }

    useEffect(() => {
        if (['ASM_ADMIN', 'ASM_USER'].includes(userType)) {
            loadAsoOrgList()
        }
        if (addStatus === true) {
            loadDetailsData()
        }
    }, [addStatus])

    const onGridReady = useCallback(async (params) => {
        tradingRef.current.api.showLoadingOverlay();
        const url = ['ASM_ADMIN', 'ASM_USER'].includes(userType) ? `/projectcredits/newtradingDetails/assetOwner/${val}`
            : `/projectcredits/newtradingDetails/assetOwner`;
        await fetchWrapper(`${API_URL}${url}`)
            .then((res) => {
                if (res.status === 200) {
                    tradingRef.current.api.hideOverlay();
                    setData(res.json)
                    setLoading(false)
                }
            }, (err) => {
                tradingRef.current.api.hideOverlay();
                if (err.status === 404) {
                    tradingRef.current.api.showNoRowsOverlay();
                }
            })
    })

    return (
        <Fragment>
            <Row>
                <Col xxl={12}>
                    <div className="d-flex mtop-1">
                        <Breadcrumbs parent="Carbon Trading" mainTitle='Trading' title="Trading" />
                        {
                            (['ASM_ADMIN', 'ASM_USER'].includes(userType)) && <div className="d-flex aso-dropdown">
                                <select className="form-select"
                                    onChange={(e) => loadData(e.target.value)}
                                >
                                    <option value=''>Select Asset Owner Organization</option>
                                    {
                                        asoList?.length > 0 && asoList?.map((item) => {
                                            return (
                                                <option value={item?.assetOwnerId}>{item?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        }
                    </div>

                    {open && <PlaceOrderModal value={open} setOpenModal={setOpen} data={creditsData} asoOrgid={selectedAsoOrg} setAdd={setAddStatus} />}

                    <Card className="">
                        <CardBody className='data-tables trading-view ptop-0'>
                            {/* <DataTableComponent tableData={data} columnsData={tableColumns} loading={loading}
                                setShowFilter={setShowFilter} /> */}
                            <div className="trading-table">
                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridTable
                                        columnData={tableColumns}
                                        tableData={data}
                                        agRef={tradingRef}
                                        onGridReady={onGridReady}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default CarbonTrading;