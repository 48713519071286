export const scope1Categories = [
    { category: 'Stationary Combustion', checked: false },
    { category: 'Mobile Sources', checked: false },
    { category: 'Refrigeration / AC Equipment Use', checked: false },
    { category: 'Fire Suppression', checked: false },
    { category: 'Purchased Gases', checked: false },
]

export const scope2Categories = [
    { category: 'Purchased and Consumed Electricity', checked: false },
    { category: 'Purchased and Consumed Steam', checked: false },
]

export const scope3Categories = [
    { category: 'Employee Business Travel', checked: false },
    { category: 'Employee Commuting', checked: false },
    { category: 'Upstream Transportation and Distribution', checked: false },
    { category: 'Indirect Waste', checked: false },
]

export const greivanceData = [
    {
        stakeholderGroup: 'Communities'
    },
    {
        stakeholderGroup: 'Investors (other than shareholders)'
    },
    {
        stakeholderGroup: 'Shareholders'
    },
    {
        stakeholderGroup: 'Employees and workers'
    },
    {
        stakeholderGroup: 'Customers'
    },
    {
        stakeholderGroup: 'Value Chain Partners'
    },
    {
        stakeholderGroup: 'Other (please specify)'
    },
];

export const employee = [
    {
        particulars: 'Permanent (D)'
    },
    {
        particulars: 'Other than Permanent (E) '
    }
]

export const workers = [
    {
        particulars: 'Permanent (F)'
    },
    {
        particulars: 'Other than Permanent (G) '
    }
]

export const diffabledEmployee = [
    {
        particulars: 'Permanent (D)'
    },
    {
        particulars: 'Other than Permanent (E) '
    },

]

export const diffabledWorkers = [
    {
        particulars: 'Permanent (F)'
    },
    {
        particulars: 'Other than Permanent (G) '
    }
]

export const female = [
    {
        representationType: 'Board of Directors'
    },
    {
        representationType: 'Key Management Personnel'
    }
];

export const permanentEmpData = [
    {
        particulars: 'Permanent Employees'
    },
    {
        particulars: 'Permanent Workers'
    }
]

export const policyTemplateData = [
    {
        serialId: 1,
        serialNo: 'PP-1',
        questionText: 'Whether your entity’s policy/policies cover each principle and its core elements of the Responsible Business Guidelines. (Yes/No)',
        type: 'text'
    },
    {
        serialId: 2,
        serialNo: 'PP-2',
        questionText: 'Has the policy been approved by the Board? (Yes/No)',
        type: 'text'
    },
    {
        serialId: 3,
        serialNo: 'PP-3',
        questionText: 'Web Link of the Policies, if available',
        type: 'text'
    },
    {
        serialId: 4,
        serialNo: 'PP-4',
        questionText: 'Whether the entity has translated the policy into procedures.(Yes/No)',
        type: 'text'
    },
    {
        serialId: 5,
        serialNo: 'PP-5',
        questionText: 'Do the enlisted policies extend to your value chain partners? (Yes/No)',
        type: 'text'
    },
    {
        serialId: 6,
        serialNo: 'PP-6',
        questionText: 'Name of the national and international codes/certifications/standards/labels standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and mapped to each principle.',
        type: 'text'
    },
    {
        serialId: 7,
        serialNo: 'PP-7',
        questionText: 'Specific commitments, goals and targets set by the entity with defined timelines, if any.',
        type: 'text'
    },
    {
        serialId: 8,
        serialNo: 'PP-8',
        questionText: 'Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met.',
        type: 'text'
    }
];

export const reviewParticulars = [
    {
        questionText: 'Performance against above policies and follow up action',
        serialNo: 'NR-1'
    },
    {
        questionText: 'Compliance with statutory requirements of relevance to the principles, and rectification of any non-compliances',
        serialNo: 'NR-2'
    }
];

export const principleList = [
    'Ethical Transparent Accountability',
    'Sustainable Safe Practices',
    'Employee Well - being Promotion',
    'Stakeholder Interest Responsiveness',
    'Human Rights Advocacy',
    'Environmental Protection Efforts',
    'Responsible Transparent Advocacy',
    'Inclusive Equitable Development',
    'Responsible Consumer Engagement'
]

export const externalParticulars = [
    {
        serialNo: 'EE-01',
        questionText: 'Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.'
    }
];

export const policyQuestions = [
    {
        serialNo: 'PC-01',
        questionText: 'The entity does not consider the principles material to its business (Yes/No)', type: 'checkbox'
    },
    {
        serialNo: 'PC-02',
        questionText: 'The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)', type: 'checkbox'
    },
    {
        serialNo: 'PC-03',
        questionText: 'The entity does not have the financial or/human and technical resources available for the task (Yes/No)', type: 'checkbox'
    },
    {
        serialNo: 'PC-04',
        questionText: 'It is planned to be done in the next financial year (Yes/No)', type: 'checkbox'
    },
    {
        serialNo: 'PC-05',
        questionText: 'Any other reason (please specify)', type: 'text'
    }
];

export const trainingData = [
    {
        segment: 'Board of Directors'
    },
    {
        segment: 'Key Managerial Personnel'
    },
    {
        segment: 'Employees other than BoD and  KMPs'
    }, 
    {
        segment: 'Workers'
    }
]

export const penaltyData = [
    {
        segment: 'Penalty/Fine'
    },
    {
        segment: 'Settlement'
    },
    {
        segment: 'Compounding Fee '
    }
]

export const nonmonetaryPenaltyData = [
    {
        segment: 'Imprisonment'
    },
    {
        segment: 'Punishment'
    }
];

export const misleadingData = [
    {
        segment: 'Directors'
    },
    {
        segment: 'KMPs'
    },
    {
        segment: 'Employees'
    },
    {
        segment: 'Workers'
    },
];

export const complaintsData = [
    {
        segment: 'Number of complaints received in relation to issues of Conflict of Interest of the Directors'
    },
    {
        segment: 'Number of complaints received in relation to issues of Conflict of Interest of the KMPs'
    },
];

export const businessDetailsData = [
    {
        parameters: 'Concentration of Purchases', 
        metrics: 'Total purchase percentage(%)'
    },
    {
        parameters: 'Concentration of Purchases', 
        metrics: 'Total number of purchases'
    },
    {
        parameters: 'Concentration of Purchases', 
        metrics: 'Total purchase Percentage(%)'
    },
]