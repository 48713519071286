import React, { Fragment, useState, useEffect } from "react";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { Breadcrumbs, H6, P, Image, Btn, H3, H5, H4 } from "../../../AbstractElements";
import { Card, CardBody } from "reactstrap";
import StationaryCombustion from "./StationaryCombustion";
import MobileSources from "./MobileSources";

const EmissionUnits = () => {
    const [activeKey, setActiveKey] = useState(1);
    return (
        <Fragment>
            <Breadcrumbs mainTitle={"Emission Factors"} />
           
                    <div className="emission-factor">
                        <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <Row>
                                
                                <Col lg={3}>
                                <Card>
                                    <CardBody>
                                    <div>
                                        <Nav variant="tabs" defaultActiveKey="1" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="1">Stationary Combustion</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="2">Mobile Sources</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="3">Refrigeration / AC Equipment Use</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="4">Fire Suppression</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="5">Purchased Gases</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="6">Electricity</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="7">Steam</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="8">Employee Business Travel</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="9">Employee Commuting</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="10">Upstream Transportation and Distribution</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="11">Indirect Waste</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    </CardBody>
                                </Card>
                                    
                                </Col>
                                <Col lg={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={'1'}>
                                            <StationaryCombustion />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={'2'}>
                                            <MobileSources />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>


                        </Tab.Container>
                    </div>
                

        </Fragment>
    )
}

export default EmissionUnits;