import { X } from "react-feather";
import React, { useState } from "react";
import { H4, H3 } from '../../../AbstractElements'
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const EmissionModal = ({ value, setOpenModal, data }) => {
    const [open, setOpen] = useState(value);

    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }
    return (
        <Modal isOpen={open} centered={true} size="lg">
            <ModalHeader >
                <H3 attrH3={{ className: 'modal-title-color mb-0' }}>{data?.title}</H3>
                <X className="x-button" onClick={onClose} />
            </ModalHeader>
            <ModalBody>
                <div>
                    <H3 attrH3={{ className: 'f-w-600' }}>{data?.formula}</H3>
                </div>
                <div className="mtop-1">
                    <H4 attrH4={{ className: 'f-w-600' }}>Description</H4>
                    <ul>
                        {
                            data?.descriptionArray?.map((i) => (
                                <li>
                                    <p>{i}</p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default EmissionModal;