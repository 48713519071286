export const baselineEmission = {
    title: 'Baseline Emission',
    formula: 'BEy = ∑ EDiy * EFify * 100 * IRiy - 1 / (AFECiy * MPGiy)',
    descriptionArray: [
        'BEy = Baseline emissions in year(y) (tCO2e)',
        'EDi,y = Electricity delivered by project charging systems serving applicable fleet(i) in project year(y) (kwh)',
        'EFj,f,y = Emission factor for the fossil fuel(f) used by comparable fleet vehicles(j) in year(y) (tCO2e/gallon)',
        'IRi = Technology improvement rate factor for applicable fllet(i)',
        'AFECi,y = Weighted average electricity consumption per 100 miles rating for EVs in applicable fleet(i) in project year(y) (kwh/100 miles)',
        'MPGi,y = Weighted average miles per gallon rating for the fossil fuel vehicles comparable to each EV in applicable fleet(i) in project year(y) (miles per gallon)' 
    ]
}

export const projectEmission = {
    title: 'Project Emission',
    formula: 'PEy = ∑ij ECijy * EFkwijy',
    descriptionArray: [
        'PEy = Project emissions in year(y) (tCO2e)',
        'ECi,j,y = Electricity consumed by project chargers sourced from region(j) serving applicable fleet(i) in project year(y) (kwh/year)',
        'EFkwi,j,y = Emission factor (average) for the electricity sourced from region(j) consumed by project charging systems serving applicable fleet(i) in year(y) (tCO2e/kwh)'
    ]
}

