import React, { Fragment, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button, FormGroup, Input, Row, Col } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";

const Operations = ({ activeTab, setActive }) => {
    const [exportContribution, setExportContribution] = useState();
    const [typeOfCustomer, setTypeOfCustomer] = useState();

    const onSubmit = async () => {
        const data = [
            {
                serialNo: 1,
                groupName: 'Operations',
                key: 'q1',
                value: exportContribution
            },
            {
                serialNo: 1,
                groupName: 'Operations',
                key: 'q2',
                value: typeOfCustomer
            }
        ];
        const options = {
            method: 'POST',
            body: {
                attributesKeyValueData: data
            }
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(11)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <h3>Operations</h3>
            <Col className="mtop-1 d-flex" lg='12'>
                <label className="col-6">What is the contribution of exports as a percentage of the total turnover of the entity?</label>
                <input type="number" className="form-control" value={exportContribution}
                    onChange={(e) => setExportContribution(e.target.value)} />

            </Col>
            <Col className="mtop-1 d-flex" lg='12'>
                <label className="col-6">A brief on type of customers</label>
                <textarea className="form-control" rows={5} value={typeOfCustomer}
                    placeholder="Provide an overview of the various groups of customers that the company caters to, such as individual consumers, businesses, government agencies, or non-profit organizations."
                    onChange={(e) => setTypeOfCustomer(e.target.value)} />
            </Col>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(9)}>Back</Button>
                <Button color="primary" className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default Operations;