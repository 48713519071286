import React, { Fragment, useEffect, useState } from "react";
import { service, factories, models } from "powerbi-client";
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { CardBody, Card, Button, Container, Row, Col } from "reactstrap";
import { H3 } from '../../AbstractElements';
import subscribeImg from '../../assets/images/subscribe.jpeg';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { defaultUserType } from "../../Common/api/helper";


const PowerBiReport = (props) => {
    const [errStatus, setErrStatus] = useState(false);
    const [report, setReport] = useState();
    let reportContainer;
    let reportRef = React.createRef();
    const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
    let embedContainer = document.getElementById('reportContainer');
    const navigate = useNavigate();
    const [noData, setNodata] = useState(false);

    const getAllBaseYears = async () => {
        await fetchWrapper(`${API_URL}/targetsettings`)
            .then((res) => {
                console.log(res.json);
                if (res.status === 200) {
                    getEmbedReportDetails();
                }
            }, (err) => {
                console.log(err)
                if (err.status === 404) {
                    setErrStatus(true)
                }
            })
    }

    const checkTargetData = async () => {
        await fetchWrapper(`${API_URL}/targetsettings/targetdata`)
            .then((res) => {
                if (res.json?.targetYear) {
                    getEmbedReportDetails();
                }
            }, (err) => {
                console.log(err)
                if (err.status === 404) {
                    setErrStatus(true)
                }
            })
    }

    useEffect(() => {
        // if (props.request_param?.reportId === 'GHGEmissionDashboard') {
        //     getAllBaseYears();
        // } else if (props.request_param?.reportId === 'Target-Net-Zero-Emission') {
        //     checkTargetData();
        // } else {
        //     getEmbedReportDetails();
        // }
        getEmbedReportDetails();
        if (reportRef !== null) {
            reportContainer = reportRef['current'];
        }

    }, []);

    const renderPowerBiReport = async (embedToken, embedURL, reportId) => {
        const embedConfiguration = {
            type: "report",
            tokenType: models.TokenType.Embed,
            accessToken: embedToken,
            embedUrl: embedURL,
            id: reportId,
            permissions: models.Permissions.All,
            settings: {
                // filterPaneEnabled: true,
                // navContentPaneEnabled: true
            }
        };
        const report = powerbi.embed(reportContainer, embedConfiguration);
        await new Promise(resolve => report.on('loaded', resolve));
        setReport(report);
    }

    const getEmbedReportDetails = async () => {
        const options = {
            method: 'POST',
            body: props.request_param
        }
        await fetchWrapper(`${API_URL}/reports/reportMetadata`, options)
            .then((res) => {
                if (res && res.json) {
                    renderPowerBiReport(res.json.embedId, res.json.embedUrl, res.json.reportId);
                    console.log(report)

                } else {
                    // console.log(res);
                }
            })
            .catch((err) => {
                setErrStatus(true)
                console.log(err)
            });
    }


    const exportPdf = async () => {
        const options = {
            method: 'POST',
            body: props.request_param
        }
        await fetchWrapper(`${API_URL}/reports/reportData/exportFile`, options)
            .then((res) => {
                console.log(res.json)
                if (res.status === 200) {
                    toast.success(`Please use the Report ID ${res.json?.reportId} as reference to download the file`)
                }
            }, (err) => console.log(err))
    }

    return (
        <Fragment>
            <Container fluid={true} >
                <Row>
                    <Col lg="12" >
                        <div className="d-flex">
                            {
                                !errStatus && <div className="mtop-1-hf mb-1">
                                    <div>
                                        <Button color="primary" onClick={exportPdf}>PDF Export</Button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div
                            className="mtop-1"
                            id="reportContainer"
                            ref={reportRef} >
                            {
                                !errStatus && <div>Loading the report...</div>
                            }
                            {
                                errStatus && <div className="subscribe-card">
                                    <div className="w-55 dashboard-content">
                                        <img src={subscribeImg} width={350} height={350} />
                                        <H3 attrH3={{ className: 'credits-table text-center' }}>Currently there is no data available to build your dashboard.</H3>
                                    </div>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>

            </Container>
        </Fragment>
    );
};

export default PowerBiReport;