import React, { Fragment, useState } from 'react';
import { Breadcrumbs, H1, H5, LI, UL } from '../../AbstractElements';
import { Container, Row, Col, Card, CardBody, CardHeader, Button, Table, CardFooter } from 'reactstrap';
import { Download } from 'react-feather';

const Subscription = () => {
    return (
        <Fragment>
            <Container fluid={true}>
                <Breadcrumbs parent="Billing" title="Subscription" mainTitle="Subscription" />
                <Row>
                    <Col lg='12'>
                        <Card>
                            <CardBody>
                                <Table striped>
                                    <thead>
                                        <th>Billing Date</th>
                                        <th>Project Name</th>
                                        <th>Subscription</th>
                                        <th>Billing Amount</th>
                                        <th>View Invoice</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>06-03-22</td>
                                            <td>Green Carbon</td>
                                            <td>Yearly</td>
                                            <td>$ 1000 
                                                {/* <Button outline color='primary' className='ml-1'>Upgrade</Button> */}
                                            </td>
                                            <td>
                                                {/* <i className="fa fa-file fa-2x document-icon" /> */}
                                                <Download className='document-icon' />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                            {/* <CardFooter className='d-flex'>
                                <Button color='primary'>Renew Now</Button>
                                <Button color='primary' className='ml-2'>Cancel Renewal</Button>
                                <Button color='primary' outline className='ml-2'>Auto Renew on</Button>
                            </CardFooter> */}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Subscription