import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { CardBody, Row, Container, Col, Card, CardHeader, Table } from "reactstrap";
import LayoutLoader from "../LayoutLoader/LayoutLoader";
import { Link, useParams } from "react-router-dom";
import { Btn } from '../../../AbstractElements';
import { defaultUserType } from "../../api/helper";
import MapComponent from "../MarkerMap/MapComponent";

const ViewEvStation = () => {
    const [data, setData] = useState();
    const id = useParams()?.id;
    const [markPosition, setMarkPosition] = useState({
        lat: 37.7749, // Set your initial latitude
        lng: -122.4194, // Set your initial longitude
      })
    
    const mapStyles = {
        width: '100%',
        height: '400px'
    }
    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/evstation/${id}`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
                let geolocationData = res.json?.geolocation?.split('=')[1].split(',');
                const data = {lat: Number(geolocationData[0]), lng: Number(geolocationData[1])}
                setMarkPosition(data)
            })
            .catch((error) => {
                console.debug(error)
            })
    }

    const userType = sessionStorage.getItem('userType')

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs parent="Location" title="View" mainTitle={data?.projectName} />
            <Container fluid={true} className="ecommerce-page">
                <Row>
                    <Col lg={6}>
                        <Card className="browser-table">
                            <CardHeader className="">
                                <h2>EV Station Details</h2>
                            </CardHeader>
                            <CardBody className="br-10">
                                <div className="table-responsive mb-0">
                                    <Table className="table ">
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Station ID</td><td className="text-left">{data?.stationId}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Charging Unit Capacity</td><td className="text-left">{data?.chargingUnitCapacity} KW</td>
                                            </tr>
                                            <tr className="border-none">
                                                <td className="fw-td-bold">City</td><td className="text-left">{data?.city}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">State</td><td>{data?.state}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="browser-table">
                        <CardHeader className="">
                                {/* <h2></h2> */}
                            </CardHeader>
                            <CardBody className="br-10 mtop-2">
                                <div className="table-responsive mb-0">
                                    <Table className="table ">
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Station Name</td><td >{data?.name}</td>
                                            </tr>
                                            {/* <tr>
                                                <td className="fw-td-bold">Gun Capacity</td><td >{data?.gunCapacity} Amp</td>
                                            </tr> */}
                                            <tr className="border-none">
                                                <td className="fw-td-bold">Location</td><td>{ data?.addressLine1 + ', ' + data?.addressLine2 }</td>
                                            </tr>
                                            
                                            <tr>
                                                <td className="fw-td-bold">Country</td><td>{data?.country}</td>
                                            </tr>
                                            
                                        </tbody>
                                    </Table>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <MapComponent position={markPosition} setMarker={setMarkPosition} styles={mapStyles}/>
                <div className="mb-5">
                    <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-station-lists/${data?.projectId}/${data?.projectName}`}>
                        <Btn attrBtn={{ color: 'secondary' }} >
                            Back
                        </Btn>
                    </Link>
                </div>
            </Container>
        </Fragment>
    )
}

export default ViewEvStation;