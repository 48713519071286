import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { Breadcrumbs } from "../../../AbstractElements";
import { defaultUserType } from "../../api/helper";
import EvChargerForm from "../Forms/EvChargerForm";
import { useParams } from "react-router";

const EditEvCharger = () => {
    const [ data, setData ] = useState();
    const id = useParams().id;
    let projectId;
    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/evchargers/${id}`, options)
        .then((res)=> {
            console.log(res)
            setData(res.json)
            projectId = res.json?.projectId
        })
        .catch((error) => {
            console.debug(error)
        })
    }

    const userType = sessionStorage.getItem('userType')

    return (
        <Fragment>
            <Breadcrumbs parent="Location" title="Edit" mainTitle={data?.projectName} />
            <EvChargerForm action={'Edit'} navigateUrl={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-charger-lists/${data?.projectId}/${data?.projectName}`} projectId={data?.projectId} preData={data} />
        </Fragment>
    )
}

export default EditEvCharger;