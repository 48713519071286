import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import HeaderCard from '../../Common/Component/HeaderCard';
import { Breadcrumbs } from '../../AbstractElements';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { PlusCircle } from 'react-feather';
import AddUser from './AddUser';
import { Link } from 'react-router-dom';
import { Edit, Trash2 } from 'react-feather';


const dummytabledata = [
    {
        id: 1,
        firstName: 'Saravanan',
        lastName: 'Krishnan',
        emailId: 'abc@gmail.com',
        phone: '+91 9876543215',
        userType: 'Power User',
        edit: <Link to={`${process.env.PUBLIC_URL}/admin/edit-user`}>
            <Edit className='action-icon'/>
        </Link>,
        delete: <div>
            <Trash2 className='action-icon' />
        </div>
    },
    {
        id: 2,
        firstName: 'Saravanan',
        lastName: 'Krishnan',
        emailId: 'abc@gmail.com',
        phone: '+91 9876543215',
        userType: 'Power User',
        edit: <Link to={`${process.env.PUBLIC_URL}/admin/edit-user`}>
            <Edit className='action-icon'/>
        </Link>,
        delete: <div>
            <Trash2 className='action-icon' />
        </div>
    },
    {
        id: 3,
        firstName: 'Saravanan',
        lastName: 'Krishnan',
        emailId: 'abc@gmail.com',
        phone: '+91 9876543215',
        userType: 'Power User',
        edit: <Link to={`${process.env.PUBLIC_URL}/admin/edit-user`}>
            <Edit className='action-icon'/>
        </Link>,
        delete: <div>
            <Trash2 className='action-icon' />
        </div>
    },
    {
        id: 4,
        firstName: 'Saravanan',
        lastName: 'Krishnan',
        emailId: 'abc@gmail.com',
        phone: '+91 9876543215',
        userType: 'Power User',
        edit: <Link to={`${process.env.PUBLIC_URL}/admin/edit-user`}>
            <Edit className='action-icon'/>
        </Link>,
        delete: <div>
            <Trash2 className='action-icon' />
        </div>
    },
    {
        id: 5,
        firstName: 'Saravanan',
        lastName: 'Krishnan',
        emailId: 'abc@gmail.com',
        phone: '+91 9876543215',
        userType: 'Power User',
        edit: <Link to={`${process.env.PUBLIC_URL}/admin/edit-user`}>
            <Edit className='action-icon'/>
        </Link>,
        delete: <div>
            <Trash2 className='action-icon' />
        </div>
    },
];


const UserManagement = () => {
    const [open, setOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const plusIcon = {
        height: 50,
        width: 50,
        fill: '#2E7D32',
        strokeWidth: 1,
        color: '#fff'
    }

    const tableColumns = [
        {
            name: 'First Name',
            selector: row => `${row.firstName}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Last Name',
            selector: row => `${row.lastName}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Email Id',
            selector: row => `${row.emailId}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Phone',
            selector: row => `${row.phone}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'User Type',
            selector: row => `${row.userType}`,
            sortable: true,
            filterable: showFilter,
            center: false,
        },
        {
            name: 'Edit',
            selector: row => row['edit'],
            sortable: true,
            center: false,
        },
        {
            name: 'Delete',
            selector: row => row['delete'],
            sortable: true,
            center: false,
        }
    ];

    
    const handleClick = () => setOpen(true);

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className='d-flex justify-between mb-2 mt-2 user_card'>
                            <HeaderCard title={'User List'} />
                            <div className='ml-50 d-flex' onClick={handleClick}>
                                <PlusCircle className='plus-icon' />
                                <h5 style={{ paddingLeft: '10px', paddingTop: '15px' }}>Add User</h5>
                            </div>
                        </div>
                        {open && <AddUser value={open} setOpenModel={setOpen} type='add' /> }
                        <Card>
                            <CardBody className='data-tables'>
                            <DataTableComponent tableData={dummytabledata} columnsData={tableColumns} title={'Users List'} setShowFilter={setShowFilter}/>
                            </CardBody>
                        </Card>
                    </Col>
                    
                </Row>
            </Container>
        </Fragment>
    );

};

export default UserManagement;