

export const registryUnitType = [
    {
        "registry": "Verra",
        "unitType": [
            "VCU"
        ]
    }
]


export const currencyValueData = {
    "United States": "$",
    "United Kingdom": "£",
    "United Arab Emirates": "د.إ",
    "India": '₹',
    "France": "€",
    "Germany": '€'
}

export const currencySymbol = {
    "USD": "$",
    "AUD": "A$",
    "INR": '₹',
    "CAD": "C$",
    "CNY": "¥",
    "JPY": "¥",
    "GBP": "£",
    "EUR": "€",
    "RUB": "₽",
    "WON": "₩",
    'CHF': "Fr",
    "NZD": "$",
    "SGD": "S$",
    "ZAR": "R",
    "AED": "AED"
}