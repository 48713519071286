import ev from '../../../assets/images/offset-project/ev.jpg'
import solar from '../../../assets/images/offset-project/solar.jpg'
import plastic from '../../../assets/images/offset-project/plastic.jpg'
import farm from '../../../assets/images/offset-project/farm.jpg'
import lighting from '../../../assets/images/offset-project/lighting.jpg'

export const descriptionData = {
    'Land-based farming': 'A land based project uses land for urban agriculture, gardening, beautification and other productive uses, whether for profit or as a community based activity.',
    'BMS/Lighting': 'Lighting or illumination is the deliberate use of light to achieve practical or aesthetic effects. Lighting includes the use of both artificial light sources like lamps and light fixtures, as well as natural illumination by capturing daylight.',
    'Plastic collection and recycling': 'Plastic recycling is the processing of plastic waste into other products. Recycling can reduce dependence on landfill, conserve resources and protect the environment from plastic pollution and greenhouse gas emissions. Recycling rates lag those of other recoverable materials, such as aluminium, glass and paper.',
    'Solar Captive': 'A solar power plant is a facility that converts solar radiation, made up of light, heat, and ultraviolet radiation, into electricity suitable to be supplied to homes and industries.',
    'EV Charging Stations': 'An EV charging station is an infrastructure which supplies the electricity for an Electric Vehicle to get charged. Charging Stations are generally placed near Public areas, Shopping Malls and places where density of vehicles is high.'
};

export const keyData = {
    'Land-based farming': 'Farm',
    'BMS/Lighting': 'Lighting',
    'Plastic collection and recycling': 'Plastic',
    'Solar Captive': 'Solar',
    'EV Charging Stations': 'EV'
}

export const priceData = {
    'Land-based farming': 7.30,
    'BMS/Lighting': 3.45,
    'Plastic collection and recycling': 1.25,
    'Solar Captive': 2.85,
    'EV Charging Stations': 4.52
}

export const imgData = {
    'Land-based farming': farm,
    'BMS/Lighting': lighting,
    'Plastic collection and recycling': plastic,
    'Solar Captive': solar,
    'EV Charging Stations': ev
}

export const unSdgs = {
    'Land-based farming': '7, 11, 14',
    'BMS/Lighting': '3, 5, 4,8',
    'Plastic collection and recycling': '5, 9, 16',
    'Solar Captive': '12, 14, 17',
    'EV Charging Stations': '9, 13, 15'
}

