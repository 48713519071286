import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from '../ButtonLoader';

const EvChargerForm = ({ title, action, navigateUrl, cancel, preData, projectId, setAdd }) => {
    const [data, setData] = useState()
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({ defaultValues: preData });
    const navigate = useNavigate();
    const [monitoringLevel, setMonitoringLevel] = useState();
    const [station, setStation] = useState(preData?.stationId);
    const [stationList, setStationList] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const loadProjectData = async () => {
        await fetchWrapper(`${API_URL}/projects/${projectId}`)
            .then((res) => {
                const data = res.json;
                setMonitoringLevel(data?.monitoringLevel);
            }, (err) => console.log(err))
    }

    const loadStationList = async () => {
        await fetchWrapper(`${API_URL}/evstation/project/${projectId}`)
        .then((res) => {
            setStationList(res.json)
        }, (err) => console.log(err))
    }

    const onSubmit = async (data) => {
        setLoading(true)
        data.projectId = projectId;
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/evchargers`
            message = 'EV charger Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/evchargers/${data.id}`
            message = 'EV charger Updated Successfully!'
        }
        const options = {
            method: method,
            body: data
        }

        await fetchWrapper(url, options)
            .then((res) => {
                if (res.ok) {
                    toast.success(message)
                    setLoading(false)
                    setAdd(true)
                    action === 'Add' && cancel()
                    action === 'Edit' && navigate(navigateUrl)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status == 409) {
                    toast.error('Location Already Exist..')
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        loadProjectData();
        loadStationList()
        setStation(preData?.stationId)
        reset(preData)
    }, [preData])

    
    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    {title && <H3 attrH3={{ className: 'mtop-2' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 bs-none'}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup>
                                            <Label>Station Name <span className="required">*</span></Label>
                                            <select className="form-select" name="stationName" value={station}
                                                {...register('stationId', { required: true })}
                                                onChange={(e)=> setStation(e.target.value)}
                                            >
                                                <option value="">-Select Station-</option>
                                                {
                                                    stationList?.length > 0 && stationList?.map((item) => {
                                                        return <option value={item?.id}>{item.name}</option>
                                                    })
                                                }
                                                
                                            </select>
                                            <span className="text-danger">{errors?.stationId && 'Station Name is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Charger ID <span className="required">*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="chargerId"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.chargerId}
                                                {...register('chargerId', {required: true})}
                                            />
                                            <span className="text-danger">{errors.chargerId?.type === 'required' && 'Charger ID is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                <Col lg="6">
                                        <FormGroup >
                                            <Label>Current Type (AC/DC) <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="currentType"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.currentType}
                                                {...register('currentType', { required: true })}
                                            />
                                            <span className="text-danger">{errors?.currentType && 'Current Type is Required'}</span>
                                        </FormGroup>
                                    </Col> 
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label >Gun Capacity (Amp) <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="gunCapacity"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.gunCapacity}
                                                {...register('gunCapacity')}
                                            />
                                            <span className="text-danger">{errors?.gunCapacity && 'Gun Capacity is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="d-flex " style={{ marginTop: "25px" }}>
                                    {
                                        loading ? <ButtonLoader color="primary" loaderText={'Submitting'} /> : <Button color="primary" type="submit">{action}</Button>
                                    }
                                    
                                    <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default EvChargerForm;

