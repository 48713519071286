import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { Button } from "reactstrap";
import { H3, H4 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useEffect } from "react";
import moment from "moment";
import { defaultStatus, defaultStatusColorCodes, statusIconList } from "../../api/helper";
import { Tooltip } from "react-tooltip";

const ProjectTransitionModal = ({ value, setOpenModal, projectId }) => {
    const [open, setOpen] = useState(value);
    const [transitionData, setTransitionData] = useState()

    const loadData = async () => {
        await fetchWrapper(`${API_URL}/projectsstatus/project/${projectId}`)
            .then(res => {
                console.log(res)
                setTransitionData(res.json)
            })
    }

    useEffect(() => {
        loadData()
    }, [])

    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>View Project Transitions</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody >
                    <div className="project-transition">
                        <Table className="table" >
                            <thead>
                                <tr className="project-transition-table">
                                    <th className="table-txt-color">Updated By</th>
                                    <th className="table-txt-color">Updated On</th>
                                    <th className="table-txt-color">Substate</th>
                                    <th className="table-txt-color">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    transitionData && transitionData?.map((item) => {
                                        return (
                                            <tr>
                                                <td>{item?.name}</td>
                                                <td>{moment(item.last_modified_ts).format('DD MMM, YYYY hh:mm A')}</td>
                                                <td className="w-15">{item?.subState}</td>
                                                <td scope="row" className="w-12">
                                                    <a data-tooltip-id="status" data-tooltip-content={defaultStatus[item.state]}>
                                                        <img src={statusIconList[item.state]} height={50} width={50} className="padd-8px"/>
                                                    </a>
                                                    <Tooltip id="status" />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button className="margin-inline-end ml-15 mb-15" color="secondary" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default ProjectTransitionModal