import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import HeaderCard from "../HeaderCard";
import Chart from 'react-apexcharts';

const DonutChartClass = () => {
    const [display, setDisplay] = useState(false)
     useEffect(() => {
        setTimeout(() => {
            setDisplay(true)
        },500);
    }, [])
    if(!display){
      return null;
    }
    const apexDonutCharts = {
        series: [21, 33, 29, 17],
        options: {
            labels: ['HVAC 345 Kg 21%', 'Electricity 653 Kg 33%', 'Travel 580 Kg 29%', 'Food 248 Kg 17%'],
            chart: {
                width: 500,
                type: 'donut',
            },
            colors: ['#F50057', '#F8BBD0', '#80DEEA', '#00796B'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 260
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                       total: {
                            show: true,
                            formatter: () => '',
                            label: '1,826 Kg'
                        }
                    }
                  }
                }
              }
        },
    };
    return (
        <Fragment>
                <Card>
                    <h4 style={{color: '#2b7704', marginTop: '20px', marginLeft: '4%'}}>CO<sub>2</sub> EMISSIONS By Category</h4>
                    <CardBody className="apex-chart" style={{height: '100%'}}>
                        <div id='donutchart'>
                            <Chart options={apexDonutCharts.options} series={apexDonutCharts.series} className='apexcharts-legend' type="donut" width={450} />
                        </div>
                    </CardBody>
                </Card>
        </Fragment>
    )
}

export default DonutChartClass;