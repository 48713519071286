import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import CommonDashboard from "../../Common/Component/Dashboard/CommonDashboard";

const UserDashboard = () => {
    return (
        <Fragment>
            <CommonDashboard />
        </Fragment>
    );
};

export default UserDashboard;