import React, { Fragment, useState } from "react";
import { CardBody, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import ButtonLoader from "../ButtonLoader";

const EditConsumptionModal = ({ data, value, setOpenModal }) => {
    const [open, setOpen] = useState(value);
    const [loading, setLoading] = useState(false);
    const [newConsumption, setNew] = useState(data?.actualConsumption);

    const projectData = useSelector(state => state?.projectDetails?.detailsData);

    const onClose = () => {
        setOpen(false);
        setOpenModal(false);
    }

    const onSubmit = async () => {
        setLoading(true)
        let bodyData = {
            actualConsumption: newConsumption,
            projectId: data.projectId,
            chargerId: data.chargerId,
            stationId: data.stationId,
            monitoringLevel: data.monitoringLevel,
        }
        const options = {
            method: 'PUT',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/evconsumption/${data.id}`, options)
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    toast.success('Consumption Updated Successfully!')
                    onClose()
                } else {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Edit Consumption</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Station Name</label>
                                    <input className={`form-control`} type="text"
                                        name='unitType' value={data?.stationName} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                        {
                            projectData?.monitoringLevel === 'CHARGER' && <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Charger ID</label>
                                        <input className={`form-control`} type="text"
                                            name='unitType' value={data?.clientChargerId} disabled />
                                    </FormGroup>
                                </div>
                            </Col>
                        }
                        {
                            projectData?.monitoringLevel === 'STATION' && <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Charging Unit Capacity</label>
                                        <input className={`form-control`} type="text"
                                            name='unitType' value={data?.chargingUnitCapacity} disabled />
                                    </FormGroup>
                                </div>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Reporting Frequency</label>
                                    <input className={`form-control`} type="text"
                                        name='unitType' value={data?.reportingPeriodFrequency} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Reporting Period</label>
                                    <input className={`form-control`} type="text"
                                        name='unitType' value={data?.reportingPeriod} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Actual Consumption</label>
                                    <input className={`form-control`} type="number"
                                        name='unitType' value={newConsumption}
                                        onChange={(e) => setNew(e.target.value)} />
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex">
                        {
                            loading ? <ButtonLoader loaderText={'Updating'} color={'primary'} /> : <Button color="primary" onClick={onSubmit} >Update</Button>
                        }

                        <Button color="secondary" className="ml-1" onClick={onClose}>Cancel</Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default EditConsumptionModal;