import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AgGridTable from '../AgGridTable';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { defaultUserType } from '../../api/helper';

const OwnershipHistory = () => {
    const orgId = useParams().id;
    const orgName = useParams()?.name;
    const [data, setData] = useState();
    const historyRef = useRef(null);
    const navigate = useNavigate();

    const userType = useSelector(state => state.user.userType);

    const loadHistory = async () => {
        await fetchWrapper(`${API_URL}/ownershiphistory/org/${orgId}`)
            .then((res) => {
                setData(res.json)
            }, (err) => console.log(err))
    }

    const columnData = [
        {
            headerName: 'Control Type',
            field: 'controlType',
            filter: true,
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Control Percentage',
            field: 'controlTypePercentage',
            filter: true,
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Effective Date',
            field: 'effectiveDate',
            filter: true,
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            valueGetter: (params) => `${params.data.effectiveDate ? moment(params.data.effectiveDate).format('DD MMM, YYYY') : '-'}`,
            suppressMovable: true
        },
    ]

    const onGridReady = useCallback(async (params) => {
        historyRef.current.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/ownershiphistory/org/${orgId}`)
            .then((res) => {
                setData(res.json)
                historyRef.current.api.hideOverlay();
            }, (err) => console.log(err))
    })

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/sub-organization-list`)
    }
    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs mainTitle={`${orgName} - Ownership History`} />
            <Row>
                <Col lg={8}>
                    <Card className=''>
                        <CardBody className='ownershipHistory'>
                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                <AgGridTable
                                    columnData={columnData}
                                    tableData={data}
                                    agRef={historyRef}
                                    onGridReady={onGridReady}
                                />
                            </div>
                            <div >
                                <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default OwnershipHistory;