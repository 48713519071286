import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button, FormGroup, Input, Row, Col } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";

const GovernanceOversight = ({ activeTab, setActive }) => {
    const [overSightData, setOversight] = useState();
    const [directorReport, setDirectReport] = useState();
    const [sustainabilityIssues, setSustainabilityIssues] = useState();

    const getGovernanceData = async() => {
        await fetchWrapper(`${API_URL}/emissionsReport/disclosureQuestionsData`)
        .then((res) => {
            console.log(res.json)
            setDirectReport(res.json?.find(ele => ele?.questionText === 'q1')?.entityDetails);
            setOversight(res.json?.find(ele => ele?.questionText === 'q2')?.entityDetails);
            setSustainabilityIssues(res.json?.find(ele => ele?.questionText === 'q3')?.entityDetails);
        }, (err) => {
            console.log(err)
        })
    }

    useEffect(()=>{
        getGovernanceData()
    }, [])

    const onSubmit = async() => {
        const data = [
            {
                serialNo: 'GO-1',
                questionText: 'q1',
                entityDetails: directorReport
            },
            {
                serialNo: 'GO-2',
                questionText: 'q2',
                entityDetails: overSightData
            },
            {
                serialNo: 'GO-3',
                questionText: 'q3',
                entityDetails: sustainabilityIssues
            }
        ]
        const options = {
            method: 'POST',
            body: {
                disclosureQuestionsData: data
            }
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
        .then((res) =>{

        }, (err) => {
            console.log(err)
            if(err.status === 200) {
                setActive(5)
            } else if(err.status === 500) {
                toast.error('Something went wrong!')
            }
        })
    }
    return (
        <Fragment>
            <h3>Governance Oversight</h3>
            <Row>
                <Col className="mtop-1" lg='12'>
                    <FormGroup>
                        <label>Statement by director responsible for the business responsibility report, highlighting ESG related challenges, 
                            targets and achievements (listed entity has flexibility regarding the placement of this disclosure) </label>
                        <textarea className="form-control mtop-1" value={directorReport} rows={5} 
                        onChange={(e) => setDirectReport(e.target.value)}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
            <Col className="mtop-1" lg='12'>
                    <FormGroup>
                        <label>Details of the highest authority responsible for implementation and oversight 
                            of the Business Responsibility policy (ies)</label>
                        <textarea className="form-control mtop-1" rows={5} value={overSightData} 
                        onChange={(e) => setOversight(e.target.value)}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="mtop-1" lg='12'>
                    <FormGroup>
                        <label>Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability 
                            related issues? (Yes / No). If yes, provide details.</label>
                        <textarea className="form-control mtop-1" rows={5} value={sustainabilityIssues} 
                        onChange={(e) => setSustainabilityIssues(e.target.value)}/>
                    </FormGroup>
                </Col>
            </Row>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(4)}>Back</Button>
                <Button color="primary" className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default GovernanceOversight;