import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import LocationForm from "../Forms/LocationForm";
import { defaultUserType } from "../../api/helper";
import MapComponent from "../MarkerMap/MapComponent";

const MapLocationModal = ({value, setOpenModal, data, locationData}) => {
    const [open, setOpen] = useState(value);
    

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    const mapStyles = {
        width: '100%',
        height: '400px'
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Map Location
                        <X className="x-button" onClick={onClose} /></H3>
                </ModalHeader>
                <ModalBody>
                    <div className="mb-1">{`${locationData?.addressLine1}, ${locationData?.addressLine2}${locationData?.addressLine2 && ','} ${locationData?.city}, ${locationData?.stateCode ? locationData?.stateCode : locationData?.state} - ${locationData?.zipCode}`}</div>
                    <MapComponent position={data}  styles={mapStyles} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default MapLocationModal;