import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";

const VerticalProgressTracker = ({ registryId, projectId }) => {
    const [stepList, setStepList] = useState([]);
    const [activeStatusIndex, setIndex] = useState();
    const [activeStatus, setActice] = useState();

    const filterSteps = (steps, currentStatus) => {
        let modifiedFromValidation = [], modifiedFromVerify = [];
        let validationSubStatus = ['Validation - Accepted', 'Validation - Need information', 'Validation - Correction Implemention', 'Validation - Resubmission', 'Validation - Rejected'];
        let verificationSubStatus = ['Verification - Accepted', 'Verification - Need information', 'Verification - Correction Implemention', 'Verification - Resubmission', 'Verification - Rejected'];
        if(currentStatus) {
            if ((steps?.indexOf(currentStatus) < steps?.indexOf('Validation')) || (steps?.indexOf(currentStatus) === steps?.indexOf('Validation'))) {
                modifiedFromValidation = steps.filter(step => !validationSubStatus.includes(step));
            } else if (steps?.indexOf(currentStatus) > steps?.indexOf('Validation')) {
                if (validationSubStatus?.includes(currentStatus)) {
                    if (currentStatus === ("Validation - Accepted")) {
                        modifiedFromValidation = steps?.filter(step => !validationSubStatus?.includes(step) || step === 'Validation - Accepted')
                    } else if (currentStatus === "Validation - Need information") {
                        let tempStatus = ['Validation - Accepted', 'Validation - Need information', 'Validation - Correction Implemention', 'Validation - Resubmission', 'Validation - Rejected'];
                        tempStatus.splice(0, 1); tempStatus.splice(tempStatus.length - 1, 1, validationSubStatus[0]);
                        modifiedFromValidation = [...steps?.slice(0, steps?.indexOf('Validation - Accepted')), 
                            ...tempStatus, ...steps?.slice(steps?.indexOf('Validation - Rejected') + 1)
                        ]
                    } else if (currentStatus === 'Validation - Rejected') {
                        modifiedFromValidation = steps?.filter((item, index) => (index <= steps?.indexOf('Validation - Rejected')) && (!validationSubStatus?.includes(item) || item === 'Validation - Rejected'))
                            .map((ele) => ele);
                    }
                } else {
                    modifiedFromValidation = steps?.filter(step => !validationSubStatus?.includes(step) || step === 'Validation - Accepted')
                }
            } 
        } else {
            modifiedFromValidation = steps.filter(step => !validationSubStatus.includes(step));
        }
        console.log(modifiedFromValidation)
        if(currentStatus) {
            if ((modifiedFromValidation?.indexOf(currentStatus) < modifiedFromValidation?.indexOf('Verification')) || 
            (modifiedFromValidation?.indexOf(currentStatus) === modifiedFromValidation?.indexOf('Verification'))) {
                modifiedFromVerify = modifiedFromValidation.filter(step => !verificationSubStatus.includes(step));
            } else if (modifiedFromValidation?.indexOf(currentStatus) > modifiedFromValidation?.indexOf('Verification')) {
                if (verificationSubStatus?.includes(currentStatus)) {
                    if (currentStatus === ("Verification - Accepted")) {
                        modifiedFromVerify = modifiedFromValidation?.filter(step => !verificationSubStatus?.includes(step) || step === 'Verification - Accepted')
                    } else if (currentStatus === "Verification - Need information") {
                        let tempStatus = ['Verification - Accepted', 'Verification - Need information', 'Verification - Correction Implemention',
                            'Verification - Resubmission', 'Verification - Rejected']
                        tempStatus.splice(0, 1);
                        tempStatus.splice(tempStatus.length - 1, 1, verificationSubStatus[0]);
                        modifiedFromVerify = [...modifiedFromValidation?.slice(0, modifiedFromValidation?.indexOf('Verification - Accepted')), 
                            ...tempStatus, ...modifiedFromValidation?.slice(modifiedFromValidation?.indexOf('Verification - Rejected') + 1)
                        ]
                    } else if (currentStatus === 'Verification - Rejected') {
                        modifiedFromVerify = modifiedFromValidation?.filter((item, index) => (index <= modifiedFromValidation?.indexOf('Verification - Rejected'))
                            && (!verificationSubStatus?.includes(item) || item === 'Verification - Rejected'))
                            .map((ele) => ele);
                    }
                } else {
                    modifiedFromVerify = modifiedFromValidation?.filter(step => !verificationSubStatus?.includes(step) || step === 'Verification - Accepted')
                }
            }
        } else {
            modifiedFromVerify = modifiedFromValidation.filter(step => !verificationSubStatus.includes(step));
        }
        console.log(modifiedFromVerify)
        setStepList(modifiedFromVerify);
    };

    const getLifecycleStatus = async () => {
        await fetchWrapper(`${API_URL}/projectregistrylifecycle/project?projectId=${projectId}`)
            .then((res) => {
                const statusIndex = stepList?.indexOf(res.json?.lifecycleStatus);
                setActice(res.json?.lifecycleStatus)
                setIndex(statusIndex + 1);
            }, (err) => {
                console.log(err)
                setActice(null)
            })
    }

    const getStatusList = async () => {
        await fetchWrapper(`${API_URL}/registrylifecyclestatuses/registry/${registryId}`)
            .then((res) => {
                setStepList(res.json?.map(ele => ele?.lifecycleStatus))
                // filterSteps(res.json?.map(ele => ele?.lifecycleStatus), activeStatus)
            }, (err) => console.log(err))
    }


    useEffect(() => {
        getStatusList()
        
    }, [])

    useEffect(() => {
        getLifecycleStatus()
    }, [stepList]);

    useEffect(()=> {
        filterSteps(stepList, activeStatus)
    }, [activeStatus])

    return (
        <Fragment>
            <div className="vertical-timeline mtop-1">
                <div className="v-timeline">
                    {
                        stepList?.map((item, index) => {
                            return (
                                <li className={`v-timeline-tracker `}>
                                    <div className={`v-timeline-content ${(index + 1) <= activeStatusIndex && 'active'}`}>
                                        <div className={`v-timeline-title ${(index + 1) === activeStatusIndex && 'v-timeline-title-active'}`}>{item}</div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default VerticalProgressTracker;