import React, { useEffect, useState } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import AgGridTable from "../AgGridTable";
import { Card, CardBody } from "reactstrap";
import { thousandSeparator } from "../../api/helper";

const MobileSources = () => {
    const [data, setData] = useState([]);

    const getMobileFactors = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/MobileSources`)
            .then((res) => {
                console.log(res.json)
                setData(res.json)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getMobileFactors()
    }, [])

    const columns = [
        {
            headerName: 'Vehicle Type',
            field: 'vehicleType',
            editable: true,
            filter: false,
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            sortable: false,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Fuel Type',
            field: 'fuelType',
            editable: true,
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Type',
            field: 'type',
            editable: true,
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        {
            headerName: 'Unit',
            field: 'units',
            editable: true,
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Country',
            field: 'countryCode',
            editable: true,
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'CH\u2084 Factor',
            field: 'ch4FactorUnit',
            editable: true,
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false,
            cellRenderer: (params) => {
                return <div className="text-right w-70px">{params?.data?.ch4FactorUnit ?
                    thousandSeparator(params?.data?.ch4FactorUnit.toFixed(2)) : ''}</div>;
            },
        },
        {
            headerName: 'N\u2082O Factor',
            field: 'n2oFactorUnit',
            editable: true,
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false,
            cellRenderer: (params) => {
                return <div className="text-right w-70px">{params?.data?.n2oFactorUnit ?
                    thousandSeparator(params?.data?.n2oFactorUnit.toFixed(2)) : ''}</div>;
            },
        },
        {
            headerName: 'Distance Unit',
            field: 'distanceUnit',
            editable: true,
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false,
            cellClass: 'cell-wrap'
        },
    ]

    return (
        <div>
            <Card>
                <CardBody className="pad-10">
                    <div className="factor-table">
                        <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                            <AgGridTable
                                columnData={columns}
                                tableData={data}
                                paginationSize={10}
                            // agRef={groupsRef}
                            // onGridReady={onGridReady}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default MobileSources;