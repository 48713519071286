import React, { Fragment, useState } from "react";
import { Table } from "react-bootstrap";


const TableView = ({ columns, data, title, spanTable }) => {
    let keys;
    if (!spanTable) {
        keys = columns?.map(ele => ele?.fieldName);
    } else {
        keys = columns.flatMap(row => 
            row.filter(col => col.fieldName).map(col => col.fieldName)
        );
    }
    if(!spanTable) {
        return (
            <div className="ghg-data-preview">
                <h3>{title}</h3>
                <div className="mtop-1">
                    <Table responsive>
                        <thead>
                            <tr>
                                {
                                    columns?.map((item) => (
                                        <th>{item?.name}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((item, index) => (
                                    <tr>
                                        {
                                            keys?.map((key) => (
                                                <td>{item[key]}</td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    } else {
        return (
            <div className="ghg-data-preview">
                <h3>{title}</h3>
                <div className="mtop-1">
                    <Table responsive>
                        <thead>
                            <tr>
                                {
                                    columns[0]?.map((item) => (
                                        <th rowSpan={item?.rowspan} colSpan={item?.colspan}>{item?.name}</th>
                                    ))
                                }
                            </tr>
                            <tr>
                                {
                                    columns[1]?.map((item) => (
                                        <th>{item?.name}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((item, index) => (
                                    <tr>
                                        {
                                            keys?.map((key) => {
                                                return(
                                                <td>{item[key]}</td>
                                            )})
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
    
}

export default TableView;