import React, { useState, Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Link, } from 'react-router-dom';
import { Form, FormGroup, Row, Col, Table, CardBody, Card, Spinner } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Trash2 } from 'react-feather';
import moment from 'moment';
import DataTableComponent from '../DataTable/DataTableComponent';
import { toast } from 'react-toastify';
import ButtonLoader from '../ButtonLoader';


const Notes = ({ setSteps, projectId }) => {
    const [noteText, setNoteText] = useState('')
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [notesLoading, setNotesLoading] = useState(false);

    const onSubmit = async () => {
        setLoading(true)
        let data = {
            "description": noteText,
            "projectId": projectId
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/note`, options)
            .then((res) => {
                console.log(res)
                if (res.ok) {
                    setLoading(false)
                    setNoteText('')
                    toast.success('Notes Added Successfully!')
                    loadData(projectId)
                }
            })
            .catch((err) => {
                setLoading(false)
                toast.error('Something went wrong')
                console.error(err)
            })
    }

    const loadData = async (id) => {
        setNotesLoading(true)
        const option = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/note/project/${id}`)
            .then((res) => {
                console.log(res)
                setData(res.json)
                setNotesLoading(false)

            })
            .catch(err => {
                setNotesLoading(false)
                console.log(err)
            })
    }


    useEffect(() => {
        loadData(projectId)
    }, [projectId])

    const defaultUserTypes = {
        ASO_USER: 'Asset Owner',
        ASO_ADMIN: 'Asset Owner',
        ASR_USER: 'Assessor',
        ASR_ADMIN: 'Assessor',
        SYS_ADMIN: 'Platform Admin',
        SYS_USER: 'Platform User'
    }


    const onClose = () => {
        const userType = window.location.pathname.split('/')[1]
        navigate(`${process.env.PUBLIC_URL}/${userType}/projects`)
        sessionStorage.removeItem('projectId')
    }


    return (
        <Fragment>
            <h2 className='pb-3'>Notes</h2>
            <Row>
                <Col lg={8}>
                    <FormGroup className='mb-3'>
                        <textarea rows={4} cols={25} className="form-control" name='notes' placeholder='Notes..' value={noteText}
                            onChange={(e) => setNoteText(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col lg='4'>
                    <div className="btn-mb d-flex mtop-2">
                        {
                            loading ? <ButtonLoader loaderText={"Submitting"} color={"primary"} className={'btn-font'} /> :
                                <Btn attrBtn={{ color: 'primary', className: 'btn-font', onClick: onSubmit }}>{'Submit'}</Btn>
                        }
                        <Btn attrBtn={{ color: 'secondary', className: 'btn-font ml-1', onClick: onClose }}>{'Close'}</Btn>
                        <Btn attrBtn={{ color: 'light', className: 'btn-font ml-1', onClick: () => setSteps(pre => pre - 1) }}>{'Back'}</Btn>
                    </div>
                </Col>
            </Row>
            <Card className='notes-card'>
                <CardBody>
                    <Row>
                        <Col lg={12}>
                            <Table striped bordered >
                                <thead className='f-16'>
                                    <tr className='table-header-bg text-left'>
                                        <th>No</th>
                                        <th>Notes</th>
                                        <th>By</th>
                                        <th>On</th>
                                        <th>Email ID</th>
                                        <th>User Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        notesLoading ? <Spinner color='#2B7704' type='grow' className='mtop-2' /> :
                                            data && data.length > 0 && data.map((item, key) => {
                                                return (
                                                    <tr className='table-body text-left'>
                                                        <td>{key + 1}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.name}</td>
                                                        <td>{moment(item.createdOn).format('DD MMM, YYYY hh:mm A')}</td>
                                                        <td>{item.email}</td>
                                                        <td>{defaultUserTypes[item.personaType]}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </Table>
                            {
                                data && data?.length === 0 && <div className='d-flex-center mtop-2'>No Notes Found..</div>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </Fragment>
    )
}

export default Notes;