import React, { Fragment, useState } from 'react';
import { Breadcrumbs, H1, H5, LI, UL } from '../../AbstractElements';
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import AddPaymentModal from './AddPaymentModal';

const PaymentMethod = () => {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    return (
        <Fragment>
            <Container fluid={true}>
                <Breadcrumbs parent="Billing" title="Payment Method" mainTitle="Payment Method" />
                <Row>
                    <Col lg='4'>
                        <Card className='card-style'>
                            <div className="profile-nav onhover-dropdown">
                                <i className="fa fa-ellipsis-v ellips"></i>
                                <UL attrUL={{ className: "profile-dropdown onhover-show-div card-menu" }}>
                                    <LI>Edit</LI>
                                    <LI>Delete</LI>
                                    <LI>View Transaction History</LI>
                                </UL>
                            </div>
                            <CardBody>
                                <H1>Paypal</H1>
                                <div className='mtop-2 fo-7'>
                                    <H5 className='fo-7'>499</H5>
                                </div>
                                <div className='mtop-2'>
                                <Button outline color='primary'>Backup Payment</Button>
                                </div>
                                
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg='4'>
                        <Card className='card-style card-bg' onClick={handleOpen}>
                            <div className='add-card'>
                                <H5 attrH5={{className: 'hover-pointer'}}>Add Payment Method</H5>
                            </div>
                        </Card>
                    {open && <AddPaymentModal value={open} setOpenModal={setOpen} />}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default PaymentMethod;
