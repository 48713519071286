import React, { Fragment, useState, useEffect } from "react";
import ViewPage from "../../Common/Component/ViewProject";

const ViewAsmUserProject = () => {
    return (
        <Fragment>
            <ViewPage type='asmUser' />
        </Fragment>
    )
}

export default ViewAsmUserProject;