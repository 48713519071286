import React, { Fragment, useState } from "react";
import { CardBody, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { currencySymbol } from "../../api/currencyData";
import { currencyFormatter } from "../../api/helper";
import ButtonLoader from '../ButtonLoader';


const PlaceOrderModal = ({ data, value, setOpenModal, asoOrgid, setAdd }) => {
    const [open, setOpen] = useState(value);
    const [amount, setAmount] = useState(0);
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(false)
    const [orderError, setOrderError] = useState(false);
    

    const handleClose = () => { 
        setOpen(false);
        setOpenModal(false);
    }
   
    const handleBalance = (value) => {
        setAmount(value);
        setOrderError(false)
        let creditBalance
        creditBalance = data?.orgCurrency === "USD" ? (data?.certifiedCurrencyBalance - Number(value)) * data?.midRate * data?.splitValue
            : (data?.certifiedCurrencyBalance - Number(value)) * data?.midRate * data?.splitValue * data?.exchangeRate
        setBalance(creditBalance)
    }

    const handleSubmit = async () => {
        if(!amount) {
            setOrderError(true)
        } else {
            setLoading(true)
        const option = {
            method: 'POST',
            body: {
                projectId: data?.projectId,
                quantity: amount,
                currency: data?.certifiedCurrency,
                orderType: "SELL",
                assetOwnerId: asoOrgid
            }
        }
        await fetchWrapper(`${API_URL}/orders`, option)
            .then((res) => {
                if (res.status === 200) {
                    setAdd(true)
                    setLoading(false)
                    toast.success('Order Placed Successfully!')
                    handleClose();
                }
            }, (err) => {
                console.log(err)
                setLoading(false)
                handleClose()
            })
        }
        
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Place Order</H3>
                    <X className="x-button" onClick={handleClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Unit Type</label>
                                    <input className={`form-control`} type="text"
                                        name='unitType' value={data?.unitType} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Unit Market Rate (USD)</label>
                                    <input className={`form-control`} type="text"
                                        name='unitMarketRate' value={`$ ${data?.midRate} per Unit`} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Available Carbon Credits</label>
                                    <input className={`form-control`} type="text"
                                        name='certifiedCredits' value={data?.certifiedCurrencyBalance} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                        {
                            data?.orgCurrency != "USD" && <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Exchange Rate(USD - {data?.orgCurrency})</label>
                                        <input className={`form-control`} type="text"
                                            name='exchangeRate' value={data?.exchangeRate} disabled />
                                    </FormGroup>
                                </div>
                            </Col>
                        }

                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Order Quantity <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} type="text" name='amount' max={data?.certifiedCurrencyBalance}
                                        onChange={(e) => handleBalance(e.target.value)} />
                                        {orderError === true && <span className="text-danger">Order Amount is required</span>}
                                </FormGroup>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Indicative Cash Balance</label>
                                    <input className={`form-control mb-1`} type="text" name='balance'
                                        value={currencyFormatter(balance, data?.orgCurrency)} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {
                                amount > data?.certifiedCredits && <span className="text-danger">Amount limit exceed</span>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {
                                loading ? <ButtonLoader color="primary" loaderText={'Submitting'} /> :
                                    <Button onClick={handleSubmit} color="primary">Submit</Button>
                            }
                            <Button onClick={handleClose} color="secondary"  className="ml-1">Cancel</Button>
                        </Col>
                    </Row>
                </ModalBody>


            </Modal>
        </Fragment>
    )
}

export default PlaceOrderModal;