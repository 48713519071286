import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Table, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { keyData, priceData } from './DummyData';
import { currencyFormatter, defaultUserType } from '../../api/helper';
import DatePicker from 'react-datepicker';

const OrderReview = () => {
    const [amount, setAmount] = useState(0);
    const [vintageYear, setVintage] = useState();
    const projectId = useParams()?.id;
    const projectData = useSelector(state => state?.projectDetails?.detailsData);
    const navigate = useNavigate();
    const userType = useSelector(state => state?.user?.userType);

    return (
        <Fragment>
            <Breadcrumbs mainTitle={'Order Review'} />
            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            <Table bordered>
                                <thead>
                                    <tr className="notes-table-header">
                                        <th>Project Name</th>
                                        <th>Technology</th>
                                        <th>Year</th>
                                        <th>Amount</th>
                                        <th>Price/t</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='ptop-1'>{projectData?.name}</td>
                                        <td className='ptop-1'>{keyData[projectData?.subCategoryItemName]}</td>
                                        <td className='w-15 mr-1'>
                                            <div className='d-flex'>
                                                <DatePicker
                                                    className="datepicker-here form-control mr-1"
                                                    selected={vintageYear}
                                                    onChange={(e) => setVintage(e)}
                                                    showYearPicker
                                                    dateFormat="yyyy"

                                                />
                                            </div>
                                            <div>
                                                <span className='review-span'>994567 tons available</span>
                                            </div>
                                        </td>
                                        
                                        <td>
                                            <input className='form-control' type='number' placeholder='0' min={0} value={amount} onChange={(e) => setAmount(e.target.value)} />
                                        </td>
                                        <td className='ptop-1'>{currencyFormatter(priceData[projectData?.subCategoryItemName], 'USD')}</td>
                                        <td className='ptop-1'>{currencyFormatter(amount * Number(priceData[projectData?.subCategoryItemName]), 'USD')}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div>
                <Button color='secondary' onClick={()=>navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/buy-offsets`)}>Browse Projects</Button>
                <Button color='primary' className='manage-asm-but'>Proceed Payment</Button>
            </div>
        </Fragment>
    )
}

export default OrderReview;