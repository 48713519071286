import React, { useState } from "react";
import { Fragment } from "react";
import { H4, H3 } from '../../../AbstractElements'
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import { baselineEmission, projectEmission } from "./CalculationHelper";
import EmissionModal from "./EmissionModal";

const EvChargeCalculation = () => {
    const [cardShow, setCardShow] = useState(false);
    const [cardData, setCardData] = useState();

    const emissionType = {
        baseline: baselineEmission, 
        project: projectEmission
    }

    const emissionClick = (type) => {
        setCardData(emissionType[type])
        setCardShow(true)
    }

    return (
        <Fragment>
            <Row>
                <Col lg='8'>
                    <Card>
                        <CardBody>
                            <div>
                                <H3 attrH3={{ className: 'f-w-600' }}>ERy (Carbon Credits) = (BEy - PEy - LEy) * Dy</H3>
                            </div>
                            <div className="mtop-1">
                                <H4 attrH4={{ className: 'f-w-600' }}>Description</H4>
                                <ul>
                                    <li>
                                        <p>ERy = Net GHG emissions reductions and removals in year(y) (tCO2e)</p>
                                    </li>
                                    <li>
                                        <p>BEy = Baseline emissions in year(y) (tCO2e)</p>
                                    </li>
                                    <li>
                                        <p>PEy = Project emissions in year(y) (tCO2e)</p>
                                    </li>
                                    <li>
                                        <p>LEy = Leakage in year(y) (tCO2e)</p>
                                    </li>
                                    <li>
                                        <p>Dy = Discount factor to be applied in year(y) (%)</p>
                                    </li>
                                </ul>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <Card>
                        <CardBody>
                            <p>Example</p>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Emission Factor</th>
                                        <th >
                                            <div className="d-flex cur-pointer" onClick={(e) => emissionClick('baseline')}>
                                                Baseline Emission <div className="ml-1" >
                                                    <i class="fa-regular fa-circle-question" ></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex cur-pointer" onClick={(e) => emissionClick('project')}>
                                                Project Emission <div className="ml-1" >
                                                    <i class="fa-regular fa-circle-question" ></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th>Carbon Credits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2024</td>
                                        <td className="text-right">0.94</td>
                                        <td className="text-right">44.42</td>
                                        <td className="text-right">38.14</td>
                                        <td className="text-right">6.28</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td className="text-right">0.94</td>
                                        <td className="text-right">44.42</td>
                                        <td className="text-right">38.14</td>
                                        <td className="text-right">6.28</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                cardShow && <EmissionModal value={cardShow} setOpenModal={setCardShow} data={cardData} />
            }
        </Fragment>
    )
}

export default EvChargeCalculation;

