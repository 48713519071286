import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { defaultUserType, defaultMonitoringColumnName } from '../../api/helper';
import { useSelector } from 'react-redux';
import EditConsumptionModal from '../EditConsumption/EditConsumptionModal';
import AgGridTable from '../AgGridTable';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import EditMonitoringModal from '../EditMonitoringModal/EditCollectionModal';


const MonitoringHistory = () => {
    const [collectionData, setCollectionData] = useState([]);
    const projectId = useParams()?.id;
    const projectName = useParams()?.name;
    const type = useParams()?.type;
    const [showFilter, setShowFilter] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const historyRef = useRef(null);
    const navigate = useNavigate();
    const [year, setYear] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [update, setUpdate] = useState(false)

    const userType = useSelector(state => state?.user?.userType)

    const dataCollectionFrequency = useSelector(state => state?.projectDetails?.detailsData?.dataCollectionFrequency);


    const loadCollection = async (month, year) => {
        console.log(type, dataCollectionFrequency)
        let targetUrl = dataCollectionFrequency === 'MONTHLY' ?
            `projectmonitoring/project/${projectId}/year/${moment(year).format('YYYY')}` :
            (type === 'plastic' && dataCollectionFrequency === 'DAILY') ?  
            `plasticcreditsmonitoring/project/${projectId}/${dataCollectionFrequency}/${parseInt(moment(year).format('YYYY'))}/${moment(month).format('MMMM')}`
            : `projectmonitoring/project/${projectId}/period/${moment(month).format('MMM')}/year/${moment(year).format('YYYY')}`;
        await fetchWrapper(`${API_URL}/${targetUrl}`)
            .then((res) => {
                setCollectionData(res.json)
            }, (err) => console.log(err))
    }

    const handleEdit = (item) => {
        if (!item?.creditUnits) {
            setOpen(true)
            setData(item)
        }
    }

    const wasteCollectionColumns = [
        {
            headerName: 'Period',
            field: 'period',
            filter: true,
            suppressMovable: true,
            flex: 1
        },
        {
            headerName: defaultMonitoringColumnName[type],
            field: 'quantity',
            filter: true,
            suppressMovable: true,
            flex: 1,
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.quantity ?
                    params?.data?.quantity.toFixed(2) : ''}</div>;
            },
        },
        {
            headerName: 'Credits',
            field: 'creditUnits',
            filter: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.creditUnits ?
                    params?.data?.creditUnits.toFixed(2) : ''}</div>;
            },
            flex: 1
        },
        {
            headerName: 'Edit',
            maxWidth: 100,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleEdit(params.data)} >
                        <Edit className={`edit-icon ${params.data.credits ? 'disable-icon' : ''}`} />
                    </div>
                )
            },
            suppressMovable: true
        }
    ];

    const plasticCollectionMonitoring = [
        {
            headerName: 'Period',
            field: 'date',
            filter: true,
            suppressMovable: true,
            flex: 1
        },
        {
            headerName: 'Rigid',
            field: 'rigidQuantity',
            filter: true,
            suppressMovable: true,
            flex: 1
        },
        {
            headerName: 'Composite',
            field: 'compositeQuantity',
            filter: true,
            suppressMovable: true,
            flex: 1
        },
        {
            headerName: 'Flexible',
            field: 'flexibleQuantity',
            filter: true,
            suppressMovable: true,
            flex: 1
        },
        {
            headerName: 'Total',
            field: 'totalQuantity',
            filter: true,
            suppressMovable: true,
            flex: 1
        },
        {
            headerName: 'Credits',
            field: 'creditUnits',
            filter: true,
            suppressMovable: true,
            flex: 1,
        },
        {
            headerName: 'Edit',
            maxWidth: 50,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleEdit(params.data)} >
                        <Edit className={`edit-icon ${params.data.credits ? 'disable-icon' : ''}`} />
                    </div>
                )
            },
            suppressMovable: true
        }
    ]

    const handleBack = () => {
        console.log(type)
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/monitoring/${projectId}/${projectName}/${type}/${dataCollectionFrequency}`)
    }

    useEffect(() => {
        if (update) {
            loadCollection(new Date(), new Date())
        }
    }, [update])

    const handleMonth = (e) => {
        setMonth(e)
        loadCollection(e, year)
    }

    const handleYear = (e) => {
        setYear(e)
        loadCollection(month, e)
    }

    const onGridReady = useCallback(async (params) => {
        historyRef.current.api.showLoadingOverlay();
        let targetUrl = dataCollectionFrequency === 'MONTHLY' ?
            `projectmonitoring/project/${projectId}/year/${moment(year).format('YYYY')}` :
            (type === 'plastic' && dataCollectionFrequency === 'DAILY') ?  
            `plasticcreditsmonitoring/project/${projectId}/${dataCollectionFrequency}/${parseInt(moment(year).format('YYYY'))}/${moment(month).format('MMMM')}`
            : `projectmonitoring/project/${projectId}/period/${moment(month).format('MMM')}/year/${moment(year).format('YYYY')}`;
        await fetchWrapper(`${API_URL}/${targetUrl}`)
            .then((res) => {
                historyRef.current.api.hideOverlay();
                setCollectionData(res.json)
            }, (err) => console.log(err))
    })

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs title="List" mainTitle={`${projectName} - Monitoring History`} />
            <Row>
                <Col lg='10'>
                    <Card className=' newtable'>
                        <CardBody className='data-tables responsive-view waste-collection-history' >
                            <Row>
                                <Col sm='3'>
                                    <FormGroup>
                                        <label>Select Year</label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={year}
                                            onChange={(e) => handleYear(e)}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            maxDate={new Date()}
                                        />
                                    </FormGroup>
                                </Col>
                                {
                                    dataCollectionFrequency === 'DAILY' && <Col sm='3'>
                                        <FormGroup>
                                            <label>Select Month</label>
                                            <DatePicker
                                                className="datepicker-here form-control"
                                                selected={month ? month : new Date()}
                                                onChange={(e) => handleMonth(e)}
                                                showMonthYearPicker
                                                dateFormat="MMM"
                                                placeholderText="-Month-"
                                                maxDate={new Date()}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                <AgGridTable
                                    columnData={(type === 'plastic' && dataCollectionFrequency === 'DAILY') ? plasticCollectionMonitoring :  wasteCollectionColumns}
                                    tableData={collectionData}
                                    agRef={historyRef}
                                    onGridReady={onGridReady}
                                />
                            </div>
                            <div>
                                <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                open && <EditMonitoringModal value={open} setOpenModal={setOpen} data={data} setUpdate={setUpdate} />
            }
        </Fragment>
    )
}

export default MonitoringHistory;