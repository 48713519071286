import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { defaultUserType } from '../../api/helper';
import { useSelector } from 'react-redux';
import EditConsumptionModal from '../EditConsumption/EditConsumptionModal';
import AgGridTable from '../AgGridTable';
import moment from 'moment';

const ConsumptionHistory = () => {
    const [consumptionData, setConsumptionData] = useState([]);
    const projectId = useParams()?.id;
    const projectName = useParams()?.name;
    const [showFilter, setShowFilter] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const historyRef = useRef(null);
    const navigate = useNavigate();

    const userType = useSelector(state => state?.user?.userType)

    const projectData = useSelector(state => state?.projectDetails?.detailsData);

    const loadConsumption = async () => {
        let url = projectData?.monitoringLevel === 'CHARGER' ? 'evconsumption/history/CHARGER/project' : 'evconsumption/history/STATION/project';
        await fetchWrapper(`${API_URL}/${url}/${projectId}`)
            .then((res) => {
                setConsumptionData(res.json)
            }, (err) => console.log(err))
    }

    const handleEdit = (item) => {
        if (!item?.creditUnits) {
            setOpen(true)
            setData(item)
        }
    }

    const chargerColumns = [
        {
            headerName: 'Station Name',
            field: 'stationName',
            flex: 3,
            filter: true, 
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Charger ID',
            field: 'clientChargerId',
            filter: true, 
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Reporting Frequency',
            field: 'reportingPeriodFrequency',
            filter: true, 
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Reporting Period Value',
            filter: 'agDateColumnFilter',
            valueGetter: (params) => {
                return `${moment.months(params.data.reportingPeriodFrequencyValue - 1)}, ${params.data.reportingPeriod}` 
            }, 
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Consumption (KW)',
            field: 'actualConsumption',
            filter: true, 
            flex: 1.25,
            filterParams: {
                filterOptions: ['equals', 'greaterThan', 'lessThan'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true,
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Credits',
            field: 'creditUnits',
            filter: true,
            maxWidth: 130,
            valueFormatter: params => {
                return Number(params.data.creditUnits).toFixed(2)
            },
            suppressMovable: true,
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Edit',
            maxWidth: 50,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleEdit(params.data)}>
                        <Edit className={`edit-icon ${params.data.creditUnits ? 'disable-icon' : ''}`} />
                    </div>
                )
            },
            suppressMovable: true
        }
    ]

    const stationColumns = [
        {
            headerName: 'Station Name',
            field: 'stationName',
            filter: true,
            flex: 3,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Reporting Frequency',
            field: 'reportingPeriodFrequency',
            filter: true,
            flex: 2,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Reporting Period Value',
            filter: 'agDateColumnFilter',
            flex: 2,
            valueGetter: (params) => {
                return `${moment.months(params.data.reportingPeriodFrequencyValue - 1)}, ${params.data.reportingPeriod}` 
            },
            suppressMovable: true
        },
        {
            headerName: 'Consumption',
            field: 'actualConsumption',
            filter: true,
            flex: 2,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true,
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Credits',
            field: 'creditUnits',
            filter: true,
            flex: 2,
            valueFormatter: params => {
                return Number(params.data.creditUnits).toFixed(2)
            },
            suppressMovable: true,
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Edit',
            maxWidth: 50,
            flex: 2,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleEdit(params.data)}>
                        <Edit className={`edit-icon ${params.data.credits ? 'disable-icon' : ''}`} />
                    </div>
                )
            },
            suppressMovable: true
        }
    ]


    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/view-project/${projectId}`)
    }

    useEffect(() => {
        loadConsumption();
    }, [open])

    const onGridReady = useCallback(async (params) => {
        historyRef.current.api.showLoadingOverlay();
        let url = projectData?.monitoringLevel === 'CHARGER' ? 'evconsumption/history/CHARGER/project' : 'evconsumption/history/STATION/project';
        await fetchWrapper(`${API_URL}/${url}/${projectId}`)
            .then((res) => {
                historyRef.current.api.hideOverlay();
                setConsumptionData(res.json)
            }, (err) => console.log(err))
    })

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs title="List" mainTitle={`${projectName} - EV Consumption History`} />
            <Row>
                <Col lg={`12`}>
                    <Card className='newtable'>
                        <CardBody className='data-tables responsive-view history-table ptop-0'>
                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                <AgGridTable
                                    columnData={projectData?.monitoringLevel === 'CHARGER' ? chargerColumns : stationColumns}
                                    tableData={consumptionData}
                                    agRef={historyRef}
                                    onGridReady={onGridReady}
                                    rowHeight={70}
                                />
                            </div>
                            <div>
                                <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                open && <EditConsumptionModal value={open} setOpenModal={setOpen} data={data} />
            }
        </Fragment>
    )
}

export default ConsumptionHistory;