import React, { Fragment, useState, useEffect } from "react";
import ViewPage from "../../Common/Component/ViewProject";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";


const ViewAdminProject = () => {
    const [action, setAction] = useState();
    const [formData, setFormData] = useState();
    const [documentsData, setDocumentsData] = useState();
    const projectId = window.location.pathname.split('/')[3]
    
    const loadDocuments = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/document/${projectId}`, options)
        .then((res)=> {
            console.log(res)
            const data = res.json
            setDocumentsData(data)
        })
    }

    useEffect(()=>{
        setAction()
        loadDocuments()
    }, [])

    return (
        <Fragment>
            <ViewPage type={'admin'} 
            documents={documentsData} projectId={projectId}/>
        </Fragment>
    )
}

export default ViewAdminProject;