import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button, FormGroup, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { currencySymbol } from "../../../api/currencyData";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";

const SocialResponsibility = ({ activeTab, setActive }) => {
    const currency = useSelector(state => state?.user?.userDetails?.currency);
    const [csrApplicable, setCsrApplicable] = useState();
    const [turnOver, setTurnover] = useState();
    const [networth, setNetworth] = useState();
    const [orgDetails, setOrg] = useState();

    const getCSRData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/csrData`)
            .then((res) => {
                console.log(res.json)
            }, (err) => console.log(err))
    }

    const getOrgDetails = async () => {
        await fetchWrapper(`${API_URL}/users/orgDetails`)
            .then(res => {
                setOrg(res.json[0])
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getCSRData()
        getOrgDetails()
    }, [])

    const userDetails = useSelector(state => state?.user?.userDetails)


    const onSubmit = async () => {
        const data = [
            {
                serialNp: 1,
                details: "Is CSR applicable",
                companyParticulars: csrApplicable,
            },
            {
                serialNp: 2,
                details: "turnover",
                companyParticulars: turnOver,
            },
            {
                serialNp: 3,
                details: "networth",
                companyParticulars: networth,
            }
        ];
        const options = {
            method: 'POST',
            body: {
                csrData: data
            }
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            }, (err) => {
                if (err.status === 200) {
                    setActive(16)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }
    return (
        <Fragment>
            <h3>Corporate Social Responsibility</h3>
            <div className="mtop-1 company-profile">
                <Row>
                    <Col lg='6'>
                        <FormGroup>
                            <label>Networth (In {currencySymbol[currency]})</label>
                            <input type="text" className="form-control" value={networth} onChange={(e) => setNetworth(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col lg='6'>
                        <FormGroup>
                            <label>Turnover (In {currencySymbol[currency]})</label>
                            <input type="text" className="form-control" placeholder="" value={turnOver} onChange={(e) => setTurnover(e.target.value)} />
                        </FormGroup>
                    </Col>
                </Row>
                {
                    orgDetails?.country === 'India' && <Row>
                        <Col lg='6'>
                            <FormGroup>
                                <label>Is CSR applicable as per section 135 of Companies Act, 2013</label>
                                <div className='d-flex ml-1'>
                                    <input className="form-check-input radio_animated" type='radio' value={true}
                                        checked={csrApplicable === true ? true : false}
                                        onChange={(e) => setCsrApplicable(true)}
                                    /> <lable>Yes</lable>
                                    <input className="form-check-input radio_animated ml-1" type='radio' value={false}
                                        checked={csrApplicable === false ? true : false}
                                        onChange={(e) => setCsrApplicable(false)}
                                    /> <lable>No</lable>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                }
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(14)}>Back</Button>
                <Button color="primary" className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default SocialResponsibility;