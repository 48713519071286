import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { createProject } from '../../Common/Constant';
import { useSelector } from 'react-redux';
import HeaderCard from '../../Common/Component/HeaderCard';
import fetchWrapper from '../../Common/api/fetchWrapper';
import moment from 'moment';
import { API_URL } from '../../Common/Config/Config';
import { Eye, Edit } from 'react-feather';



const DataTables = (props) => {
  const [tableData, setTableData] = useState([]);
  const [showFilter, setShowFilter] = useState(false)
  const token = sessionStorage.getItem('accessToken');
  
  const tableColumns = [
    {
      name: 'Project Name',
      selector: row => row['name'],
      sortable: true,
      center: false,
      filterable: showFilter,
      with: '50px',
    },
    {
      name: 'Project Type',
      selector: row => `${row.type}`,
      sortable: true,
      center: false,
      filterable: showFilter,
      with: '30px',
  
    },
    {
      name: 'Country',
      selector: row => `${row.country}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Assessor',
      selector: row => `${row.assessor}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Start Date',
      selector: row => `${moment(new Date(row.start_date)).format('DD MMM, YYYY')}`,
      sortable: true,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'End Date',
      selector: row => `${moment(new Date(row.end_date)).format('DD MMM, YYYY')}`,
      sortable: true,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Status',
      selector: row => `${row.status}`,
      sortable: true,
      center: false,
      filterable: showFilter,
      with: '20px',
  
    },
    {
      name: "View",
      cell: (row) => (
        <Link to={`${process.env.PUBLIC_URL}/asm-user/view-project`} >
          <Eye className={`action-icon`} />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Edit className={`action-icon ${row.status == 'APPROVED' ? 'disable-icon' : ''}`} />
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];
  
  const loadData = async () => {
    const options = {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    }
    await fetchWrapper(`${API_URL}/projects`, options)
      .then((res) => {
        const data = res.json;
        setTableData(data)
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    loadData();
  }, [])

  
  console.log(tableData)
  return (
    <Fragment>
      <Breadcrumbs parent="Projects" title="Project Lists" mainTitle="Projects" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className='data-tables'>
              <DataTableComponent tableData={tableData} columnsData={tableColumns} title={'Recent Projects'} setShowFilter={setShowFilter}/>
                <div className="text-left">
                  <Link className="link" to={`${process.env.PUBLIC_URL}/asm-user/project-initiation`} style={{ fontSize: '18px' }}>
                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont icofont-plus-circle" ></i>
                    &nbsp;&nbsp;{createProject}</Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default DataTables;