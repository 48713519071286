import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    Button
} from "reactstrap";
import { H3, H4 } from "../../../../AbstractElements";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast, ToastContainer } from "react-toastify";
import ButtonLoader from "../../ButtonLoader";
import { useNavigate } from 'react-router';
import { X } from "react-feather";

const CancelOrderModal = ({ value, setOpenModal, data }) => {
    const [open, setOpen] = useState(value)
    const [loading, setLoading] = useState(false)

    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }

    const handleCancelOrder = async () => {
        const options = {
            method: 'PUT',
            body: {
                orderId: data.orderId
            }
        }
        await fetchWrapper(`${API_URL}/orders/cancelOrder`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    toast.success('Order Cancelled Successfully!')
                    setOpen(false)
                    setOpenModal(false)
                }
            }, (err) => {
                console.log(err)
                toast.error('Something went wrong!')
            })

    }

    return (
        <Fragment>
            <ToastContainer />
            <Modal isOpen={open} size='md' centered={true}>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Cancel Order</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody className="pt-15">
                    <p >Are you sure you want to cancel this order?</p>
                    <div className="d-flex mtop-2">
                        {
                            loading ? <ButtonLoader loaderText={'Canelling'} color='primary' /> : <Button color="primary" onClick={handleCancelOrder}>Yes</Button>
                        }
                        <Button color="secondary"  className="ml-1" onClick={onClose}>No</Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default CancelOrderModal