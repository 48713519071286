import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button } from "reactstrap";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";

const ProductService = ({ activeTab, setActive }) => {
    const [activityData, setActivityData] = useState([...Array(3)].map((_, index) => ({ serialNo: index + 1 })));
    const [productData, setProductData] = useState([...Array(3)].map((_, index) => ({ serialNo: index + 1 })));

    const getActivityData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/businessActivitiesData`)
            .then((res) => {
                setActivityData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getProductData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/productsServicesData`)
            .then((res) => {
                setProductData(res.json)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getActivityData()
        getProductData()
    }, [])

    const activityColumnData = [
        {
            headerName: 'S.No',
            field: 'serialNo',
            editable: false,
            filter: false,
            flex: 0.5,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Description of Main Activity',
            field: 'descriptionMainActivity',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Description of Business Activity',
            field: 'descriptionBusinessActivity',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '% of Turnover of the entity',
            field: 'percentageTurnOver',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.percentageTurnOver ?
                    thousandSeparator(params?.data?.percentageTurnOver.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
    ];

    const productColumnData = [
        {
            headerName: 'S.No',
            field: 'serialNo',
            editable: false,
            filter: false,
            flex: 0.5,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Product/Service',
            field: 'productService',
            editable: true,
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Industry Classification Code',
            field: 'nicCode',
            editable: true,
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '% of total Turnover contributed',
            field: 'percentageTurnOver',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.percentageTurnOver ?
                    thousandSeparator(params?.data?.percentageTurnOver.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
    ];

    const onSubmit = async () => {
        console.log(activityData, productData)
        const data = {
            businessActivitiesData: activityData?.filter(item => Object.keys(item)?.length > 2),
            productsServicesData: productData?.filter(item => Object.keys(item)?.length > 2)
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)

            }, (err) => {
                console.log(err)
                if (err?.status === 200) {
                    setActive(10)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }

            })

    }


    return (
        <Fragment>
            <h3>Products/Services</h3>
            <div className="mtop-1 company-profile">
                <h5>Details of business activities (accounting for 90% of the turnover)</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={activityData}
                        columnData={activityColumnData}
                    />
                    <CustomFooter handleAdd={() => setActivityData([...activityData, { id: activityData?.length + 1 }])} />
                </div>
            </div>

            <div className="mtop-1">
                <h5>Products/Services sold by the entity (accounting for 90% of the entity’s Turnover)</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={productData}
                        columnData={productColumnData}
                    />
                    <CustomFooter handleAdd={() => setProductData([...productData, { id: productData?.length + 1 }])} />
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(8)}>Back</Button>
                <Button color="primary" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default ProductService;