import React, { Fragment, useEffect, useState } from 'react';
import { Image, LI, P, UL } from '../../../../AbstractElements';
import { User, Mail, FileText, Lock } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { Account, Inbox, logout, Taskboard } from '../../../../Common/Constant';
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { FaUserCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { USER_DETAILS } from '../../../../Common/Constant';
import ChangePassword from '../../../Component/Profile/ChangePassword';

const LogoutClass = () => {
    const Navigate = useNavigate();
    const [profileData, setProfileData] = useState();
    const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
    const auth_profile = JSON.parse(sessionStorage.getItem('auth_profile'));
    const [profile, setProfile] = useState('');
    const [name, setName] = useState('');
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)

    const userDetails = useSelector(state => state?.user?.userDetails);

    useEffect(() => {
        setProfile(sessionStorage.getItem('profileURL'));
        setName(sessionStorage.getItem('Name') || 'Helen Walter');
    }, [setProfile, setName]);

    const Logout = () => {
        sessionStorage.removeItem('profileURL');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('auth_profile');
        sessionStorage.removeItem('Name');
        sessionStorage.setItem('authenticated', false);
        sessionStorage.setItem('login', false);
        sessionStorage.clear();
        sessionStorage.setItem('logoutTime', new Date().toLocaleTimeString())
        Navigate(`${process.env.PUBLIC_URL}/login`);
    };

    return (
        <Fragment>
            {open && <ChangePassword value={open} setOpenModal={setOpen} />}
            <LI attrLI={{ className: "profile-nav onhover-dropdown p-0" }} >
                <div className="d-flex profile-media">
                    <FaUserCircle className='m-0 profile-icon' height={50} width={50}/>
                    {/* <Image attrImage={{ className: 'b-r-50 m-0', src: profileImg, alt: '' }} /> */}
                    <div className="flex-grow-1"><span>{userDetails?.firstName + " " + userDetails?.lastName}</span>
                        {/* <P attrPara={{ className: "mb-0 font-roboto" }} >Admin <i className="middle fa fa-angle-down"></i></P> */}
                    </div >
                </div >
                <UL attrUL={{ className: "profile-dropdown onhover-show-div" }}>
                    <LI>
                        <Link to={`${process.env.PUBLIC_URL}/edit-profile`}><User />{'Profile Settings'}</Link>
                    </LI>
                    <LI>
                        <Link onClick={handleOpen}><Lock />{'Change Password'}</Link>
                    </LI>
                    <LI attrLI={{ onClick: Logout }}>
                        <Link className='logout-button'>{logout}</Link>
                    </LI>
                </UL>
            </LI >
        </Fragment >
    );
};

export default LogoutClass;