import React, { Fragment, useState } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../AbstractElements";
import { useForm } from "react-hook-form";

const AddUser = ({ value, setOpenModal, data, type }) => {
    const [open, setOpen] = useState(value);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    if(type === 'edit') {
        setValue('firstName', data.firstName);
        setValue('lastName', data.lastName);
        setValue('email', data.email);
        setValue('phone', data.phone);
        setValue('userType', data.userType);
    }

    const onSubmit = () => {
        // toast.success('Password Changed Successfully!')
        onClose();
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader>
                    <H3>{type === 'add' ? 'Add User' : 'Edit User'}</H3>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='12'>
                            <Card>
                                <CardBody className="bs-none">
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>First Name</Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="firstName"
                                                        type='text'
                                                        {...register('firstName', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.firstName && errors.firstName.type === 'required' && 'First Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>Last Name</Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="lastName"
                                                        type='text'
                                                        {...register('lastName', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.lastName && errors.lastName.type === 'required' && 'Last Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>Email ID</Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="email"
                                                        type='email'
                                                        {...register('email', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.email && errors.email.type === 'required' && 'First Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>Phone</Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="phone"
                                                        type='text'
                                                        {...register('phone', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.phone && errors.phone.type === 'required' && 'Last Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='6'>
                                                <Label>User Type</Label>
                                                <select className="form-select"  {...register('userType', { required: true })} >
                                                    <option value=''>Select User Type</option>
                                                    <option value="1">{'Admin'}</option>
                                                    <option value="2">{'Power User'}</option>
                                                    <option value="3">{'Operational User'}</option>
                                                    <option value="4">{'Demo User'}</option>
                                                </select>
                                                <span className='text-danger'>{errors.userType && errors.userType.type === 'required' && 'User Type is required'}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center justify-content-center " style={{ marginTop: "25px" }}>
                                                <Button color="primary" >{type === 'add' ? 'Add' : 'Edit'}</Button>
                                                <Button color="primary" onClick={onClose} style={{ marginLeft: "20px" }}> Cancel</Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddUser;