import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { logo } from './airoiLogo';
import moment from 'moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getHeaderToExport = (gridApi) => {
    const columns = gridApi?.getAllDisplayedColumns();
    return columns.map((column) => {
        const { headerName } = column.getColDef();
        const sort = column.getSort();
        const headerNameUppercase = headerName[0]?.toUpperCase() + headerName?.slice(1);
        const headerCell = {
            text: headerNameUppercase + (sort ? ` (${sort})` : ''),
            bold: true,
            margin: [0, 10, 0, 10],
        };
        return headerCell;
    });
};

/**
* This function iterates over all of the rows and columns to create
* a matrix of cells
*/
const getRowsToExport = (gridApi) => {
    const columns = gridApi.getAllDisplayedColumns();
    const rightAlignIds = ["value", "revisedValue", "reduction", "reductionPercentage"]
    const rowsToExport = [];
    gridApi.forEachNodeAfterFilterAndSort((node) => {
        const rowToExport = columns.map((column) => {
            if(column.colId === 'id') {
                return {
                    text: gridApi.getValue(column, node) ?? '',
                    alignment: 'center'
                }
            }
            if (rightAlignIds.includes(column.colId)) {
                return {
                    text: gridApi.getValue(column, node) ?? '',
                    alignment: 'right'
                }
            } else {
                return {
                    text: gridApi.getValue(column, node) ?? '',
                    alignment: 'left'
                }
            }

        }
        );
        rowsToExport.push(rowToExport);
    });

    return rowsToExport;
};

/** 
* This function returns a PDF document definition object - the input for pdfMake.
*/
const getDocument = (gridApi) => {
    const columns = gridApi.getAllDisplayedColumns();
    const headerRow = getHeaderToExport(gridApi);
    const rows = getRowsToExport(gridApi);
    return {
        pageOrientation: 'landscape', // can also be 'portrait'
        pageMargins: [20, 20, 20, 20],
        watermark: {
            text: 'ACTION PLAN',
            color: 'gray',
            opacity: 0.2,
            bold: true,
            italics: false,
            angle: 320,
            fontSize: 100
        },
        footer: function (currentPage, pageCount) {
            return {
                text: currentPage.toString() + ' of ' + pageCount,
                alignment: 'center'
            }
        },
        content: [
            {
                image: logo,
                width: 75,
                height: 25
            },
            {
                columns: [
                    { text: 'ACTION PLAN - TRACK IT!', style: 'header', alignment: 'center' },
                    { text: 'Date: ' + moment(new Date()).format('DD MMM, YYYY'), alignment: 'right', width: '13%' }
                ]
            },

            {
                table: {
                    // the number of header rows
                    headerRows: 1,

                    // the width of each column, can be an array of widths `${100 / columns.length}%`
                    widths: [20, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '20%'],

                    // all the rows to display, including the header rows
                    body: [headerRow, ...rows],

                    // Header row is 40px, other rows are 15px
                    heights: (rowIndex) => (rowIndex === 0 ? 40 : 25),
                },
                style: 'table'
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 5, 0, 0]
            },
            table: {
                margin: [0, 20, 0, 0]
            }
        }
    };
};

export const exportToPDF = (gridApi) => {
    const doc = getDocument(gridApi);
    pdfMake.createPdf(doc).download();
};
