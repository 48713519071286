import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Progress, Row } from 'reactstrap';
// import { apexBarChart } from "../../Common/Data/Charts/ApexChart";
import { H3, H4 } from "../../../AbstractElements";
import './Dashboard.css';
import AlmostThere from './AlmostThereChart';

const BarChartClass = () => {
    const [display, setDisplay] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setDisplay(true)
        }, 500);
    }, [])
    if (!display) {
        return null;
    }
    return (
        <Fragment>
                {/* <Row style={{height: '50%'}}>
                    <AlmostThere />
                </Row> */}
                    <Card>
                        <h5 style={{ color: '#2b7704', marginTop: '30px', marginLeft: '4%' }}>CRITICAL CATEGORIES</h5>
                        <h4 style={{ color: '#2b7704', marginLeft: '4%' }}>Here is where you are emitting the most CO<sub>2</sub></h4>
                        <CardBody style={{ paddingTop: '10px' }}>
                            <div id='basic-bar'>
                                {/* <Chart options={apexBarChart.options} series={apexBarChart.series} type="bar" height={350} /> */}
                                <h4 style={{ textAlign: 'left', color: '#607D8B' }}>HVAC</h4>
                                <Progress multi >
                                    <Progress bar value={70} style={{ backgroundColor: '#00796B', textAlign: 'left', paddingLeft: '5px' }} >4,532 Kg</Progress>
                                    <Progress bar value={30} style={{ backgroundColor: '#F50057', textAlign: 'left', paddingLeft: '5px' }}>+4,532 Kg</Progress>
                                </Progress>
                                <h4 style={{ textAlign: 'left', color: '#607D8B' }}>Travel</h4>
                                <Progress multi >
                                    <Progress bar value={70} style={{ backgroundColor: '#00796B', textAlign: 'left', paddingLeft: '5px' }} >3,874 Kg</Progress>
                                    <Progress bar value={30} style={{ backgroundColor: '#F50057', textAlign: 'left', paddingLeft: '5px' }}>+3,874 Kg</Progress>
                                </Progress>
                                <h4 style={{ textAlign: 'left', color: '#607D8B' }}>Electricity</h4>
                                <Progress multi style={{ width: '90%' }} >
                                    <Progress bar value={80} style={{ backgroundColor: '#00796B', textAlign: 'left', paddingLeft: '5px' }} >792 Kg</Progress>
                                    <Progress bar value={20} style={{ backgroundColor: '#F50057', textAlign: 'left', paddingLeft: '5px' }}>+792 Kg</Progress>
                                </Progress>
                            </div>
                        </CardBody>
                    </Card>
                
            
        </Fragment>
    );
};

export default BarChartClass;