import React, { Fragment, useState } from 'react';
import { Breadcrumbs, H2, H3, H5, LI, UL, Image } from '../../AbstractElements';
import { Container, Row, Col, Button, Modal, ModalBody, ModalHeader, FormGroup, Label, Input, Form } from 'reactstrap';
import { X } from 'react-feather';
import mastercard from '../../assets/images/ecommerce/mastercard.png';
import visa from '../../assets/images/ecommerce/visa.png';
import paypal from '../../assets/images/ecommerce/paypal.png';
import { SelectYear, SelectMonth, ExpirationDate } from '../../Common/Constant';
import { useForm } from 'react-hook-form';

const AddPaymentModal = ({ value, setOpenModal }) => {
    const [open, setOpen] = useState(value);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [expiryMonth, setExpiryMonth] = useState();
    const [expiryYear, setExpiryYear] = useState();
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const onClose = () => {
        setOpen(false);
        setOpenModal(false);
    }
     const onSubmit = (data) => {
        if(data){
            console.log(data)
            onClose();
        }
        
     }
    console.log(expiryMonth, expiryYear)
    return (
        <Fragment>
            <Container fluid={true}>
                <Modal centered={true} isOpen={open}>
                    <ModalHeader className='d-flex'>
                        <H2>Add Payment Method</H2>
                        <X className='x-button' onClick={onClose} />
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg='12'>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='d-flex'>
                                        <Label className="col-form-label ">Card</Label>
                                        <FormGroup className="mb-3 col-12 ml-1">
                                            <UL attrUL={{ className: 'simple-list payment-opt d-flex flex-row' }}>
                                                <LI><Image attrImage={{ src: `${mastercard}`, alt: '' }} /></LI>
                                                <LI><Image attrImage={{ src: `${visa}`, alt: '' }} /></LI>
                                                <LI><Image attrImage={{ src: `${paypal}`, alt: '' }} /></LI>
                                            </UL>
                                        </FormGroup>
                                    </div>
                                    <Row className="theme-form e-commerce-form">
                                        <FormGroup className="mb-3 col-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Card number"
                                                minLength={16}
                                                maxLength={16}
                                                inputMode="numeric"
                                                {...register('cardNumber', {required: true, pattern: /^[0-9\b]+$/  })}
                                            />
                                            <span className='text-danger'>{errors.cardNumber && errors.cardNumber.type === 'required' && 'Card Number Required'}</span>
                                            <span className='text-danger'>{errors.cardNumber && errors.cardNumber.type === 'pattern' && 'Invalid Card Number'}</span>
                                        </FormGroup>

                                        <div className="col-12">
                                            <Label className="col-form-label p-b-20">
                                                {ExpirationDate}
                                            </Label>
                                            {console.log(expiryMonth, expiryYear)}
                                        </div>
                                        <span className='text-danger'>{expiryYear == currentYear && expiryMonth <= currentMonth && 'Invalid Date'}</span>
                                        <FormGroup className="mb-3 col-3 p-r-0">
                                            <select className="form-select" name='expiryMonth' 
                                            {...register('expiryMonth', { required: true })}
                                            onChange={e => setExpiryMonth(e.target.value)}
                                            >
                                                <option value=''>MM</option>
                                                {SelectMonth.map((months, i) =>
                                                    <option key={i} value={i+1}>{months}</option>
                                                )}
                                            </select>
                                            <span className='text-danger'>{errors.expiryMonth && errors.expiryMonth.type === 'required' && 'Please select month'}</span>
                                        </FormGroup>
                                        <FormGroup className="mb-3 col-3 p-r-0">
                                            <select className="form-select" name='expiryYear' 
                                            {...register('expiryYear', { required: true })} onChange={e => setExpiryYear(e.target.value)}>
                                                <option value=''>YYYY</option>
                                                {SelectYear.map((years, i) =>
                                                    <option key={i} value={years}>{years}</option>
                                                )}
                                            </select>
                                            <span className='text-danger'>{errors.expiryYear && errors.expiryYear.type === 'required' && 'Please select Year'}</span>
                                        </FormGroup>
                                        <FormGroup className="mb-3 col-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="CVV number"
                                                minLength={3}
                                                maxLength={3}
                                                {...register('cvvNumber', {required: true, pattern: /^[0-9\b]+$/  })}
                                            />
                                            <span className='text-danger'>{errors.cvvNumber && errors.cvvNumber.type === 'required' && 'CVV Number Required'}</span>
                                            <span className='text-danger'>{errors.cvvNumber && errors.cvvNumber.type === 'pattern' && 'Invalid CVV Number'}</span>
                                        </FormGroup>
                                        <div className="col-12">
                                            <Button color='primary' type='submit'>
                                                {'Save'}
                                            </Button>
                                            <Button color='primary' className='ml-1' outline onClick={onClose}>
                                                {'Cancel'}
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>

                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default AddPaymentModal;