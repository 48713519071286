import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Col, Container } from 'reactstrap'
import { H3,P,Btn } from '../../../AbstractElements'
import { BACK_TO_HOME_PAGE, Error2Head, ErrorPageContent } from '../../../Common/Constant'
import { Error2 } from './Svg';
import { logoutHelper } from '../../api/logout_helper'

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1)
  }

  return (
    <Fragment>
       <div className='page-wrapper compact-wrapper' id='pageWrapper'>
          <div className='error-wrapper'>
            <Container>
              <div className='svg-wrapper error2'>
                <Error2/>
              </div>
              <Col md="8" className="offset-md-2">
                  <H3>{Error2Head}</H3>
                  <P attrPara={{ className: 'sub-content' }} >{ErrorPageContent}</P>
                  <Button color='primary' onClick={logoutHelper}>BACK TO LOGIN</Button>
              </Col>
            </Container>
          </div>
      </div>  
    </Fragment>
  )
}
export default ErrorPage