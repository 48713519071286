import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { createProject } from '../../Common/Constant';
import fetchWrapper from '../../Common/api/fetchWrapper';
import moment from 'moment';
import { API_URL } from '../../Common/Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import DeleteProject from '../../Common/Component/DeleteProject/DeleteProject';
import { defaultStatus, defaultStatusColorCodes } from '../../Common/api/helper';
import {FaChargingStation} from 'react-icons/fa'
import LayoutLoader from '../../Common/Component/LayoutLoader/LayoutLoader';


const AsmAdminProjectList = (props) => {
  const [tableData, setTableData] = useState([]);
  const [showFilter, setShowFilter] = useState(false)
  const [open, setOpen] = useState(false)
  const [dataId, setDataId] = useState('');
  const [loading, setLoading] = useState(false)
  const [progressOpen, setProgressOpen] = useState(false);


  const handleModal = (id, status) => {
    if (status === 'DRAFT') {
      setOpen(true)
      setDataId(id)
    }
  }

  const handleProgress = (status) => {
    setProgressOpen(true)
  }

  const preValidatedStatus = ['ACCEPTED', 'SUBMITTED_TO_REGISTRY', 'APPROVED_BY_REGISTRY', 'ACTIVE', 'SUSPENDED', 'RETIRED', 'INASSESSMENT_ALLOCATED']


  const loadData = async () => {
    setLoading(true)
    const options = {
      method: 'GET'
    }
    await fetchWrapper(`${API_URL}/projects`, options)
      .then((res) => {
        console.log(res);
        const data = res.json;
        setTableData(data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      });

  }

  const tableColumns = [
    {
      name: 'Project Name',
      sortable: true,
      center: false,
      filterable: showFilter,
      with: '50px',
      selector: (row) => `${row.name}`
    },
    {
      name: 'Country',
      selector: row => `${row.country}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
      grow: 0.5
    },
    {
      name: 'Asset Owner',
      selector: row => `${row.asset_org_name}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Assessor',
      selector: row => `${row.assessorOrgId == null ? 'Unassigned' : row.assessor_org_name}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Assessment Date',
      selector: row => row.assessment_due_date === null ? 'NIL' : `${moment(new Date(row.assessment_due_date)).format('DD MMM, YYYY')}`,
      sortable: true,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Status',
      center: false,
      // with: '20px',
      cell: (row) => (
        <div >
            <p style={{
              fontSize: '12px', border: '2px solid', backgroundColor: defaultStatusColorCodes[row.status], padding: '2px 17px',
              borderRadius: '15px', borderColor: defaultStatusColorCodes[row.status], fontWeight: 500, textAlign: 'center', color: '#fff'
            }}>
              {defaultStatus[row.status]}
            </p>
        </div>
      )
    },
    {
      name: "Category",
      cell: (row) => (
        <Link to={`${process.env.PUBLIC_URL}/aso-admin/ev-station-lists/${row.id}/${row.name}`} className={`${row.subCategoryItemName !== 'EV Charging Stations' ? 'disable-ev-icon' : ''}`}>
          <FaChargingStation className={`${row.subCategoryItemName === 'EV Charging Stations' ? 'ev-icon' : 'disable-ev-icon'}`} />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "View",
      cell: (row) => (
        <Link to={`${process.env.PUBLIC_URL}/asm-admin/view-project/${row.id}`} >
          <Eye className={`action-icon`} />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <Link to={`${process.env.PUBLIC_URL}/asm-admin/edit-project/${row.id}`}
          className={`${preValidatedStatus.includes(row.status) === true ? 'disable-icon' : ''}`} >
          <Edit className={`edit-icon ${preValidatedStatus.includes(row.status) === true ? 'disable-icon' : ''}`} />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div onClick={() => handleModal(row.id, row.status)} >
          <Trash2 className={` ${row.status === 'DRAFT' ? 'trash-icon' : 'disable-icon'}`} />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  useEffect(() => {
    loadData();
  }, [open])

  return (
    <Fragment>
      <div className="d-flex">
       <LayoutLoader />
        <Breadcrumbs parent="Carbon Credits" subParent="Projects" title="Project List" mainTitle="Project List" />
        <div className="text-right action_button mr-13">
          <Link className="link action_button" to={`${process.env.PUBLIC_URL}/asm-admin/project-initiation/Internal`} style={{ fontSize: '18px' }}>
            <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
            &nbsp;&nbsp;{createProject}</Link>
        </div>
      </div>
      {open && <DeleteProject value={open} setOpenModal={setOpen} dataId={dataId} />}
      {/* {progressOpen && <ProgressBarModel value={progressOpen} setOpenModal={setProgressOpen} />} */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className=" newtable ">
              <CardBody className='data-tables responsive-view'>
                <DataTableComponent tableData={tableData} columnsData={tableColumns} title={'Recent Projects'}
                  setShowFilter={setShowFilter} loading={loading} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default AsmAdminProjectList;