import React, { Fragment, useState, useEffect } from "react";
import AssessorViewProject from "../../Common/Component/AssessorViewProject";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";


const ViewAssessorProject = () => {
    const [action, setAction] = useState();
    const [formData, setFormData] = useState();
    const [documentsData, setDocumentsData] = useState();
    const [notes, setNotes] = useState();
    const projectId = window.location.pathname.split('/')[4]

    const loadData = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/projects/${projectId}`, options)
        .then((res)=>{
            console.log(res)
            const data = res.json
            setFormData(data)
        })
        .catch((err) => console.log(err));
    }

    const loadDocuments = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/document/${projectId}`, options)
        .then((res)=> {
            console.log(res)
            const data = res.json
            setDocumentsData(data)
        })
    }


    useEffect(()=>{
        setAction(window.location.pathname.split('/')[3])
        loadDocuments()
    }, [])
    return (
        <Fragment>
            <AssessorViewProject type={'assessor'} formAction={action}  documents={documentsData} projectId={projectId}/>
        </Fragment>
    )
}

export default ViewAssessorProject;