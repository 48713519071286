import React, { Fragment, useState, useEffect } from "react";
import ViewPage from "../../Common/Component/ViewProject";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";


const ViewAsmAdminProject = () => {
    const [formData, setFormData] = useState({});
    const projectId = window.location.pathname.split('/')[3]
    const loadData = async (id) => {
        const options = {
          method: 'GET'
        }
        await fetchWrapper(`${API_URL}/document/${id}`, options)
          .then((res) => {
            console.log(res);
            const data = res.json;
            setFormData(data)
            
           })
          .catch((err) => console.log(err));
          
      }
     useEffect(()=>{
        const id = window.location.pathname.split('/')[3]
        loadData(id)
     }, [])
    return (
        <Fragment>
            { formData && <ViewPage type='asm-admin' projectId={projectId} documents={formData}/> } 
        </Fragment>
    )
}

export default ViewAsmAdminProject;