import React, { Fragment, useState, useEffect, useCallback } from "react";
import { CardBody } from "reactstrap";
import {
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "../../Data/CountryList/Defaultdata";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";

const CategoryForm = ({ title, action, navigateUrl, cancel, preData, setAdd }) => {
    const [loading, setLoading] = useState(false)
    const [registryTypes, setRegistryTypes] = useState([]);
    const [data, setData] = useState(action === 'Edit' ? preData : '')
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, resetField } = useForm({ defaultValues: preData });
    const [rate, setRate] = useState(0);
    const [categoryTypes, setCategoryType] = useState([]);
    const [unitTypeList, setUnitTypeList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [registry, setRegistry] = useState(preData?.registryType);
    const [registryId, setRegistryId] = useState(preData?.registryId);
    const [unitType, setUnitType] = useState();
    const navigate = useNavigate();
    const [projectCategoryId, setProjectCategoryId] = useState(preData?.projectCategoryId);
    const [subCategory, setSubcategory] = useState([]);
    const [subCategoryId, setSubcategoryId] = useState(preData?.projectSubCategoryId);
    const [subCategoryItemList, setSubcategoryItemList] = useState([]);
    const [subCategoryItemId, setSubcategoryItemId] = useState(preData?.subCategoryItemId);
    const [lowerValue, setLower] = useState(preData?.lowerBound);
    const [upperValue, setUpper] = useState(preData?.upperBound)

    const getResitryTypes = async (val) => {
        await fetchWrapper(`${API_URL}/registry/projectcategory/${val}`)
            .then((res) => {
                setRegistryTypes(res.json);
            })
            .catch(error => console.error(error))
    }

    const getProjectCategory = async () => {
        await fetchWrapper(`${API_URL}/projectcategory`)
            .then((res) => {
                setCategoryType(res.json);
            })
            .catch(err => console.log(err))
    }

    const getSubcategory = async (val) => {
        setProjectCategoryId(val)
        setValue('projectCategoryId', val);
        resetField('projectSubCategoryId');
        setSubcategoryId();
        setRegistryId();
        resetField('registryId')
        setSubcategoryItemId();
        resetField('subCategoryItemId')
        setSubcategoryItemId();
        resetField('unittypeId');
        setUnitType();
        resetField('currency')
        setSubcategoryItemList([]);
        getResitryTypes(val)
        await fetchWrapper(`${API_URL}/subcategory/category/${val}`)
            .then((res) => {
                setSubcategory(res.json);
            })
            .catch(error => console.error(error))
        
    }

    const getSubCategoryItems = async (val) => {
        await fetchWrapper(`${API_URL}/subcategoryitems/subcategory/${subCategoryId}/registry/${val}`)
            .then((res) => {
                setSubcategoryItemList(res.json);
            })
            .catch(error => console.error(error))
    }

    const getUnitType = async (value) => {
        const registryData = registryTypes.find(i => i.registryId === value);
        setRegistryId(value);
        setRegistry(registryData?.registry);
        setValue('registry', registryData?.registry)
        getSubCategoryItems(value)
        await fetchWrapper(`${API_URL}/unittypes/${registryData?.registry}`)
            .then((res) => {
                setUnitTypeList(res.json);
            })
            .catch(error => console.error(error))
    }

    const handleSubcategory = (val) => {
        setSubcategoryId(val)
        setRegistryId();
        resetField('registryId')
        setSubcategoryItemId();
        resetField('subCategoryItemId')
        setSubcategoryItemId();
        resetField('unittypeId');
        setUnitType();
        resetField('currency')
    }


    const onSubmit = async (data) => {
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/projectcategoryrates`
            message = 'Credit Rates Submitted Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/projectcategoryrates/${data.id}`
            message = 'Credit Rates Updated Successfully!'
        }
        const options = {
            method: method,
            body: data
        }
        await fetchWrapper(url, options)
            .then((res) => {
                if (res.ok) {
                    toast.success(message)
                    setAdd(true)
                    action === 'Add' && cancel()
                    action === 'Edit' && navigate(navigateUrl)
                }
            })
            .catch((err) => {
                if (err.status === 500) {
                    toast.error('Something went wrong')
                }
                if (err.status === 409) {
                    toast.error('Credit Rate already Exists')
                }
            })
    }

    useEffect(() => {
        reset(preData)
        if (preData) {
            getResitryTypes(preData?.projectCategoryId);
            getUnitType(preData?.registryId);
            getCurrrencies(preData?.unittypeId);
            setLower(preData?.lowerBound)
            setUpper(preData?.upperBound)
        }
    }, [reset, preData])


    useEffect(() => {
        getProjectCategory();
        reset(preData)
    }, [reset]);


    const handleUpperBound = (value) => {
        setUpper(value)
        let regRate = (parseFloat(value) + parseFloat(lowerValue)) / 2;
        setRate(parseFloat(regRate))
        setValue('midRate', regRate);
    }

    const handleLowerBound = (value) => {
        console.log(value, upperValue)
        setLower(value)
        let regRate = (parseFloat(upperValue) + parseFloat(value)) / 2;
        setRate(parseFloat(regRate))
        setValue('midRate', regRate);
    }



    const getCurrrencies = async (id) => {
        const unit = unitTypeList?.find(i => i.id === id)
        setUnitType(unit?.type)
        await fetchWrapper(`${API_URL}/unitcurrency/${unit?.type}`)
            .then((res) => {
                setCurrencyList(res.json);
            })
            .catch(error => console.error(error))
    }

    return (
        <Fragment>
            <Row>
                <Col lg={action === 'Add' ? "12" : '8'}>
                    {title && <H3 attrH3={{ className: 'mtop-2 ml-15' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 mb-0 bs-none'}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Project Category{action === 'Add' && <span className='required'>*</span>}</Label>
                                            {
                                                action === 'Add' && <select className="form-select" name="projectCategoryId"
                                                    value={preData?.projectCategoryId}
                                                    disabled={action === 'Edit' ? true : false}
                                                    {...register('projectCategoryId', { required: true })}
                                                    onChange={(e) => getSubcategory(e.target.value)}
                                                >
                                                    <option value="">-Select Project Category-</option>
                                                    {
                                                        categoryTypes?.length > 0 && categoryTypes?.map((i) => {
                                                            return (
                                                                <option value={i.id}>{i.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                            {
                                                action === 'Edit' && <input
                                                    className="form-control"
                                                    name="projectCategoryId"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={true}
                                                    value={preData?.projectCategory}
                                                />
                                            }
                                            <span className="text-danger">{errors.projectCategoryId && 'Project Category is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Project Subcategory<span className="text-danger">*</span></label>
                                            {
                                                action === 'Add' && <select className='form-select' value={preData?.projectSubCategoryId}
                                                    disabled={action === 'Edit' ? true : false}
                                                    {...register('projectSubCategoryId', { required: true })}
                                                    onChange={(e) => handleSubcategory(e.target.value)}
                                                >
                                                    <option value=''>Select Project Subcategory</option>
                                                    {
                                                        subCategory?.length > 0 && subCategory?.map((i) => {
                                                            return (
                                                                <option value={i.id}>{i.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                            {
                                                action === 'Edit' && <input
                                                    className="form-control"
                                                    name="projectSubCategoryId"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={true}
                                                    value={preData?.projectSubCategory}
                                                />
                                            }
                                            <span className='text-danger'>{errors.projectSubcategoryId && errors.projectSubcategoryId.type === 'required'
                                                && 'Project Category is required'}</span>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Registry Type{action === 'Add' && <span className='required'>*</span>}</Label>
                                            {
                                                action === 'Add' && <select className="form-select" name="registryType"
                                                    value={registryId}
                                                    disabled={action === 'Edit' ? true : false}
                                                    {...register('registryId', { required: true })}
                                                    onChange={(e) => getUnitType(e.target.value)}
                                                >
                                                    <option value="">-Select Registry Type-</option>
                                                    {
                                                        registryTypes?.length > 0 && registryTypes.map((item) => {
                                                            return (
                                                                <option value={item.registryId}>{item.registry}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                            {
                                                action === 'Edit' && <input
                                                    className="form-control"
                                                    name="registryType"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={true}
                                                    value={preData?.registryType}
                                                />
                                            }
                                            <span className="text-danger">{errors.registryId && 'Registry Type is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Project Subcategory Items<span className="text-danger">*</span></label>
                                            {
                                                action === 'Add' && <select className='form-select' value={subCategoryItemId}
                                                    disabled={action === 'Edit' ? true : false}
                                                    {...register('subCategoryItemId', { required: true })}
                                                    onChange={(e) => setSubcategoryItemId(e.target.value)}
                                                >
                                                    <option value=''>Select Project Subcategory Items</option>
                                                    {
                                                        subCategoryItemList?.length > 0 && subCategoryItemList?.map((i) => {
                                                            return (
                                                                <option value={i.id}>{i.itemName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                            {
                                                action === 'Edit' && <input
                                                    className="form-control"
                                                    name="subCategoryItem"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={true}
                                                    value={preData?.subCategoryItem}
                                                />
                                            }
                                            <span className='text-danger'>{errors.subCategoryItemId && errors.subCategoryItemId.type === 'required'
                                                && 'Project Subcategory Item is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Unit Type{action === 'Add' && <span className='required'>*</span>}</Label>
                                            {
                                                action === 'Add' && <select className="form-select" name="unitTypeId"
                                                    disabled={((registry === 'NO PREFERENCE') || (action === 'Edit')) ? true : false}
                                                    value={preData?.unittypeId}
                                                    {...register('unittypeId', { required: (registry !== 'NO PREFERENCE' ? true : false) })}
                                                    onChange={(e) => getCurrrencies(e.target.value)}
                                                >
                                                    <option value="">-Select Unit Type-</option>
                                                    {
                                                        unitTypeList?.length > 0 && unitTypeList.map((item) => {
                                                            return (
                                                                <option value={item.id}>{item.type}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                            {
                                                action === 'Edit' && <input
                                                    className="form-control"
                                                    name="unitType"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={true}
                                                    value={preData?.unitType}
                                                />
                                            }
                                            <span className="text-danger">{errors.unittypeId && 'Unit Type is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-6">
                                            <label>Currency{action === 'Add' && <span className='required'>*</span>}</label>
                                            {
                                                action === 'Add' && <select className="form-select" name='currency' value={preData?.currency}
                                                    disabled={action === 'Edit' ? true : false}
                                                    {...register('currency', { required: true })}>
                                                    <option value={''}>-Select Currency-</option>
                                                    {
                                                        registry === 'NO PREFERENCE' && <option value={'USD'}>{'USD'}</option>
                                                    }
                                                    {
                                                        currencyList?.length > 0 && currencyList.map((item) => {
                                                            return (
                                                                <option value={item.currency}>{item.currency}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                            {
                                                action === 'Edit' && <input
                                                    className="form-control"
                                                    name="currency"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={true}
                                                    value={preData?.currency}
                                                />
                                            }
                                            <span className="text-danger">{errors.currency && 'Currency is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3">
                                        <FormGroup >
                                            <Label >Lower Bound<span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="lowerBound"
                                                placeholder=""
                                                type="number"
                                                value={lowerValue}
                                                {...register('lowerBound', { required: true, min: 0, validate: { checkValue: (v, formvalues) => v < formvalues?.upperBound } })}
                                                onChange={(e) => handleLowerBound(e.target.value)}
                                            />
                                            <span className="text-danger">
                                                {errors.lowerBound?.type === 'required' && 'Lower Bound is Required'}
                                                {errors.lowerBound?.type === 'min' && 'Lower Bound should be positive'}
                                                {errors.lowerBound?.type === 'checkValue' && 'Value should be lesser than Upper Bound value.'}
                                            </span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup >
                                            <Label >Upper Bound<span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="upperBound"
                                                placeholder=""
                                                type="number"
                                                value={upperValue}
                                                {...register('upperBound', { required: true, min: 0, validate: { checkValue: (v, formValues) => v > formValues?.lowerBound } })}
                                                onChange={(e) => handleUpperBound(e.target.value)}
                                            />
                                            <span className="text-danger">
                                                {errors.upperBound?.type === 'required' && 'Upper Bound is Required'}
                                                {errors.upperBound?.type === 'min' && 'Upper Bound should be positive'}
                                                {errors.upperBound?.type === 'checkValue' && 'Value should be greater than lower bound value.'}
                                            </span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label >Mid Rate <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="midRate"
                                                placeholder={rate}
                                                type="text"
                                                {...register('midRate', {
                                                    required: true, min: 0, validate: {
                                                        checklower: (v, formValues) => v > formValues?.lowerBound,
                                                        checkUpper: (v, formValues) => v < formValues?.upperBound
                                                    }
                                                })}
                                                onChange={(e) => setRate(e.target.value)}
                                            />
                                            <span className="text-danger">
                                                {errors.midRate?.type === 'required' && 'Registry rate is Required'}
                                                {errors.midRate?.type === 'min' && 'Registry rate should be positive'}
                                                {errors.midRate?.type === 'checklower' && 'Value should be greater than lower bound value.'}
                                                {errors.midRate?.type === 'checkUpper' && 'Value should be lesser than upper bound value.'}
                                            </span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        <Button color="primary" type="submit">{'Submit'}</Button>
                                        <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default CategoryForm;