import React, { Fragment, useState } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { X } from "react-feather";
import { useForm } from "react-hook-form";
import { H3 } from "../../../AbstractElements";

const PeriodicAssesment = ({ value, setOpenModal, formdata, setFormdata }) => {
    const [open, setOpen] = useState(value);
    const [type, setType] = useState();
    const [frequency, setFrequency] = useState();
    const [dataFrequency, setDataFrequency] = useState();
    const [volume, setVolume] = useState();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }

    const handlePeriodicType = (e) => {
        setType(e.target.value);
    }

    const onSubmit = () => {
        const data = {
            periodicAssesmentType: type == 1 ? 'FREQUENCY' : 'VOLUME',
            frequency,
            dataCollectionFrequency: dataFrequency,
            volume
        }
        setFormdata(prev => ({ ...prev, ...data }))
        onClose();
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size={'md'}>
                <ModalHeader className="d-flex">
                    <H3 attrH3={{ className: 'modal-title-color' }}>Periodic Assessment</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md='12'>
                            <FormGroup>
                                <label>Periodic Assesment Type <span className="text-danger">*</span></label>
                                <select className="form-select"
                                    {...register('periodicAssesmentType', { required: true })}
                                    onChange={(e) => handlePeriodicType(e)}
                                >
                                    <option value={''}>-Select Type-</option>
                                    <option value={1}>Frequency</option>
                                    <option value={2}>Volume</option>
                                </select>
                                <span className="text-danger">{errors.periodicAssesmentType && errors.periodicAssesmentType.type === 'required'
                                    && 'Select Periodic Assesment Type'}</span>
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        type == 1 && <div>
                            <Row>
                                <Col md='12'>
                                    <FormGroup>
                                        <label>Frequency <span className="text-danger">*</span></label>
                                        <select className="form-select" name="frequency"
                                            {...register('frequency', { required: true })}
                                            onChange={(e) => setFrequency(e.target.value)}>
                                            <option value={''}>-Select Frequency-</option>
                                            <option value={'MONTHLY'}>Monthly</option>
                                            <option value={'QUARTERLY'}>Quarterly</option>
                                            <option value={'HALFYEARLY'}>Half yearly</option>
                                            <option value={'YEARLY'}>Yearly</option>
                                        </select>
                                        <span className="text-danger">{errors.frequency && errors.frequency.type === 'required'
                                            && 'Select Frequency'}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='12'>
                                    <FormGroup>
                                        <label>Data Collection Frequency <span className="text-danger">*</span></label>
                                        <select className="form-select" name="dataCollectionFrequency"
                                            {...register('dataCollectionFrequency', { required: true })}
                                            onChange={(e) => setDataFrequency(e.target.value)}>
                                            <option value={''}>-Select Data Collection Frequency-</option>
                                            <option value={'DAILY'}>Daily</option>
                                            <option value={'MONTHLY'}>Monthly</option>
                                            <option value={'QUARTERLY'}>Quarterly</option>
                                            <option value={'HALFYEARLY'}>Half yearly</option>
                                            <option value={'YEARLY'}>Yearly</option>
                                        </select>
                                        <span className="text-danger">{errors.dataCollectionFrequency && errors.dataCollectionFrequency.type === 'required'
                                            && 'Select Frequency'}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    }
                    {
                        type == 2 && <Row>
                            <Col md='12' >
                                <FormGroup>
                                    <label>Volume(MTCO<sub>2</sub>e) <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' type="text"
                                        name='Volume' {...register('volume', { required: true, pattern: /^[0-9\b]+$/ })} onChange={(e) => setVolume(e.target.value)} />
                                    <span className="text-danger">{errors.volume && errors.volume.type === 'required'
                                        && 'Volume Required'}</span>
                                    <span className="text-danger">{errors.volume && errors.volume.type === 'pattern'
                                        && 'Volume should be numeric'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md='3' >
                            <Button onClick={onSubmit} color="primary">Save</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default PeriodicAssesment;