import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { H3, H4 } from "../../../AbstractElements";
import { descriptionData, keyData, priceData, imgData, unSdgs } from "../BuyOffsets/DummyData";


const ProjectCard = ({ data, countryCode }) => {

    return (
        <Fragment>

            <Card className="buy-offset-shadow">
                <CardBody className="buyoffset-card-padding p-0">
                    {
                        data?.image && <div className="">
                            <img className="card-img" src={`data:image/jpeg;base64,${data?.image}`} />
                        </div>
                    }
                    <div className="p-15">
                        <div className="card-title">{data?.name}</div>
                        <p className="mtop-10 card-certify">Certified by {data?.assessor_org_name}</p>
                        <p className="mtop-10 card-certify">UN SDGs: {unSdgs[data?.subCategoryItemName]}</p>
                        <p className="card-certify">
                            <i class="fa-solid fa-hand-holding-dollar fa-beat fa-2xl" style={{color: '#2b7704'}}></i> 
                            <span className="ml-1 span-text">994567 tons</span> 
                        </p>
                        
                        <div className="mtop-1 card-para-overflow">
                            <p className="card-para">{descriptionData[data?.subCategoryItemName]}</p>
                        </div>
                        <div className="mtop-1 d-flex">
                            <div className="price-div">
                                <div className="card-price">{priceData[data?.subCategoryItemName]} $/t</div>
                            </div>
                            <div className="country-div ml-1">
                                <div className="card-country">{countryCode}</div>
                            </div>
                            <div className="project-type-div ml-1">
                                <div className="card-project-type">{keyData[data?.subCategoryItemName]}</div>
                            </div>
                        </div>
                        <div className="mtop-1">
                            
                        </div>
                    </div>

                </CardBody>
            </Card>


        </Fragment>
    )
}

export default ProjectCard;