import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, FormGroup, Label, Button } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import moment from "moment";

const DataUploadModal = ({ value, setOpenModal, setUpload, template }) => {
    const [open, setOpen] = useState(value);
    const [uploadPeriod, setUploadPeriod] = useState('YEAR');
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(new Date());
    const [month, setMonth] = useState('');
    const [quarter, setQuarter] = useState('');
    const [locationData, setLocationData] = useState([]);
    const [location, setLocation] = useState();
    const [processStatus, setProcessStatus] = useState(false);
    const [uploadFileType, setUploadFileType] = useState('EMISSION');

    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    const getVersionFromFileName = (fileName) => {
        const regex = /_V_(\d+(\.\d+)+)/; // Regular expression to match version in the format "_V_X.X.X"
        const match = fileName.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return null; // If no version found, return null
        }
    }

    const getFileNameWithoutVersion = (fileName) => {
        const regex = /^(.*?)_V_\d+(\.\d+)+/; // Regular expression to match everything before "_V_X.X" and optional space and parentheses
        const match = fileName.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return null; // If no match found, return null
        }
    }

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const handleChange = async (files) => {
        if (uploadPeriod === 'MONTH' && !month) {
            toast.error('Please select Month..')
            return
        } else if (uploadPeriod === 'QUARTER' && !quarter) {
            toast.error('Please select Quarter..')
            return
        } else if (!uploadPeriod) {
            toast.error('Please select Period..')
            return
        } else if(getFileNameWithoutVersion(files[0].file.name) !== getFileNameWithoutVersion(template?.templateName)) {
            toast.error('Please Upload Valid Template file..')
            return
        } else if(Number(getVersionFromFileName(files[0].file.name)) !== Number(getVersionFromFileName(template?.templateName))) {
            toast.error('Please get latest version of GHG template')
            return
        } else {    
            setLoading(true)
            const authToken = sessionStorage.getItem('accessToken')
            let data = new FormData();
            data.append('file', files[0]?.file);
            data.append('isEmissionFileUpload', true);
            data.append('upload_period', uploadPeriod);
            data.append('year', new Date(year).getFullYear());
            data.append('month', uploadPeriod === 'MONTH' ? moment(month).format('MMM') : '');
            data.append('quarter', uploadPeriod === 'QUARTER' ? moment(quarter).quarter() : '');
            data.append('locationId', location);
            data.append('uploadFileType', uploadFileType);
            const options = {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${authToken}`
                },
                body: data
            }
            await fetchWrapper(`${API_URL}/document?projectId=`, options)
                .then((res) => {
                    console.log(res)
                    if (res.ok) {
                        setUpload(true)
                        setLoading(false)
                        setMonth();
                        setQuarter();
                        toast.success('Document Uploaded successfully!')
                        onClose()
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    console.error(error)
                    if (error.status == 412) {
                        toast.error('File with the same name already exists')
                    }
                })
        }
    };

    const handleLocation = async (e) => {
        setLocation(e.target.value);
        await fetchWrapper(`${API_URL}/document/location/${e.target.value}/canUploadFile`)
            .then((res) => {

                console.log(res.json)
            }, (err) => {
                console.log(err)
                if (err.status === 403) {
                    toast.error('File Upload in Progress..')
                    onClose()
                }
            })
    }

    const loadData = async () => {
        await fetchWrapper(`${API_URL}/location`)
            .then((res) => {
                if (res.status === 200) {
                    setLocationData(res.json)
                }
            }, (error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        loadData();
    }, [])
    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color mb-0' }}>GHG Emission Data Upload</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='4'>
                            <FormGroup>
                                <Label>Location</Label>
                                <select className='form-select'
                                    {...register('location', { required: true })}
                                    onChange={(e) => handleLocation(e)}
                                >
                                    <option value=''>Select Location</option>
                                    {
                                        locationData?.map((item) => <option value={item.locationId}>{item?.locationName}</option>)
                                    }
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='4'>
                            <FormGroup>
                                <Label>Select Year</Label>
                                <DatePicker
                                    className="datepicker-here form-control"
                                    selected={year}
                                    onChange={(e) => setYear(e)}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    maxDate={new Date()}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='4'>
                            <FormGroup>
                                <Label>Period</Label>
                                <select className='form-select' value={uploadPeriod}
                                    {...register('upload_period', { required: true })}
                                    onChange={(e) => setUploadPeriod(e.target.value)}
                                >
                                    <option value=''>Select Period</option>
                                    <option value="MONTH">MONTH</option>
                                    <option value="QUARTER">QUARTER</option>
                                    <option value="YEAR">YEAR</option>
                                </select>
                            </FormGroup>
                        </Col>
                        {
                            uploadPeriod === 'MONTH' && <Col lg='4'>
                                <FormGroup>
                                    <Label>Select Month</Label>
                                    <DatePicker
                                        className="datepicker-here form-control"
                                        selected={month}
                                        onChange={(e) => setMonth(e)}
                                        showMonthYearPicker
                                        dateFormat="MMM"
                                        placeholderText="-Month-"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            uploadPeriod === 'QUARTER' && <Col lg='4'>
                                <FormGroup>
                                    <Label>Select Quarter</Label>
                                    <DatePicker
                                        className="datepicker-here form-control"
                                        selected={quarter}
                                        onChange={(e) => setQuarter(e)}
                                        showQuarterYearPicker
                                        dateFormat="QQQ"
                                        placeholderText="-Quarter-"
                                    />
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col lg='12'>
                            {loading ? <div className="text-center loading-text">Loading....</div> : <></>}
                            <div style={{ opacity: loading ? '0.1' : '1' }}>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={async () => {
                                        const result = await trigger();
                                    }}
                                    onSubmit={handleChange}
                                    accept={'.xlsm'}
                                    maxFiles={1}
                                    multiple={false}
                                    maxSizeBytes={4194304}
                                    inputContent="Drag and Drop File"
                                    submitButtonDisabled={processStatus}
                                />
                            </div>
                            <p className='no-wrap mtop-1'>[Note:Supported formats xlsx,xls,PDF,Doc,jpeg,KML and file size should be 4MB per file]</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='3'>
                            <Button color="secondary" className="mtop-1" onClick={onClose}>Cancel</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default DataUploadModal;