import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import LoginForm from './LoginForm';
import img from '../../../assets/images/banner/signup-banner.png'
import { API_URL } from '../../Config/Config';
import fetchWrapper from '../../api/fetchWrapper';

const LoginComponent = () => {
    const [isServerDown, setServerDown] = useState(false);
    const [maintenanceMsg, setMsg] = useState();

    let temp = {
        "statusCode": 503,
        "body": "{\"message\":\"Our system is undergoing upgrade to help us serve you better. Please come back later\"}",
        "headers": {
            "Content-Type": "application/json"
        }
    }
    console.log(temp?.body?.split(':')[1].split('"')[1])
    const getAppHealth = async() => {
        const options = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": '*'
            }
        }
        // await fetchWrapper(`${API_URL}/apphealth`, options)
        // .then(res => {
        //     console.log(res)
        // })
        // .catch((err) => {
            
        //     if(err?.json?.statusCode === 503) {
        //         setServerDown(true)
                
        //     }
        //     console.log(err)
            
        // })
    }

    useEffect(() => {
        // getAppHealth()
    }, [])

    return (
        <Fragment>
            <Container fluid={true} className="p-0">
                <Row className="m-0">
                    <Col xl='12 p-0'>
                        <div className="login-card">
                            <LoginForm />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default LoginComponent;