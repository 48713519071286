import React, { Fragment, useEffect } from 'react';
import Leftbar from './LeftBar';
import Rightbar from './RightBar';
import Searchbar from './Search';
import { Row } from 'reactstrap';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { logoutHelper, updatedAccessToken } from '../../api/logout_helper';

const Header = () => {
    const expiresIn = sessionStorage.getItem('expiresIn');
    const location = useLocation();
    const onIdle = () => {
        logoutHelper();
    }

    const onIdleRefer = () => {
        updatedAccessToken();
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: 1200_000, // 20 mins idle time
        throttle: 500
    })

    const { getIdleTime } = useIdleTimer({
        onIdleRefer,
        timeout: `${Number(expiresIn)}_000`,
        throttle: 500
    })

    useEffect(() => {
        console.log('Location changed');
      }, [location]);

    useEffect(() => {
        const interval = setInterval(() => {
            getRemainingTime();
            getIdleTime();
        }, 500);

        return () => {
            clearInterval(interval)
        }
    })
    
    return (
        <Fragment>
            <Row className="header-wrapper m-0">
                <Leftbar />
                <Searchbar />
                <Rightbar />
            </Row>
        </Fragment >
    );
};
export default Header;