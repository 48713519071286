import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Table, Button, Input, Modal, ModalBody, ModalHeader, Form, FormGroup, Label } from 'reactstrap';
import { Breadcrumbs, H3 } from "../../../AbstractElements";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { currencyFormatter } from '../../api/helper'
import { toast } from 'react-toastify';
import { X } from "react-feather";
import DatePicker from 'react-datepicker';
import moment from "moment";

const ExchangeOrderDetails = ({ value, setOpenModal, orderData }) => {
    const [open, setOpen] = useState(value);
    const [execution, setExecution] = useState()
    const [settlement, setSettlement] = useState()
    const [settlementAmount, setSettleAmount] = useState(0);
    const [loading, setLoading] = useState();
    const [price, setPrice] = useState(0);
    const [commission, setCommission] = useState(0);
    const { register, formState: { errors }, handleSubmit, control, setValue } = useForm();
    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }

    const handleExecution = date => {
        setExecution(date)
    }

    const handleSettlementDate = date => {
        setSettlement(date)
    }

    const handleAmount = (priceValue, commissionValue) => {
        let amount = ((Number(priceValue) * Number(orderData?.quantity)) - Number(commissionValue)) * Number(orderData?.splitValue) ;
        setSettleAmount(amount)
        setValue('finalSettlementAmount', amount)
    }

    const handlePrice = (value) => {
        setPrice(value)
        handleAmount(value, commission)
    }

    const handleCommission = (value) => {
        setCommission(value)
        handleAmount(price, value);
    }

    const onSubmit = async (data) => {
        data.orderId = orderData?.orderId;
        data.quantity = orderData?.quantity;
        data.currency = orderData?.currency;
        const options = {
            method: "POST",
            body: data
        }
        await fetchWrapper(`${API_URL}/orders/fillOrder`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    toast.success("Order Filled Successfully!")
                    setOpen(false)
                    setOpenModal(false)
                }
            }, (err) => {
                console.log(err, 456654)
                if (err.status === 200) {
                    toast.success("Order Filled Successfully!")
                    setOpen(false)
                    setOpenModal(false)
                }
            })
    }

    return (
        <Fragment>
            <Modal isOpen={value} centered={true} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Fill Order </H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <Label>Order ID</Label>
                                        <input
                                            className="form-control"
                                            name="orderId"
                                            type="text"
                                            required
                                            defaultValue={orderData?.orderId}
                                            disabled={true}
                                            {...register('orderId')}
                                        />
                                        <span className="text-danger">{errors.orderId && 'Order ID is Required'}</span>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <Label>External Trade ID <span className='required'>*</span></Label>
                                        <input
                                            className="form-control"
                                            name="externalTradeId"
                                            type="text"
                                            {...register('externalTradeId', { required: true })}
                                        />
                                        <span className="text-danger">{errors.externalTradeId && 'External Trade ID is Required'}</span>
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <Label>Quantity</Label>
                                        <input
                                            className="form-control"
                                            name="quantity"
                                            type="text"
                                            defaultValue={orderData?.quantity}
                                            disabled={true}
                                            {...register('quantity')}
                                        />
                                        <span className="text-danger">{errors.quantity && 'Quantity is Required'}</span>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <Label>Price <span className='required'>*</span></Label>
                                        <input
                                            className="form-control"
                                            name="price"
                                            type="text"
                                            {...register('price', { required: true, pattern: /^[0-9\b]+$/ })}
                                            onChange={(e)=>handlePrice(e.target.value)}
                                        />
                                        <span className="text-danger">{errors?.price?.type == 'required' && 'Price is Required'}</span>
                                        <span className="text-danger">{errors?.price?.type == 'pattern' && 'Price should be Numeric'}</span>
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <Label>Unit Type</Label>
                                        <input
                                            className="form-control"
                                            name="unitType"
                                            type="text"
                                            required
                                            defaultValue={orderData?.currency}
                                            disabled={true}
                                            {...register('unitType')}
                                        />
                                        <span className="text-danger">{errors.unitType && 'Unit Type is Required'}</span>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <Label>Commission <span className='required'>*</span></Label>
                                        <input
                                            className="form-control"
                                            name="commission"
                                            type="text"
                                            {...register('commission', { required: true, pattern: /^(\d{1,5}|\d{0,5}\.\d{1,2})$/ })}
                                            onChange={(e)=>handleCommission(e.target.value)}
                                        />
                                        <span className="text-danger">{errors?.commission?.type == 'required' && 'Commission is Required'}</span>
                                        <span className="text-danger">{errors?.commission?.type == 'pattern' && 'Commission should be Numeric'}</span>
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <Label>Exchange <span className='required'>*</span></Label>
                                        <input
                                            className="form-control"
                                            name="exchange"
                                            type="text"
                                            {...register('exchange', { required: true })}
                                        />
                                        <span className="text-danger">{errors.exchange && 'Exchange is Required'}</span>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <Label>Final Settlement Amount <span className='required'>*</span></Label>
                                        <input
                                            className="form-control"
                                            name="finalSettlementAmount"
                                            type="text"
                                            disabled={true}
                                            value={settlementAmount}
                                            {...register('finalSettlementAmount', { required: true, pattern: /^[0-9\b]+$/ })}
                                        />
                                        <span className="text-danger">{errors?.finalSettlementAmount?.type == 'required' && 'Final Settlement Amount is Required'}</span>
                                        <span className="text-danger">{errors?.finalSettlementAmount?.type == 'pattern' && 'Final Settlement Amount should be Numeric'}</span>
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <FormGroup className="mb-3">
                                    <Label>Execution Date <span className='required'>*</span></Label>
                                    <Controller
                                        name='executionTimeout'
                                        control={control}
                                        rules={{ required: true }}
                                        onChange={handleExecution}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DatePicker
                                                    className="datepicker-here form-control"
                                                    selected={value}
                                                    onChange={e => {
                                                        handleExecution(e);
                                                        onChange(e);
                                                    }}
                                                    showMonthDropdown
                                                    dateFormatCalendar="MMMM"
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    dropdownMode="select"
                                                    dateFormat="dd MMM, yyyy"
                                                    minDate={new Date(moment(new Date()).add(1, 'day'))}
                                                />
                                            )
                                        }}
                                    />
                                    <span className='text-danger'>{errors.executionTimeout && errors.executionTimeout.type === 'required' && 'Execution Date is required'}</span>
                                </FormGroup>
                            </Col>
                            <Col lg={6}>
                                <FormGroup className="mb-3">
                                    <Label>Settlement Date <span className='required'>*</span></Label>
                                    <Controller
                                        name='settlementDate'
                                        control={control}
                                        rules={{ required: true }}
                                        onChange={handleSettlementDate}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DatePicker
                                                    className="datepicker-here form-control"
                                                    selected={value}
                                                    onChange={e => {
                                                        handleSettlementDate(e);
                                                        onChange(e);
                                                    }}
                                                    showMonthDropdown
                                                    dateFormatCalendar="MMMM"
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    dropdownMode="select"
                                                    dateFormat="dd MMM, yyyy"
                                                    minDate={new Date(moment(new Date()).add(1, 'day'))}
                                                />
                                            )
                                        }}
                                    />
                                    <span className='text-danger'>{errors.settlementDate && errors.settlementDate.type === 'required' && 'Settlement Date is required'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <div className="d-flex " style={{ marginTop: "25px" }}>
                                {
                                    loading ? <ButtonLoader loaderText={`Submitting`} color="primary" /> : <Button color="primary" type="submit" >Submit</Button>
                                }
                                <Button color='secondary'  className="ml-1" onClick={onClose}> Cancel</Button>
                            </div>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ExchangeOrderDetails;