import { PROJECT_DETAILS, METHODOLOGY, DOCUMENTS_DETAILS, PROPONENT_DETAILS } from "../Constant"

const projectDetails = (state = {}, action) => {
    switch (action.type) {
        case PROJECT_DETAILS:
            return {
                ...state,
                detailsData: action.payload
            }
        default: {
            return state;
        }
    }
}

export default projectDetails;