import React, { Fragment, useState } from "react";
import { CardBody, CardFooter, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3, H4 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";

const DeclineModal = ({ value, setOpenModal, projectId, type }) => {
    const [open, setOpen] = useState(value);
    const [reason, setReason] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onClose = async () => {
        try {
            setLoading(true)
            const options = {
                method: 'PATCH',
                body: {
                    projectId: projectId,
                    description: reason
                }
            }

            await fetchWrapper(`${API_URL}/projects/${projectId}/return`, options)
                .then((res) => {
                    console.log(res)
                    if (res.status === 200) {
                        setLoading(false)
                        setOpen(false);
                        setOpenModal(false);
                        toast.warning('Project Rejected Successfully!')
                        navigate(`${process.env.PUBLIC_URL}/${type}/projects`)
                    }
                })
        } catch (error) {
            if (error.status === 200) {
                setLoading(false)
                setOpen(false);
                setOpenModal(false);
                toast.warning('Project Rejected Successfully!')
                navigate(`${process.env.PUBLIC_URL}/${type}/projects`)
            }
            console.log(error)
        }
    }


    const handleClose = () => {
        setOpen(false);
        setOpenModal(false);
    }


    return (
        <Fragment>
            <Modal centered={true} isOpen={open}>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Decline Project</H3>
                    <X className="x-button" onClick={handleClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md='12'>
                            <Card>
                                <CardBody className="bs-none">
                                    <H4>Reason for Decline</H4>
                                    <textarea className="form-control" rows="5" cols="5"
                                        placeholder="Enter your Reson.."
                                        onChange={(e) => setReason(e.target.value)}
                                    ></textarea>
                                    <div className="d-flex">
                                        {
                                            loading ? <ButtonLoader loaderText={'Submitting'} color="primary" /> : <Button color="primary" onClick={onClose}>Submit</Button>
                                        }
                                        <Button color="secondary" className="ml-1" onClick={handleClose}>Cancel</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default DeclineModal;