import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";

const RegistrySubstates = ({value, setOpenModal, registryType, projectId}) => {
    const [open, setOpen] = useState(value);
    const [substates, setSubstates] = useState([]);
    const [selectedState, setSelectedState] = useState();
    const [loading, setLoading] = useState();

    const loadStates = async () => {
        await fetchWrapper(`${API_URL}/registrytypes/${registryType}/substates`)
        .then((res) => {
            setSubstates(res.json)
        }, (err) => console.log(err))
    }

    useEffect(()=>{
        loadStates();
    }, [])

    const onClose = () => {
        setOpen(false);
        setOpenModal(false);
    }

    const handleSubmit = async () => {
        setLoading(true);
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/projects/${projectId}/registry/${selectedState}`, options)
            .then((res)=>{
                if(res.status === 200) {
                    setLoading(false);
                    setOpen(false);
                    setOpenModal(false);
                    toast.success('Registry status updated Successfully!')
                } else {
                    setLoading(false);
                    setOpen(false);
                    setOpenModal(false);
                    toast.error('Registry status cannot be updated')
                }
            }, (err)=>console.log(err))
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='md'>
                <ModalBody>
                    <Row>
                        <Col md='12'>
                            <Card>
                                <CardBody className="bs-none">
                                    <FormGroup>
                                        <Label>Change Status</Label>
                                        <select className="form-select" name="registrySubstates" 
                                            onChange={(e)=>setSelectedState(e.target.value)}
                                        >
                                            <option value={''}>-Please Select Substate-</option>
                                            {
                                                substates?.length > 0 && substates.map((item)=>{
                                                    return(
                                                        <option value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </FormGroup>
                                    <Row>
                                        <Col lg='6' className="d-flex">
                                            {
                                                loading ? <ButtonLoader loaderText={'Submitting'} color="primary" /> : 
                                                <Button color="primary" onClick={handleSubmit}>Save</Button>
                                            }
                                            <Button color='secondary' className="ml-1"  onClick={onClose}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default RegistrySubstates;