import React, { Fragment, useState, useEffect } from "react";
// import { dummytabledata } from "./AsrUserlist";
import UserForm from "../../Common/Component/UserForm";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";

const EditAsrUser = () => {
    const [ data, setData ] = useState();
    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);

    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/users/${id}`, options)
        .then((res)=> {
            console.log(res)
            setData(res.json)
        })
        .catch((error) => {
            console.debug(error)
        })
    }
    return (
        <Fragment>
            <UserForm action={'Edit'} title={'Edit Assessor'} navigateUrl={`${process.env.PUBLIC_URL}/asr-admin/user-list`} preData={data} />
        </Fragment>
    )
}

export default EditAsrUser;