import React, { Fragment, useEffect, useState } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate, useParams } from "react-router";
import { Row, Col, CardBody, Card, Button } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import LayoutLoader from "../LayoutLoader/LayoutLoader";
import { imgData, descriptionData, priceData, image } from "./DummyData";
import MapComponent from "../MarkerMap/MapComponent";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_DETAILS } from "../../Constant";
import { defaultUserType, goalDescriptions, goalsObject } from "../../api/helper";

const OffsetProjectDetails = () => {
    const [projectData, setProjectData] = useState();
    const [markPosition, setMarkPosition] = useState({
        lat: 37.7749, // Set your initial latitude
        lng: -122.4194, // Set your initial longitude
    })
    const navigate = useNavigate();
    const projectId = useParams()?.id;
    const dispatch = useDispatch();
    const userType = useSelector(state => state.user?.userType);
    const [goalList, setGoalList] = useState([]);
    const [goalDesc, setGoalDesc] = useState();

    const mapStyles = {
        width: '95%',
        height: '300px'
    }

    const getProjectData = async () => {
        await fetchWrapper(`${API_URL}/projects/${projectId}`)
            .then((res) => {
                setProjectData(res.json)
                let goals = res?.json?.sustainableDevelopmentGoals?.split(',');
                setGoalList(goals);
            }, (err) => console.log(err))
    }

    const getGeoCoordinates = async () => {
        const options = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }
        await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${Number(projectData?.zip_code)}&key=957546b57ea94219afd1f70a4b7a6c50`, options)
            .then((res) => res.json())
            .then((response) => {
                let geoData = response?.results[0]?.geometry;
                setMarkPosition({
                    lat: geoData?.lat,
                    lng: geoData?.lng
                })
                console.log(geoData)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    
   
    useEffect(() => {
        getProjectData()
        getGeoCoordinates()
    }, [])

    const placeOrder = () => {
        dispatch({
            type: PROJECT_DETAILS,
            payload: projectData
        });
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/offset-order-review/${projectId}`);
    }
    
    return (
        <Fragment>
            <LayoutLoader />
            <Row>
                <Col>
                    <Card className="mtop-1">
                        <CardBody className="offset-details offset-card-bg">
                            <H3>{`Project Details - ${projectData?.name}`}</H3>
                            <div className="mtop-1 d-flex" >
                                <div className="project-type-details">
                                    <div className="project-type">{`${projectData?.city}, ${projectData?.country}`}</div>
                                </div>
                                <div className="project-type-details ml-1">
                                    <div className="project-type">{projectData?.subCategoryItemName}</div>
                                </div>
                            </div>
                            <Row>
                                <Col lg='7'>
                                    {
                                        projectData?.image && <div className="mtop-1">
                                            <img src={`data:image/jpeg;base64,${projectData?.image}`} className="br-10" height={300} width={650} />
                                        </div>
                                    }
                                    <div className="mtop-1">
                                        <p className="project-desc">{descriptionData[projectData?.subCategoryItemName]}</p>
                                    </div>
                                    <div className="mtop-1">
                                        <div className="card-title">Location</div>
                                        <div>
                                            <MapComponent position={markPosition} setMarker={setMarkPosition} styles={mapStyles} zipcode={projectData?.zip_code} />
                                        </div>

                                    </div>
                                </Col>
                                <Col lg='5'>
                                    <div className="mtop-1">
                                        <div className="border-1 br-10">
                                            <div className="card-title ml-1">Inventory</div>
                                            <div className="line-div"></div>
                                            <div className="d-flex mtop-1 mb1 justify-between">
                                                <div className="w-17">
                                                    <p className="price-details ml-1 mb-0">2020</p>
                                                    <p className="available-style ml-1">10818 tons available</p>
                                                </div>
                                                <div>
                                                    <p className="price-details ml-1 mb-1 mr-1">{priceData[projectData?.subCategoryItemName]}</p>
                                                </div>
                                            </div>
                                            <div className="mtop-1">
                                                <p className="f-11 mb-0 ml-1">You'll select your vintage and quantities on the next step</p>
                                                <Button color="primary" className="order-button" onClick={placeOrder}>Order</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mtop-2">
                                        <div className="card-title">UN Sustainable Development Goals</div>
                                        <div>
                                            {
                                                goalList?.map((item) => {
                                                    return (
                                                        <div className="mtop-1 goal-item-card">
                                                            <div className="d-flex">
                                                                <div className="goal-box">{item}</div> 
                                                                <div className="goal-name">{goalDescriptions[item]}</div>
                                                            </div>
                                                            <div className="mtop-2">{goalsObject[item]}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>
            </Row>

        </Fragment>
    )
}

export default OffsetProjectDetails;