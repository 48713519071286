import React, { useEffect, useState } from 'react'
import Routers from './Route';
import AnimationThemeProvider from './Common/_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './Common/_helper/Customizer/CustomizerProvider';

const App = () => {
  return (
    <AnimationThemeProvider>
      <CustomizerProvider>
          <Routers />
      </CustomizerProvider >
    </AnimationThemeProvider >
  )
};

export default App;
