import React, { Fragment, useEffect, useState, useRef } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { Plus, Trash2, XCircle } from "react-feather";
import { toast } from "react-toastify";
import { uuidGenerator } from "./ghgHelper";
import { useSelector } from "react-redux";
import moment from "moment";
import { alertFunction, notifyFunction, storeData, deleteCacheData, getCacheData } from "./ghgHelper";


let error = false;
const Electricity = ({ setSteps, preData, setElectricity, electricity, timeStamp }) => {
    const [data, setData] = useState([...Array(10)].map((_, index) => ({ id: index + 1, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false })));
    const [noOfRow, setNoOfRow] = useState(1);
    const [loading, setLoading] = useState(false);
    const [rowIndex, setRowIndex] = useState([]);
    const electricityRef = useRef();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState()
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }

    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
            let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({ id: index + 1, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false }))];
            setData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        if (electricity?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateTable(electricity)
        } else if(electricity?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if(electricity?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, electricity, populateTable)
            } else { //saved is latest
                notifyFunction(electricity, populateTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        loadData(cacheData?.energyData, cacheData?.modifiedTs)
    }

    useEffect(() => {
        getCache()
    }, [])

    const handleClear = async(params) => {
        const updatedRowData = data.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                : ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        const tempData = {
            ...cacheData,
            energyData: updatedRowData?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await deleteCacheData(keyObject, tempData)
        setData(updatedRowData);
    };

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                || params.data.hasOwnProperty('sourceArea')
                || params.data.hasOwnProperty('electricityPurchased')
                || params.data.hasOwnProperty('co2Factor')
                || params.data.hasOwnProperty('ch4Factor')
                || params.data.hasOwnProperty('n2oFactor')) {
                    params.data.sourceId = `EC-${params.node.rowIndex + 1}`;
                    return params.data.sourceId;
                }
            },
            filter: false,
            flex: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: true,
            filter: false,
            flex: 3,
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        {
            headerName: 'Source Area (sq ft)',
            field: 'sourceArea',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.sourceArea ? thousandSeparator(params?.data?.sourceArea?.toFixed(2)) : ''}</div>;
            },
            filter: false,
            editable: true,
            flex: 1.5,
        },
        {
            headerName: 'Electricity Purchased (kWh)',
            field: 'electricityPurchased',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.electricityPurchased ? thousandSeparator(params?.data?.electricityPurchased?.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
        },
        {
            headerName: 'Market Based',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'CO\u2082 Emission Factors (kg/kWh)',
                    field: 'co2Factor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.co2Factor ? thousandSeparator(params?.data?.co2Factor?.toFixed(2)) : ''}</div>;
                    },
                    flex: 2,
                    editable: true,
                    headerClass: 'group-header-class',
                },
                {
                    headerName: 'CH\u2084 Emission Factors (g/kWh)',
                    field: 'ch4Factor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.ch4Factor ? thousandSeparator(params?.data?.ch4Factor?.toFixed(2)) : ''}</div>;
                    },
                    flex: 2,
                    editable: true,
                    headerClass: 'group-header-class',
                },
                {
                    headerName: 'N\u2082O Emission Factors (g/kWh)',
                    field: 'n2oFactor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.n2oFactor ? thousandSeparator(params?.data?.n2oFactor?.toFixed(2)) : ''}</div>;
                    },
                    flex: 2,
                    editable: true,
                    headerClass: 'group-header-class',
                },
            ]
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    params.data.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const addingRows = (val) => {
        setNoOfRow(val)
    }

    const addRows = () => {
        const rowData = [];
        for (let i = 1; i <= noOfRow; i++) {
            rowData.push({ id: data?.length + i, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false })
        }
        setData([...data, ...rowData]);
    }

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    };

    const getGridData = () => {
        if (electricityRef.current) {
            const gridApi = electricityRef.current.api;
            const tableState = data
                .filter((rowNode) => rowNode.sourceId)
                .map((rowNode, index) => {
                    if (rowNode.sourceArea && 
                        rowNode.electricityPurchased && rowNode.sourceDescription) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            sourceArea: Number(rowNode.sourceArea),
                            electricityPurchased: parseFloat(rowNode.electricityPurchased),
                            co2Factor: rowNode.co2Factor ? parseFloat(rowNode.co2Factor) : null,
                            ch4Factor: rowNode.ch4Factor ? parseFloat(rowNode.ch4Factor) : null,
                            n2oFactor: rowNode.n2oFactor ? parseFloat(rowNode.n2oFactor) : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(index + 1);
                        return {}
                    }
                });
            if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                error = true;
            } else {
                error = false
            }
            return tableState.filter(ele => Object.keys(ele)?.length > 0);
        }
        return [];
    }

    const onSubmit = async () => {
        const gridData = getGridData();
        if (error === true) {
            toast.error(`Please fill missing values for row ${[...new Set(rowIndex)].join(',')}`)
            return
        } else {
            setElectricity(gridData)
            setSteps(0)
        }
    }

    const createCache = async (data) => {
        const tempData = {
            ...cacheData,
            energyData: data?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = (event) => {
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === true || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === true) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        createCache(data?.filter(ele => ele?.sourceId))
    }
    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Total Amount of Electricity Purchased</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            
            {deleteLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div className="mtop-1 ag-group-header" style={{ opacity: deleteLoading ? '0.2' : '1' }}>
                <div className="electricity-table">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={data?.filter(ele => ele?.isDelete === false)}
                            columnData={columnData}
                            gridOptions={gridOptions}
                            agRef={electricityRef}
                            onCellValueChanged={onCellValueChanged}
                        />
                        <div className="ag-custom-footer">
                            <div className="d-flex ">
                                <button className="add-row-button" onClick={addRows}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                <input
                                    type="number"
                                    className="ml-1 input-number"
                                    value={noOfRow}
                                    onChange={(e) => addingRows(e.target.value)}
                                />
                                <h5 className="ml-1">rows</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </Fragment>
    )
}

export default Electricity;