export const ghgTopics = [
    {
        query: 'What is the GHG platform?',
        ans: 'The GHG platform is a comprehensive tool designed to help businesses and organizations track, manage, and reduce their greenhouse gas emissions.'
    },
    {
        query: 'Who can use the GHG platform?',
        ans: 'The platform is suitable for companies, non-profits, government agencies, and any organization interested in monitoring and reducing their carbon footprint.'
    },
    {
        query: 'How do I sign up for the GHG platform?',
        ans: 'You can sign up by visiting our website and clicking on the "Sign Up" button. Follow the prompts to create an account.'
    }
]

export const ghgGeneral = [
    {
        query: 'What is a carbon footprint?',
        ans: 'A carbon footprint is the total amount of GHGs emitted directly or indirectly by an individual, organization, event, or product.'
    }

]


export const GHGPlatform = [
    {
        query: 'What are GHG emissions?',
        ans: 'GHG emissions are gases released into the atmosphere that trap heat and contribute to global warming. Common GHGs include carbon dioxide (CO2), methane (CH4), and nitrous oxide (N2O).'
    },
    {
        query: 'Why are GHG emissions important?',
        ans: 'GHG emissions are important because they contribute to climate change, which affects weather patterns, sea levels, and ecosystems.'
    },
    {
        query: 'How are GHG emissions measured?',
        ans: 'GHG emissions are measured in terms of the amount of gas emitted, often in metric tons of CO2 equivalent (CO2e), which accounts for the different warming effects of each gas.'
    },
    {
        query: 'How can I reduce my GHG emissions?',
        ans: 'You can reduce emissions by using energy more efficiently, switching to renewable energy sources, reducing waste, and adopting sustainable practices in transportation and agriculture.'
    },
    {
        query: 'What is a GHG platform?',
        ans: ' A GHG platform is a tool that helps organizations track, manage, and report their greenhouse gas emissions.'
    },
    {
        query: 'Who can use the GHG platform?',
        ans: 'The platform is suitable for companies, non-profits, government agencies, and any organization interested in monitoring and reducing their carbon footprint.'
    },
    {
        query: 'Why is tracking GHG emissions important?',
        ans: 'Tracking GHG emissions helps organizations understand their environmental impact, comply with regulations, and work towards reducing their carbon footprint.'
    },
    {
        query: 'How does a GHG platform help organizations?',
        ans: 'A GHG platform helps organizations understand their environmental impact, comply with regulations, set emission reduction goals, and report on sustainability efforts.'
    },
    {
        query: 'What are the key features of a GHG platform?',
        ans: 'Key features typically include capturing data, setting emission reduction targets, developing action plans for achieving those targets, and analysing reports via dashboards.'
    },

]

export const groups = [
    {
        query: 'What is a group in the GHG platform?',
        ans: 'A group is a collection of users or departments that share common characteristics or responsibilities. Groups help in organizing data and assigning tasks efficiently.'
    },
    {
        query: 'Why should I create a group?',
        ans: 'Creating a group helps in better management and tracking of GHG emissions data.'
    },
    {
        query: 'How do I create a new group?',
        ans: "To create a new group,  click on 'Add Group,' enter the group name, and a brief description for your group."
    },
    {
        query: 'How to edit group?',
        ans: "Click on the 'Edit' button, make the necessary changes, and save."
    },
    {
        query: 'How to delete group?',
        ans: 'Click on the "Delete" button, and confirm the deletion when prompted.Once the group is deleted, verify that it has been removed from the list of groups.'
    },
    {
        query: 'Is there a limit to the number of group for creating?',
        ans: 'You can create as many groups as you need on the GHG platform, there is no limit.'
    }

]

export const locations = [
    {
        query: 'What is a location in a GHG platform?',
        ans: 'A location in a GHG platform refers to a specific physical or geographical place where greenhouse gas emissions are measured, tracked, and reported. This could be an office building, service station, retail store, warehouse, factory, or any other place where activities that produce emissions occur.'
    },
    {
        query: 'How do I create a new location in the GHG platform?',
        ans: "Go to the 'Locations' section of the platform, click on 'Add Location,' enter the required information."
    },
    {
        query: 'What information do I need to provide when creating a location?',
        ans: 'Information that are required for creating a location such as the group name (select from the dropdown list), location type (Select respective list from the dropdowns), address, location, city, zip code, country(select from the dropdowns), state(select from the dropdowns), and save the details by clicking "Add".'
    },
    {
        query: 'Can I edit a location after it has been created?',
        ans: "Click on the 'Edit' button, make the necessary changes, and save."
    },
    {
        query: 'What if I need to delete a location?',
        ans: "To delete a location, go to the 'Locations' section, select the location you wish to delete, and click the 'Delete' button. Confirm the deletion when prompted. Be cautious as this action may remove all associated emissions data."
    },
    {
        query: 'Can I track multiple locations in the GHG platform?',
        ans: `Yes, you can track multiple locations. This allows you to compare emissions data across different sites and get a comprehensive view of your organization's total emissions.`
    },
    {
        query: 'How do locations help in analysing emissions data?',
        ans: 'Locations allow you to pinpoint where emissions are highest, identify specific sources of emissions, and implement targeted strategies for reduction.'
    }

]

export const dataCapture = [
    {
        query: 'What is data capture in a GHG platform?',
        ans: 'Data capture in a GHG platform refers to the process of collecting and entering data related to greenhouse gas emissions from various activities within an organization.'
    },
    {
        query: 'Why is data capture important?',
        ans: 'Accurate data capture is essential for tracking the amount of greenhouse gases emitted, analysing trends, complying with environmental regulations, and implementing strategies to reduce emissions.'
    },
    {
        query: 'What details are covered in the Data Capture section?',
        ans: "The Data Capture section includes the location, period, year, and employee count. Additionally, it provides a summary table of the organization's emissions, which covers Scope 1, 2, and 3 categories."
    },
    {
        query: 'How should I enter the basic details on the Data Capture main page?',
        ans: 'Select the location from the dropdown, select the year from the dropdown for which you are entering the data, and enter the employee count for the selected location.'
    },
    {
        query: "Why can't I select the period from the dropdown, and why is it frozen?",
        ans: 'The period is frozen and automatically populated based on the based on the GHG Reporting Frequency provided in the customer onboarding form. '
    },
    {
        query: '',
        ans: ''
    },
    {
        query: '',
        ans: ''
    },
    {
        query: '',
        ans: ''
    },
    {
        query: '',
        ans: ''
    },
    {
        query: '',
        ans: ''
    },
    {
        query: '',
        ans: ''
    },
    {
        query: '',
        ans: ''
    },
    {
        query: '',
        ans: ''
    },
    {
        query: '',
        ans: ''
    }
]

export const carbonCredits = [
    {
        query: 'What is a carbon credit?',
        ans: 'A carbon credit is a permit that allows a company or organization to emit a certain amount of carbon dioxide or other greenhouse gases. One carbon credit equals one metric ton of CO2 or the equivalent amount of a different greenhouse gas.'
    },
    {
        query: 'What is the Green Carbon Wallet?',
        ans: 'The Green Carbon Wallet is a digital platform provided by AIROI where users can manage their carbon credits. It allows users to buy and sell  carbon credits, providing a secure and transparent way to track their carbon offsetting activities.'
    },
    {
        query: 'What types of carbon offset projects does our platform support?',
        ans: 'Our Platform supports a variety of verified carbon offset projects, including reforestation, renewable energy, energy efficiency, sustainable agriculture, Plastic waste and so on. Each project is vetted to ensure it meets rigorous environmental standards.'
    },
    {
        query: 'How are the carbon credits verified?',
        ans: 'All carbon credits available on the Green Carbon Wallet platform are verified by national and international recognized standards such as Verified Carbon Standard (VCS) , Gold Standard, Plastic Credit Exchange (PCX), Universal Carbon Registry (UCR) and so on. This ensures that the emission reductions are real, measurable, and permanent.'
    },
    {
        query: 'Can I trade carbon credits on the Green Carbon Wallet platform?',
        ans: 'Yes, the platform includes a marketplace where users can buy and sell carbon credits. This enables flexibility in managing carbon portfolios and supports a dynamic market for carbon offsets.'
    }
]

export const projects = [
    {
        query: 'How do I create a new project?',
        ans: "This process initiates a new project. To create a new project, fill in all mandatory fields required in the form. It's crucial to provide accurate details as these will be displayed on the project list section of the homepage. Once all details are entered, click submit."
    },
    {
        query: 'What can I see on the project list page?',
        ans: 'You can view the list of projects that your organization has created. This page displays columns such as project name, status, UN SDGs, project type, country, and actions.'
    },
    {
        query: 'What does the status column indicate?',
        ans: 'The status column shows the current state of your created projects. Hovering over the icon reveals whether a project is active, submitted, or in another state.'
    },
    {
        query: 'What does "project type" mean?',
        ans: 'Project type refers to the category under which the project was created. The same type name is displayed on this page. '
    },
    {
        query: 'What do UN SDGs mean?',
        ans: 'UN SDGs stand for United Nations Sustainable Development Goals. These are a set of 17 global goals adopted by all UN Member States in 2015 as part of the 2030 Agenda for Sustainable Development. The SDGs are designed to address various global challenges, including poverty, inequality, climate change, environmental degradation, peace, and justice. Each goal has specific targets to be achieved by 2030, aiming to create a more sustainable and equitable world for all. Below are the 17 goals along with their descriptions: ',
        list: [
            'No Poverty - End poverty in all its forms everywhere. ',
            'Zero Hunger - End hunger, achieve food security and improved nutrition, and promote sustainable agriculture. ',
            'Good Health and Well-being - Ensure healthy lives and promote well-being for all at all ages. ',
            'Quality Education - Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
            'Gender Equality - Achieve gender equality and empower all women and girls.',
            'Clean Water and Sanitation - Ensure availability and sustainable management of water and sanitation for all. ',
            'Affordable and Clean Energy - Ensure access to affordable, reliable, sustainable, and modern energy for all.',
            'Decent Work and Economic Growth - Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all. ',
            'Industry, Innovation, and Infrastructure - Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation. ',
            'Reduced Inequalities - Reduce inequality within and among countries',
            'Sustainable Cities and Communities - Make cities and human settlements inclusive, safe, resilient, and sustainable. ',
            'Responsible Consumption and Production - Ensure sustainable consumption and production patterns.',
            'Climate Action - Take urgent action to combat climate change and its impacts. ',
            'Life Below Water - Conserve and sustainably use the oceans, seas, and marine resources for sustainable development.',
            'Life On Land - Protect, restore, and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, halt and reverse land degradation, and halt biodiversity loss.',
            'Peace, Justice, and Strong Institutions - Promote peaceful and inclusive societies for sustainable development, provide access to justice for all, and build effective, accountable, and inclusive institutions at all levels. ',
            'Partnerships for the Goals - Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development. ',
        ]
    },
    {
        query: 'What does the "actions" column signify? ',
        ans: 'The actions column allows you to view the project status and edit the details of the project you created. By clicking the eye icon, you can view the profile, details, status, notes, documents, and baseline calculations specific to that project type. Clicking the pen icon allows you to edit the project details. Once the project reaches active status, you can no longer edit the project details. If the project is still in draft mode, you can delete the project before submitting.'
    }
];

export const existingProject = [
    {
        query: 'What is the purpose of the existing project feature?',
        ans: 'This feature is used to onboard existing projects onto our platform.'
    },
    {
        query: 'What details need to be filled out?',
        ans: "It's necessary to complete all mandatory fields in the form. After all sections are filled out, the user can click submit."
    }
]

export const wallet = [
    {
        query: 'What does this page tell me about?',
        ans: 'This page provides an overview of the project name, pending credit value, certified credit value, and pending sale settlement value. It helps you gain a detailed understanding of the credits associated with your projects. If you click the trading button, it will take you to the trading section where you can explore this area in depth.'
    }
];

export const trading = [
    {
        query: 'How can I trade with my credits?',
        ans: 'You can initiate trading by clicking the trading button, which opens a new window displaying the monetary value of your credits. If you wish to sell , you can proceed by clicking the appropriate button and following the subsequent steps. Fill in all the details and click submit.'
    },
    {
        query: 'What does the Orders section deal with?',
        ans: 'This section provides a view of the projects you are planning to sell. It includes order ID, order date, project name, quantity, monetary value, status, and an option to view more details.'
    },
    {
        query: 'How can I view the project status?',
        ans: 'Click on the eye icon to be taken to the next page where you can see basic details and view the transaction. Only the system admin has the authority to sell orders. If the status is "Filled," it means that your order is sold. If the status is "Open," it means that the order is processing. If the status is "New," it means that you are ready to sell the credits.'
    }
];

export const general = [
    {
        query: 'What is the Green Carbon Wallet and What Do We Provide to You?',
        ans: 'At AIROI, we have developed a ‘Green-Carbon’ Wallet, which serves as both a marketplace for buyers to purchase carbon or plastic credits to offset their emission levels, and a platform for sellers to trade their carbon or plastic credits. This Green-Carbon Wallet helps you measure your carbon footprint, calculate the number of credits or offsets you have, and connect with potential buyers to sell or offset your credits.'
    },
    {
        query: 'What can I do with the carbon credits section in this platform?',
        ans: 'In the carbon credits section, you can: ',
        list: [
            'View Project Details - Access detailed information about your carbon credit projects.',
            'Track UN SDG Goals - Identify and monitor the UN Sustainable Development Goals applicable to your projects.',
            'Monitor Project Status - Follow a step-by-step status update for each project to stay informed of their progress.',
            'Manage Carbon Credits - Check the number of carbon credits you own and their current status.'
        ]
    },
    {
        query: 'What are the features of our platform?',
        ans: 'Our platform offers a comprehensive suite of features designed to help you manage and reduce your carbon footprint: ',
        list: [
            'GHG Emission Tracking - Monitor and visualize your greenhouse gas emissions through interactive dashboards.',
            'Emission Reduction Planning - Set and manage your action plans to achieve net zero goals.',
            'Carbon Credit Management - View, track, and manage your carbon credits, including project details and status updates.',
            'Scope Applicability - Customize your data entry by selecting relevant categories to streamline your workflow.',
            ' Air Quality Index (AQI) Monitoring - Access real-time air quality data to ensure compliance with standards and identify improvement areas.',
            'UN SDG Integration - Align your projects with relevant UN Sustainable Development Goals and track their progress.'
        ]
    },
    {
        query: 'What are the benefits of the GHG section in this platform?',
        ans: 'The GHG section of our platform provides several key benefits: ',
        list: [
            'Comprehensive Tracking - Monitor your greenhouse gas emissions across various scopes and categories.',
            'Visualization Tools - Use interactive dashboards to visualize your emission data and trends.',
            'Reduction Planning - Set action plans for emission reduction and track your progress towards net zero goals.',
            'Scope Customization - Select applicable categories to streamline data entry and focus on relevant information.',
            'Set Group and Location - Organize and manage your emissions data by different groups and locations, allowing for more precise tracking and analysis.',
            'Comparing Your Actual vs Target Data - Easily compare your current emissions data against your target goals to identify areas for improvement and measure your progress towards achieving those goals.'
        ]
    },
    {
        query: 'What is the benefit of using the AQI section in our platform?',
        ans: 'The AQI section provides real-time air quality data, helping you monitor and manage air quality levels. This feature can be particularly useful for identifying areas where improvements are needed and for ensuring compliance with air quality standards. It helps you take proactive measures to improve air quality and ensure a healthier environment. '
    },
    {
        query: 'What is the benefit of using the decarbonization analysis section in our platform?',
        ans: 'The decarbonization analysis section allows you to conduct in-depth analyses to identify opportunities for reducing carbon emissions. It provides insights and recommendations for implementing effective decarbonization strategies. This helps you to not only meet regulatory requirements but also to enhance your sustainability initiatives and move closer to achieving your net zero goals.'
    }
]


const objtemp = {
    query: '',
    ans: ''
}