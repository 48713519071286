import React, { Fragment, useState, useEffect } from "react";
import AdminForm from "../../Common/Component/AdminForm";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import LayoutLoader from '../../Common/Component/LayoutLoader/LayoutLoader'

const BOEditCustomer = () => {
    const [ data, setData ] = useState();
    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);
    
    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization/${id}`, options)
        .then((res)=> {
            console.log(res)
            setData(res.json)
        })
        .catch((error) => {
            console.debug(error)
        })
    }

    return (
        <Fragment>
            <LayoutLoader />
            <AdminForm type={'customer'} action={'Edit'} title={'Edit Organization'} navigateUrl={`${process.env.PUBLIC_URL}/back-office/customer-list`} preData={data} />
        </Fragment>
    )
}

export default BOEditCustomer;