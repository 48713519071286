import React, { Fragment } from "react";
import { Card, CardBody, Col, Table } from "reactstrap";
import { H5, Image } from "../../../AbstractElements";
import { TrendingDown, TrendingUp, ShoppingCart, Zap } from 'react-feather'

const EmployeeData = [
    {
        id: 1,
        img: <ShoppingCart style={{paddingRight: '5%'}}/>,
        name: 'Silvan',
        pera: '25-10-2020',
        designation: <div style={{color: '#26A69A', display: 'flex'}}>
            <h4>184Kg</h4><TrendingDown />
        </div>,

    },
    {
        id: 2,
        img: <Zap style={{paddingRight: '5%'}} />,
        name: 'Heating',
        pera: '25-10-2020',
        designation: <div style={{color: '#F44336', display: 'flex'}}>
            <h4>184Kg</h4><TrendingDown />
        </div>, 

    },
    {
        id: 3,
        img: <ShoppingCart style={{paddingRight: '5%'}} />,
        name: 'Matas',
        pera: '25-10-2020',
        designation: <div style={{color: '#26A69A', display: 'flex'}}>
            <h4>184Kg</h4><TrendingDown />
        </div>,

    },
    {
        id: 4,
        img: <Zap style={{paddingRight: '5%'}} />,
        name: 'Trip to Jutland',
        pera: '25-10-2020',
        designation: <div style={{color: '#F44336', display: 'flex'}}>
            <h4>184Kg</h4><TrendingDown />
        </div>,

    },]

const ActivityChart = () => {
    return (
        <Fragment>
                <Card className="employee-status">
                    <h5 style={{ color: '#90A4AE', marginTop: '30px', marginLeft: '4%', display: 'flex' }}>LATEST ACTIVITY <div style={{ textAlign: 'right', paddingLeft: '40%' }}>SEE ALL</div></h5>
                    <CardBody>
                        <div className="table-responsive">
                            <Table className="table table-bordernone">
                                <tbody>
                                    {EmployeeData.map((data) => {
                                        return (
                                            <tr key={data.id} style={{display: 'flex'}}>
                                                <td>
                                                    <div className="d-flex">
                                                        {data.img}
                                                        <div className="flex-grow-1">
                                                            <H5 attrH5={{ className: 'font-primary' }} >{data.name} <span className="text-muted">{data.pera}</span></H5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{textAlign: 'left'}}>{data['designation']}</td>

                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
        </Fragment>
    );
};
export default ActivityChart;