import React, { Fragment, useState } from "react";
// import {apexSteplineChart1} from "../../Common/Data/Charts/ApexChart/index"
import {
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardHeader,
} from "reactstrap";
import Chart from "react-apexcharts";
import { H3, P } from "../../../AbstractElements";
import { RecentOrders } from "../../Constant";
import './Dashboard.css';

export const apexSteplineChart1 = {
  series: [{
    data: [17000, 20000, 16000, 14000, 16000, 13000, 15000, 17000, 15000]
  }],

  options: {
    chart: {
      type: 'line',
      height: 350,
      width: "100%",

      toolbar: {
        show: false
      }
    },
    grid: {
      show: false,

    },
    annotations: {
      yaxis: [
        {
          y: 16000,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396'
            },
            text: 'Your Goal'
          }
        }
      ]
    },
    stroke: {
      curve: 'smooth',
      colors: ['#00796B'],
      width: 2,
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: '',
      align: 'left'
    },
    colors: ["#f5f5f5"],
    markers: {
      hover: {
        sizeOffset: 4
      }
    },
    xaxis: {
      type: 'category',
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    }
  },

};

const CarbonFoot = () => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  //   seconddropdowm

  const [categories, setCategories] = useState(false)

  const open = () => setCategories(!categories)



  return (
    <Fragment>
      <Col xxl="12" lg="12" sm="12 box-col-40">
        <Card className="recent-order card_dropdown">
          <CardHeader className="pb-0">
            <div className="d-flex align-itmes-center justify-content-between">
              <H3>CARBON FOOTPRINT</H3>
              <div className="d-flex align-itmes-center" >
                <div className=" d-flex align-itmes-center" style={{ marginRight: '25px' }}>
                  <P >View :</P>
                  <Dropdown
                    className="ml-2"
                    isOpen={dropdownOpen}
                    toggle={toggle}
                  >
                    <DropdownToggle color="" caret>
                      MONTHLY
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem className="dropdown-item">YEARLY</DropdownItem>
                      <DropdownItem className="dropdown-item">WEEKLY</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className=" d-flex align-itmes-center ">
                  <P>CATEGORIES :</P>
                  <Dropdown
                    className="ml-2"
                    isOpen={categories}
                    toggle={open}
                  >
                    <DropdownToggle color="" caret>
                      ALL
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem className="dropdown-item">HVAC</DropdownItem>
                      <DropdownItem className="dropdown-item">Electricity</DropdownItem>
                      <DropdownItem className="dropdown-item">Travel</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody className="pb-0">
            <div className="yearly-view" id="yearly-view" style={{ width: '100%' }}>
              <Chart options={apexSteplineChart1.options} series={apexSteplineChart1.series} type="line" height={200} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default CarbonFoot;
