import React, { Fragment, useState, useEffect } from "react";
import { CardBody } from "reactstrap";
import {
    Row,
    Card,
    Col,
    Button
} from "reactstrap";
import { Breadcrumbs, H3 } from "../../../AbstractElements";
import DualListBox from "react-dual-listbox";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { defaultUserType } from '../../api/helper'

const ASMMapping = () => {
    const [selected, setSelected] = useState([]);
    const [data,setData] = useState();
    const assetManagerId = window.location.pathname.split('/')[3];
    const navigate = useNavigate();
    var options;
    const loadData = async () => {
        await fetchWrapper(`${API_URL}/organization/assetmanager/${assetManagerId}`)
        .then(res => {
            console.log(res);
            if(res.ok) {
                const resData = res.json;
                if(resData?.mappedAssetOwners?.length > 0) {
                    const selectedValues = resData?.mappedAssetOwners?.map(i => i.assetOwnerId)
                    setSelected([...selectedValues])
                }
                setData([...resData?.mappedAssetOwners, ...resData?.unMappedAssetOwners])
            }
        })
        .catch((err)=> console.log(err))
    }

    if(data && data.length > 0) {
        options = data?.map(i => {
            return {
                value: i.assetOwnerId,
                label: i.name
            }
        })
    }
    
    useEffect(() => {
        loadData()
    }, [])

    const handleSubmit = async () => {
        const reqOptions = {
            method: 'PUT',
            body: {
                "assetOwnerIds": [...selected]
            }
        }
        await fetchWrapper(`${API_URL}/organization/assetmanager/${assetManagerId}/relation`, reqOptions)
        .then((res) => {
            console.log(res)
            toast.success('Asset Owners Mapped Successfully!')
            loadData()
        })
        .catch(err => {
            if(err.status == 200) {
                toast.success('Asset Owners Mapped Successfully!')
                loadData()
                console.error(err)
            }
        })
    }

    const handleCancel = () => {
        const userType = sessionStorage.getItem('userType')
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/edit-customer/${assetManagerId}`)
    }

    return (
        <Fragment>
            <LayoutLoader />
        <Breadcrumbs mainTitle="Asset Manager Management" parent="Organization" title="Asset Manager Management" />
        <Row>
            <Col lg={8}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={11}>
                                <div className="shadow_apply">
                                    {
                                        data && data.length > 0 && <DualListBox
                                        options={options}
                                        selected={selected}
                                        onChange={(value) => setSelected(value)}
                                        icons={{
                                            moveLeft: <span className="fa fa-chevron-left" />,
                                            moveRight: <span className="fa fa-chevron-right" />,
                                        }}
                                    />
                                    }
                                
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <div className="mt-40" >
                                    <Button color='primary' className='btn-font' onClick={handleSubmit} >Submit</Button>
                                    <Button color='secondary' className='btn-font ml-1' onClick={handleCancel} >Cancel</Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Fragment>
)
}


export default ASMMapping;