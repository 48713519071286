import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import CategoryForm from "../Forms/CategoryForm";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useParams } from "react-router";

const EditCategory = () => {
    const [data, setData] = useState();
    const { id } = useParams();
    
    const loadCreditRateData = async () => {
        await fetchWrapper(`${API_URL}/projectcategoryrates/${id}`)
        .then((res) => {
            setData(res.json)
        }, (err) => console.log(err))
    } 

    useEffect(()=> {
        loadCreditRateData();
    }, [])

    return (
        <Fragment>
            <Breadcrumbs parent="Category" title="Edit" mainTitle='Edit Category' />
            <CategoryForm action={'Edit'}  navigateUrl={`${process.env.PUBLIC_URL}/admin/credit-rates`} preData={data}/>
        </Fragment>
    )
}

export default EditCategory;