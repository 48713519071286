import React, { Fragment, useEffect, useState, useRef } from 'react';
import ReactDatasheet from 'react-datasheet';
import { ValueViewer } from './TargetData';

const DataSheet = ({ dataRef, dataGrid, onCellChanged }) => {
    return (
        <div className='table-container'>
            <ReactDatasheet
                ref={dataRef}
                data={dataGrid}
                valueRenderer={(cell) => cell?.value}
                valueViewer={ValueViewer}
                onCellsChanged={(changes) => onCellChanged(changes)}
            />
        </div>

    )
}

export default DataSheet;