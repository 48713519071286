import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { countries } from "../../Data/CountryList/Defaultdata";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ButtonLoader from '../ButtonLoader';

const UserForm = ({ title, action, navigateUrl, cancel, preData, setAdd }) => {
    const [stateList, setStateList] = useState([]);
    const [country, setCountry] = useState();
    const [data, setData] = useState(action === 'Edit' ? preData : '');
    const [loading, setLoading] = useState(false);
    const { register, formState: { errors }, reset, getValues, handleSubmit } = useForm({ defaultValues: data });
    const countryChangeEvent = (item, countryList) => {
        const selectedItem = countryList.find((countryItem) => countryItem.country === item);
        setCountry(item);
        setStateList(selectedItem.states);
    }

    const defaultUser = {
        ASO_ADMIN: 'ASO_USER',
        ASR_ADMIN: 'ASR_USER',
        PRP_ADMIN: 'PRP_USER'
    }

    const navigate = useNavigate();

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        reset(preData)
    }, [preData])


    const onSubmit = async (data) => {
        setLoading(true)
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        try {
            if (action === 'Add') {
                data.persona_type = defaultUser[sessionStorage.getItem('userType')]
                url = `${API_URL}/users`
                message = 'User Created Successfully!'
            }
            if (action === 'Edit') {
                url = `${API_URL}/users/${data.id}`
                message = 'User Updated Successfully!'
            }
            const options = {
                method: method,
                body: data
            }
            await fetchWrapper(url, options)
                .then((res) => {
                    if (res.ok) {
                        toast.success(message)
                        setAdd(true)
                        setLoading(false)
                        action === 'Add' && cancel()
                        action === 'Edit' && navigate(navigateUrl)
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    if (err.status == 409) {
                        toast.error('User Already Exist..')
                    }
                })

        } catch (error) {

        }
    }

    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    {title && <H3 attrH3={{ className: 'mtop-2 ml-15' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 mb-0 bs-none'}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg='6'>
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup style={{ marginBottom: "8px" }}>
                                                <Label for="exampleSelect">First Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="first_name"
                                                    type="text"
                                                    defaultValue={data?.first_name || ''}
                                                    {...register('first_name', { required: true })}
                                                />
                                                {console.log(errors)}
                                                <span className="text-danger">{errors.first_name && 'First Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label for="exampleEmail">  Last Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="last_name"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                    defaultValue={data?.last_name || ''}
                                                    {...register('last_name', { required: true })}
                                                />
                                                <span className="text-danger">{errors.last_name && 'Last Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="exampleSelect">Email {action === 'Add' && <span className='required'>*</span>}</Label>
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={action === 'Edit' ? true : false}
                                                    defaultValue={data?.first_name || ''}
                                                    {...register('email', { required: true,  pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                />
                                                <span className="text-danger">{errors.email?.type === 'required' && 'email is Required'}</span>
                                                <span className="text-danger">{errors.email?.type === 'pattern' && 'Invalid Email'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label for="exampleEmail">  Phone <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="phone"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                    defaultValue={data?.last_name || ''}
                                                    {...register('phone', { required: true, pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/  })}
                                                />
                                                <span className="text-danger">{errors.phone?.type === 'required' && 'Phone is Required'}</span>
                                                <span className="text-danger">{errors.phone?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-flex" >
                                        {
                                            loading ? <ButtonLoader color="primary" loaderText={ action === "Add" ? 'Adding' : 'Updating' } /> : 
                                            <Button color="primary" >{action === "Add" ? action : 'Update'}</Button>
                                        }
                                        
                                        <Link to={navigateUrl}>
                                            <Button color="secondary" className="ml-1" onClick={cancel}> Cancel</Button>
                                        </Link>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default UserForm;