import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, FormGroup, Label, Button } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import moment from "moment";
import { typeOfCategories } from './ghgHelper';


const EvidenceUploadModal = ({ value, setOpenModal, emissionId, setUpload, type }) => {
    const [open, setOpen] = useState(value);
    const [loading, setLoading] = useState(false);
    const [processStatus, setProcessStatus] = useState(false);

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const handleChange = async (files) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken')
        let data = new FormData();  
        files?.map(file => {
            console.log(file)
            data.append('files', file?.file);
        })
        data.append('emissionDatasetId',emissionId );
        data.append('category', typeOfCategories[type])
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${authToken}`
            },
            body: data
        }
        await fetchWrapper(`${API_URL}/document/uploadAttachments`, options)
            .then((res) => {
                console.log(res)
                if (res.ok) {
                    setLoading(false)
                    setUpload(true)
                    toast.success('Document Uploaded successfully!')
                    onClose()
                }
            })
            .catch((error) => {
                setLoading(false)
                console.error(error)
                if (error.status == 412) {
                    toast.error('File with the same name already exists')
                }
                if (error.status === 200) {
                    setLoading(false)
                    setUpload(true)
                    toast.success('Document Uploaded successfully!')
                    onClose()
                }
            })
    };


    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color mb-0' }}>Evidence Files Upload</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='12'>
                            {loading ? <div className="text-center loading-text">Loading....</div> : <></>}
                            <div style={{ opacity: loading ? '0.1' : '1' }}>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    // onChangeStatus={async () => {
                                    //     const result = await trigger();
                                    // }}
                                    onSubmit={handleChange}
                                    accept={'.xlsx,.xls, .pdf, .csv, .xlsm, .kml, .jpeg, .png, .jpg'}
                                    maxFiles={5}
                                    multiple={true}
                                    maxSizeBytes={4194304}
                                    inputContent="Drag and Drop Files"
                                    submitButtonDisabled={processStatus}
                                />
                            </div>
                            <p className='no-wrap mtop-1'>[Note:Supported formats xlsx,xls,PDF,Doc,jpeg,png and file size should be 4MB per file]</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='3'>
                            <Button color="secondary" className="mtop-1" onClick={onClose}>Cancel</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default EvidenceUploadModal;